import { cloneDeep } from 'lodash';
import { userDefault, IUser } from 'root/models';
import { IPaginationState, PaginationState } from 'root/store';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeUser } from './types';

// export interface IUserState {
//   loading: boolean;
// }
export interface IUserState extends ICRUDState<IUser> {
  loading: boolean;
  detail: IUser;
}

export const defaultState: IUserState = {
  ...CRUDState(),
  detail: userDefault(),
  loading: false
};

export const mutations = {
  ...crudMutations('user'),
  [MutationTypeUser.UserLoading](state: IUserState) {
    state.loading = true;
  },
  [MutationTypeUser.UserLoaded](state: IUserState) {
    state.loading = false;
  },
  [MutationTypeUser.UserResetStore](state: IUserState) {
    state.data = [];
    state.pagination = PaginationState({size: 5});
  },
  [MutationTypeUser.SetUserDetail](state: IUserState, payload) {
    state.detail = payload;
  },
  [MutationTypeUser.ClearUserDetail](state: IUserState) {
    state.detail = userDefault();
  },
  [MutationTypeUser.SetUserPaginationSort](state: IUserState) {
    state.pagination = {
      page: 1,
      size: 5
    };
  },
  [MutationTypeUser.SetUserPagination](state: IUserState, payload: IPaginationState) {
    const pagination = cloneDeep(state.pagination);

    state.pagination = {
      ...pagination,
      ...payload
    };
  }
};
