import { IUser, IUserRegisterForm } from 'root/models';
import { IHttpServiceClientOptions } from '../Core';

export interface IProfileHttpService {
  getProfile(): Promise<IUser>;
  updateProfile(form: IUserRegisterForm): Promise<IUser>;
  logOut(): Promise<any>;
  register(form: IUserRegisterForm): Promise<IUser>;
  // updateUserNotificationSetting(form: IUserNotificationSettingForm): Promise<any>;
}

export function ProfileHttpService(options: IHttpServiceClientOptions): IProfileHttpService {

  const baseUrl = '/me',
    url = options.httpService.getUrl('/admin/profile', options.mock),
    urlLogout = options.httpService.getUrl(`${baseUrl}/logout`, options.mock);
    // urlUserNotificationSetting = options.httpService.getUrl(`${baseUrl}/notification`, options.mock);

  function register(form: IUserRegisterForm): Promise<IUser> {
    const registerPath = options.httpService.getUrl('/admin/profile');

    return options.httpService.post(registerPath, form);
  }

  function logOut(): Promise<any> {
    return options.httpService.put(urlLogout, null);
  }

  function getProfile(): Promise<IUser> {
    const profilePath = options.httpService.getUrl('/admin/profile');

    return options.httpService.get(profilePath);
  }

  function updateProfile(form: IUserRegisterForm): Promise<IUser> {
    return options.httpService.put(url, form);
  }

  // function updateUserNotificationSetting(form: IUserNotificationSettingForm): Promise<any> {
  //   return options.httpService.put(urlUserNotificationSetting, form);
  // }

  return {
    getProfile,
    updateProfile,
    logOut,
    register
    // updateUserNotificationSetting
  };
}
