import { ruleEmail, ruleRequired } from 'root/helpers';
import { CMAAuth, IFormLogin } from 'root/services';
import Vue from 'vue';
import Component from 'vue-class-component';

import { FooterGuest } from 'root/components/FooterGuest';
import { IUser, RouterDictionaryName } from 'root/models';
import { ActionType, IState, Layout, MutationType } from 'root/store';
import { Route } from 'vue-router';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'footer-guest': FooterGuest
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser
    })
  },
  beforeRouteLeave: async (to: Route, _, next) => {
    const isRequired = !to.meta.isGuest
      && (to.name !== RouterDictionaryName.SignUp.toString()
          && to.name !== RouterDictionaryName.ForgotPassword.toString()),
      isAuth = await CMAAuth.currentUser();

    if (isRequired && !isAuth) {
      return next('/login');
    }

    return next();
  }
})

export class LoginContainer extends Vue {

  public authUser: IUser;
  public email: string = '';
  public password: string = '';
  public loading: boolean = false;
  public form: IFormLogin = {
    email: '',
    password: ''
  };
  public $refs: {
    loginForm: any
  };

  public showPassword: boolean = false;
  public errorMessage: string = null;
  public valid: boolean = false;

  public get rules() {
    return {
      required: ruleRequired(),
      email: ruleEmail()
    };
  }

  public submit() {
    this.$refs.loginForm.validate(async (valid) => {
      if (valid) {
        this.loading = true;
        CMAAuth.signIn({
          email: this.form.email,
          password: this.form.password
        }).then((user) => {
          this.loading = false;
          this.$store.dispatch(ActionType.ChangeStateAuth, { user, router: this.$router });
        }).catch((error) => {
          this.loading = false;
          this.$store.dispatch(ActionType.CatchException, error);
        });
      }

      return;
    });

  }

  public mounted() {
    this.$nextTick(() => {
      if (this.authUser && this.authUser.id) {
        this.$store.commit(MutationType.SetLayout, Layout.Home);
        this.$router.replace('/');

      }
    });
  }
}
