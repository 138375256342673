export enum ActionTypePlan {
  PlanCreate = 'planCreate',
  PlanUpdate = 'planUpdate',
  PlanFilter = 'planFilter',
  PlanFilterNoCache = 'planFilterNoCache',
  PlanFindById = 'planFindById',

  planFetchMany = 'planFetchMany',
}

export enum MutationTypePlan {
  PlanLoading = 'planLoading',
  PlanLoaded = 'planLoaded',
  PlanPaginationChange = 'planPaginationChange',
  PlanPaginationReset = 'planPaginationReset',
  PlanRefreshModels = 'planRefreshModels'
}

export enum GetterTypePlan {
  GetPlanDetail = 'getPlanDetail'
}
