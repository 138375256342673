import { TabPane } from 'element-ui';
import { CMAHeader } from 'root/components';
import { IBreadcrumb, IProduct } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ProductDetailView } from '../Components/ProductDetailView';
import { TransactionHistory } from '../Components/TransactionHistory';
import { ActionTypeProduct } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'product-detail-view': ProductDetailView,
    'transaction-history': TransactionHistory
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.product.data
    })
  }
})

export class ProductDetail extends Vue {
  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Redemption',
      path: '/redemption'
    },
    {
      label: 'Product List',
      path: '/redemption/product-list'
    },
    {
      label: 'View',
      path: '',
      current: true
    }
  ];
  public activeTab: ProductTab = ProductTab.Transaction;

  public get productid(): string {
    return this.$route.params.productId;
  }

  public get productDetail(): IProduct {
    return this.$store.getters.getProductById(this.productid);
  }
  public changeTab(tab: TabPane) {
    if (tab.name === ProductTab.Transaction) {
      this.$store.dispatch(ActionTypeProduct.TransactionListOfProductFilter, {
        id: this.productid
      });
    }

    return;
  }
  public clickEdit() {
    this.$router.push(`/redemption/${this.productid}/edit`);
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeProduct.ProductFindById, {
        id: this.productid
      });
      this.$store.dispatch(ActionTypeProduct.TransactionListOfProductFilter, {
        id: this.productid
      });
    });
  }
}

const enum ProductTab {
  Transaction = 'transaction',
  Detail = 'detail'
}
