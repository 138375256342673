import Vue from 'vue';
import Vuex from 'vuex';

import { actions } from './actions';
import { getters } from './getters';
import { globalState, mutations } from './mutations';

// module
import * as assignment from 'root/pages/Assignment/Store';
import * as dashboard from 'root/pages/Dashboard/Store';
import * as examination from 'root/pages/Examination/Store';
import * as exchange from 'root/pages/ExchangeRate/Store';
import * as library from 'root/pages/Library/Store';
import * as notification from 'root/pages/Notification/Store';
import * as product from 'root/pages/Product/Store';
import * as report from 'root/pages/Reports/Store';
import * as plan from 'root/pages/Subscription/PlanStore';
import * as subscription from 'root/pages/Subscription/Store';
import * as user from 'root/pages/Users/Store';

export * from './types';

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  getters,
  mutations,
  state: {
    global: globalState,
    route: {
      params: {},
      path: '',
      query: {}
    }
  },
  modules: {
    user,
    library,
    assignment,
    product,
    notification,
    exchange,
    plan,
    subscription,
    dashboard,
    report,
    examination
  }
});

export * from './types';
