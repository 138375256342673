/* tslint:disable ordered-imports */
import Amplify from '@aws-amplify/core';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import Vue from 'vue';
import VueLogger from 'vuejs-logger';
import { mapState } from 'vuex';
import { sync } from 'vuex-router-sync';
import VueLazyload, { VueLazyloadOptions } from 'vue-lazyload';
import { i18n } from './locales';
import {
  HomeLayout,
  LoginLayout
} from './layouts';

import {
  SplashScreenComponent, TopAlertComponent,
} from './components';

import { configLogger } from './helpers';
import './main.scss';
import router from './router';
import store, { ActionType, IState } from './store';

import { cmaHttpClient } from './api/http';
import { CMAAuth } from './services';
Amplify.configure({
  Auth: {
    identityPoolId: process.env.AWS_IDENTITY_POOL_ID,
    region: process.env.AWS_REGION,
    userPoolId: process.env.USER_POOL_ID,
    userPoolWebClientId: process.env.CLIENT_ID
  },
  Storage: {
    bucket: process.env.AWS_BUCKET_NAME,
    region: process.env.AWS_REGION,
  },
});

sync(store, router);

Vue.use(ElementUI, { locale });

const optionLazy: VueLazyloadOptions = {
  listenEvents: ['scroll'],
  observer: true,
  dispatchEvent: true,
  silent: true,
  preLoad: 0.1,
  error: '/assets/img/image-placeholder.svg',
  loading: '/assets/img/image-placeholder.svg'
};
Vue.use(VueLazyload, optionLazy);

Vue.use(<any> VueLogger, configLogger());

// tslint:disable
new Vue({
  el: '#app',
  router,
  store,
  i18n: i18n(),
  async created() {
    this.$store.dispatch(ActionType.InitializeSettings);
    this.$root._i18n.locale = 'en';

    const data = await cmaHttpClient.global.getConfiguration();
    // .then(data => {
    this.$store.dispatch(ActionType.SetGlobalConfig, data);
    // });

    CMAAuth.currentSession()
      .then((user) => {
        this.$store.dispatch(ActionType.ChangeStateAuth, { router: this.$router, user });
      })
      .catch((error) => {
        this.$store.dispatch(ActionType.ChangeStateAuth, { router: this.$router, user: null });
      })

  },
  computed: {
    ...mapState({
      layout: (state: IState) => {
        return state.global.layout;
      },
      isLoading: (state: IState) => {
        return state.global.isLoading;
      },
      topAlertOpened: (state: IState) => {
        const topAlert = state.global.topAlert;
        if (topAlert.opened) {
          return topAlert;
        }
        return false;
      }
    })
  },
  components: {
    'home-layout': HomeLayout,
    'login-layout': LoginLayout,
    'splash-screen': SplashScreenComponent,
    'top-alert': TopAlertComponent
  }
});