import { IUser } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IReportHttpService
  extends ICRUDHttpService<any> {
  filterLeaderBoard(paramFilter): Promise<IUser[]>;
  filterTransactions(bodyFilter, paramFilter): Promise<any>;
  filterProducts(bodyFilter, paramFilter): Promise<any>;
  filterSubscription(bodyFilter, paramFilter): Promise<any>;
  filterSmarterGame(bodyFilter, paramFilter): Promise<any>;
  filterIndividualGame(bodyFilter, paramFilter): Promise<any>;
  filterStatisticUser(bodyFilter, paramFilter): Promise<any>;
  filterAssignment(bodyFilter, paramFilter): Promise<any>;
  filterAssignmentIndividual(bodyFilter, paramFilter): Promise<any>;
}

export function ReportHttpService(options: IHttpServiceClientOptions): IReportHttpService {

  const basePath = '/admin/reports';

  function filterLeaderBoard(paramFilter): Promise<IUser[]> {
    const userPath = options.httpService.getUrl(`${basePath}/coolest`);

    return options.httpService.post(userPath, {
      ...paramFilter
    });
  }

  function filterTransactions(bodyFilter, paramFilter): Promise<any> {
    const { progress, centre, text, textProduct, productId } = paramFilter;
    // tslint:disable-next-line:max-line-length
    const transactionPath = options.httpService.getUrl(`${basePath}/redemptions/transactions?${progress ? `progress=${progress}` : ''}${centre ? `&centre=${centre}` : ''}${productId ? `&productId=${productId}` : ''}${text ? `&text=${text}` : ''}${textProduct ? `&textSearchProduct=${textProduct}` : ''}`);

    return options.httpService.post(transactionPath, {
      ...bodyFilter
    });
  }
  function filterProducts(bodyFilter, paramFilter): Promise<any> {
    const { status, centre, text, id } = paramFilter;
    // tslint:disable-next-line:max-line-length
    const transactionPath = options.httpService.getUrl(`${basePath}/products?${status ? `status=${status}` : ''}${centre ? `&centre=${centre}` : ''}${id ? `&id=${id}` : ''}${text ? `&text=${text}` : ''}`);

    return options.httpService.post(transactionPath, {
      ...bodyFilter
    });
  }

  function filterSubscription(bodyFilter, paramFilter): Promise<any> {
    const { centre, planId } = paramFilter;
    // tslint:disable-next-line:max-line-length
    const transactionPath = options.httpService.getUrl(`${basePath}/subscriptions?${centre ? `centre=${centre}` : ''}${planId ? `&planId=${planId}` : ''}`);

    return options.httpService.post(transactionPath, {
      ...bodyFilter
    });
  }
  function filterSmarterGame(bodyFilter, paramFilter): Promise<any> {
    const { centre, level } = paramFilter;
    // tslint:disable-next-line:max-line-length
    const transactionPath = options.httpService.getUrl(`${basePath}/games/smarter?${centre ? `centre=${centre}` : ''}${level ? `&level=${level}` : ''}`);

    return options.httpService.post(transactionPath, {
      ...bodyFilter
    });
  }
  function filterIndividualGame(bodyFilter, paramFilter): Promise<any> {
    const { typeGame, text } = paramFilter;
    // tslint:disable-next-line:max-line-length
    const transactionPath = options.httpService.getUrl(`${basePath}/games/individual?${text ? `text=${text}` : ''}${typeGame ? `&typeGame=${typeGame}` : ''}`);

    return options.httpService.post(transactionPath, {
      ...bodyFilter
    });
  }
  function filterAssignmentIndividual(bodyFilter, paramFilter): Promise<any> {
    const { status, text } = paramFilter;
    // tslint:disable-next-line:max-line-length
    const transactionPath = options.httpService.getUrl(`${basePath}/assignments/individual?${text ? `text=${text}` : ''}${status ? `&status=${status}` : ''}`);

    return options.httpService.post(transactionPath, {
      ...bodyFilter
    });
  }
  function filterStatisticUser(bodyFilter, paramFilter): Promise<any> {
    const { centre } = paramFilter;
    // tslint:disable-next-line:max-line-length
    const transactionPath = options.httpService.getUrl(`${basePath}/users?${centre ? `centre=${centre}` : ''}`);

    return options.httpService.post(transactionPath, {
      ...bodyFilter
    });
  }
  function filterAssignment(bodyFilter, paramFilter): Promise<any> {
    const { target } = paramFilter;
    // tslint:disable-next-line:max-line-length
    const transactionPath = options.httpService.getUrl(`${basePath}/assignments?${target ? `target=${target}` : ''}`);

    return options.httpService.post(transactionPath, {
      ...bodyFilter
    });
  }

  return {
    ...CRUDHttpService<any>({
      ...options,
      basePath
    }),
    filterLeaderBoard,
    filterTransactions,
    filterProducts,
    filterSubscription,
    filterSmarterGame,
    filterIndividualGame,
    filterStatisticUser,
    filterAssignment,
    filterAssignmentIndividual
  };
}
