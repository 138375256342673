import { IExchangeRate, IExchangeRateForm } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IExchangeRateHttpService
  extends ICRUDHttpService<IExchangeRate> {
  getData(params): Promise<any>;
  updateRate(form: IExchangeRateForm): Promise<any>;
  getCurrentExchangeRate(): Promise<IExchangeRate>;
}

export function ExchangeRateHttpService(options: IHttpServiceClientOptions): IExchangeRateHttpService {

  const basePath = '/admin/exchange-rates';
  const baseURL = options.httpService.getUrl(basePath, options.mock);

  function getData(params): Promise<any> {
    return options.httpService.get(baseURL, {
      params
    });
  }

  function updateRate(form: IExchangeRateForm): Promise<any> {
    return options.httpService.post(baseURL, form);
  }

  function getCurrentExchangeRate(): Promise<IExchangeRate> {
    const currentURL = options.httpService.getUrl(`${basePath}/current`, options.mock);

    return options.httpService.get(currentURL);
  }

  return {
    ...CRUDHttpService<IExchangeRate>({
      ...options,
      basePath
    }),
    getData,
    updateRate,
    getCurrentExchangeRate
  };
}
