import { cloneDeep } from 'lodash';
import { INotification } from 'root/models';
import { IPaginationState } from 'root/store';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeNotification } from './types';

export interface INotificationState extends ICRUDState<INotification> {
  loading: boolean;
}

export const defaultState: INotificationState = {
  ...CRUDState(),
  loading: false
};

export const mutations = {
  ...crudMutations('notification'),
  [MutationTypeNotification.NotificationLoading](state: INotificationState) {
    state.loading = true;
  },
  [MutationTypeNotification.NotificationLoaded](state: INotificationState) {
    state.loading = false;
  },
  [MutationTypeNotification.SetNotificationPagination](state: INotificationState, payload: IPaginationState) {
    const pagination = cloneDeep(state.pagination);
    state.pagination = {
      ...pagination,
      ...payload
    };
  },
};
