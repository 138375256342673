import { Form } from 'element-ui';
import { cloneDeep, omit } from 'lodash';
import * as moment from 'moment';
import { CMAHeader } from 'root/components';
import { ruleMinValue, ruleRequired } from 'root/helpers';
import { planForm, AccessibilityType, IBreadcrumb, IPlan, PlanStatus } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { SubscriptionRouterPath } from '..';
import { ActionTypePlan, GetterTypePlan } from '../PlanStore/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader
  },
  computed: {
    ...mapGetters(['planStatus', 'accessibilityList']),
    ...mapState({
      loading: (state: IState) => state.plan.loading
    })
  }
})

export class PlanManagementEditor extends Vue {

  public get planId() {
    return this.$route.params.planId;
  }
  public get isUpdating(): boolean {
    return !!this.planId;
  }
  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        path: '/'
      },
      {
        label: 'Subscription',
        path: SubscriptionRouterPath.Default,
      },
      {
        label: 'Plan Management',
        path: SubscriptionRouterPath.PlanManagement,
      },
      {
        label: this.isUpdating ? 'Edit' : 'Add New',
        path: '',
        current: true
      }
    ];
  }

  public get showDatePicker(): boolean {
    return this.form.accessibility === AccessibilityType.Limited;
  }

  public get type(): string {
    if (this.isUpdating) {
      return this.form.type;
    }

    return 'paid';
  }
  public $refs: {
    form: Form
  };
  public rules: any = {
    required: ruleRequired(),
    minValue: ruleMinValue(0, {
      text: `Value must be more than 0`
    })
  };

  public form: IPlan = planForm();

  public startDateOptions = {
    disabledDate: (time) => {
      return time.getTime() > moment(this.form.to).add(-1, 'days');
    }
  };
  public endDateOptions = {
    disabledDate: (time) => {
      return time.getTime() < moment(this.form.from).add(1, 'days');
    }
  };

  public changeAccessibility(newValue) {
    this.form.accessibility = newValue;
    this.form.from = null;
    this.form.to = null;
    this.$refs.form.clearValidate(['from', 'to']);
  }
  public cancel() {
    this.$router.push(SubscriptionRouterPath.Default);
  }
  public submit() {
    this.$refs.form.validate((valid) => {
      if (valid) {
        let form = <any> cloneDeep(this.form);
        if (form.accessibility === AccessibilityType.Permanent) {
          form = omit(form, ['from', 'to']);
        } else {
          form = {
            ...form,
            from: moment(form.from).utc().format('YYYY-MM-DD HH:mm:ss'),
            to: moment(form.to).utc().format('YYYY-MM-DD HH:mm:ss')
          };
        }
        if (this.isUpdating) { // update plan
          this.$store.dispatch(ActionTypePlan.PlanUpdate, {
            id: this.planId,
            form: omit(form, ['id', 'type', 'status', 'duration', 'price', 'accessibility', 'from', 'to']),
            onSuccess: () => {
              this.$message.success(this.$t('update.successfully').toString());
              this.$router.push(SubscriptionRouterPath.Default);
            }
          });
        } else { // add new plan
          this.$store.dispatch(ActionTypePlan.PlanCreate, {
            form,
            onSuccess: () => {
              this.$message.success('Add new plan successfuly!');
              this.$router.push(SubscriptionRouterPath.Default);
            }
          });
        }
      }

      return;
    });
  }

  protected mounted() {
    this.$nextTick(async () => {
      if (this.isUpdating) {
        await this.$store.dispatch(ActionTypePlan.PlanFindById, {id: this.planId});
        const plan: IPlan = this.$store.getters[GetterTypePlan.GetPlanDetail](this.planId);

        if (!this.isAvailabelEdit(plan)) {
          this.$router.replace(SubscriptionRouterPath.Default);
          this.$message(this.$t('permission_denied').toString());

          return;
        }
        this.form = this.$store.getters[GetterTypePlan.GetPlanDetail](this.planId);
      }

      return;
    });
  }
  private isAvailabelEdit(plan: IPlan) {
    return this.isUpdating && plan.status === PlanStatus.Active;
  }
}
