import { IUser, IUserRegisterForm } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IUserHttpService
  extends ICRUDHttpService<IUser, IUserRegisterForm> {
  changePassword(id: number, password: string): Promise<any>;
  softDelete(id: number): Promise<any>;
}

export function UserHttpService(options: IHttpServiceClientOptions): IUserHttpService {

  const basePath = '/admin/users';
  // const baseURL = options.httpService.getUrl(basePath, options.mock);
  // const userPickerUrl = options.httpService.getUrl(`${basePath}/picker`, options.mock);

  function changePassword(id: number, password: string): Promise<any> {
    const baseURL = options.httpService.getUrl(basePath, true);

    return options.httpService.put(`${baseURL}/${id}/password`, {
      password
    });
  }

  function softDelete(id: number): Promise<any> {
    const baseURL = options.httpService.getUrl(`${basePath}/${id}/soft-delete`);

    return options.httpService.del(baseURL);
  }

  // function getUserDetailById(id: number): Promise<IUser> {
  //   return options.httpService.get(`${baseURL}/${id}`);
  // }

  return {
    ...CRUDHttpService<IUser, IUserRegisterForm>({
      ...options,
      basePath
    }),
    changePassword,
    softDelete
  };
}
