import { Gender } from './Common';
import { ILibrary, MethodType, Operator } from './Library';
import { ISimpleUser } from './User';

export enum AssignmentPushStatus {
  Sent = 'sent',
  New = 'new'
}

export enum AssignmentStatus {
  New = 'new',
  Completed = 'completed',
  Overdue = 'overdue'
}

export interface IAssignment {
  id: number;
  owner: ISimpleUser;
  createdAt: Date;
  library: ILibrary;
  cases?: ICase[];
  isStarted?: boolean;
  target?: AssignmentTarget;
  assigneeIds?: number[];
}

export interface IAssignmentForm {
  target: AssignmentTarget;
  grade: number;
  method: MethodType;
  title: string;
  desc: string;
  startTime: Date;
  endTime: Date;
  assigneeIds: number[];
  assignees?: ISimpleUser[];
  centre?: string[];
}

export interface IAssignmentFilterQuestion {
  operators: Operator;
  numbers: number;
  mixed: boolean;
  digits: number;
  digitOne: number;
  digitTwo: number;
  totalQuestion: number;
  method: MethodType;
  grade: number;
}

export interface ICase {
  operators: Operator;
  numbers: number;
  digits: number | string;
  digitOne: number;
  digitTwo: number;
  totalQuestion: number;
  status?: QuestionStatus;
  isCurrency?: boolean;
  caseId?: number;
  decimal: any;
  maximumQuestion: number;
}

export interface IAssignmentCase {
  maximumQuestion: number;
  id?: number;
  config?: IAssignmentConfig;
  configCase: IAssignmentConfig;
  questions: IQuestion[];
  status?: QuestionStatus;
}

export interface IAssignmentConfig {
  decimal: any;
  mixedDecimal: any;
  operators: Operator[];
  mixed: boolean;
  numbers: number;
  digitCfg: IDigitCfg[];
  isCurrency?: boolean;
}

export interface IDigitCfg {
  digits: number;
  numbers: number;
}

export interface IQuestion {
  config: IAssignmentConfig;
  expression: string;
  id?: number;
  libraryId?: number;
  result?: string;
  status?: QuestionStatus;
}

export const defaultAssignmentForm = (): IAssignmentForm => {
  return {
    desc: '',
    endTime: null,
    startTime: null,
    title: '',
    grade: null,
    method: MethodType.Empty,
    target: null,
    assigneeIds: [],
    assignees: [],
    centre: null
  };
};

export enum QuestionStatus {
  PendingDelete = 'pending_delete',
  Deleted = 'deleted'
}

export enum AssignmentTarget {
  Single = 'single',
  Group = 'group',
  Broadcast = 'broadcast',
  Centre = 'centre'
}

export interface IAssignmentTargetFilterForm {
  centre: Centre;
  gender: Gender;
  age: number;
  text: string;
  target: AssignmentTarget;
}

export const assignmentTargetFilterForm = (): IAssignmentTargetFilterForm => {
  return {
    age: null,
    centre: null,
    gender: null,
    text: '',
    target: null
  };
};

export enum Centre {
  Queensland = 'queensland',
  Woodsland = 'woodsland',
  BukitBatok = 'bukit_batok',
  Empty = ''
}

export interface IAssignmentParamsFilter {
  target?: AssignmentTarget;
  method?: MethodType;
  grade?: number;
  status?: string;
  isStarted?: AssignmentPushStatus;
  text?: string;
  centre?: Centre;
}

export interface ICentre {
  value: string;
  name: string;
}

export interface IAssignmentWarning {
  message: string;
  isWarning: boolean;
  index: number;
}

export interface IAssignmentTemplateForm {
  title: string;
  method: MethodType;
  questionIds?: number[];
  grade?: number;
  desc?: string;
}
export const assignmentTemplateForm = (): IAssignmentTemplateForm => {
  return {
    title: '',
    method: null,
    questionIds: []
  };
};

export interface IAssignmentStatistic {
  countCompleted: number;
  countNew: number;
  countOverdue: number;
}
