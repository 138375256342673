import { IProduct, IRedemption, ISubscription, IUser } from 'root/models';
import { CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeReport } from './types';

export interface IReportState extends ICRUDState<any> {
  loading: boolean;
  leaderBoard: IUser[];
  leaderBoardLoading: boolean;

  transactions: IRedemption[];
  transactionsLoading: boolean;

  products: IProduct[];
  productsLoading: boolean;

  subscription: ISubscription[];
  subscriptionLoading: boolean;

  games: any[];
  gameLoading: boolean;

  gameIndividuals: any[];
  gameIndividualLoading: boolean;

  userStatistics: any[];
  userStatisticLoading: boolean;

  assignments: any[];
  assignmentLoading: boolean;

  assignmentIndividual: any[];
  assignmentIndividualLoading: boolean;
}

export const defaultState: IReportState = {
  ...CRUDState(),
  loading: false,
  leaderBoard: [],
  leaderBoardLoading: false,

  transactions: [],
  transactionsLoading: false,

  products: [],
  productsLoading: false,

  subscription: [],
  subscriptionLoading: false,

  gameLoading: false,
  games: [],

  gameIndividualLoading: false,
  gameIndividuals: [],

  userStatistics: [],
  userStatisticLoading: false,

  assignments: [],
  assignmentLoading: false,

  assignmentIndividual: [],
  assignmentIndividualLoading: false
};

export const mutations = {
  [MutationTypeReport.ReportLeaderBoardLoading](state: IReportState) {
    state.leaderBoardLoading = true;
  },
  [MutationTypeReport.ReportLeaderBoardLoaded](state: IReportState) {
    state.leaderBoardLoading = false;
  },
  [MutationTypeReport.ReportLeaderBoardSetData](state: IReportState, data) {
    state.leaderBoard = data;
  },
  [MutationTypeReport.ReportTransactionLoading](state: IReportState) {
    state.transactionsLoading = true;
  },
  [MutationTypeReport.ReportTransactionLoaded](state: IReportState) {
    state.transactionsLoading = false;
  },
  [MutationTypeReport.ReportTransactionSetData](state: IReportState, data) {
    state.transactions = data;
  },

  [MutationTypeReport.ReportProductsLoading](state: IReportState) {
    state.productsLoading = true;
  },
  [MutationTypeReport.ReportProductsLoaded](state: IReportState) {
    state.productsLoading = false;
  },
  [MutationTypeReport.ReportProductsSetData](state: IReportState, data) {
    state.products = data;
  },

  [MutationTypeReport.ReportSubscriptionLoading](state: IReportState) {
    state.subscriptionLoading = true;
  },
  [MutationTypeReport.ReportSubscriptionLoaded](state: IReportState) {
    state.subscriptionLoading = false;
  },
  [MutationTypeReport.ReportSubscriptionSetData](state: IReportState, data) {
    state.subscription = data;
  },

  [MutationTypeReport.ReportGameLoading](state: IReportState) {
    state.gameLoading = true;
  },
  [MutationTypeReport.ReportGameLoaded](state: IReportState) {
    state.gameLoading = false;
  },
  [MutationTypeReport.ReportGameSetData](state: IReportState, data) {
    state.games = data;
  },

  [MutationTypeReport.ReportGameIndividualLoading](state: IReportState) {
    state.gameIndividualLoading = true;
  },
  [MutationTypeReport.ReportGameIndividualLoaded](state: IReportState) {
    state.gameIndividualLoading = false;
  },
  [MutationTypeReport.ReportGameIndividualSetData](state: IReportState, data) {
    state.gameIndividuals = data;
  },

  [MutationTypeReport.ReportUserStatisticLoading](state: IReportState) {
    state.userStatisticLoading = true;
  },
  [MutationTypeReport.ReportUserStatisticLoaded](state: IReportState) {
    state.userStatisticLoading = false;
  },
  [MutationTypeReport.ReportUserStatisticSetData](state: IReportState, data) {
    state.userStatistics = data;
  },

  [MutationTypeReport.ReportAssignmentLoading](state: IReportState) {
    state.assignmentLoading = true;
  },
  [MutationTypeReport.ReportAssignmentLoaded](state: IReportState) {
    state.assignmentLoading = false;
  },
  [MutationTypeReport.ReportAssignmentSetData](state: IReportState, data) {
    state.assignments = data;
  },

  [MutationTypeReport.ReportAssignmentIndividualLoading](state: IReportState) {
    state.assignmentIndividualLoading = true;
  },
  [MutationTypeReport.ReportAssignmentIndividualLoaded](state: IReportState) {
    state.assignmentIndividualLoading = false;
  },
  [MutationTypeReport.ReportAssignmentIndividualSetData](state: IReportState, data) {
    state.assignmentIndividual = data;
  },
};
