import * as js2excel from 'js2excel';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { CMAButtonFilter, CMAHeader, CMAListData, CMASelect } from 'root/components';
import { formatBirthday } from 'root/helpers';
import { i18n } from 'root/locales';
// import { generateUnitKey } from 'root/helpers';
import { AssignmentTarget, IBreadcrumb, ISubscription } from 'root/models';
// import { ActionTypePlan } from 'root/pages/Subscription/PlanStore/types';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ReportRouterPath } from '..';
import { ActionTypeReport, MutationTypeReport } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-list-data': CMAListData,
    'cma-btn-filter': CMAButtonFilter,
    'cma-header': CMAHeader,
    'cma-select': CMASelect
  },
  computed: {
    ...mapGetters(['targetOptions']),
    ...mapState({
      data: (state: IState) => state.report.assignments,
      loading: (state: IState) => state.report.assignmentLoading
    }),
    cloneTargetOptions() {
      const targetOptions = cloneDeep(this.targetOptions);
      targetOptions[0].label = this.$t('all');

      targetOptions.push({
        value: AssignmentTarget.Centre,
        label: this.$t(AssignmentTarget.Centre),
      });

      return targetOptions;
    },
    startDatePicker: {
      get() {
        return this.filterBody.startDate ? moment(this.filterBody.startDate).utc(true).tz(moment.tz.guess()) : null;
      },
      set(value) {
        this.filterBody.startDate = value;
      }
    },
    endDatePicker: {
      get() {
        return this.filterBody.endDate ? moment(this.filterBody.endDate).utc(true).tz(moment.tz.guess()) : null;
      },
      set(value) {
        this.filterBody.endDate = value;
      }
    }
  }
})

export class ReportAssignmentGeneral extends Vue {
  public filterBody = {
    startDate: null,
    endDate: null
  };
  public filterParams = {
    target: null
  };
  public filterEnable: boolean = false;
  public data: ISubscription[];

  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        path: '/'
      },
      {
        label: 'Report',
        path: '',
      },
      {
        label: 'Assignment',
        path: '',
        current: true
      }
    ];
  }

  public reportRedemptionNavigation: any[] = [
    {
      label: 'Assignment',
      path: ReportRouterPath.Assignment
    },
    {
      label: 'Individual',
      path: ReportRouterPath.AssignmentIndividual
    }
  ];

  public get columns() {
    return [
      {
        label: 'title',
        prop: 'title',
        width: 200,
      },
      {
        label: 'target',
        prop: 'target',
        // width: 170
      },
      {
        label: 'total target',
        prop: 'total',
        width: 100,
      },
      {
        prop: 'countCompleted',
        label: 'total status in completed',
        width: 100,
      },
      {
        prop: 'countOverdue',
        label: 'total status in overdue',
        width: 100,
      },
      {
        prop: 'countNew',
        label: 'total status in new',
        width: 100,
      },
      {
        prop: 'grade',
        label: 'grade',
        formatter: (model) => this.grade(model.library, model.library.method),
        width: 100,
      },
      {
        prop: 'method',
        label: 'method',
        formatter: (model) => i18n().t(model.library.method),
        width: 200,
      },
      {
        prop: 'totalQuestion',
        label: 'total question',
        width: 100,
      },
      {
        prop: 'totalPoint',
        label: 'total point',
      },
      {
        prop: 'startTime',
        label: 'Start time',
        formatter: (model: any) => formatBirthday(model.startTime),
        width: 200,
      },
      {
        prop: 'endTime',
        label: 'End time',
        formatter: (model: any) => formatBirthday(model.endTime),
        width: 200,
      },
      {
        prop: 'createdBy',
        label: 'Created by',
        formatter: (model: any) => model.owner.fullName,
        width: 200,
      },
      {
        prop: 'createdAt',
        label: 'Created time',
        formatter: (model: any) => formatBirthday(model.createdAt),
        width: 200,
      },
    ];
  }

  public grade(grade: any, key: string) {
    // const grade = <any> find(user.grade, (e: any) => e && e.type === key);

    if (!grade) {
      return '';
    }
    if (grade.grade === 0) {
      return 'DAN';
    }
    if (grade.grade === 14) {
      return 'Foundation';
    }

    if (key === 'ama') {
      return `${grade.grade.number} ${grade.grade.number > 1 ? 'numbers' : 'number'}
        ${grade.grade.digit} ${grade.grade.digit > 1 ? 'digits' : 'digit'}`;
    }

    return grade.grade;
  }
  public changeFilter(value, key) {
    this.filterParams[key] = value;
    this.filterEnable = true;
  }

  public exportToExcel() {
    const columns: any = cloneDeep(this.columns);

    const data = cloneDeep(this.data).map((redemption) => {
      const result: any = {};
      columns.forEach((column) => {
        result[column.label.toUpperCase()] = typeof column.formatter === 'function'
          ? column.formatter(redemption) : redemption[column.prop];
      });

      return result;
    });

    const startDate = moment(this.filterBody.startDate).utc(true).tz(moment.tz.guess()).format('DDMMYYYY'),
      endDate = moment(this.filterBody.endDate).utc(true).tz(moment.tz.guess()).format('DDMMYYYY');

    try {
      js2excel.default.json2excel({
        data,
        // tslint:disable-next-line:max-line-length
        name: `asm-general_${startDate}_${endDate}`
      });
    } catch (error) {
      console.error('export error', error);
    }
  }
  public applyFilter() {
    this.filterEnable = false;
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      // this.$store.dispatch(ActionTypePlan.planFetchMany);
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeReport.ReportAssignmentSetData, []);
    // this.$store.commit(MutationTypePlan.PlanRefreshModels, []);
  }

  private fetchData() {
    this.$store.commit(MutationTypeReport.ReportAssignmentSetData, []);
    this.$store.dispatch(ActionTypeReport.ReportAssignmentFilter, {
      filterBody: cloneDeep(this.filterBody),
      filterParams: this.filterParams
    });
  }
}
