import { stripObject } from '@hgiasac/helper';
import { cloneDeep, filter } from 'lodash';
import * as moment from 'moment';
import { CMAButtonFilter, CMAListData, CMASelect } from 'root/components';
import { formaterListCounter, fullNameUser } from 'root/helpers';
import { AssignmentStatus, AssignmentTarget, IAssignment, IPagingParams, UserRole } from 'root/models';
import { GetterTypeUser, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypeAssignment, MutationTypeAssignment } from '../../Store/types';
import { AssignmentDetailSingle } from '../AssignmentDetailSingle';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-select': CMASelect,
    'cma-list-data': CMAListData,
    'assignment-detail-single': AssignmentDetailSingle,
    'cma-btn-filter': CMAButtonFilter
  },
  computed: {
    ...mapGetters(['genderOptions', 'paginationOption', 'statusOptions', 'sortType']),
    ...mapState({
      detail: (state: IState) => state.assignment.detail,
      listCentre: (state: IState) => state.global.globalConfig.listCentre,
      data: (state: IState) => state.assignment.users.data,
      loading: (state: IState) => state.assignment.users.loading,
      pagination: (state: IState) => state.assignment.users.pagination,
      statistic: (state: IState) => state.assignment.statistic
    }),
    cloneCentreOptions() {
      const listCentre = cloneDeep(this.listCentre);

      return [
        {
          value: null,
          name: this.$t('all.centre')
        },
        ...listCentre
      ];
    },
    cloneGenderOptions() {
      const genderOptions = cloneDeep(this.genderOptions);

      return [
        {
          label: this.$t('all.gender'),
          value: null
        },
        ...genderOptions
      ];
    },
    actions() {
      return ['view'];
    }
  },
  watch: {
    paginationSize(newValue) {
      if (newValue) {
        this.$store.commit(MutationTypeAssignment.SetAssignmentUserPagination, {
          size: newValue,
          page: 1
        });
        this.fetchData();
      }

      return;
    },
    'orderForm.value'(newValue: any) {
      if (newValue) {
        this.$store.commit(MutationTypeAssignment.SetAssignmentUserPagination, {
          page: 1,
          order: newValue,
          descending: this.orderForm.desc
        });
        this.fetchData();
      }

      return;
    },
    'orderForm.desc'(newValue: any) {
      this.$store.commit(MutationTypeAssignment.SetAssignmentUserPagination, {
        page: 1,
        order: this.orderForm.value,
        descending: newValue
      });
      this.fetchData();
    }
  }
})

export class AssignmentDetailGroup extends Vue {
  public cloneGenderOptions: any;
  public cloneCentreOptions: any;
  public statusOptions: any;
  public data: any;
  public pagination: IPagingParams;
  public detail: IAssignment;
  public filterEnable: boolean = false;

  public userId: number = null;

  public paginationSize: number = 20;

  public visibleAssignmentSingle: boolean = false;
  public paramsFilter: any = {
    gender: null,
    centre: null,
    statusAssignment: null,
    text: ''
  };
  public get assignmentId() {
    return this.$route.params.assignmentId;
  }
  public get general() {
    const data = cloneDeep(this.data),
      _new = filter(data, (e) => e.statusAssignment === AssignmentStatus.New).length,
      completed = filter(data, (e) => e.statusAssignment === AssignmentStatus.Completed).length,
      overdue = filter(data, (e) => e.statusAssignment === AssignmentStatus.Overdue).length;

    return {
      completed,
      overdue,
      new: _new
    };
  }
  public get filterOptions() {
    return {
      gender: this.cloneGenderOptions,
      centre: this.cloneCentreOptions,
      statusAssignment: [
        {
          value: null,
          label: 'Progress'
        },
        {
          value: 'new',
          label: 'New'
        },
        {
          value: 'completed',
          label: 'Completed'
        },
        {
          value: 'overdue',
          label: 'Overdue'
        }
      ]
    };
  }
  public orderForm = {
    value: null,
    desc: false
  };
  public get formaterListCounter() {
    return formaterListCounter(this.pagination, this.data.length);
  }
  public get assignmentSort() {
    return [
      {
        label: this.$t('none'),
        value: null
      },
      {
        label: this.$t('fullName'),
        value: 'fullName'
      },
      {
        label: this.$t('level'),
        value: 'level'
      }
    ];
  }
  public get columns() {
    return [
      {
        label: 'Full name',
        formatter: (model: any) => {
          return fullNameUser(model);
        },
        avatar: {
          formatter: (model: any) => {
            return model ? model : null;
          }
        }
      },
      {
        prop: 'gender',
        label: 'Gender',
        width: 120
      },
      {
        prop: 'birthday',
        label: 'date of birth',
        formatter: (model: any) => {
          return moment(model.birthday).format('DD/MM/YYYY');
        },
        width: 170
      },
      {
        prop: 'centre',
        label: 'centre',
        width: 170
      },
      {
        prop: 'level',
        label: 'level',
        width: 120
      },
      {
        prop: 'statusAssignment',
        label: 'Progress',
        width: 150,
        labelStyle: (model: any) => {
          return model.statusAssignment;
        }
      }];
  }

  public disabledTarget(name: string) {
    return ((this.detail.target === AssignmentTarget.Centre
      || this.$store.getters[GetterTypeUser.GetAuthuserRole] === UserRole.Teacher)
      && name === AssignmentTarget.Centre);
  }
  public clickDetail(id: number) {
    // this.$router.push(`/assignments/${id}`);
    this.userId = id;
    this.visibleAssignmentSingle = true;
  }

  public changePagination() {
    this.fetchData();
  }

  public beforeOpen() {
    //
  }

  public handleClickCloseDialog() {
    this.visibleAssignmentSingle = false;
  }

  public applyFilter() {
    // this.$store.commit(MutationTypeAssignment.AssignmentPaginationReset);
    this.filterEnable = false;
    this.fetchData();
  }
  public changeFilter(v, name) {
    this.filterEnable = true;
    this.paramsFilter[name] = v;
  }
  protected mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeAssignment.AssignmentGetUsers, {
        id: this.assignmentId
      });
      this.$store.dispatch(ActionTypeAssignment.AssignmentGetStatistic, this.assignmentId);
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeAssignment.AssignmentClearUsers);
    this.$store.commit(MutationTypeAssignment.AssignmentClearStatistic);
  }

  private fetchData() {
    const _filter = cloneDeep(this.paramsFilter);
    const page = this.pagination;

    const formConverted: any = {
      params: {
        ...stripObject(_filter),
        ...page
      }
    };

    this.$store.dispatch(ActionTypeAssignment.AssignmentGetUsers, {
      id: this.assignmentId,
      ...formConverted
    });
  }
}
