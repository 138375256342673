import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { getFirstDay, getLastDay, parseTimeLocal } from 'root/helpers';
import { IRedemptionStatistic, IStatisticFilter, RedemptionStatusColor, StatisticType } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import { Doughnut } from 'vue-chartjs';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeDashboard, MutationTypeDashboard } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'doughnut-chart': Doughnut
  },
  computed: {
    ...mapState({
      redemption: (state: IState) => state.dashboard.redemption,
      paramsFilter: (state: IState) => state.dashboard.redemptionFilter
    }),
    convertedData() {
      const redemption: IRedemptionStatistic[] = cloneDeep(this.redemption);
      const labels: string[] = [];
      const data: number[] = [];
      const bgColor: number[] = [];

      redemption.map((e) => {
        labels.push(this.$t(e.progress).toString());
        data.push(e.count);
        bgColor.push(RedemptionStatusColor[e.progress]);
      });

      return {
        labels,
        datasets: [{
          data,
          fill: false,
          backgroundColor: bgColor,
        }]
      };
    },
    timePicker: {
      get() {
        return moment(this.paramsFilter.startDate).utc(true).tz(moment.tz.guess());
      },
      set(value) {
        this.paramsFilter.startDate = value;
      }
    }
  }
})

export class DashboardRedemptionActivities extends Vue {
  public $refs: {
    doughnutChart: Doughnut
  };

  public loading: boolean = false;

  public paramsFilter: IStatisticFilter;
  public convertedData: any;
  public redemption: IRedemptionStatistic[];
  private options: any = {
    responsive: true, maintainAspectRatio: false,
    drawOnChartArea: false,
    legend: {
      position: 'bottom',
    },
    scales: {
      xAxes: [{
        display: false
      }],
      yAxes: [{
        display: false
      }]
    },
    elements: {
      line: {
        tension: 0.000001
      }
    },
  };

  public get getFormat(): string {
    if (this.paramsFilter.type === StatisticType.Month) {
      return 'MMM, yyyy';
    }
    if (this.paramsFilter.type === StatisticType.Week) {
      return 'Week WW yyyy';
    }

    return 'dd/MM/yyyy';
  }
  public get getTimePickerType(): string {
    if (this.paramsFilter.type === StatisticType.Month || this.paramsFilter.type === StatisticType.Week) {
      return this.paramsFilter.type;
    }

    return 'date';
  }
  public get formatterTitle(): string {
    switch (this.paramsFilter.type) {
    case StatisticType.Day:
      return parseTimeLocal(this.paramsFilter.startDate).toString();
    case StatisticType.Week:
    case StatisticType.Month:
      const duration = this.paramsFilter.type === StatisticType.Week ? 6 : 30;
      const endDate = moment(this.paramsFilter.startDate).add(duration, 'days').toISOString();

      return `${parseTimeLocal(this.paramsFilter.startDate)}
        - ${parseTimeLocal(endDate)}`;
    default:
      break;
    }

    return '';
  }
  public get total(): number {
    const redemption = this.redemption;
    let total: number = 0;
    redemption.map((e) => {
      total = total + e.count;
    });

    return total;
  }

  public async changeFilter(value: any, key: string) {
    const paramsFilter = this.paramsFilter;
    if (key === 'type') {
      this.paramsFilter.startDate = getFirstDay(value);
      this.paramsFilter.maxDate = value !== StatisticType.Day ? getLastDay(value, this.paramsFilter.startDate) : null;
    } else if (key === 'startDate') {
      if (this.paramsFilter.type === StatisticType.Week) {
        this.paramsFilter.startDate = getFirstDay('week', value);
      }
      this.paramsFilter.maxDate = paramsFilter.type === StatisticType.Day ? null
        : getLastDay(paramsFilter.type, this.paramsFilter.startDate);
    }

    this.renderChart();
  }

  protected mounted() {
    this.$nextTick(async () => {
      this.$store.commit(MutationTypeDashboard.DashboardClearRedemption);
      this.renderChart();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationTypeDashboard.DashboardClearRedemption);
  }

  private async renderChart() {
    const paramsFilter = cloneDeep(this.paramsFilter);
    if (paramsFilter.startDate && paramsFilter.type) {
      await this.$store.dispatch(ActionTypeDashboard.DashboardFilterRedemption);
      this.$refs.doughnutChart.renderChart(this.convertedData, this.options);
    }
  }
}
