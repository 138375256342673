import { CMAAvatar } from 'root/components';
import { formatBirthday, fullNameUser } from 'root/helpers';
import { planForm, userDefault, IPhoneInput, ISubscription } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-avatar': CMAAvatar
  },
  props: {
    visible: Boolean,
    data: {
      type: Object,
      default: (): ISubscription => {
        return {
          id: null,
          createdAt: null,
          nextDateBilling: null,
          owner: userDefault(),
          plan: planForm(),
          processing: null,
          updatedAt: null
        };
      }
    }
  }
})

export class SubscriptionDetailView extends Vue {
  public fullNameUser = fullNameUser;
  public formatBirthday = formatBirthday;

  public formaterPhone(model: IPhoneInput) {
    if (!model) {
      return '';
    }

    return `${model.code ? model.code : ''} ${model.number}`;
  }

  public beforeOpen() {
    //
  }

  public handleClickCloseDialog() {
    this.$emit('update:visible', false);
  }
}
