import { TabPane } from 'element-ui';
import { CMAHeader } from 'root/components';
import { cmaFormatDateFullTime, cmaFormatDateTime, fullNameUser } from 'root/helpers';
import { AssignmentTarget, IAssignment, IBreadcrumb } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { AssignmentDetailContent } from '../Components/AssignmentDetailContent';
import { AssignmentDetailGroup } from '../Components/AssignmentDetailGroup';
import { AssignmentDetailSingle } from '../Components/AssignmentDetailSingle';
import { ActionTypeAssignment, MutationTypeAssignment } from '../Store/types';
import { AssignmentRouterName } from '../index';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'assignment-detail-content': AssignmentDetailContent,
    'assignment-detail-group': AssignmentDetailGroup,
    'assignment-detail-single': AssignmentDetailSingle
  },
  computed: {
    ...mapState({
      detail: (state: IState) => state.assignment.detail,
      questions: (state: IState) => state.assignment.question,
      loading: (state: IState) => state.assignment.loading,
      questiosLoading: (state: IState) => state.assignment.questionLoading
    })
  },
  beforeRouteLeave(to, _, next) {
    if (to.name !== AssignmentRouterName.AssignmentEdit) {
      this.$store.commit(MutationTypeAssignment.ResetAssignmentDetail);
    }

    next();
  }
})

export class AssignmentDetail extends Vue {
  public cmaFormatDateTime = cmaFormatDateTime;
  public cmaFormatDateFullTime = cmaFormatDateFullTime;
  public fullNameUser = fullNameUser;
  public detail: IAssignment;
  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Assignment',
      path: '/assignments'
    },
    {
      path: '',
      current: true,
      label: 'Assignment Details'
    }
  ];

  public activeTab: AssignmentTab = AssignmentTab.Result;

  public get assignmentId() {
    return this.$route.params.assignmentId;
  }

  public get userId() {
    return this.isSingleAssignment && this.detail.assigneeIds[0];
  }
  public get isSingleAssignment(): boolean {
    return this.detail && this.detail.target === AssignmentTarget.Single;
  }

  public goEdit() {
    this.$router.push(`/assignments/${this.assignmentId}/edit`);
  }

  public changeTab(tab: TabPane) {
    // this.activeTab = tab;
    console.log(tab.name);
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeAssignment.GetAssignmentDetail, {
        id: this.assignmentId
      });
    });
  }

  public beforeDestroy() {
    // this.$store.commit(MutationTypeAssignment.ResetAssignmentDetail);
  }
}

const enum AssignmentTab {
  Result = 'result',
  Content = 'content',
}
