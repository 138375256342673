import { Message } from 'element-ui';
import { cmaHttpClient } from 'root/api/http';
import { ActionType, IState } from 'root/store';
import { ActionContext } from 'vuex';
import { IExchangeRateState } from './mutations';
import { ActionTypeExchangeRate, MutationTypeExchangeRate } from './types';

export const { getData, updateRate, getCurrentExchangeRate } = cmaHttpClient.exchange;
export const actions = {
  async [ActionTypeExchangeRate.ExchangeRateGetData](
    { commit, dispatch }: ActionContext<IExchangeRateState, IState>, {params}
  ) {
    commit(MutationTypeExchangeRate.ExchangeRateLoading);
    try {
      const result = await getData(params);
      commit(MutationTypeExchangeRate.ExchangeRateSetData, result);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeExchangeRate.ExchangeRateLoaded);
  },
  async [ActionTypeExchangeRate.ExchangeRateUpdateRate](
    { dispatch, commit }: ActionContext<IExchangeRateState, IState>, {
      form, onSuccess, onFailure
    }
  ) {
    commit(MutationTypeExchangeRate.ExchangeRateLoading);
    try {
      const result = await updateRate(form);
      Message.success(result.message.toString());
      onSuccess();
      // commit(MutationTypeExchangeRate.ExchangeRateSetData, result);
    } catch (error) {
      if (typeof(onFailure) === 'function') {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeExchangeRate.ExchangeRateLoaded);
  },
  async [ActionTypeExchangeRate.GetCurrentExchangeRate](
    { dispatch, commit }: ActionContext<IExchangeRateState, IState>
  ) {
    commit(MutationTypeExchangeRate.ExchangeRateLoading);
    try {
      const current = await getCurrentExchangeRate();
      commit(MutationTypeExchangeRate.SetCurrentExchangeRate, current);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeExchangeRate.ExchangeRateLoaded);
  }
};
