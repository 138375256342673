import { CMAAvatar, CMAHeader } from 'root/components';
import { cmaFormatDateFullTime, fullNameUser } from 'root/helpers';
import { IBreadcrumb } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { NotificationRouterName } from '..';
import { ActionTypeNotification, GetterTypeNotification } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'cma-avatar': CMAAvatar
  }
})

export class NotificationView extends Vue {
  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Notification',
      path: '/notifications'
    },
    {
      label: 'View',
      path: '',
      current: true
    }
  ];
  public cmaFormatDateFullTime = cmaFormatDateFullTime;

  public get notifyId(): string {
    return this.$route.params.notifyId;
  }

  public get notification() {
    return this.$store.getters[GetterTypeNotification.GetNotificationById](this.notifyId);
  }
  public get fullName() {
    if (!this.notification) {
      return '';
    }

    return fullNameUser(this.notification.owner);
  }

  public clickEdit() {
    this.$router.push({
      name: NotificationRouterName.Edit,
      params: {
        notifyId: this.notifyId
      }
    });
  }
  protected mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeNotification.NotificationFindById, {
        id: this.notifyId
      });
    });
  }
}
