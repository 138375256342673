import { cloneDeep, findIndex } from 'lodash';
import { IAssignment, IAssignmentStatistic } from 'root/models';
import { IPaginationState } from 'root/store';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeAssignment } from './types';

export interface IAssignmentState extends ICRUDState<IAssignment> {
  questionLoading: boolean;
  detail: IAssignment;
  question: any;
  users: any;
  template: ICRUDState<any>;
  assignmentSelected: IAssignment;
  statistic: IAssignmentStatistic;
}

export const defaultState: IAssignmentState = {
  ...CRUDState(),
  questionLoading: false,
  detail: {
    createdAt: null,
    id: null,
    library: null,
    owner: {
      fullName: '',
      id: null,
      avatar: ''
    },
    cases: []
  },
  question: {
    data: [],
    pagination: {}
  },
  users: {
    data: [],
    pagination: {
      size: 20,
      page: 1
    },
    detail: {}
  },
  template: CRUDState(),
  assignmentSelected: {
    createdAt: null,
    id: null,
    library: null,
    owner: {
      fullName: '',
      id: null,
      avatar: ''
    },
    cases: []
  },
  statistic: {
    countCompleted: 0,
    countNew: 0,
    countOverdue: 0
  }
};

export const mutations = {
  ...crudMutations('assignment'),
  [MutationTypeAssignment.AssignmentQuestionLoading](state: IAssignmentState, loading) {
    state.questionLoading = loading;
  },
  [MutationTypeAssignment.AssignmentLoading](state: IAssignmentState) {
    state.loading = true;
  },
  [MutationTypeAssignment.AssignmentLoaded](state: IAssignmentState) {
    state.loading = false;
  },
  [MutationTypeAssignment.SetAssignmentDetail](state: IAssignmentState, payload) {
    state.detail = payload;
  },
  [MutationTypeAssignment.SetAssignmentQuestions](state: IAssignmentState, payload) {
    state.question = payload;
  },
  [MutationTypeAssignment.ResetAssignmentDetail](state: IAssignmentState) {
    state.detail = {
      createdAt: null,
      id: null,
      library: null,
      owner: {
        fullName: '',
        id: null,
        avatar: ''
      }
    };
  },
  [MutationTypeAssignment.SetAssignmentByUser](state: IAssignmentState, payload) {
    state.data = payload.data;
    state.pagination = payload.pagination;
  },
  [MutationTypeAssignment.AssignmentSetUsers](state: IAssignmentState, payload) {
    state.users.data = payload.data;
    state.users.pagination = payload.pagination;
  },
  [MutationTypeAssignment.AssignmentClearUsers](state: IAssignmentState) {
    state.users.data = [];
    state.users.pagination = {
      size: 20,
      page: 1
    };
  },
  [MutationTypeAssignment.AssignmentSetUserDetail](state: IAssignmentState, payload) {
    state.users.detail = payload;
  },
  [MutationTypeAssignment.AssignmentClearUserDetail](state: IAssignmentState) {
    state.users.detail = {};
  },
  [MutationTypeAssignment.SetAssignmentUserPagination](state: IAssignmentState, payload) {
    state.users.pagination = {
      ...state.users.pagination,
      ...payload
    };
  },
  [MutationTypeAssignment.SetAssignmentPagination](state: IAssignmentState, payload: IPaginationState) {
    const pagination = cloneDeep(state.pagination);
    state.pagination = {
      ...pagination,
      ...payload
    };
  },
  [MutationTypeAssignment.AssignmentTemplateSetPagination](state: IAssignmentState, payload: IPaginationState) {
    const pagination = cloneDeep(state.template.pagination);
    state.template.pagination = {
      ...pagination,
      ...payload
    };
  },
  [MutationTypeAssignment.AssignmentSetStatistic](state: IAssignmentState, payload: IAssignmentStatistic) {
    state.statistic = payload;
  },
  [MutationTypeAssignment.AssignmentClearStatistic](state: IAssignmentState) {
    state.statistic = {
      countCompleted: 0,
      countNew: 0,
      countOverdue: 0
    };
  },
  [MutationTypeAssignment.AssignmentTemplateLoading](state: IAssignmentState) {
    state.template.loading = true;
  },
  [MutationTypeAssignment.AssignmentTemplateLoaded](state: IAssignmentState) {
    state.template.loading = false;
  },
  [MutationTypeAssignment.AssignmentTemplateAddModel](state: IAssignmentState, payload) {
    state.template.data = [
      payload,
      ...state.template.data
    ];
  },
  [MutationTypeAssignment.AssignmentTemplateUpdateModel](state: IAssignmentState, payload) {
    const template = cloneDeep(state.template.data);

    const index = findIndex(template, (e) => e.id === payload.id);

    if (index < 0) {
      return;
    }
    template[index] = payload;
    state.template.data = template;
  },
  [MutationTypeAssignment.AssignmentTemplateSetModels](state: IAssignmentState, payload) {
    state.template.data = payload.data;
    state.template.pagination = payload.pagination;
  },
  [MutationTypeAssignment.AssignmentTemplateSelect](state: IAssignmentState, payload) {
    state.assignmentSelected = payload;
  },
  [MutationTypeAssignment.AssignmentTemplateResetAssignmentSelected](state: IAssignmentState) {
    state.assignmentSelected = {
      createdAt: null,
      id: null,
      library: null,
      owner: {
        fullName: '',
        id: null,
        avatar: ''
      },
      cases: []
    };
  },
};
