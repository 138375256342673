import Vue from 'vue';
import Component from 'vue-class-component';
import { DashboardSubscribed } from '../Components/DashboardSubscribed';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'dashboard-subscribed': DashboardSubscribed
  }
})

export class DashboardSubscription extends Vue {}
