import { RouteConfig } from 'vue-router';
import { UserContainer } from './UserContainer';
import { UserDetail } from './UserDetail';
import { UserEditor } from './UserEditor';
import { UsersList } from './UsersList';

export enum userRouterName {
  userEdit = 'userEdit',
  UserDetail = 'userDetail'
}

export enum userRouterPath {
  user = '/users',
  userDetal = '/users/:userId',
  userAddNew = '/users/new',
}

export const userRouter: RouteConfig = {
  path: userRouterPath.user,
  component: UserContainer,
  children: [
    {
      path: '',
      component: UsersList
    },
    {
      path: userRouterPath.userAddNew,
      component: UserEditor
    },
    {
      path: ':userId/edit',
      component: UserEditor,
      name: userRouterName.userEdit
    },
    {
      path: userRouterPath.userDetal,
      component: UserDetail,
      name: userRouterName.UserDetail
    }
  ]
};
