import { stripObject } from '@hgiasac/helper';
import { cloneDeep, omit } from 'lodash';
import { CMAButtonFilter, CMAListData, CMASelect } from 'root/components';
import { formatBirthday } from 'root/helpers';
import { IPagingParams, ISubscription, ISubscriptionFilter } from 'root/models';
import { ActionTypeSubscription, MutationTypeSubscription } from 'root/pages/Subscription/Store/types';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-select': CMASelect,
    'cma-list-data': CMAListData,
    'cma-btn-filter': CMAButtonFilter
  },
  props: {
    userId: [Number, String]
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.subscription.data,
      loading: (state: IState) => state.subscription.loading,
      pagination: (state: IState) => state.subscription.pagination,
    }),
    ...mapGetters(['sortType',
      'planTypesSelection', 'subscriptionStatusSelection']),
  },
  watch: {
    'orderForm.value'(newValue: any) {
      this.$store.commit(MutationTypeSubscription.SubscriptionPaginationChange, {
        page: 1,
        size: this.pagination.size,
        order: newValue,
        descending: this.orderForm.desc
      });
      this.fetchData();
    },
    'orderForm.desc'(newValue: any) {
      this.$store.commit(MutationTypeSubscription.SubscriptionPaginationChange, {
        page: 1,
        size: this.pagination.size,
        order: this.orderForm.value,
        descending: newValue
      });
      this.fetchData();
    }
  }
})

export class UserSubscriptionList extends Vue {
  public pagination: IPagingParams;
  public data: ISubscription[];
  public planTypesSelection: any[];
  public subscriptionStatusSelection: any[];

  public filterEnable: boolean = false;
  public paginationSize: number = 20;
  public orderForm = {
    value: null,
    desc: false
  };
  public detailVisible: boolean = false;
  public detail: ISubscription = null;

  public paramsFilter: ISubscriptionFilter = {
    title: '',
    type: null,
    processing: null
  };

  public get filterOptions() {
    return {
      type: this.planTypesSelection,
      processing: this.subscriptionStatusSelection
    };
  }
  public get subscriptionSort() {
    return [
      {
        label: this.$t('none'),
        value: null
      },
      {
        label: 'Created At',
        value: 'createdAt'
      }
    ];
  }

  public get columns() {
    return [
      {
        prop: 'planName',
        label: `plan's name`,
        formatter: (model: ISubscription) => model.plan.name
      },
      {
        prop: 'desc',
        label: 'description',
        formatter: (model: ISubscription) => model.plan.desc,
        width: 110
      }, {
        prop: 'type',
        label: 'type',
        formatter: (model: ISubscription) => model.plan.type,
        width: 70
      }, {
        prop: 'price',
        label: 'price',
        formatter: (model: ISubscription) => `$${model.plan.price}`
      }, {
        prop: 'duration',
        label: 'duration',
        formatter: (model: ISubscription) => `${model.plan.duration} ${model.plan.duration > 1 ? 'months' : 'month'}`
      }, {
        prop: 'created at',
        label: 'create at',
        formatter: (model: ISubscription) => formatBirthday(model.createdAt)
      }, {
        prop: 'next billing date',
        label: 'next billing date',
        formatter: (model: ISubscription) => formatBirthday(model.nextDateBilling)
      }, {
        prop: 'status',
        label: 'status',
        formatter: (model: ISubscription) => this.$t(model.processing),
        labelStyle: (model: ISubscription) => model.processing
      }];
  }

  public changeFilter(v: any, name: string) {
    this.filterEnable = true;
    this.paramsFilter[name] = v;
  }

  public applyFilter() {
    this.fetchData();
    this.filterEnable = false;
  }

  public changePaginationFilter(value) {
    this.$store.commit(MutationTypeSubscription.SubscriptionPaginationChange, {
      size: value,
      page: 1
    });
    this.fetchData();
  }

  public changePagination() {
    this.fetchData();
  }
  public clickDetail(id: number) {
    const sub = cloneDeep(this.data).find((e) => e.id.toString() === id.toString());
    this.detail = sub;
    this.detailVisible = true;
  }

  protected mounted() {
    this.$nextTick(() => {
      this.fetchData();
    });
  }

  private fetchData() {
    const _filter = cloneDeep(this.paramsFilter);
    const page = this.pagination;

    const formConverted: any = {
      params: {
        ...stripObject(_filter),
        ...omit(page, ['totalPerPage', 'total', 'totalPages'])
      }
    };

    this.$store.dispatch(ActionTypeSubscription.SubscriptionFilterByUser, {
      userId: this.$props.userId,
      paramFilter:  formConverted
    });
  }
}
