import { find } from 'lodash';
import { INotificationState } from './mutations';
import { GetterTypeNotification } from './types';

export const getters = {
  [GetterTypeNotification.GetNotificationById](state: INotificationState) {
    return (notifyId: string) => {
      const current = find(state.data, (e) => e.id.toString() === notifyId.toString());

      return current ? current : null;
    };
  }
};
