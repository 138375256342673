import { IPlan } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IPlanHttpService
  extends ICRUDHttpService<IPlan> {
}

export function PlanHttpService(options: IHttpServiceClientOptions): IPlanHttpService {

  const basePath = '/admin/plans';

  return {
    ...CRUDHttpService<IPlan>({
      ...options,
      basePath
    })
  };
}
