import { safeParseInt } from '@hgiasac/helper';
import {cloneDeep} from 'lodash';
import moment from 'moment-timezone';
import { IPhoneInput, IUser } from 'root/models';
// import { i18n } from 'root/locales';
// import { IUser } from 'root/models';

export function fullNameUser(model: IUser) {
  return model && model.fullName ? `${model.fullName}` : '';
}

export function formatTimeDuration(time: Date, locale?: string) {
  if (!time) {
    return '';
  }

  return moment(time).locale(locale || 'vi').fromNow();
}

export function cmaFormatDateTime(time: Date, locale: string = 'en') {
  if (!time) {
    return '';
  }

  return moment(time).locale(locale).format('DD MMMM, YYYY');
}
export function cmaFormatDateFullTime(time: Date, locale: string = 'en') {
  if (!time) {
    return '';
  }

  return moment(time).locale(locale).format('HH:mm DD/MM/YYYY');
}

export function formatBirthday(time: Date | string, locale = 'en') {
  if (!time) {
    return '';
  }

  return moment(time).locale(locale).format('DD/MM/YYYY');
}

export function formatStringAndNumber(s: string) {
  const pattern = /[@#$%^&*(),.?":{}|<>]/g;

  return s.replace(pattern, '');
}

export function formaterListCounter(pagination, length) {
  const _pagination = cloneDeep(pagination),
    { page, size } = _pagination,
    total = _pagination && _pagination.total ? _pagination.total : '',
    from = (page - 1) * size + 1,
    to = from + length - 1;

  return `You're viewing item from
   ${to ? from : 0} to ${to} in ${total ? total : 0} ${ total && total > 1 ? 'results' : 'result'}`;
}

export function getRandomColor() {
  const letters = '0123456789ABCDEF'.split('');
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
}

export function getFirstDay(type: string = 'day', value?: Date) {
  const _date = value ? value : moment().tz(moment.tz.guess()).format('YYYY-MM-DD');

  if (type === 'week') {
    return moment(_date).day('monday').toISOString();
  }
  if (type === 'month') {
    return moment(_date).startOf('month').format('YYYY-MM-DD');
  }

  return moment(_date).toISOString();
}

export function getLastDay(type: string = 'day', value?: Date | string) {
  const _date = value ? value : moment().tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');

  if (type === 'week') {
    if (value) {
      return moment(_date).add(7, 'days').format('YYYY-MM-DD HH:mm:ss');
    }

    return moment(_date).endOf('week').add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
  }
  if (type === 'month') {
    return moment(_date).endOf('month').add(1, 'seconds').format('YYYY-MM-DD HH:mm:ss');
  }

  return moment(_date).utc().format('YYYY-MM-DD HH:mm:ss');
}

export function parseTimeLocal(time: Date | string) {
  return moment(time).utc(true).tz(moment.tz.guess()).format('DD/MM/YYYY');
}

export function convertParamFilter(paramFilter) {
  paramFilter.startDate = moment(paramFilter.startDate).tz(moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
  paramFilter.maxDate = paramFilter.maxDate
    ? moment(paramFilter.maxDate).tz(moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss')
    : null;
}

export function formaterPhone(model: IPhoneInput) {
  if (!model) {
    return '';
  }

  return `${model.code ? model.code : ''} ${model.number || '__'}`;
}
export function secondsToFormat(s: number) {
  const date = new Date(null);
  date.setSeconds(s); // specify value for SECONDS here

  return date.toISOString().substr(11, 8);
}

export function generateMaxRange(digits) {
  return Math.pow(10, digits);
}
export function roundNumber(placeDecimal = 2) {
  return (n: number) => {
    if (!n.toString().includes('.')) {
      return n.toString();
    }
    const numberMax = generateMaxRange(placeDecimal);

    return (Math.round(n * numberMax) / numberMax).toString();
  };
}
export function keepZeroDecimal(digit: number, n: any) {
  const length = (() => {
    let temp = n;
    temp = temp.toString().replace('.', '');

    return temp.length;
  })();
  const arr = n.toString().split('.');
  if (arr.length > 0) {
    if (safeParseInt(arr[0]) === 0) {
      if (length !== digit) { return parseFloat(n).toFixed(digit - 1); }

      return n;
    }
    const countCurrent = (() => {
      return {
        count: length,
        length: digit - arr[0].length
      };
    })();
    if (countCurrent.count !== digit) {
      n = parseFloat(n);

      return countCurrent.length === 0 ? n : n.toFixed(countCurrent.length < 0 ? digit : countCurrent.length);
    }
  }

  return n;
}

export function forceResultToDecimal(n: any, c: number): any {
  n = n.toString();
  n = roundNumber(c)(n);
  let prefix = '';
  if (/-/.test(n)) {
    n = n.replace('-', '');
    prefix = '-';
  }
  const arr = n.split('.');

  return prefix + keepZeroDecimal(arr[0].length + c, n);
}
