import { omit } from 'lodash';
import { isFunction } from 'lodash';
import { cmaHttpClient } from 'root/api/http';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { ISubscriptionState } from './mutations';
import { ActionTypeSubscription, MutationTypeSubscription } from './types';

const { filter, filterSubscriptionByUser, filterTransactions } = cmaHttpClient.subscription;
export const actions = {
  ...crudActions('subscription', {
    filter
  }),
  async [ActionTypeSubscription.SubscriptionFilterByUser](
    {commit, dispatch}: ActionContext<ISubscriptionState, IState>, {
      userId,
      paramFilter,
      onSuccess,
      onFailure
    }
  ) {
    commit(MutationTypeSubscription.SubscriptionLoading);
    const order = !paramFilter || !paramFilter.params || !paramFilter.params.order ? undefined
        : (paramFilter.params.descending ? '-' + paramFilter.params.order : paramFilter.params.order);
    paramFilter.params.order = order;
    paramFilter.params = {
      ...omit(paramFilter.params, ['totalPerPage', 'total', 'totalPages', 'descending'])
    };
    try {
      const result = await filterSubscriptionByUser(userId, paramFilter);
      commit(MutationTypeSubscription.SetSubscription, result);
      if (isFunction(onSuccess)) {
        onSuccess();
      }
    } catch (error) {
      if (isFunction(onFailure)) {
        onFailure();
      }
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeSubscription.SubscriptionLoaded);
  },
  /**
   * transaction by plan
   */
  async [ActionTypeSubscription.TransactionFilterByPlan](
    {commit, dispatch}: ActionContext<ISubscriptionState, IState>, {
      id,
      paramFilter,
      onSuccess,
      onFailure
    }
  ) {
    commit(MutationTypeSubscription.TransactionByPlanLoading);
    try {
      const payload = await filterTransactions(id, paramFilter);
      commit(MutationTypeSubscription.SetTransactionByPlan, payload);
      onSuccess();
    } catch (error) {
      onFailure();
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeSubscription.TransactionByPlanLoaded);
  }
};
