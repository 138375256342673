import { RouteConfig } from 'vue-router';
import { LibraryContainer } from './LibraryContainer';
import { LibraryDetail } from './LibraryDetail';
import { LibraryEditor } from './LibraryEditor';
import { LibraryList } from './LibraryList';

export enum LibraryRouterName {
  LibraryEdit = 'LibraryEdit'
}

export enum LibraryRouterPath {
  Library = '/question-bank',
  LibraryNew = '/question-bank/new',
  LibraryDetail = '/question-bank/:libraryId',
  LibraryEdit = '/question-bank/:libraryId/edit',
}

export const LibraryRouter: RouteConfig = {
  path: LibraryRouterPath.Library,
  component: LibraryContainer,
  children: [
    {
      path: '',
      component: LibraryList
    },
    {
      path: LibraryRouterPath.LibraryNew,
      component: LibraryEditor
    },
    {
      path: LibraryRouterPath.LibraryDetail,
      component: LibraryDetail
    },
    {
      path: LibraryRouterPath.LibraryEdit,
      component: LibraryEditor,
      name: LibraryRouterName.LibraryEdit
    }
  ]
};
