import { IProduct, IProductCreate } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IProductHttpService
  extends ICRUDHttpService<IProduct, IProductCreate> {
  transactionFilter(form: any): Promise<any>;
  transactionUpdate(id: number, form: any): Promise<any>;
  transactionUserFilter(userId: number, paramsFilter): Promise<any>;
  transactionListOfDetail(productId: number, paramsFilter): Promise<any>;
}

export function ProductHttpService(options: IHttpServiceClientOptions): IProductHttpService {

  const basePath = '/admin/products';

  function transactionFilter(form: any): Promise<any> {
    const tranFilterPath = options.httpService.getUrl(`/admin/redemptions`, options.mock);

    return options.httpService.get(tranFilterPath, form);
  }
  function transactionUserFilter(userId: number, paramsFilter): Promise<any> {
    const userPath = options.httpService.getUrl(`/admin/redemptions/${userId}/users`, options.mock);

    return options.httpService.get(userPath, paramsFilter);
  }
  function transactionUpdate(id: number, form: any): Promise<any> {
    const tranUpdatePath = options.httpService.getUrl(`/admin/redemptions/${id}/${form.progress}`, options.mock);

    return options.httpService.put(tranUpdatePath, {});
  }
  function transactionListOfDetail(productId: number, paramsFilter): Promise<any> {
    const tranListDetailUrl = options.httpService.getUrl(`${basePath}/${productId}/transactions`, options.mock);

    return options.httpService.get(tranListDetailUrl, {
      params: paramsFilter
    });
  }

  return {
    ...CRUDHttpService<IProduct, IProductCreate>({
      ...options,
      basePath
    }),
    transactionFilter,
    transactionUpdate,
    transactionUserFilter,
    transactionListOfDetail
  };
}
