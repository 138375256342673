import { fullNameUser } from 'root/helpers';
import { IUser, UserRole } from 'root/models';
import { DashboardRouterPath } from 'root/pages/Dashboard';
import { ExaminationRouterPath } from 'root/pages/Examination';
import { ExchangeRateRouterPath } from 'root/pages/ExchangeRate';
import { LibraryRouterPath } from 'root/pages/Library';
import { NotificationRouterPath } from 'root/pages/Notification';
import { ProductRouterPath } from 'root/pages/Product';
import { ReportRouterPath } from 'root/pages/Reports';
import { SubscriptionRouterPath } from 'root/pages/Subscription';
import { userRouterPath } from 'root/pages/Users';
import { ActionType, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { CMAAvatar } from '../CMAAvatar';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-avatar': CMAAvatar
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser
    })
  }
})
export class Navigator extends Vue {
  public authUser: IUser;
  public showUserMenu: boolean = false;
  public collapse: string = '';
  public navigations: any = [
    {
      groupName: 'menu',
      children: [
        {
          path: DashboardRouterPath.Default,
          label: 'Dashboard'
        },
        {
          path: '/users',
          label: 'Users'
        },
        // {
        //   path: '/course',
        //   label: 'Course'
        // },
        {
          label: 'Redemption',
          path: ProductRouterPath.Redemption,
          collapse: true,
          children: [
            {
              path: '/redemption/product-list',
              label: 'Product List'
            },
            {
              path: '/redemption/transaction',
              label: 'Transaction'
            }
          ]
        },
        // {
        //   path: '/games',
        //   label: 'Games'
        // },
        {
          label: 'Assignment',
          collapse: true,
          children: [
            {
              path: '/assignments',
              label: 'List'
            },
            {
              path: '/assignments/template',
              label: 'Template'
            }
          ]
        },
        {
          label: 'Report',
          collapse: true,
          path: ReportRouterPath.Default,
          children: [
            {
              path: ReportRouterPath.User,
              label: 'User'
            },
            {
              path: ReportRouterPath.Assignment,
              label: 'Assignment'
            },
            {
              path: '/report/leader-board',
              label: 'LeaderBoard'
            },
            {
              path: '/report/subscription',
              label: 'Subscription'
            },
            {
              path: ReportRouterPath.Redemption,
              label: 'Redemption'
            },
            {
              path: ReportRouterPath.SmarterGame,
              label: 'Game'
            }
          ]
        },
        // {
        //   path: '/report',
        //   label: 'Report'
        // },
        {
          path: '/question-bank',
          label: 'Question Bank'
        },
        {
          path: '/examination',
          label: 'Examination'
        },
        {
          path: '/notifications',
          label: 'Notifications'
        },
        {
          path: '/exchange-rate',
          label: 'Exchange rate'
        },
        {
          label: 'Subscription',
          path: '/subscription',
          collapse: true,
          children: [
            {
              path: '/subscription/plan-management',
              label: 'Plan Management'
            },
            {
              path: '/subscription/list',
              label: 'Transaction List'
            },
            {
              path: '/subscription/stripe-config',
              label: 'Stripe Configuration'
            }
          ]
        }
      ]
    },
    // {
    //   groupName: 'support',
    //   children: [
    //     {
    //       path: '/help',
    //       label: 'Need Help?'
    //     },
    //     {
    //       path: '/contact-us',
    //       label: 'Contact Us'
    //     },
    //     {
    //       path: '/knowledge-base',
    //       label: 'Knowledge Base'
    //     }
    //   ]
    // }
  ];

  public fullNameUser = fullNameUser;
  public get getProfileUrl(): string {
    return `/users/${this.authUser.id}`;
  }
  public get getPathName(): string {
    const route = this.$route;

    return route.path;
  }
  public isShowNavigator(model: string) {
    if (this.authUser.role === UserRole.SuperAdmin) {
      return model === userRouterPath.user;
    }

    if (this.authUser.role === UserRole.Teacher) {
      return model !== NotificationRouterPath.Default
        && model !== LibraryRouterPath.Library
        && model !== ExchangeRateRouterPath.Default
        && model !== ProductRouterPath.Redemption
        && model !== SubscriptionRouterPath.Default
        && model !== DashboardRouterPath.Default
        && model !== ReportRouterPath.Default
        && model !== ExaminationRouterPath.Default;
    }
    if (this.authUser.role === UserRole.CentreManager) {
      return model !== NotificationRouterPath.Default
        && model !== LibraryRouterPath.Library
        && model !== ExchangeRateRouterPath.Default
        && model !== ProductRouterPath.Redemption
        && model !== SubscriptionRouterPath.Default
        && model !== DashboardRouterPath.Default
        && model !== ExaminationRouterPath.Default;
    }

    return true;
  }
  public isShowSubMenu(path: string) {
    if (this.authUser.role === UserRole.CentreManager && (
      path === ReportRouterPath.User
      || path === '/report/subscription'
      || path === ReportRouterPath.SmarterGame
    )) {
      return false;
    }

    return true;
  }
  public isGroupActive(model: any): boolean {
    return this.collapse === model.label;
  }
  public clickEditProfile(e) {
    e.preventDefault();

    this.showUserMenu = false;
    this.$router.push(this.getProfileUrl);
  }
  public clickSettings(e) {
    e.preventDefault();
    this.showUserMenu = false;
  }
  public logout() {
    const router = this.$router;
    this.$store.dispatch(ActionType.Unauthenticated, router);
  }
}
