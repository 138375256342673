import { cloneDeep } from 'lodash';
import { ILibrary, ILibraryCase, MethodType } from 'root/models/Library';
import { IPaginationState } from 'root/store';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeLibrary } from './types';

// export interface ILibraryState {
//   loading: boolean;
// }
export interface ILibraryState extends ICRUDState<ILibrary> {
  loading: boolean;
  detail: ILibrary;
  questions: any;
  questiosLoading: boolean;
  cases: ILibraryCase[];
}

export const defaultState: ILibraryState = {
  ...CRUDState(),
  loading: false,
  detail: {
    createdAt: null,
    grade: null,
    id: null,
    method: MethodType.Empty,
    status: '',
    totalQuestion: null,
    owner: {
      fullName: '',
      id: null,
      avatar: ''
    },
    cases: []
  },
  questiosLoading: false,
  questions: {
    data: [],
    pagination: {}
  },
  cases: []
};

export const mutations = {
  ...crudMutations('library'),
  [MutationTypeLibrary.LoadingLibrary](state: ILibraryState, loading: boolean) {
    state.loading = loading;
  },
  [MutationTypeLibrary.SetLibraryDetail](state: ILibraryState, payload) {
    state.detail = payload;
  },
  [MutationTypeLibrary.SetLibraryQuestionsLoading](state: ILibraryState, loading: boolean) {
    state.questiosLoading = loading;
  },
  [MutationTypeLibrary.SetLibraryQuestions](state: ILibraryState, payload) {
    state.questions = payload;
  },
  [MutationTypeLibrary.ResetLibraryDetail](state: ILibraryState) {
    state.questions = {
      data: [],
      pagination: {}
    };
    state.detail = {
      cases: [],
      createdAt: null,
      grade: null,
      id: null,
      method: MethodType.Empty,
      status: '',
      totalQuestion: null,
      owner: {
        fullName: '',
        id: null,
        avatar: ''
      }
    };
  },
  [MutationTypeLibrary.SetLibraryPagination](state: ILibraryState, payload: IPaginationState) {
    const pagination = cloneDeep(state.pagination);

    state.pagination = {
      ...pagination,
      ...payload
    };
  },
  [MutationTypeLibrary.setLibraryAllCases](state: ILibraryState, payload: ILibraryCase[]) {
    state.cases = payload;
  }
};
