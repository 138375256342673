import { cloneDeep, omit } from 'lodash';
import { compareObject } from 'root/helpers';
import { IAssignmentCase, ICase } from 'root/models';
import { ILibraryCase, Operator } from 'root/models/Library';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { CaseNameSelect } from '../CaseNameSelect';
// import { LibraryExpression } from '../LibraryExpression';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'case-name-select': CaseNameSelect
  },
  props: {
    disabled: Boolean,
    index: Number,
    isUpdating: Boolean,
    data: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    ...mapGetters(['operatorOptions', 'numberOptions', 'digitOptions', 'decimalOptions']),
    ...mapState({
      loading: (state: IState) => state.assignment.loading,
      caseList: (state: IState) => state.library.cases
    })
  }
})

export class SearchFollowCase extends Vue {
  public defaultConfig: ICase = {
    operators: Operator.Plus,
    numbers: null,
    digits: null,
    digitOne: null,
    digitTwo: null,
    totalQuestion: null,
    caseId: null,
    decimal: 0,
    maximumQuestion: 0
  };
  public config: ICase = cloneDeep(this.defaultConfig);
  public data: IAssignmentCase[];
  public isUpdating: boolean;
  public totalQuestionError: boolean = false;

  public caseList: ILibraryCase[]; // from store
  public cases: ICase[] = [];
  public errorIndex: number = null;

  public setErrorTotalQuestionIndex(index: number) {
    this.errorIndex = index;
  }

  public disabledDigitMore(operators): boolean {
    operators = operators || this.config.operators;

    return operators === Operator.Plus || operators === Operator.PlusAndSub;
  }
  public validate(callback?: any) {
    const cases = cloneDeep(this.cases);
    let result = true;

    cases.forEach((e, index) => {
      if (!e.totalQuestion) {
        result = false;
        this.errorIndex = index;
      }
    });

    return result ? callback() : null;
  }

  public changeTotalQuestion(value) {
    this.config.totalQuestion = value;
    if (this.config.totalQuestion > this.config.maximumQuestion) {
      return this.totalQuestionError = true;
    }

    this.totalQuestionError = false;
  }

  public addCase() {
    this.validate(async () => {
      const valid = await this.compareCases();
      if (!valid) {
        if (this.config.totalQuestion > this.config.maximumQuestion) {
          return this.totalQuestionError = true;
        }
        this.totalQuestionError = false;
        this.cases.push(cloneDeep(this.config));

        return;
      }
      this.errorIndex = null;
    });
  }

  public clickDropdown(index) {
    this.cases = this.cases.filter((_e, i) => i !== index);
  }

  public generateQuestion() {
    this.validate(() => {
      this.errorIndex = null;
      this.$emit('generateQuestion', this.cases);
    });
  }

  public get isDisabledCase() {
    return !(this.config.caseId && this.config.totalQuestion);
    // return model.status === QuestionStatus.Deleted;
  }

  public changeCase(id: number) {
    const caseList = cloneDeep(this.caseList);
    const _case = caseList.find((e) => e.id === id),
      configBase = _case.config;
    this.config = {
      ...this.config,
      caseId: _case.id,
      numbers: _case.config.numbers,
      digits: configBase.mixed ? 'Mixed'
        : configBase.digitCfg.length === 1 ? configBase.digitCfg[0].digits : null,
      decimal: configBase.mixedDecimal ? 'Mixed' : configBase.decimal,
      operators: configBase.operators.length > 1 ? Operator.PlusAndSub : configBase.operators[0],
      digitOne: configBase.digitCfg.length === 2 ? configBase.digitCfg[0].digits : null,
      digitTwo: configBase.digitCfg.length === 2 ? configBase.digitCfg[1].digits : null,
      isCurrency: configBase.isCurrency,
      maximumQuestion: _case.totalQuestion
    };
  }

  public formatterCaseName(id: number) {
    const caseList = cloneDeep(this.caseList),
      data = cloneDeep(this.data),
      list = [...caseList, ...data];
    const _case: any = list.find((e: any) => e.id === id);

    return _case ? _case.name : '';
  }

  public mounted() {
    this.$nextTick(() => {
      if (this.isUpdating) {
        const data = cloneDeep(this.data);

        this.cases = data.map((e): ICase => {
          const configBase = e.configCase || e.config;

          return {
            caseId: e.id,
            isCurrency: configBase.isCurrency,
            status: e.status,
            totalQuestion: e.questions.length,
            numbers: configBase ? configBase.numbers : null,
            operators: configBase.operators.length > 1 ? Operator.PlusAndSub : configBase.operators[0],
            digits: configBase.mixed ? 'Mixed'
              : configBase.digitCfg.length === 1 ? configBase.digitCfg[0].digits : null,
            decimal: configBase.mixedDecimal ? 'Mixed' : configBase.decimal,
            digitOne: configBase.digitCfg.length === 2 ? configBase.digitCfg[0].digits : null,
            digitTwo: configBase.digitCfg.length === 2 ? configBase.digitCfg[1].digits : null,
            maximumQuestion: e.maximumQuestion
          };
        });
      }

      return;
    });
  }

  private async compareCases() {
    const cases = cloneDeep(this.cases),
      config = cloneDeep(this.config);

    const arr = await Promise.all(cases.map((e) => {
      return compareObject(omit(e, ['totalQuestion', 'status', 'maximumQuestion']),
        omit(config, ['totalQuestion', 'status', 'maximumQuestion']));
    }));

    return arr.filter((e) => e === true).length > 0;
  }

  private validateTotalQuestion() {
    if (this.config.totalQuestion > this.config.maximumQuestion) {
      return this.totalQuestionError = true;
    }

    this.totalQuestionError = false;
  }
}
