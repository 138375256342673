import { cmaHttpClient } from 'root/api/http';
import { crudActions } from 'root/store/helpers';

const { create, filter, getById, update } = cmaHttpClient.notification;
export const actions = {
  ...crudActions('notification', {
    create,
    filter,
    getById,
    update
  })
};
