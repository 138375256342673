import { i18n } from 'root/locales';
import { AccessibilityType, PlanStatus, PlanType } from 'root/models';
import { IPlanState } from './mutations';
import { GetterTypePlan } from './types';

export const getters = {
  planTypesSelection() {
    return [
      {
        value: null,
        label: i18n().t('all.type')
      },
      {
        value: PlanType.Free,
        label: i18n().t(PlanType.Free)
      },
      {
        value: PlanType.Paid,
        label: i18n().t(PlanType.Paid)
      }
    ];
  },
  planStatus() {
    return [
      {
        value: PlanStatus.Active,
        label: i18n().t(PlanStatus.Active)
      },
      {
        value: PlanStatus.Disabled,
        label: i18n().t(PlanStatus.Disabled)
      }
    ];
  },
  accessibilityList() {
    return [
      {
        value: AccessibilityType.Limited,
        label: i18n().t(AccessibilityType.Limited)
      },
      {
        value: AccessibilityType.Permanent,
        label: i18n().t(AccessibilityType.Permanent)
      }
    ];
  },
  [GetterTypePlan.GetPlanDetail](state: IPlanState) {
    return (id: number | string) => {
      return state.data.find((e) => e.id.toString() === id.toString());
    };
  }
};
