export enum MutationTypeAssignment {
  LoadingAssignment = 'loadingAssignment',
  SetAssignmentDetail = 'SetAssignmentDetail',
  SetAssignmentQuestions = 'SetAssignmentQuestions',
  AssignmentQuestionLoading = 'AssignmentQuestionLoading',
  ResetAssignmentDetail = 'ResetAssignmentDetail',
  AssignmentLoading = 'assignmentLoading',
  AssignmentLoaded = 'assignmentLoaded',
  SetAssignmentByUser = 'SetAssignmentByUser',
  AssignmentPaginationReset = 'assignmentPaginationReset',
  SetAssignmentPagination = 'SetAssignmentPagination',
  AssignmentRefreshModels = 'assignmentRefreshModels',
  AssignmentFilterChange = 'assignmentFilterChange',
  AssignmentPaginationChange = 'assignmentPaginationChange',
  AssignmentSetUsers = 'AssignmentSetUsers',
  AssignmentClearUsers = 'AssignmentClearUsers',
  SetAssignmentUserPagination = 'SetAssignmentUserPagination',
  AssignmentSetUserDetail = 'AssignmentSetUserDetail',
  AssignmentClearUserDetail = 'AssignmentClearUserDetail',
  AssignmentSetStatistic = 'AssignmentSetStatistic',
  AssignmentClearStatistic = 'AssignmentClearStatistic',

  AssignmentTemplateLoading = 'AssignmentTemplateLoading',
  AssignmentTemplateLoaded = 'AssignmentTemplateLoaded',
  AssignmentTemplateAddModel = 'AssignmentTemplateAddModel',
  AssignmentTemplateUpdateModel = 'AssignmentTemplateUpdateModel',
  AssignmentTemplateSetModels = 'AssignmentTemplateSetModels',
  AssignmentTemplateSelect = 'AssignmentTemplateSelect',
  AssignmentTemplateResetAssignmentSelected = 'AssignmentTemplateResetAssignmentSelected',
  AssignmentTemplateSetPagination = 'AssignmentTemplateSetPagination'
}

export enum ActionTypeAssignment {
  AssignmentFilterNoCacheNoLoading = 'assignmentFilterNoCacheNoLoading',
  AssignmentFilterNoCache = 'assignmentFilterNoCache',
  GetAssignmentDetail = 'GetAssignmentDetail',
  GetAssignmentQuestions = 'GetAssignmentQuestions',
  AssignmentCreate = 'assignmentCreate',
  AssignmentUpdate = 'assignmentUpdate',
  AssignmentDelete = 'assignmentDelete',
  GetAssignmentByUser = 'GetAssignmentByUser',
  AssignmentGetUsers = 'AssignmentGetUsers',
  AssignmentGetUserDetail = 'AssignmentGetUserDetail',
  AssignmentGetStatistic = 'AssignmentGetStatistic',

  AssignmentTemplateCreate = 'AssignmentTemplateCreate',
  AssignmentTemplateFilter = 'AssignmentTemplateFilter',
  AssignmentTemplateDelete = 'AssignmentTemplateDelete',
  AssignmentTemplateGet = 'AssignmentTemplateGet',
  AssignmentTemplateUpdate = 'AssignmentTemplateUpdate'
}
