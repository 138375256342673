import * as js2excel from 'js2excel';
import { cloneDeep, omit } from 'lodash';
import moment from 'moment-timezone';
import { CMAButtonFilter, CMAHeader, CMAListData, CMASelect } from 'root/components';
import { formatBirthday } from 'root/helpers';
import { i18n } from 'root/locales';
import { IBreadcrumb, IProduct, IRedemption } from 'root/models';
import { MutationTypeProduct } from 'root/pages/Product/Store/types';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypeReport, MutationTypeReport } from '../Store/types';
import { ReportRouterPath } from '../index';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-list-data': CMAListData,
    'cma-btn-filter': CMAButtonFilter,
    'cma-header': CMAHeader,
    'cma-select': CMASelect
  },
  computed: {
    ...mapGetters(['statusOptions']),
    ...mapState({
      listCentre: (state: IState) => state.global.globalConfig.listCentre,
      data: (state: IState) => state.report.products,
      products: (state: IState) => state.product.data,
      loading: (state: IState) => state.report.productsLoading
    }),
    cloneCentreOptions() {
      const listCentre = cloneDeep(this.listCentre);

      return [
        {
          value: null,
          name: this.$t('all.centre')
        },
        ...listCentre
      ];
    },
    cloneStatusOptions() {
      const statusOptions = cloneDeep(this.statusOptions);
      statusOptions[0].label = this.$t('all.status');

      return statusOptions;
    },
    cloneProductOptions() {
      const products: any[] = cloneDeep(this.products);

      return products.map((e) => {
        return {
          value: e.id,
          name: e.title
        };
      }).filter((e) => e.name.toLowerCase().indexOf(this.filterProductName.toLowerCase()) > -1);

      // return result;
    },
    startDatePicker: {
      get() {
        return this.filterBody.startDate ? moment(this.filterBody.startDate).utc(true).tz(moment.tz.guess()) : null;
      },
      set(value) {
        this.filterBody.startDate = value;
      }
    },
    endDatePicker: {
      get() {
        return this.filterBody.endDate ? moment(this.filterBody.endDate).utc(true).tz(moment.tz.guess()) : null;
      },
      set(value) {
        this.filterBody.endDate = value;
      }
    }
  }
})

export class ReportRedemptionProduct extends Vue {
  public filterBody = {
    startDate: null,
    endDate: null,
    // type: FilterProductType.All
  };
  public filterParams = {
    id: null,
    status: null,
  };
  public filterEnable: boolean = false;
  public filterProductName: string = '';
  public data: IRedemption[];
  public reportRedemptionNavigation: any[] = [
    {
      label: 'Product',
      path: ReportRouterPath.RedemptionProduct
    },
    {
      label: 'Transaction',
      path: ReportRouterPath.RedemptionTransaction
    }
  ];

  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        path: '/'
      },
      {
        label: 'Report',
        path: '',
      },
      {
        label: 'Redemption',
        path: '',
        current: true
      }
    ];
  }

  public columns = [
    {
      label: 'Product Name',
      prop: 'title',
      formatter: (model: IProduct) => {
        return model.title;
      },
      avatar: {
        formatter: (model: IProduct) => {
          return model ? {
            avatar: model.images.find((e) => e.isPrimary).src
          } : null;
        }
      }
    },
    {
      prop: 'id',
      label: 'Product ID',
      width: 100,
    },
    {
      label: 'Point',
      prop: 'point',
      width: 100,
      // formatter: (model: IProduct) => model.point
    },
    {
      prop: 'createdAt',
      label: 'Created at',
      width: 150,
      formatter: (model: any) => {
        return model.createdAt ? formatBirthday(model.createdAt) : '__';
      },
    },
    {
      prop: 'saleQuantity',
      label: 'order qty',
      width: 100,
    },
    {
      prop: 'status',
      label: `status`,
      formatter: (model: IProduct) => i18n().t(model.status) || '__',
      labelStyle: (model: IProduct) => model.status,
      width: 150
    }];

  public remoteMethod(query: string) {
    this.filterProductName = query;
  }

  public changeFilter(value, key) {
    this.filterParams[key] = value;
    this.filterEnable = true;
  }
  // public changeType(value: FilterProductType) {
  //   this.filterBody = {
  //     startDate: null,
  //     endDate: null,
  //     type: value
  //   };
  //   this.filterParams = {
  //     id: null,
  //     status: null
  //   };
  // }

  public exportToExcel() {
    const columns: any = cloneDeep(this.columns);

    const data = cloneDeep(this.data).map((redemption) => {
      const result: any = {};
      columns.forEach((column) => {
        result[column.label.toUpperCase()] = typeof column.formatter === 'function'
          ? column.formatter(redemption) : redemption[column.prop];
      });

      return result;
    });

    const startDate = moment(this.filterBody.startDate).utc(true).tz(moment.tz.guess()).format('DD-MM-YYYY'),
      endDate = moment(this.filterBody.endDate).utc(true).tz(moment.tz.guess()).format('DD-MM-YYYY');
    try {
      js2excel.default.json2excel({
        data,
        // tslint:disable-next-line:max-line-length
        name: `products_${startDate}_${endDate}`
      });
    } catch (error) {
      console.error('export error', error);
    }
  }
  public applyFilter() {
    this.filterEnable = false;
    this.fetchData();
  }

  protected mounted() {
    // this.$nextTick(() => {
    //   this.$store.dispatch(ActionTypeProduct.ProductFetchMany);
    // });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeReport.ReportProductsSetData, []);
    this.$store.commit(MutationTypeProduct.ProductRefreshModels, []);
  }

  private fetchData() {
    this.$store.commit(MutationTypeReport.ReportProductsSetData, []);
    this.$store.dispatch(ActionTypeReport.ReportProductsFilter, {
      filterBody: omit(cloneDeep(this.filterBody), ['type']),
      filterParams: this.filterParams
    });
  }
}
// const enum FilterProductType {
//   All = 'all',
//   Single = 'single'
// }
