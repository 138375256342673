import { stripObject } from '@hgiasac/helper';
import { cloneDeep } from 'lodash';
import { CMAButtonFilter } from 'root/components';
import { confirmAction } from 'root/helpers';
import { IAssignmentParamsFilter } from 'root/models';
import { IState } from 'root/store';
import { IPaginationResult } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypeAssignment, MutationTypeAssignment } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean
  },
  components: {
    'cma-btn-filter': CMAButtonFilter
  },
  computed: {
    ...mapState({
      assignmentLoading: (state: IState) => state.assignment.template.loading,
      assignmentList: (state: IState) => state.assignment.template.data,
      pagination: (state: IState) => state.assignment.template.pagination
    }),
    ...mapGetters(['gradeOptions', 'methodOptions']),
    cloneGradeOptions() {
      const gradeOptions = cloneDeep(this.gradeOptions);

      return [
        {
          value: null,
          label: this.$t('select')
        },
        ...gradeOptions
      ];
    },
    cloneMethodOptions() {
      const methodOptions = cloneDeep(this.methodOptions);

      return [
        {
          value: null,
          label: this.$t('select')
        },
        ...methodOptions
      ];
    }
  }

})

export class AssignmentTemplateCreateDialog extends Vue {

  public get leftForm() {
    return [
      {
        value: 'grade',
        options: this.cloneGradeOptions
      },
      {
        value: 'method',
        options: this.cloneMethodOptions
      }
    ];
  }
  public cloneMethodOptions: any;
  public cloneGradeOptions: any;
  public filterEnable: boolean = false;

  public form: IAssignmentParamsFilter = {
    grade: null,
    method: null,
    text: ''
  };

  public handleClickCloseDialog() {
    this.$emit('update:visible', false);
  }

  public beforeOpen() {
    this.$store.dispatch(ActionTypeAssignment.AssignmentTemplateFilter);
  }

  public applyFilter() {
    this.filterEnable = false;
    this.fetchData();
  }
  public changeFilter(v, name) {
    this.filterEnable = true;
    this.form[name] = v;
  }
  public select(model: any) {
    confirmAction(this, {
      title: this.$t('action.confirm'),
      message: 'Web site will direct to new page!',
      handleFunction: async () => {
        await this.$store.commit(MutationTypeAssignment.AssignmentTemplateSelect, model);
        this.$router.push('/assignments/new');
      }
    });
  }

  private fetchData(pagination?: IPaginationResult) {
    const filter = cloneDeep(this.form);
    const page = pagination ? pagination : this.$data.pagination;
    const formConverted: any = {
      params: {
        ...stripObject(filter),
        ...page
      }
    };
    this.$store.dispatch(ActionTypeAssignment.AssignmentTemplateFilter, formConverted);
  }
}
