import VueRouter from 'vue-router';
import { ActionContext } from 'vuex';

import { cmaHttpClient } from 'root/api/http';
import { parseParamUrl } from 'root/helpers';
import { IErrorException } from 'root/models';
import {
  // deleteTagForNotification,
  CMAAuth,
  // Logger
} from 'root/services';
import { i18n } from '../locales';
import { RouteDictionary } from '../router';
import { ActionType, IState, Layout, MutationType, TypeAlert } from './types';

export const actions = {
  // global
  [ActionType.InitializeSettings](
    { commit }: ActionContext<IState, IState>
  ) {
    const locale = 'en';
    i18n().locale = locale;
    commit(MutationType.SetCurrentLocale, locale);
  },
  [ActionType.SetLayout](
    { commit }: ActionContext<IState, IState>
  ) {
    commit(MutationType.SetLayout);
  },
  [ActionType.SetGlobalConfig](
    { commit }: ActionContext<IState, IState>,
    globalConfig
  ) {
    commit(MutationType.SetGlobalConfig, globalConfig);
  },
  [ActionType.GoBack](
    _: ActionContext<any, any>,
    router: VueRouter,
  ) {
    router.back();
  },
  [ActionType.GoToRoute](
    _: ActionContext<any, any>,
    { router, route, paramUrl }
  ) {
    const method = typeof route === 'string' ? 'push'
      : (route.method || 'push');
    router[method](parseParamUrl(route, paramUrl));
  },
  [ActionType.CollapseMenu](
    { commit }: ActionContext<any, any>,
    isCollapse: boolean,
  ) {
    commit(MutationType.SetMenuCollapse, isCollapse);
  },
  [ActionType.CatchException](
    { commit }: ActionContext<any, any>,
    ex: IErrorException
  ) {
    console.error(ex);
    const message = ex.code
      ? (i18n().t(ex.code) !== ex.code ? i18n().t(ex.code) : ex.message)
      : ex.message;
    commit(MutationType.OpenTopAlert, {
      message,
      type: TypeAlert.Error
    });
  },
  [ActionType.CloseTopAlert](
    { commit }: ActionContext<IState, IState>
  ) {
    commit(MutationType.CloseTopAlert);
  },
  [ActionType.GetProfile](
    {}: ActionContext<IState, IState>
  ) {
    return cmaHttpClient.profile.getProfile();
  },
  // login
  async [ActionType.ChangeStateAuth](
    { dispatch, commit, state }: ActionContext<IState, IState>,
    { router, user }
  ) {
    if (user) {
      // get profile 
      commit(MutationType.OpenPlashScreen);
      try {
        const profile = await dispatch(ActionType.GetProfile);
        commit(MutationType.ClosePlashScreen);
        // can not get profile, go back to login page
        if (!profile) {
          dispatch(ActionType.CatchException, {
            message: 'This user doesn\'t exist in CMA System'
          });

          commit(MutationType.SetLayout, Layout.Login);
          router.replace(RouteDictionary.Login);

          return CMAAuth.signOut();
        }
        // cmaHttpClient.global.getConfiguration().then((data) => {
        //   dispatch(ActionType.SetGlobalConfig, data);
        // });
        commit(MutationType.Authenticated, profile);
        commit(MutationType.SetLayout, Layout.Home);

        if (state.route.path === RouteDictionary.Login
          || state.route.path === RouteDictionary.Register
          || state.route.path === RouteDictionary.ForgotPassword) {
          router.replace(RouteDictionary.Home);
        }
      } catch (error) {
        await CMAAuth.signOut();
        dispatch(ActionType.CatchException, error);
        commit(MutationType.SetLayout, Layout.Login);
        commit(MutationType.ClosePlashScreen);
      }
    } else {
      commit(MutationType.ClosePlashScreen);
      commit(MutationType.SetLayout, Layout.Login);
      if (state.route.path !== RouteDictionary.Login) {
        router.replace(RouteDictionary.Login);
      }
    }
  },
  [ActionType.Unauthenticated](
    { commit, dispatch }: ActionContext<IState, IState>, router: VueRouter
  ) {
    commit(MutationType.OpenPlashScreen);
    CMAAuth.signOut()
      .then(() => {
        commit(MutationType.Unauthenticated);
        commit(MutationType.SetLayout, Layout.Login);
        commit(MutationType.ClearStore);
        dispatch(ActionType.ChangeStateAuth, {router, user: null});
      })
      .catch((error) => {
        dispatch(ActionType.CatchException, error);
      });
  }
};
