import * as js2excel from 'js2excel';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { CMAButtonFilter, CMAHeader, CMAListData, CMASelect } from 'root/components';
import { secondsToFormat } from 'root/helpers';
import { i18n } from 'root/locales';
import { GameType, IBreadcrumb, ISubscription } from 'root/models';
import { ActionTypePlan } from 'root/pages/Subscription/PlanStore/types';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ReportRouterPath } from '..';
import { ActionTypeReport, MutationTypeReport } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-list-data': CMAListData,
    'cma-btn-filter': CMAButtonFilter,
    'cma-header': CMAHeader,
    'cma-select': CMASelect
  },
  computed: {
    ...mapGetters(['gameTypeOptions']),
    ...mapState({
      data: (state: IState) => state.report.gameIndividuals,
      loading: (state: IState) => state.report.gameIndividualLoading
    }),
    cloneCentreOptions() {
      const listCentre = cloneDeep(this.listCentre);

      return [
        {
          value: null,
          name: this.$t('all.centre')
        },
        ...listCentre
      ];
    },
    cloneGameTypeOptions() {
      const gameTypeOptions = cloneDeep(this.gameTypeOptions);

      return gameTypeOptions.filter((e) => e.value !== GameType.Practices && e.value !== GameType.Assignment);
    },
    startDatePicker: {
      get() {
        return this.filterBody.startDate ? moment(this.filterBody.startDate).utc(true).tz(moment.tz.guess()) : null;
      },
      set(value) {
        this.filterBody.startDate = value;
      }
    },
    endDatePicker: {
      get() {
        return this.filterBody.endDate ? moment(this.filterBody.endDate).utc(true).tz(moment.tz.guess()) : null;
      },
      set(value) {
        this.filterBody.endDate = value;
      }
    }
  }
})

export class ReportIndividualGame extends Vue {
  public filterBody = {
    startDate: null,
    endDate: null
  };
  public filterParams = {
    text: null,
    typeGame: null
  };
  public filterEnable: boolean = false;
  public data: ISubscription[];

  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        path: '/'
      },
      {
        label: 'Report',
        path: '',
      },
      {
        label: 'Games',
        path: '',
        current: true
      }
    ];
  }

  public reportRedemptionNavigation: any[] = [
    {
      label: 'Smarter game',
      path: ReportRouterPath.SmarterGame
    },
    {
      label: 'Individual',
      path: ReportRouterPath.IndividualGame
    }
  ];

  public columns = [
    {
      label: 'Game Type',
      prop: 'typeGame',
      formatter: (model: any) => {
        return i18n().t(model.typeGame);
      }
    },
    {
      label: 'name',
      prop: 'name',
      formatter: (model: any) => {
        return model.owner ? model.owner.fullName : '';
      }
    },
    {
      label: 'method',
      prop: 'typeMethod',
      formatter: (model: any) => {
        return i18n().t(model.typeMethod);
      }
      // width: 170
    },
    {
      label: 'total question',
      prop: 'totalQuestion',
      formatter: (model: any) => model.metadata.totalQuestion,
    },
    {
      prop: 'correctQuestion',
      label: 'Total Correct answers',
      formatter: (model: any) => model.metadata.correctQuestion
      // width: 100,
    },
    {
      prop: 'wrongAnswer',
      label: 'Total Wrong Answers',
      // width: 200,
      formatter: (model: any) => model.metadata.totalQuestion - model.metadata.correctQuestion
    },
    {
      prop: 'time',
      label: 'Total Time',
      formatter: (model: any) => model.metadata.totalTime ? secondsToFormat(model.metadata.totalTime) : ''
    }, {
      prop: 'score',
      label: 'Point Earned',
      labelStyle: () => 'link'
    }];

  public changeFilter(value, key) {
    this.filterParams[key] = value;
    this.filterEnable = true;
  }

  public exportToExcel() {
    const columns: any = cloneDeep(this.columns);

    const data = cloneDeep(this.data).map((redemption) => {
      const result: any = {};
      columns.forEach((column) => {
        result[column.label.toUpperCase()] = typeof column.formatter === 'function'
          ? column.formatter(redemption) : redemption[column.prop];
      });

      return result;
    });

    const startDate = moment(this.filterBody.startDate).utc(true).tz(moment.tz.guess()).format('DD-MM-YYYY'),
      endDate = moment(this.filterBody.endDate).utc(true).tz(moment.tz.guess()).format('DD-MM-YYYY');

    try {
      js2excel.default.json2excel({
        data,
        // tslint:disable-next-line:max-line-length
        name: `i-game_${startDate}_${endDate}`
      });
    } catch (error) {
      console.error('export error', error);
    }
  }
  public applyFilter() {
    this.filterEnable = false;
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypePlan.planFetchMany);
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeReport.ReportGameIndividualSetData, []);
  }

  private fetchData() {
    this.$store.commit(MutationTypeReport.ReportGameIndividualSetData, []);
    this.$store.dispatch(ActionTypeReport.ReportGameIndividualFilter, {
      filterBody: cloneDeep(this.filterBody),
      filterParams: this.filterParams
    });
  }
}
