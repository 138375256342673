import { FooterGuest } from 'root/components/FooterGuest';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  template: require('./view.html'),
  components: {
    'footer-guest': FooterGuest
  },
})

export class LoginLayout extends Vue {

}
