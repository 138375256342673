import { exchangeRateDefault, ExchangeLayout, IExchangeRate } from 'root/models';
import { CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeExchangeRate } from './types';

export interface IExchangeRateState extends ICRUDState<IExchangeRate> {
  loading: boolean;
  current: IExchangeRate;
  layout: ExchangeLayout;
}

export const defaultState: IExchangeRateState = {
  ...CRUDState(),
  loading: false,
  current: exchangeRateDefault(),
  layout: ExchangeLayout.View,
  pagination: {
    size: 2
  }
};

export const mutations = {
  [MutationTypeExchangeRate.ExchangeRateSetData](state: IExchangeRateState, payload) {
    if (payload.current) {
      state.current = payload.current;
    }
    state.data = payload.data;
    state.pagination = payload.pagination;
  },
  [MutationTypeExchangeRate.ExchangeRateSetLayout](state: IExchangeRateState, layout: ExchangeLayout) {
    state.layout = layout;
  },
  [MutationTypeExchangeRate.ExchangeRateLoading](state: IExchangeRateState) {
    state.loading = true;
  },
  [MutationTypeExchangeRate.ExchangeRateLoaded](state: IExchangeRateState) {
    state.loading = false;
  },
  [MutationTypeExchangeRate.SetCurrentExchangeRate](state: IExchangeRateState, payload: IExchangeRate) {
    state.current = payload;
  }
};
