export enum ActionTypeReport {
  ReportLeaderBoardFilter = 'ReportLeaderBoardFilter',
  ReportRedemptionTransaction = 'ReportRedemptionTransaction',
  ReportProductsFilter = 'ReportProductsFilter',
  ReportSubscriptionFilter = 'ReportSubscriptionFilter',
  ReportGameFilter = 'ReportGameFilter',
  ReportGameIndividualFilter = 'ReportGameIndividualFilter',
  ReportUserStatisticFilter = 'ReportUserStatisticFilter',
  ReportAssignmentFilter = 'ReportAssignmentFilter',
  ReportAssignmentIndividualFilter = 'ReportAssignmentIndividualFilter',
}

export enum MutationTypeReport {
  ReportLeaderBoardSetData = 'ReportLeaderBoardSetData',
  ReportLeaderBoardLoading = 'ReportLeaderBoardLoading',
  ReportLeaderBoardLoaded = 'ReportLeaderBoardLoaded',
  ReportTransactionSetData = 'ReportTransactionSetData',
  ReportTransactionLoading = 'ReportTransactionLoading',
  ReportTransactionLoaded = 'ReportTransactionLoaded',

  ReportProductsSetData = 'ReportProductsSetData',
  ReportProductsLoading = 'ReportProductsLoading',
  ReportProductsLoaded = 'ReportProductsLoaded',

  ReportSubscriptionSetData = 'ReportSubscriptionSetData',
  ReportSubscriptionLoading = 'ReportSubscriptionLoading',
  ReportSubscriptionLoaded = 'ReportSubscriptionLoaded',

  ReportGameSetData = 'ReportGameSetData',
  ReportGameLoading = 'ReportGameLoading',
  ReportGameLoaded = 'ReportGameLoaded',

  ReportGameIndividualSetData = 'ReportGameIndividualSetData',
  ReportGameIndividualLoading = 'ReportGameIndividualLoading',
  ReportGameIndividualLoaded = 'ReportGameIndividualLoaded',

  ReportUserStatisticSetData = 'ReportUserStatisticSetData',
  ReportUserStatisticLoading = 'ReportUserStatisticLoading',
  ReportUserStatisticLoaded = 'ReportUserStatisticLoaded',

  ReportAssignmentSetData = 'ReportAssignmentSetData',
  ReportAssignmentLoading = 'ReportAssignmentLoading',
  ReportAssignmentLoaded = 'ReportAssignmentLoaded',

  ReportAssignmentIndividualSetData = 'ReportAssignmentIndividualSetData',
  ReportAssignmentIndividualLoading = 'ReportAssignmentIndividualLoading',
  ReportAssignmentIndividualLoaded = 'ReportAssignmentIndividualLoaded'
}
