import { i18n } from 'root/locales';
import { AssignmentTarget, GameType, LibraryStatus, MethodType, Operator } from 'root/models';
import { ILibraryState } from './mutations';
import { GetterTypeLibrary } from './types';

export const getters = {
  gradeOptions: () => {
    return [13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 'DAN'].map((e) => ({
      label: e,
      value: e === 'DAN' ? 0 : e
    }));

  },
  operatorOptions: () => [
    {
      label: Operator.Plus,
      value: Operator.Plus
    },
    {
      label: Operator.PlusAndSub,
      value: Operator.PlusAndSub
    },
    {
      label: Operator.Multi,
      value: Operator.Multi
    },
    {
      label: Operator.Div,
      value: Operator.Div
    }
  ],
  numberOptions() {
    const result = [];
    for (let index = 2; index <= 15; index++) {
      result.push({
        label: index,
        value: index
      });
    }

    return result;
  },
  digitOptions() {
    const result = [];
    for (let index = 1; index <= 11; index++) {
      result.push({
        label: index === 11 ? 'Mixed' : index,
        value: index === 11 ? 'Mixed' : index
      });
    }

    return result;
  },
  methodOptions() {
    return [
      {
        label: i18n().t(`${MethodType.AB}`),
        value: MethodType.AB
      },
      {
        label: i18n().t(`${MethodType.AMA}`),
        value: MethodType.AMA
      },
      {
        label: i18n().t(`${MethodType.FM}`),
        value: MethodType.FM
      },
      {
        label: i18n().t(`${MethodType.MA}`),
        value: MethodType.MA
      },
    ];
  },
  gameTypeOptions() {
    const result = [];
    for (const key in GameType) {
      // tslint:disable-next-line:early-exit
      if (GameType.hasOwnProperty(key)) {
        result.push({
          label: i18n().t(`${GameType[key] || 'all'}`),
          value: GameType[key] || null
        });
      }
    }

    return result;
  },
  statusOptions: () => [
    {
      label: 'Status',
      value: null
    },
    {
      label: i18n().t(LibraryStatus.Active),
      value: LibraryStatus.Active
    },
    {
      label: i18n().t(LibraryStatus.suspended),
      value: LibraryStatus.suspended
    }
  ],
  targetOptions: () => [
    {
      label: 'Target',
      value: null
    },
    {
      label: i18n().t(AssignmentTarget.Broadcast),
      value: AssignmentTarget.Broadcast
    },
    {
      label: i18n().t(AssignmentTarget.Group),
      value: AssignmentTarget.Group
    },
    {
      label: i18n().t(AssignmentTarget.Single),
      value: AssignmentTarget.Single
    }
  ],
  decimalOptions: () => {
    const result = [];
    for (let index = 0; index <= 11; index++) {
      result.push({
        label: index === 0 ? 'Select' : index === 11 ? 'Mixed' : index,
        value: index === 11 ? 'Mixed' : index
      });
    }

    return result;
  },
  [GetterTypeLibrary.getLibraryById](state: ILibraryState) {
    return (id: number) => {
      return state.cases.find((e) => e.id === id);
    };
  }
};
