import { IPlan } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypePlan } from './types';

export interface IPlanState extends ICRUDState<IPlan> {
  //
}

export const defaultState: IPlanState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations('plan'),
  [MutationTypePlan.PlanLoading](state: IPlanState) {
    state.loading = true;
  },
  [MutationTypePlan.PlanLoaded](state: IPlanState) {
    state.loading = false;
  }
};
