import { PaginationState } from 'root/store';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeExamination } from './types';

export interface IExaminationState extends ICRUDState<any> {
  loading: boolean;
}

export const defaultState: IExaminationState = {
  ...CRUDState()
};

export const mutations = {
  ...crudMutations('examination'),
  [MutationTypeExamination.SetData](state: IExaminationState, payload) {
    state.data = payload.data;
    state.pagination = payload.pagination;
  },
  [MutationTypeExamination.ClearData](state: IExaminationState) {
    state.data = [];
    state.pagination = PaginationState();
  }
};
