import { IDigitCfg } from './Assignment';
import { ISimpleUser } from './User';

export interface ILibraryConfig {
  mixed: boolean;
  operators: Operator[];
  operator: Operator;
  numbers: number;
  digitCfg: any[];
  digit?: number | string;
  isCurrency: boolean;
  decimal?: number;
  mixedDecimal?: boolean;
}
export interface ILibraryFormOutput {
  items: ILibraryFormOutputItem;
}
export interface ILibraryFormOutputItem extends ILibraryCase {
  key?: string;
}
export interface ILibraryInput {
  method: MethodType;
  grade: number | string;
  totalQuestion?: number;
  status?: string;
}
export interface ILibrary extends ILibraryInput {
  owner: ISimpleUser;
  createdAt: Date;
  id: number;
  cases: ILibraryCase[];
}

export interface ILibraryCase {
  totalQuestion?: number;
  id?: number;
  name: string;
  config: ILibraryCaseConfig;
  questions: ILibraryCaseQuestion[];
}
export interface ILibraryCaseConfig {
  operators?: Operator[];
  mixed?: boolean;
  mixedDecimal?: boolean;
  decimal: number | string;
  digitCfg?: IDigitCfg[];
  digit?: number | string;
  operator?: Operator;
  numbers?: number;
  isCurrency?: boolean;
  digit1?: number | string;
  digit2?: number | string;
}
export const libraryCaseConfigDefault = (): ILibraryCaseConfig => {
  return {
    decimal: 0,
    mixed: false,
    mixedDecimal: false,
    operators: [Operator.Plus]
  };
};

export interface ILibraryCaseQuestion {
  expression: string | string[];
  isCurrency: boolean;
}

export interface ILibraryParamsFilter {
  method: MethodType;
  grade: number;
  status: string;
  order?: string;
}

export enum MethodType {
  FM = 'fm',
  AMA = 'ama',
  Empty = '',
  MA = 'ma',
  AB = 'ab'
}
export enum GameType {
  Empty = '',
  Practices = 'practices',
  DailyBonus = 'daily_bonus',
  ExtraBonus = 'extra_bonus',
  SmarterGame = 'smarter_game',
  Assignment = 'assignment'
}

export const defaultLibraryFormOutput = (): ILibraryFormOutputItem[] => {
  return [];
};

export const defaultLibraryFormIutput = (): ILibraryInput => {
  return {
    grade: null,
    method: MethodType.Empty,
    // status: '',
    // totalQuestion: null
  };
};

export enum Operator {
  Plus = '+',
  PlusAndSub = '+/-',
  Multi = '*',
  Div = '/',
  Minus = '-'
}

export enum LibraryStatus {
  Active = 'active',
  suspended = 'suspended'
}
