export enum ActionTypeProduct {
  ProductCreate = 'productCreate',
  ProductUpdate = 'productUpdate',
  ProductDelete = 'productDelete',
  ProductFilterNoCache = 'productFilterNoCache',
  ProductFilterNoCacheNoLoading = 'productFilterNoCacheNoLoading',
  ProductFetchMany = 'productFetchMany',

  ProductFindById = 'productFindById',
  // transaction
  TransactionListFilter = 'TransactionListFilter',
  TransactionListUpdate = 'TransactionListUpdate',
  TransactionUserFilter = 'TransactionUserFilter',
  TransactionListOfProductFilter = 'TransactionListOfProductFilter',
}

export enum MutationTypeProduct {
  ProductLoading = 'productLoading',
  ProductLoaded = 'productLoaded',
  ProductFilterChange = 'productFilterChange',
  ProductRefreshModels = 'productRefreshModels',

  SetProductPagination = 'SetProductPagination',

  // transaction
  TransactionLoading = 'TransactionLoading',
  TransactionLoaded = 'TransactionLoaded',
  TransactionSetData = 'TransactionSetData',
  TransactionUpdateModel = 'TransactionUpdateModel',
  SetProductTransactionPagination = 'SetProductTransactionPagination',
  TransactionClearData = 'TransactionClearData',

  SetTransactionUser = 'SetTransactionUser',
  SetTransactionListOfProduct = 'SetTransactionListOfProduct',
  SetTransactionListOfProductLoading = 'SetTransactionListOfProductLoading',
  SetTransactionListOfProductLoaded = 'SetTransactionListOfProductLoaded'
}

export enum GetterTypeProduct {
  GetRedemption = 'getRedemption'
}
