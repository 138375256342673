import { cmaFormatDateFullTime, formatBirthday, fullNameUser } from 'root/helpers';
import { LibraryQuestion } from 'root/pages/Library/Components/LibraryQuestion';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

@Component({
  template: require('./view.html'),
  components: {
    'library-question': LibraryQuestion
  },
  computed: {
    ...mapState({
      detail: (state: IState) => state.assignment.detail,
      loading: (state: IState) => state.assignment.loading,
    })
  }
})

export class AssignmentDetailContent extends Vue {
  public cmaFormatDateFullTime = cmaFormatDateFullTime;
  public fullNameUser = fullNameUser;
  public formatBirthday = formatBirthday;

}
