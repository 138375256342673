import * as js2excel from 'js2excel';
import { cloneDeep, find } from 'lodash';
import moment from 'moment-timezone';
import { CMAButtonFilter, CMAHeader, CMAListData, CMASelect } from 'root/components';
import { formaterPhone, formatBirthday } from 'root/helpers';
import { IBreadcrumb, IRedemption, IUser } from 'root/models';
import { ActionTypeProduct } from 'root/pages/Product/Store/types';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypeReport, MutationTypeReport } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-list-data': CMAListData,
    'cma-btn-filter': CMAButtonFilter,
    'cma-header': CMAHeader,
    'cma-select': CMASelect
  },
  computed: {
    ...mapGetters(['RedemptionStatusOptions']),
    ...mapState({
      listCentre: (state: IState) => state.global.globalConfig.listCentre,
      data: (state: IState) => state.report.userStatistics,
      loading: (state: IState) => state.report.userStatisticLoading
    }),
    cloneCentreOptions() {
      const listCentre = cloneDeep(this.listCentre);

      return [
        {
          value: null,
          name: this.$t('all.centre')
        },
        ...listCentre
      ];
    },
    cloneStatusOptions() {
      const statusOptions = cloneDeep(this.RedemptionStatusOptions);
      statusOptions[0].label = this.$t('all.status');

      return statusOptions;
    },
    startDatePicker: {
      get() {
        return this.filterBody.startDate ? moment(this.filterBody.startDate).utc(true).tz(moment.tz.guess()) : null;
      },
      set(value) {
        this.filterBody.startDate = value;
      }
    },
    endDatePicker: {
      get() {
        return this.filterBody.endDate ? moment(this.filterBody.endDate).utc(true).tz(moment.tz.guess()) : null;
      },
      set(value) {
        this.filterBody.endDate = value;
      }
    }
  }
})

export class ReportUser extends Vue {
  public filterBody = {
    startDate: null,
    endDate: null
  };
  public filterParams = {
    productId: null,
    progress: null,
    text: '',
    centre: null
  };
  public filterEnable: boolean = false;
  public data: IRedemption[];

  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        path: '/'
      },
      {
        label: 'Report',
        path: '',
      },
      {
        label: 'User',
        path: '',
        current: true
      }
    ];
  }

  public columns = [
    {
      label: 'Name',
      prop: 'name',
      formatter: (model: IUser) => {
        return model.fullName;
      },
      avatar: {
        formatter: (model: IUser) => {
          return model || null;
        }
      },
      width: 160
    },
    {
      label: 'centre',
      prop: 'centre',
      width: 170,
      formatter: (model: IUser) => model.centre,
    },
    {
      label: 'gender',
      prop: 'gender',
      formatter: (model: IUser) => model.gender,
    },
    {
      prop: 'ownerId',
      label: 'ID',
      formatter: (model: IUser) => `ID ${model.id}`,
      width: 100,
    },
    {
      prop: 'email',
      label: 'Email',
      width: 200,
      labelStyle: () => 'lower-case'
    },
    {
      prop: 'phone',
      label: 'Phone',
      width: 170,
      formatter: (model: IUser) => model.phone && model.phone.number ? formaterPhone(model.phone) : ''
    },
    {
      prop: 'address',
      label: 'address',
      width: 200,
    },
    {
      prop: 'createdAt',
      label: 'Joined date',
      width: 100,
      formatter: (model: IUser) => {
        return model.createdAt ? formatBirthday(model.createdAt) : '__';
      },
    },
    {
      prop: 'abGrade',
      label: 'AB Grade',
      formatter: (model: IUser) => this.grade(model, 'ab'),
      width: 100
    },
    {
      prop: 'maGrade',
      label: 'MA Grade',
      formatter: (model: IUser) => this.grade(model, 'ma'),
      width: 100
    },
    {
      prop: 'amaGrade',
      label: 'AMA Grade',
      formatter: (model: IUser) => this.grade(model, 'ama'),
      width: 200
    }
  ];

  public grade(user: IUser, key: string) {
    const grade = <any> find(user.grade, (e: any) => e && e.type === key);

    if (!grade) {
      return '';
    }
    if (grade.grade === 0) {
      return 'DAN';
    }
    if (grade.grade === 14) {
      return 'Foundation';
    }

    if (key === 'ama') {
      return `${grade.grade.number} ${grade.grade.number > 1 ? 'numbers' : 'number'}
        ${grade.grade.digit} ${grade.grade.digit > 1 ? 'digits' : 'digit'}`;
    }

    return grade.grade;
  }

  public changeFilter(value, key) {
    this.filterParams[key] = value;
    this.filterEnable = true;
  }

  public exportToExcel() {
    const columns: any = cloneDeep(this.columns);

    const data = cloneDeep(this.data).map((redemption) => {
      const result: any = {};
      columns.forEach((column) => {
        result[column.label.toUpperCase()] = typeof column.formatter === 'function'
          ? column.formatter(redemption) : redemption[column.prop];
      });

      return result;
    });

    const startDate = moment(this.filterBody.startDate).utc(true).tz(moment.tz.guess()).format('DD-MM-YYYY'),
      endDate = moment(this.filterBody.endDate).utc(true).tz(moment.tz.guess()).format('DD-MM-YYYY');

    try {
      js2excel.default.json2excel({
        data,
        // tslint:disable-next-line:max-line-length
        name: `user_${startDate}_${endDate}`
      });
    } catch (error) {
      console.error('export error', error);
    }
  }
  public applyFilter() {
    this.filterEnable = false;
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeProduct.ProductFetchMany);
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeReport.ReportUserStatisticSetData, []);
  }

  private fetchData() {
    this.$store.commit(MutationTypeReport.ReportUserStatisticSetData, []);
    this.$store.dispatch(ActionTypeReport.ReportUserStatisticFilter, {
      filterBody: cloneDeep(this.filterBody),
      filterParams: this.filterParams
    });
  }
}
