import { IAssignment, IAssignmentFilterQuestion, IAssignmentParamsFilter, IAssignmentTemplateForm } from 'root/models';
// import { IPaginationResult } from 'root/store/helpers';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IAssignmentHttpService extends ICRUDHttpService<IAssignment> {
  filterQuestion: (form: IAssignmentFilterQuestion) => Promise<any[]>;
  assignmentTemplateFilter: (form: IAssignmentParamsFilter) => Promise<any[]>;
  getLibraryQuestionById(id: number): Promise<any>;
  getAssignmentByUserId(id: number, pagination: any): Promise<any>;
  filterUsersByAssignment(id: number, filter): Promise<any>;
  getUserByAssignment(assignmentId: number, userId: number): Promise<any>;
  getAssignmentStatistic(assignmentId: number): Promise<any>;

  createAssignmentTemplate(form: IAssignmentTemplateForm): Promise<any>;
  deleteAssignmentTemplate(id: number | string): Promise<any>;
  getAssignmentTemplate(id: number | string): Promise<any>;
  updateAssignmentTemplate(id: number | string, form: IAssignmentTemplateForm): Promise<any>;
}
export function AssignmentHttpService(options: IHttpServiceClientOptions): IAssignmentHttpService {

  const basePath = '/admin/assignments';
  const baseURL = options.httpService.getUrl(basePath, options.mock);

  function filterQuestion(form: IAssignmentFilterQuestion): Promise<any> {
    return options.httpService.post(`${baseURL}/questions`, form);
  }
  function assignmentTemplateFilter(form: IAssignmentParamsFilter): Promise<any[]> {
    const templateURL = options.httpService.getUrl(`/admin/template-assignments`, options.mock);

    return options.httpService.get(templateURL, form);
  }
  function getUserByAssignment(assignmentId: number, userId: number): Promise<any[]> {
    // tslint:disable-next-line:max-line-length
    const userURL = options.httpService.getUrl(`/admin/assignments/${assignmentId}/users/${userId}`, options.mock);

    return options.httpService.get(userURL);
  }

  function getLibraryQuestionById(id: number): Promise<any> {
    const questionURL = options.httpService.getUrl(`${basePath}/${id}/questions`, options.mock);

    return options.httpService.get(questionURL);
  }

  function getAssignmentByUserId(id: number, form: any): Promise<any> {
    const userURL = options.httpService.getUrl(
      `${basePath}/users/${id}`, options.mock);

    return options.httpService.get(userURL, form);
  }

  function filterUsersByAssignment(id: number, filter): Promise<any> {
    const order = !filter || !filter.order ? null
    : (filter.descending ? '-' + filter.order : filter.order);

    filter.order = order;

    const path = options.httpService.getUrl(`${basePath}/${id}/users`);

    return options.httpService.get(path, {
      params: filter
    });
  }

  function createAssignmentTemplate(form: IAssignmentTemplateForm): Promise<any> {
    const asTemplatePath = options.httpService.getUrl('/admin/template-assignments', options.mock);

    return options.httpService.post(asTemplatePath, form);
  }
  function updateAssignmentTemplate(id: string | number, form: IAssignmentTemplateForm): Promise<any> {
    const asTemplatePath = options.httpService.getUrl(`/admin/template-assignments/${id}`, options.mock);

    return options.httpService.put(asTemplatePath, form);
  }
  function getAssignmentTemplate(id: number | string): Promise<any> {
    const asTemplatePath = options.httpService.getUrl(`/admin/template-assignments/${id}`, options.mock);

    return options.httpService.get(asTemplatePath);
  }
  function deleteAssignmentTemplate(id: number | string): Promise<any> {
    const asTemplatePath = options.httpService.getUrl(`/admin/template-assignments/${id}`, options.mock);

    return options.httpService.del(asTemplatePath);
  }

  function getAssignmentStatistic(assignmentId: number): Promise<any> {
    const statisticPath = options.httpService.getUrl(`/admin/assignments/${assignmentId}/statistic`, options.mock);

    return options.httpService.get(statisticPath);
  }

  return {
    ...CRUDHttpService<IAssignment>({
      ...options,
      basePath
    }),
    filterQuestion,
    getLibraryQuestionById,
    getAssignmentByUserId,
    createAssignmentTemplate,
    assignmentTemplateFilter,
    deleteAssignmentTemplate,
    getAssignmentTemplate,
    updateAssignmentTemplate,
    filterUsersByAssignment,
    getUserByAssignment,
    getAssignmentStatistic
  };
}
