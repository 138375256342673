import { PositionType } from 'chart.js';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { getFirstDay, getLastDay, getRandomColor, parseTimeLocal } from 'root/helpers';
import { IStatisticFilter, StatisticType } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import { Bar } from 'vue-chartjs';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeDashboard, MutationTypeDashboard } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'bar-chart': Bar
  },
  computed: {
    ...mapState({
      subscription: (state: IState) => state.dashboard.subscription,
      loading: (state: IState) => state.dashboard.loading,
      subscriptionFilter: (state: IState) => state.dashboard.subscriptionFilter
    }),
    chartData() {
      const subscription: any[] = cloneDeep(this.subscription),
        subscriptionFilter: IStatisticFilter = this.subscriptionFilter;
      const labels: string[] = [];
      let datasets: any[] = [];
      if (subscription && subscription.length > 0) {
        let _data = subscription[0].data;
        _data = _data.map((e) => {
          const color = getRandomColor();

          return {
            fill: false,
            backgroundColor: color,
            borderColor: color,
            label: e.name,
            data: [e.countSubscription]
          };
        });
        datasets = _data;
      }

      subscription.map((e, index) => {
        let text: string = '';

        switch (subscriptionFilter.type) {
        case StatisticType.Day:
          // tslint:disable-next-line:max-line-length
          text = `${moment(e.period.startHours).utc(true).tz(moment.tz.guess()).format('H')}h-${moment(e.period.endHours).utc(true).tz(moment.tz.guess()).format('H')}h`;
          break;
        case StatisticType.Week:
        case StatisticType.Month:
          text = `${moment(e.period.startHours).utc(true).tz(moment.tz.guess()).format('DD MMM')}`;
        default:
          break;
        }
        labels.push(text);
        if (index > 0) {
          const currentDatas = e.data;
          currentDatas.map((currentData, _index) => {
            const datasetsIndex = datasets[_index];
            datasetsIndex.data.push(currentData.countSubscription);
            datasetsIndex.label = currentData.name;
            datasets[_index] = datasetsIndex;
          });
        }

        return;
      });

      return {
        datasets,
        labels
      };
    },
    timePicker: {
      get() {
        return moment(this.subscriptionFilter.startDate).utc(true).tz(moment.tz.guess());
      },
      set(value) {
        this.subscriptionFilter.startDate = value;
      }
    }
  }
})

export class DashboardSubscribed extends Vue {
  public $refs: {
    barChart: Bar
  };

  public subscriptionFilter: IStatisticFilter;
  public chartData: any;
  public subscription: any;

  private options = {
    responsive: true, maintainAspectRatio: false,
    drawOnChartArea: false,
    legend: {
      position: <PositionType> 'bottom',
    },
    scales: {
      xAxes: [{
        stacked: true,
        barPercentage: 0.8,
        gridLines: {
          drawOnChartArea: false
        }
      }],
      yAxes: [{
        stacked: true,
        gridLines: {
          drawOnChartArea: false
        }
      }]
    },
    elements: {
      line: {
        tension: 0.000001
      }
    },
  };

  public get getFormat(): string {
    if (this.subscriptionFilter.type === StatisticType.Month) {
      return 'MMM, yyyy';
    }
    if (this.subscriptionFilter.type === StatisticType.Week) {
      return 'Week WW yyyy';
    }

    return 'dd/MM/yyyy';
  }
  public get getTimePickerType(): string {
    if (this.subscriptionFilter.type === StatisticType.Month || this.subscriptionFilter.type === StatisticType.Week) {
      return this.subscriptionFilter.type;
    }

    return 'date';
  }

  public get formatterTitle(): string {
    switch (this.subscriptionFilter.type) {
    case StatisticType.Day:
      return parseTimeLocal(this.subscriptionFilter.startDate).toString();
    case StatisticType.Week:
    case StatisticType.Month:
      if (!this.subscription || this.subscription.length <= 0) {
        return '';
      }
      const dataLength = this.subscription.length;

      return `${parseTimeLocal(this.subscription[0].period.startHours)}
        -${parseTimeLocal(this.subscription[dataLength - 1].period.startHours)}`;
    default:
      break;
    }

    return '';
  }

  public async change(value: any, key: string) {
    const paramsFilter = this.subscriptionFilter;
    if (key === 'type') {
      this.subscriptionFilter.startDate = getFirstDay(value);
      this.subscriptionFilter.maxDate = value !== StatisticType.Day
        ? getLastDay(value, this.subscriptionFilter.startDate) : null;
    } else if (key === 'startDate') {
      if (this.subscriptionFilter.type === StatisticType.Week) {
        this.subscriptionFilter.startDate = getFirstDay('week', value);
      }
      this.subscriptionFilter.maxDate = paramsFilter.type === StatisticType.Day ? null
        : getLastDay(paramsFilter.type, this.subscriptionFilter.startDate);
    }
    this.fetchData();
  }
  protected mounted() {
    this.$nextTick(async () => {
      this.$store.commit(MutationTypeDashboard.DashboardClearSubscription);
      this.$store.commit(MutationTypeDashboard.DashboardClearSubscriptionFilter);
      await this.$store.dispatch(ActionTypeDashboard.DashboardFilterSubscription);
      this.$refs.barChart.renderChart(this.chartData, this.options);
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeDashboard.DashboardClearSubscription);
    this.$store.commit(MutationTypeDashboard.DashboardClearSubscriptionFilter);
  }

  private async fetchData() {
    const subscriptionFilter = cloneDeep(this.subscriptionFilter);
    if (subscriptionFilter.startDate && subscriptionFilter.type) {
      await this.$store.dispatch(ActionTypeDashboard.DashboardFilterSubscription);
      this.$refs.barChart.renderChart(this.chartData, this.options);
    }

    return;
  }
}
