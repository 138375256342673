import { IPlan } from './Plan';
import { IUser } from './User';

export interface ISubscriptionFilter {
  type?: string;
  processing?: string;
  from?: Date;
  to?: Date;
  title?: string;
}

export interface ISubscription {
  id: number;
  plan: IPlan;
  nextDateBilling: Date;
  processing: ProcessingSubscription;
  owner: IUser;
  updater?: IUser;
  createdAt: Date;
  updatedAt: Date;
}

export enum ProcessingSubscription {
Processing = 'processing',
Success = 'success',
Cancel = 'cancel',
CancelPeriodEndAt = 'cancel_at_period_end',
Fail = 'fail'
}
