import { Centre } from './Assignment';
import { phoneCodeDefault, Gender, IPhoneInput, StatusCode } from './Common';

export interface IUserRegisterForm {
  centreManager?: any;
  email: string;
  phone: IPhoneInput;
  birthday: Date;
  gender: Gender;
  // address: string;
  // postalCode: number;
  password?: string;
  retypePassword?: string;
  fullName: string;
  role: UserRole;
  avatar: string;
  grade: {
    ab: number,
    ma: number,
    ama: {
      digit: number,
      number: number
    }
  };
  centre: string;
}

export enum UserRole {
  Student = 'student',
  Teacher = 'teacher',
  CentreManager = 'centre_manager',
  Admin = 'admin',
  SuperAdmin = 'super_admin',
  Empty = ''
}

export const userRegisterFormDefault = (): IUserRegisterForm => {
  return {
    avatar: '',
    email: '',
    role: null,
    birthday: null,
    gender: null,
    phone: {
      code: phoneCodeDefault,
      number: ''
    },
    // postalCode: null,
    password: '',
    retypePassword: '',
    fullName: '',
    grade: {
      ab: null,
      ma: null,
      ama: {
        digit: null,
        number: null
      }
    },
    centre: ''
  };
};

export const userDefault = (): IUser => {
  return {
    ...userRegisterFormDefault(),
    id: null,
    level: null,
    score: null
  };
};

export interface IUser extends IUserRegisterForm {
  id?: number;
  level: number;
  score: number;
  grades?: any[];
  status?: StatusCode;
  address?: string;
  createdAt?: Date;
}

export interface ISimpleUser {
  email?: string;
  gender?: string;
  id: string | number;
  fullName: string;
  avatar: string;
  centre?: string;
}

export interface IUserParamsFilter {
  gender?: Gender;
  centre?: Centre;
  role?: UserRole;
  status?: string;
  order?: string;
  text?: string;
}
