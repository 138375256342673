import { cloneDeep, find } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import { stripObject } from '@hgiasac/helper';
import { CMAButtonFilter, CMAHeader, CMAListData, CMASelect } from 'root/components';
import { cmaFormatDateTime, formaterListCounter, fullNameUser } from 'root/helpers';
import { IBreadcrumb, ILibraryParamsFilter, IPagingParams, StatusCode } from 'root/models';
import { IState } from 'root/store';
import { ActionTypeLibrary, MutationTypeLibrary } from '../Store/types';
import { LibraryRouterPath } from '../index';
// import './style.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      pagination: (state: IState) => state.library.pagination,
      data: (state: IState) => state.library.data,
      loading: (state: IState) => state.library.loading
    }),
    ...mapGetters(['actionOption', 'gradeOptions', 'sortType',
      'methodOptions', 'statusOptions', 'paginationOption']),
    cloneActionOption() {
      const actionOption = cloneDeep(this.actionOption);

      return [...actionOption, 'delete'];
    },
    cloneGradeOptions() {
      const gradeOptions = cloneDeep(this.gradeOptions);

      return [
        {
          label: this.$t('all.grade'),
          value: null
        },
        ...gradeOptions
      ];
    },
    cloneMethodOptions() {
      const methodOptions = cloneDeep(this.methodOptions);

      return [
        {
          label: this.$t('all.method'),
          value: null
        },
        ...methodOptions
      ];
    }
  },
  components: {
    'cma-header': CMAHeader,
    'cma-select': CMASelect,
    'cma-list-data': CMAListData,
    'cma-btn-filter': CMAButtonFilter
  },
  watch: {
    paginationSize(newValue) {
      if (newValue) {
        this.$store.commit(MutationTypeLibrary.SetLibraryPagination, {
          size: newValue,
          page: 1
        });
        this.fetchData();
      }

      return;
    },
    'orderForm.value'(newValue: any) {
      this.$store.commit(MutationTypeLibrary.SetLibraryPagination, {
        page: 1,
        order: newValue,
        descending: this.orderForm.desc
      });
      this.fetchData();
    },
    'orderForm.desc'(newValue: any) {
      this.$store.commit(MutationTypeLibrary.SetLibraryPagination, {
        page: 1,
        order: this.orderForm.value,
        descending: newValue
      });
      this.fetchData();
    }
  }
})

export class LibraryList extends Vue {
  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Question Bank',
      path: '',
      current: true
    }
  ];

  public get columns() {
    return [
      {
        prop: 'grade',
        label: 'Grade',
        formatter: (model: any) => {
          if (model.grade === 0) {
            return this.$t('dan');
          }

          return model.grade;
        },
        width: 74
      },
      {
        prop: 'method',
        label: 'method',
        formatter: (model: any) => {
          return this.$t(model.method).toString();
        }
      },
      {
        prop: 'totalCase',
        label: 'total case',
        width: 66
      },
      {
        prop: 'totalQuestion',
        label: 'total questions',
        width: 92
      },
      {
        label: 'created by',
        formatter: (model: any) => {
          return fullNameUser(model.owner);
        },
        avatar: {
          formatter: (model: any) => {
            return model.owner ? model.owner : null;
          },
          icon: false
        }
      },
      {
        prop: 'createdAt',
        label: 'Created at',
        formatter: (model: any) => {
          return cmaFormatDateTime(model.createdAt);
        }
      },
      {
        prop: 'status',
        label: 'status',
        formatter: (model: any) => model.status ? this.$t(model.status) : '',
        labelStyle: (model: any) => {
          return model.status;
        }
      }];
  }

  public pagination: IPagingParams;
  public paginationSize: number = 20;
  public orderForm = {
    value: null,
    desc: false
  };

  public paramsFilter: ILibraryParamsFilter = {
    grade: null,
    method: null,
    status: null,
    order: null
  };
  public data: any[];
  public filterEnable: boolean = false;

  public get formaterListCounter() {
    return formaterListCounter(this.pagination, this.data.length);
  }

  public get librarySort() {
    return [
      {
        label: this.$t('none'),
        value: null
      },
      {
        label: this.$t('grade'),
        value: 'grade'
      },
      {
        label: this.$t('method'),
        value: 'method'
      }
    ];
  }

  public goAddNewRouter() {
    this.$router.push(LibraryRouterPath.LibraryNew);
  }
  public clickDetail(id: number) {
    this.$router.push(`${LibraryRouterPath.Library}/${id}`);
  }

  public handleUpdate(id: number) {
    this.$router.push(`${LibraryRouterPath.Library}/${id}/edit`);
  }

  public handleDisabled(model: any) {
    const modelTarget = find(cloneDeep(this.data), (e) => e.id.toString() === model.id);

    this.$store.dispatch(ActionTypeLibrary.LibraryUpdate, {
      id: model.id,
      form: {
        status: modelTarget.status === StatusCode.Active ? StatusCode.Suspended : StatusCode.Active
      },
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();

        this.$message({
          type: modelTarget.status === StatusCode.Active ? 'info' : 'success',
          message: this.$t(
            modelTarget.status === StatusCode.Active ? 'disabled_successfully' :  'active_successfully').toString()
        });
        this.fetchData();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public handleDelete(model: any) {
    this.$store.dispatch(ActionTypeLibrary.LibraryDelete, {
      id: model.id,
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();

        this.$message({
          type: 'info',
          message: this.$t('delete_successfully').toString()
        });
        this.fetchData();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }

  public changeFilter(v, name) {
    this.filterEnable = true;
    this.paramsFilter[name] = v;
  }

  public mounted() {
    this.$nextTick(() => {
      const data = cloneDeep(this.$data);

      if (data && data.length > 0) {
        this.$store.dispatch(ActionTypeLibrary.LibraryFilterNoCacheNoLoading);
      } else {
        this.$store.dispatch(ActionTypeLibrary.LibraryFilterNoCache);
      }
    });
  }

  public changePagination() {
    this.$store.dispatch(ActionTypeLibrary.LibraryFilterNoCache);
  }

  public applyFilter() {
    this.$store.commit(MutationTypeLibrary.LibraryPaginationReset);
    this.fetchData();
    this.filterEnable = false;
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeLibrary.LibraryFilterChange, {});
  }

  private fetchData() {
    const filter = cloneDeep(this.paramsFilter);

    const formConverted: any = {
      params: {
        filterParams: stripObject(filter)
      }
    };

    this.$store.dispatch(ActionTypeLibrary.LibraryFilterNoCache, formConverted);
  }
}
