import { cloneDeep } from 'lodash';
import { ILibraryCase } from 'root/models';
import { ActionTypeLibrary } from 'root/pages/Library/Store/types';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    method: String,
    grade: [Number, String],
    value: [String, Number]
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.library.cases
    }),
    caseNameOptions() {
      const data = cloneDeep(this.data);

      return data.map((e: ILibraryCase) => {
        return {
          ...e,
          label: e.name,
          value: e.id
        };
      });
    }
  }
})

export class CaseNameSelect extends Vue {
  public focus() {
    if (this.$props.method && (this.$props.grade || this.$props.grade === 0)) {
      this.$store.dispatch(ActionTypeLibrary.getLibraryAllCases, {
        method: this.$props.method,
        grade: this.$props.grade
      });
    }

    return;
  }

  public change(value) {
    this.$emit('change', value);
  }
}
