import { cloneDeep } from 'lodash';
import { ISubscription, UserRole } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';

import { CMAButtonFilter, CMAListData, CMASelect } from 'root/components';
import { formatBirthday, fullNameUser } from 'root/helpers';
import { IState } from 'root/store';
import { mapState } from 'vuex';
import { MutationTypeSubscription } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-list-data': CMAListData,
    'cma-select': CMASelect,
    'cma-btn-filter': CMAButtonFilter
  },
  props: {
    visible: Boolean,
    planId: [Number, String]
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.subscription.transaction.data,
      authUser: (state: IState) => state.global.authUser,
      listCentre: (state: IState) => state.global.globalConfig.listCentre,
    }),
    cloneCentreOptions() {
      let listCentre = cloneDeep(this.listCentre);
      const authUser = cloneDeep(this.authUser);

      if (authUser.role === UserRole.CentreManager) {
        listCentre = listCentre.filter((e) => authUser.centreManager.indexOf(e.value) > -1);
      }

      return [
        {
          value: null,
          name: this.$t('all.centre')
        },
        ...listCentre
      ];

    },
  }
})

export class PlanDetailView extends Vue {
  public cloneCentreOptions: any[];
  public paramsFilter = {
    centre: null
  };
  public filterEnable: boolean = false;
  public get filterOptions() {
    return {
      centre: this.cloneCentreOptions,
    };
  }
  public get columns() {
    return [
      {
        prop: 'name',
        label: `student's name`,
        formatter: (model: ISubscription) => fullNameUser(model.owner)
      }, {
        prop: 'centre',
        label: 'centre',
        formatter: (model: ISubscription) => model.owner.centre
      }, {
        prop: 'created at',
        label: 'create at',
        formatter: (model: ISubscription) => formatBirthday(model.createdAt)
      }, {
        prop: 'status',
        label: 'status',
        formatter: (model: ISubscription) => this.$t(model.processing),
        labelStyle: (model: ISubscription) => model.processing
      }];
  }

  public changeFilter(v: any, name: string) {
    this.filterEnable = true;
    this.paramsFilter[name] = v;
  }

  public applyFilter() {
    this.fetchData();
    this.filterEnable = false;
  }

  private fetchData() {
    this.$store.commit(MutationTypeSubscription.TransactionByPlanPaginationChange, {
      page: 1
    });
    this.$store.commit(MutationTypeSubscription.ClearTransactionByPlan);
    this.$emit('filter', this.paramsFilter);
  }
}
