import { stripObject } from '@hgiasac/helper';
import { omit } from 'lodash';
import { CMAHeader, CMASelect } from 'root/components';
import { formaterListCounter } from 'root/helpers';
import { planForm, IBreadcrumb, IPagingParams, IPlan, ISubscription } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { PlanDetailView } from '../Components/PlanDetailView';
import { ActionTypePlan, GetterTypePlan } from '../PlanStore/types';
import { ActionTypeSubscription, MutationTypeSubscription } from '../Store/types';
import { SubscriptionRouterName } from '../index';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'cma-select': CMASelect,
    'plan-detail-view': PlanDetailView
  },
  computed: {
    ...mapGetters(['paginationOption', 'sortType']),
    ...mapState({
      data: (state: IState) => state.subscription.transaction.data,
      loading: (state: IState) => state.subscription.transaction.loading,
      pagination: (state: IState) => state.subscription.transaction.pagination
    }),
  },
  watch: {
    'orderForm.value'(newValue: any) {
      this.$store.commit(MutationTypeSubscription.TransactionByPlanPaginationChange, {
        page: 1,
        size: this.pagination.size,
        order: newValue,
        descending: this.orderForm.desc
      });
      this.fetchData();
    },
    'orderForm.desc'(newValue: any) {
      this.$store.commit(MutationTypeSubscription.TransactionByPlanPaginationChange, {
        page: 1,
        size: this.pagination.size,
        order: this.orderForm.value,
        descending: newValue
      });
      this.fetchData();
    }
  }
})

export class PlanDetail extends Vue {
  public pagination: IPagingParams;
  public data: ISubscription[];
  public paginationSize: number = 20;
  public detail: IPlan = planForm();

  public orderForm = {
    value: null,
    desc: false
  };
  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Subscription',
      path: '/subscription/plan-management'
    },
    {
      label: 'Plan Management',
      path: '/subscription/plan-management'
    },
    {
      label: 'View Plan Detail',
      path: '',
      current: true
    }
  ];

  public get planId(): string {
    return this.$route.params.planId;
  }
  public get formaterListCounter() {
    return formaterListCounter(this.pagination, this.data.length);
  }

  public get productDetail(): IPlan {
    return this.$store.getters.getProductById(this.planId);
  }
  public get subscriptionSort() {
    return [
      {
        label: this.$t('none'),
        value: null
      },
      {
        label: 'Price',
        value: 'price'
      }
    ];
  }
  public edit() {
    this.$router.push({
      name: SubscriptionRouterName.PlanEdit,
      params: {
        planId: this.planId
      }
    });
  }
  public changePaginationFilter(value) {
    this.$store.commit(MutationTypeSubscription.TransactionByPlanPaginationChange, {
      size: value,
      page: 1
    });
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(async () => {
      this.fetchData();
      await this.$store.dispatch(ActionTypePlan.PlanFindById, {id: this.planId});
      this.detail = this.$store.getters[GetterTypePlan.GetPlanDetail](this.planId);
    });
  }
  private fetchData(filter = {}) {
    const page = this.pagination;

    const formConverted: any = {
      ...stripObject(filter),
      ...omit(page, ['totalPerPage', 'total', 'totalPages'])
    };

    this.$store.dispatch(ActionTypeSubscription.TransactionFilterByPlan, {
      id: this.planId,
      paramFilter: formConverted,
      onSuccess: () => {
        //
      },
      onFailure: () => {
        //
      }
    });
  }
}
