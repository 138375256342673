import { getFirstDay, getLastDay } from 'root/helpers';
import { IPlan } from './Plan';
import { IUser } from './User';

export interface IStatisticsUser {
  period: any;
  data: number;
}

export interface IStatisticFilter {
  type: StatisticType;
  startDate: Date | string;
  maxDate: Date | string;
}

export const defaultStatisticFilter = (): IStatisticFilter => {
  // const now = moment().startOf('month').format('YYYY-MM-DD');
  const now = getFirstDay('month');

  return {
    type: StatisticType.Month,
    startDate: now,
    maxDate: getLastDay('month')
  };
};
export enum StatisticType {
  Day = 'day',
  Month = 'month',
  Week = 'week'
}

export interface IPeriod {
  startHours: Date;
  endHours: Date;
}

export interface IStatisticCoolest {
  date: Date;
  data?: IUser;
}

export interface ISubscriptionStatistic extends IPlan {
  countSubscription: number;
}

export interface IRedemptionStatistic {
  progress: string;
  count: number;
}

export enum RedemptionStatusColor {
  processing = '#6496ff',
  ready = '#2457c2',
  completed = '#00548c',
  rejected = '#ff4949'
}
