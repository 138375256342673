import { find } from 'lodash';
import { i18n } from 'root/locales';
import { Gender, StatusCode, UserRole } from 'root/models';
import { GetterTypeGlobal, GetterTypeUser, IState } from './types';

export const getters = {
  statusOption() {
    return [{
      value: StatusCode.Active,
      label: i18n().t(StatusCode.Active).toString()
    }, {
      value: StatusCode.Deleted,
      label: i18n().t(StatusCode.Deleted).toString()
    }];
  },
  genderOption() {
    return [
      { value: Gender.Male, label: i18n().t(Gender.Male).toString() },
      { value: Gender.Female, label: i18n().t(Gender.Female).toString() },
    ];
  },
  actionOption() {
    return [
      'view',
      'edit',
      'disabled'
    ];
  },
  roleOptions() {
    return [
      {
        value: null,
        label: 'Select'
      },
      {
        label: i18n().t(UserRole.Student).toString(),
        value: UserRole.Student
      },
      {
        label: i18n().t(UserRole.Teacher).toString(),
        value: UserRole.Teacher
      },
      {
        label: i18n().t(UserRole.CentreManager).toString(),
        value: UserRole.CentreManager
      },
      {
        label: i18n().t(UserRole.Admin).toString(),
        value: UserRole.Admin
      },
      {
        label: i18n().t(UserRole.SuperAdmin).toString(),
        value: UserRole.SuperAdmin
      }
    ];
  },
  paginationOption: () => {
    return [10, 20, 50, 100].map((e) => {
      return {
        label: `${e} items per page`,
        value: e
      };
    });
  },
  [GetterTypeUser.PermissionViewAllCentre](state: IState) {
    const authUser = state.global.authUser,
      role = authUser ? authUser.role : null;

    return role === UserRole.SuperAdmin || role === UserRole.Admin || role === UserRole.CentreManager;
  },
  [GetterTypeUser.GetAuthuserRole](state: IState) {
    const authUser = state.global.authUser;

    return authUser ? authUser.role : null;
  },
  [GetterTypeGlobal.GetCentreName](state: IState) {
    return (value: string) => {
      const centre = state.global.globalConfig.listCentre.find((e) => e.value === value);

      return centre ? centre.name : '';
    };
  },
  [GetterTypeUser.PermissionEditRole](state: IState) {
    const authUser = state.global.authUser,
      role = authUser ? authUser.role : null;

    return role === UserRole.SuperAdmin;
  },
  [GetterTypeGlobal.GetCentreValue](state: IState) {
    return (name) => {
      const current = find(state.global.globalConfig.listCentre, (e) => e.name === name);

      return current ? current.value : '';
    };
  }
};
