import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
// import moment from 'moment';
import { CMAAvatar, CMAListData } from 'root/components';
import { fullNameUser, getFirstDay, getLastDay, parseTimeLocal } from 'root/helpers';
import { IStatisticFilter, StatisticType } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeDashboard, MutationTypeDashboard } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-list-data': CMAListData,
    'cma-avatar': CMAAvatar
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.dashboard.coolest,
      coolestLoading: (state: IState) => state.dashboard.coolestLoading,
      paramsFilter: (state: IState) => state.dashboard.coolestFilter
    }),
    cloneDataTable() {
      const data = this.data;
      if (!data) {
        return [];
      }
      // const result: any[] = [];
      if (this.paramsFilter.type === StatisticType.Day) {
        return data.data;
      }

      return Array.isArray(data) ? data.map((e) => {
        return {
          ...e.data,
          date: e.date
        };
      }) : [];
    },
    timePicker: {
      get() {
        return moment(this.paramsFilter.startDate).utc(true).tz(moment.tz.guess());
      },
      set(value) {
        this.paramsFilter.startDate = value;
      }
    }
  }
})

export class DashboardCoolest extends Vue {

  public get columns() {
    return [
      {
        prop: 'date',
        label: `Date`,
        formatter: (model: any) => parseTimeLocal(model.date.startHours)
      },
      {
        prop: 'fullName',
        label: 'name',
        formatter: fullNameUser,
        avatar: {
          formatter: (model: any) => {
            return model ? model : null;
          }
        },
        width: 200
      }, {
        prop: 'centre',
        label: 'centre',
        formatter: (model: any) => {
          return model && model.centre ? model.centre : '__';
        },
        width: 200
      }, {
        prop: 'level',
        label: 'level',
        formatter: (model: any) => {
          return model && model.level ? model.level : '__';
        }
      }, {
        prop: 'point',
        label: 'Total points',
        formatter: (model: any) => {
          return model && model.point ? model.point : '__';
        },
        labelStyle: () => 'link'
      }, {
        prop: 'pointDaily',
        label: `Today's points`,
        formatter: (model: any) => {
          return model && model.pointDaily ? model.pointDaily : '__';
        },
        labelStyle: () => 'link'
      }];
  }

  public get getFormat(): string {
    if (this.paramsFilter.type === StatisticType.Month) {
      return 'MMM, yyyy';
    }
    if (this.paramsFilter.type === StatisticType.Week) {
      return 'week WW yyyy';
    }

    return 'dd/MM/yyyy';
  }
  public get getTimePickerType(): string {
    if (this.paramsFilter.type === StatisticType.Month || this.paramsFilter.type === StatisticType.Week) {
      return this.paramsFilter.type;
    }

    return 'date';
  }

  public get formatterTitle(): string {
    switch (this.paramsFilter.type) {
    case StatisticType.Day:
      return parseTimeLocal(this.paramsFilter.startDate).toString();
    case StatisticType.Month:
    case StatisticType.Week:
      if (!this.cloneDataTable || this.cloneDataTable.length <= 0) {
        return '';
      }
      const length = this.cloneDataTable.length;

      return `${parseTimeLocal(this.cloneDataTable[0].date.startHours)}
      -${parseTimeLocal(this.cloneDataTable[length - 1].date.startHours)}`;
    default:
      break;
    }
  }
  public paramsFilter: IStatisticFilter;
  public cloneDataTable: any;

  public async change(value: any, key: string) {
    const paramsFilter = this.paramsFilter;
    if (key === 'type') {
      this.paramsFilter.startDate = getFirstDay(value);
      this.paramsFilter.maxDate = value !== StatisticType.Day ? getLastDay(value, this.paramsFilter.startDate) : null;
    } else if (key === 'startDate') {
      if (this.paramsFilter.type === StatisticType.Week) {
        this.paramsFilter.startDate = getFirstDay('week', value);
      }
      this.paramsFilter.maxDate = paramsFilter.type === StatisticType.Day ? null
        : getLastDay(paramsFilter.type, this.paramsFilter.startDate);
    }
    this.$store.commit(MutationTypeDashboard.DashboardSetCoolestFilter, paramsFilter);
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.commit(MutationTypeDashboard.DashboardClearCoolest);
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationTypeDashboard.DashboardClearCoolest);
  }
  private fetchData() {
    const paramsFilter = cloneDeep(this.paramsFilter);
    this.$store.dispatch(ActionTypeDashboard.DashboardFilterCoolest, paramsFilter);
  }
}
