import { stripObject } from '@hgiasac/helper';
import { cloneDeep, find } from 'lodash';
import * as moment from 'moment';
import { CMAButtonFilter, CMAListData, CMASelect } from 'root/components';
import { cmaFormatDateFullTime, fullNameUser } from 'root/helpers';
import { transactionHistoryParamsDefault, IProduct, ITransactionHistoryParams, UserRole } from 'root/models';
import { GetterTypeUser, IState } from 'root/store';
import { IPaginationResult } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypeProduct } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-btn-filter': CMAButtonFilter,
    'cma-list-data': CMAListData,
    'cma-select': CMASelect
  },
  props: {
    productDetail: Object
  },
  computed: {
    ...mapGetters([GetterTypeUser.PermissionViewAllCentre]),
    ...mapState({
      listCentre: (state: IState) => state.global.globalConfig.listCentre,
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.product.productTransaction.data,
      pagination: (state: IState) => state.product.productTransaction.pagination,
      loading: (state: IState) => state.product.productTransaction.loading,
    }),
    cloneCentreOptions() {
      let listCentre = cloneDeep(this.listCentre);
      const authUser = cloneDeep(this.authUser);

      if (authUser.role === UserRole.CentreManager) {
        listCentre = listCentre.filter((e) => authUser.centreManager.indexOf(e.value) > -1);
      }

      return [
        {
          value: null,
          name: this.$t('all.centre')
        },
        ...listCentre
      ];
    },
  },
  watch: {
    'paramsFilter.from'() {
      this.filterEnable = true;
    },
    'paramsFilter.to'() {
      this.filterEnable = true;
    },
  }
})

export class TransactionHistory extends Vue {
  public filterEnable: boolean = false;
  public pagination: IPaginationResult;
  public cmaFormatDateFullTime = cmaFormatDateFullTime;
  public paramsFilter: ITransactionHistoryParams = transactionHistoryParamsDefault();

  public get columns() {
    return [
      {
        prop: 'owner',
        label: 'Redeemed by',
        formatter: (model: any) => {
          return fullNameUser(model.owner);
        },
        avatar: {
          formatter: (model: any) => {
            return model.owner ? model.owner : null;
          },
          icon: false
        }
      },
      {
        prop: 'centre',
        label: 'centre',
        formatter: (model: any) => {
          return model.owner.centre;
        },
      },
      {
        prop: 'createdAt',
        label: 'Redeemed At',
        formatter: (model: any) => {
          return cmaFormatDateFullTime(model.createdAt);
        },
      },
      {
        prop: 'qty',
        label: 'Quantity',
        align: 'center',
      },
      {
        prop: 'progress',
        label: 'status',
        labelStyle: (model: any) => {
          return `text--capitalize redemption__status outline-none ${model.progress}`;
        },
        width: 100
      }];
  }

  public get _productDetail(): IProduct {
    return this.$props.productDetail;
  }
  public get primaryImageURL(): string {
    const productDetail = cloneDeep(this._productDetail),
      images = productDetail ? productDetail.images : null;

    if (!images) {
      return '';
    }

    return find(images, (e) => {
      return e.isPrimary;
    }).src;
  }

  public changeFilter(v, name) {
    this.filterEnable = true;
    this.paramsFilter[name] = v;
  }
  public applyFilter() {
    this.fetchData();
  }
  public changePagination() {
    this.fetchData();
  }
  private fetchData() {
    const params = cloneDeep(this.paramsFilter);
    let paramsConverted = {};
    if (params) {
      paramsConverted = stripObject({
        ...params,
        ...this.pagination,
        from: params.from ? moment(params.from).utc().format('YYYY-MM-DD HH:mm:ss') : '',
        to: params.to ? moment(params.to).utc().format('YYYY-MM-DD HH:mm:ss') : ''
      });
    }
    this.$store.dispatch(ActionTypeProduct.TransactionListOfProductFilter, {
      id: this._productDetail.id,
      params: {
        ...paramsConverted
      }
    });
  }
}
