import { cloneDeep, find } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import { stripObject } from '@hgiasac/helper';
import * as moment from 'moment';
import { CMAButtonFilter, CMAHeader, CMAListData, CMASelect } from 'root/components';
import { formaterListCounter, fullNameUser } from 'root/helpers';
import { IBreadcrumb, IPagingParams, IUser, IUserParamsFilter, StatusCode, UserRole } from 'root/models';
import { GetterTypeUser, IState } from 'root/store';
import { IPaginationResult } from 'root/store/helpers';
import { ActionTypeUser, MutationTypeUser } from '../Store/types';
import { userRouterName, userRouterPath } from '../index';
import './style.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      pagination: (state: IState) => state.user.pagination,
      data: (state: IState) => state.user.data,
      loading: (state: IState) => state.user.loading,
      listCentre: (state: IState) => state.global.globalConfig.listCentre,
      authUser: (state: IState) => state.global.authUser
    }),
    ...mapGetters(['actionOption', 'genderOptions', 'roleOptions',
      'statusOptions', 'paginationOption', 'sortType', GetterTypeUser.PermissionViewAllCentre]),
    cloneCentreOptions() {
      let listCentre = cloneDeep(this.listCentre);
      const authUser = cloneDeep(this.authUser);

      if (authUser.role === UserRole.CentreManager) {
        listCentre = listCentre.filter((e) => authUser.centreManager.indexOf(e.value) > -1);
      }

      return [
        {
          value: null,
          name: this.$t('all.centre')
        },
        ...listCentre
      ];
    },
    cloneGenderOptions() {
      const genderOptions = cloneDeep(this.genderOptions);

      return [
        {
          label: this.$t('all.gender'),
          value: null
        },
        ...genderOptions
      ];
    },
    permissionViewRole() {
      const authUser = cloneDeep(this.authUser);

      return authUser.role !== UserRole.Teacher;
    },
    cloneActionOption() {
      const actionOption = cloneDeep(this.actionOption);

      return [...actionOption, 'delete'];
    }
  },
  components: {
    'cma-header': CMAHeader,
    'cma-select': CMASelect,
    'cma-list-data': CMAListData,
    'cma-btn-filter': CMAButtonFilter
  },
  watch: {
    paginationSize(newValue) {
      if (newValue) {
        this.$store.commit(MutationTypeUser.SetUserPagination, {
          size: newValue,
          order: this.orderForm.value,
          descending: this.orderForm.desc,
          page: 1
        });
        this.fetchData();
      }

      return;
    },
    'orderForm.value'(newValue: any) {
      this.$store.commit(MutationTypeUser.SetUserPagination, {
        page: 1,
        order: newValue,
        descending: this.orderForm.desc
      });
      this.fetchData();
    },
    'orderForm.desc'(newValue: any) {
      this.$store.commit(MutationTypeUser.SetUserPagination, {
        page: 1,
        order: this.orderForm.value,
        descending: newValue
      });
      this.fetchData();
    }
  }
})

export class UsersList extends Vue {

  public get columns() {
    return [
      {
        label: 'Student ID',
        formatter: (model: any) => model.username || '__',
        width: 100
      },
      {
        label: 'Full name',
        formatter: (model: any) => {
          return fullNameUser(model);
        },
        avatar: {
          formatter: (model: any) => {
            return model ? model : null;
          }
        }
      },
      {
        prop: 'gender',
        label: 'Gender',
        width: 74
      },
      {
        prop: 'birthday',
        label: 'date of birth',
        formatter: (model: any) => {
          return moment(model.birthday).format('DD/MM/YYYY');
        },
        width: 100
      },
      {
        prop: 'centre',
        label: 'centre',
        width: 160
      },
      {
        prop: 'role',
        label: 'role',
        formatter: (model: any) => model.role ? this.$t(model.role) : '',
        width: 92
      },
      {
        prop: 'point',
        label: 'points',
        width: 100
      },
      {
        prop: 'level',
        label: 'level',
        width: 62
      },
      {
        prop: 'status',
        label: 'status',
        formatter: (model: any) => model.status ? this.$t(model.status) : '',
        labelStyle: (model: any) => {
          return model.status;
        },
        width: 100
      }];
  }

  public get filterOptions() {
    return {
      gender: this.cloneGenderOptions,
      centre: this.cloneCentreOptions,
      role: this.cloneRoleOptions,
      status: this.statusOptions
    };
  }

  public get formaterListCounter() {
    return formaterListCounter(this.pagination, this.data.length);
  }
  public get userSort() {
    return [
      {
        label: this.$t('none'),
        value: null
      },
      {
        label: this.$t('level'),
        value: 'level'
      },
      {
        label: this.$t('fullName'),
        value: 'fullName'
      }
    ];
  }

  public get cloneRoleOptions() {
    const role: UserRole = this.$store.getters[GetterTypeUser.GetAuthuserRole];
    let roleOptions = cloneDeep(this.roleOptions);

    roleOptions[0].label = this.$t('all.role');
    if (role === UserRole.Admin) {
      roleOptions = roleOptions.filter((e) => e.value !== UserRole.SuperAdmin && e.value !== UserRole.Admin);
    }
    if (role === UserRole.Teacher) {
      roleOptions = roleOptions.filter((e) => e.value !== UserRole.SuperAdmin && e.value !== UserRole.Admin);
    }

    if (role === UserRole.CentreManager) {
      roleOptions = roleOptions.filter((e, index) => index === 0
        || e.value === UserRole.Student || e.value === UserRole.Teacher);
    }

    return roleOptions;
  }

  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'User management',
      path: '',
      current: true
    }
  ];
  public cloneGenderOptions: any;
  public listCentre: any[];
  public cloneCentreOptions: any;
  public roleOptions: any[];
  public filterEnable: boolean = false;
  public statusOptions: any;
  public paramsFilter: IUserParamsFilter = {
    centre: null,
    gender: null,
    role: null,
    status: null,
    order: null,
    text: ''
  };
  public pagination: IPagingParams;
  public paginationSize: number = 20;
  public data: any[];
  public orderForm = {
    value: null,
    desc: false
  };

  public goAddNewRouter() {
    this.$router.push(userRouterPath.userAddNew);
  }
  public clickDetail(id: number) {
    this.$router.push({
      name: userRouterName.UserDetail,
      params: {
        userId: id.toString()
      }
    });
  }

  public applyFilter() {
    this.$store.commit(MutationTypeUser.SetUserPagination, {
      page: 1,
      order: this.orderForm.value,
      descending: this.orderForm.desc
    });
    this.fetchData();
    this.filterEnable = false;
  }

  public handleUpdate(id: number) {
    this.$router.push(`${userRouterPath.user}/${id}/edit`);
  }
  public handleDelete(model: any) {
    this.$store.dispatch(ActionTypeUser.SoftDelete, {
      id: model.id,
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();

        this.$message({
          type: 'info',
          message: this.$t('delete_successfully').toString()
        });
        this.fetchData();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public handleDisabled(model: any) {
    const data = cloneDeep(this.data),
      target: IUser = find(data, (e) => e.id.toString() === model.id.toString());

    this.$store.dispatch(ActionTypeUser.UserDelete, {
      id: model.id,
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();

        this.$message({
          type: target.status === StatusCode.Active ? 'info' : 'success',
          message: this.$t(
            target.status === StatusCode.Active ? 'disabled_successfully' :  'active_successfully').toString()
        });
        this.fetchData();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }

  public changeFilter(v, name) {
    this.filterEnable = true;
    this.paramsFilter[name] = v;
  }

  public formatterCentre(model: any) {
    if (model.role === UserRole.CentreManager) {
      const listCentre = cloneDeep(this.listCentre);
      const centreManagers: any[] = model.centreManager;

      return centreManagers.map((e) => {
        const current = find(listCentre, (l) => l.value === e);

        return current.name;
      }).join(', ');
      // return model.centreManager.join(', ');
    }

    return model.centre;
  }

  public changePagination(pagination: IPaginationResult) {
    this.$store.commit(MutationTypeUser.SetUserPagination, {
      page: pagination.page,
      order: this.orderForm.value,
      descending: this.orderForm.desc,
    });
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeUser.UserFilterNoCache);
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeUser.UserFilterChange, {});
  }
  private fetchData() {
    const filter = cloneDeep(this.paramsFilter);
    // console.log('filter', filter);
    const formConverted: any = {
      params: {
        filterParams: stripObject(filter)
      }
    };

    return this.$store.dispatch(ActionTypeUser.UserFilterNoCache, formConverted);
  }
}
