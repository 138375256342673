import { Form } from 'element-ui';
import { cloneDeep } from 'lodash';
import { cmaHttpClient } from 'root/api/http';
import { CMAHeader, CMAListData } from 'root/components';
import { cmaFormatDateFullTime, ruleArrayEmails, ruleRequired } from 'root/helpers';
import { IBreadcrumb, IUser, MethodType, UserRole } from 'root/models';
import { ActionType, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypeExamination, MutationTypeExamination } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'cma-list-data': CMAListData
  },
  computed: {
    ...mapGetters(['methodOptions']),
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.examination.data,
      pagination: (state: IState) => state.examination.pagination
    }),
    cloneMethod() {
      return [
        {
          label: this.$t(`${MethodType.AB}`),
          value: MethodType.AB
        },
        {
          label: this.$t(`${MethodType.AMA}`),
          value: MethodType.AMA
        },
        {
          label: this.$t(`${MethodType.MA}`),
          value: MethodType.MA
        },
      ];
    }
  }
})

export class Generate extends Vue {
  public $refs: {
    form: Form
  };
  public rules: any = {
    required: ruleRequired(),
    arrayEmails: ruleArrayEmails()
  };
  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Examination',
      path: '',
      current: true
    }
  ];
  public form: any = {
    type: '',
    email: ''
  };
  public loading: boolean = false;
  private authUser: IUser;
  public get columns() {
    return [
      {
        prop: 'user',
        label: 'User',
        formatter: (model) => model.owner.fullName,
        width: 100
      },
      {
        prop: 'emails',
        label: 'Email',
        formatter: (model) => {
          return model.emails.replace(/\,/g, ',\n');
        },
        labelStyle: () => 'lower-case pre-code'
      },
      {
        prop: 'createdAt',
        label: 'Created At',
        width: 150,
        formatter: (model) => {
          return cmaFormatDateFullTime(model.createdAt);
        }
      },
      {
        prop: 'sentStatus',
        label: 'status',
        width: 92,
        labelStyle: (model) => model.sentStatus
      },
      {
        label: 'remark',
        prop: 'remark',
        width: 92,
        formatter: () => 'Email Sent'
      },
    ];
  }

  public submit() {
    this.$refs.form.validate((isValid) => {
      if (isValid) {
        this.loading = true;
        const form = cloneDeep(this.form),
          { type } = form;
        let { email } = form;

        email = email.replace(/\n/gi, '');

        cmaHttpClient.examination.downloadGameExamination({
          type,
          emailReceivers: email
        }).then(() => {
          this.loading = false;
          this.$message.success('Generate Successfully!');
          this.$store.dispatch(ActionTypeExamination.FilterNoCache);
        }).catch((error) => {
          this.$store.dispatch(ActionType.CatchException, error);
          this.loading = false;
        });
      }

      return;
    });
  }

  public changePagination() {
    this.$store.dispatch(ActionTypeExamination.FilterNoCache);
  }

  protected mounted() {
    this.$nextTick(() => {
      if (this.authUser.role !== UserRole.Admin) {
        this.$router.replace('/users');

        return;
      }
      this.$store.dispatch(ActionTypeExamination.FilterNoCache);
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationTypeExamination.ClearData);
  }

  private downloadPDF(response: any, name: string) {
    const url = window.URL.createObjectURL(new Blob([response], {
      type: 'application/pdf'
    }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}.pdf`);
    document.body.appendChild(link);
    link.click();
  }
}
