import { stripObject } from '@hgiasac/helper';
import { cloneDeep, findIndex, omit } from 'lodash';
import { CMAButtonFilter } from 'root/components';
import { assignmentTargetFilterForm, AssignmentTarget,
  IAssignmentTargetFilterForm, ISimpleUser, UserRole } from 'root/models';
import { ActionTypeUser, MutationTypeUser } from 'root/pages/Users/Store/types';
import { GetterTypeUser, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import './style.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-btn-filter': CMAButtonFilter
  },
  props: {
    visible: Boolean,
    target: String,
    userSeleted: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    ...mapGetters(['genderOptions', 'ageOptions', 'levelOptions', GetterTypeUser.PermissionViewAllCentre]),
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      users: (state: IState) => state.user.data,
      userLoading: (state: IState) => state.user.loading,
      pagination: (state: IState) => state.user.pagination,
      filterParams: (state: IState) => state.user.filterParams,
      listCentre: (state: IState) => state.global.globalConfig.listCentre
    }),
    cloneUsers() {
      const users = cloneDeep(this.users),
        currentUserSeleted = cloneDeep(this.currentUserSeleted);

      return users.map((e) => {
        return {
          ...e,
          seleted: findIndex(currentUserSeleted, (uSeleted: ISimpleUser) => uSeleted.id === e.id) > -1
        };
      });
    },
    cloneGenderOptions() {
      const genderOptions = cloneDeep(this.genderOptions);

      return [
        {
          label: this.$t('select'),
          value: null
        },
        ...genderOptions
      ];
    },
    cloneCentreOptions() {
      let listCentre = cloneDeep(this.listCentre);
      const authUser = cloneDeep(this.authUser);

      if (authUser.role === UserRole.CentreManager) {
        listCentre = listCentre.filter((e) => authUser.centreManager.indexOf(e.value) > -1);
      }

      return [
        {
          value: null,
          name: this.$t('all.centre')
        },
        ...listCentre
      ];
    },
    isSuperAdmin() {
      const authUser = cloneDeep(this.authUser);

      return authUser.role === UserRole.SuperAdmin;
    }
  }
})

export class AssignmentTargetDialog extends Vue {

  public get leftForm() {
    return [
      {
        value: 'centre',
        options: this.cloneCentreOptions
      },
      {
        value: 'gender',
        options: this.cloneGenderOptions
      },
      {
        value: 'age',
        options: this.ageOptions
      },
      // {
      //   value: 'level',
      //   options: this.levelOptions
      // }
    ];
  }
  public form: IAssignmentTargetFilterForm = assignmentTargetFilterForm();
  public levelOptions: any[];
  public cloneCentreOptions: any[];
  public cloneGenderOptions: any[];
  public ageOptions: any[];
  // public pagination: IPaginationResult;
  public target: AssignmentTarget;
  public userSeleted: ISimpleUser[]; // from props
  public currentUserSeleted: ISimpleUser[] = [];
  public filterParams: any;
  public filterEnable = false;

  public applyFilter() {
    this.$store.commit(MutationTypeUser.SetUserPaginationSort);
    this.filterEnable = false;
    this.search();
  }

  public changeFilter(v, name) {
    this.filterEnable = true;
    this.form[name] = v;
  }

  public beforeOpen() {
    this.$store.commit(MutationTypeUser.SetUserPaginationSort);
    this.$store.dispatch(ActionTypeUser.UserFilterNoCache, {
      params: {
        filterParams: {
          role: UserRole.Student
        }
      }
    });
    this.currentUserSeleted = cloneDeep(this.userSeleted);
  }
  public handleClickCloseDialog() {
    this.$store.commit(MutationTypeUser.UserResetStore);
    this.resetComponent();
    this.$emit('cancel');
  }
  public save() {
    const userSeleted = cloneDeep(this.currentUserSeleted);
    this.$emit('changeUserSeleted', userSeleted);
    this.$store.commit(MutationTypeUser.UserResetStore);
    this.resetComponent();
    this.$emit('update:visible', false);
  }

  public disabledSelect(model: any) {
    if (this.target === AssignmentTarget.Group) {
      return model.seleted;
    }

    return !!(this.currentUserSeleted.length > 0);
  }

  public currentChange() {
    this.search();
  }

  public selectUser(model: any) {
    const isSeleted = model.seleted;
    if (!isSeleted) {
      this.currentUserSeleted.push(model);
    }

    return;
  }
  public removeSelectUser(model: any) {
    this.currentUserSeleted = this.currentUserSeleted.filter((e) => e.id !== model.id);
  }

  private search() {
    const form = cloneDeep(this.form);

    const filter: any = {
      ...form
    };

    const formConverted: any = {
      params: {
        filterParams: {
          ...stripObject(omit(filter, 'target')),
          role: UserRole.Student
        }
      }
    };

    this.$store.dispatch(ActionTypeUser.UserFilterNoCache, formConverted);
  }

  private resetComponent() {
    this.currentUserSeleted = [];
    this.form = assignmentTargetFilterForm();
    this.$store.commit(MutationTypeUser.UserPaginationReset);
    this.$store.commit(MutationTypeUser.UserFilterChange, {});
  }
}
