import { CMAAvatar, CMAHeader } from 'root/components';
import { cmaFormatDateFullTime, fullNameUser } from 'root/helpers';
import { IBreadcrumb } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { LibraryQuestion } from '../Components/LibraryQuestion';
import { ActionTypeLibrary, MutationTypeLibrary } from '../Store/types';
import { LibraryRouterName, LibraryRouterPath } from '../index';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'cma-avatar': CMAAvatar,
    'library-question': LibraryQuestion
  },
  computed: {
    ...mapState({
      detail: (state: IState) => state.library.detail,
      questions: (state: IState) => state.library.questions,
      loading: (state: IState) => state.library.loading,
      questiosLoading: (state: IState) => state.library.questiosLoading
    })
  },
  beforeRouteLeave(to, _, next) {
    if (to.name !== LibraryRouterName.LibraryEdit) {
      this.$store.commit(MutationTypeLibrary.ResetLibraryDetail);
    }

    next();
  }
})

export class LibraryDetail extends Vue {
  public cmaFormatDateTime = cmaFormatDateFullTime;
  public fullNameUser = fullNameUser;
  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Question Bank',
      path: LibraryRouterPath.Library
    },
    {
      path: '',
      current: true,
      label: 'Record Details'
    }
  ];

  public get libraryId() {
    return this.$route.params.libraryId;
  }

  public goEditPage() {
    this.$router.push(`${LibraryRouterPath.Library}/${this.libraryId}/edit`);
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeLibrary.getLibraryId, {
        id: this.libraryId
      });
      // this.$store.dispatch(ActionTypeLibrary.getLibraryQuestions, {
      //   id: this.libraryId
      // });
    });
  }

  // public beforeDestroy() {
  //   this.$store.commit(MutationTypeLibrary.ResetLibraryDetail);
  // }
}
