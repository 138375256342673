import { stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { cloneDeep, find, omit } from 'lodash';
import * as moment from 'moment';
import { cmaHttpClient } from 'root/api/http';
import { CMAAvatar, CMAHeader, CMASelect } from 'root/components';
import { ruleCompare, ruleEmail, ruleMinLength, ruleNumber, rulePhoneNumber, ruleRequired } from 'root/helpers';
import { phoneCodeDefault, userRegisterFormDefault, Gender, IBreadcrumb,
  ICentre, IUser, IUserRegisterForm, UserRole } from 'root/models';
import { uploadToAWS } from 'root/services';
import { ActionType, GetterTypeUser, IState, MutationType } from 'root/store/types';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { userRouterPath } from '..';
import { ActionTypeUser, MutationTypeUser } from '../Store/types';
// import { ActionTypeUser } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'cma-avatar': CMAAvatar,
    'cma-select': CMASelect
  },
  computed: {
    ...mapGetters(['genderOptions', 'roleOptions', 'gradeOptions',
      GetterTypeUser.PermissionViewAllCentre,
      GetterTypeUser.PermissionEditRole]),
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      detail: (state: IState) => state.user.detail,
      detailLoading: (state: IState) => state.user.loading,
      listCentre: (state: IState) => state.global.globalConfig.listCentre,
      listCountry: (state: IState) => state.global.globalConfig.listCountry
    }),
    cloneCentreOptions() {
      let listCentre = cloneDeep(this.listCentre);
      const authUser = cloneDeep(this.authUser);

      if (authUser.role === UserRole.CentreManager) {
        listCentre = listCentre.filter((e) => authUser.centreManager.indexOf(e.value) > -1);
      }

      return listCentre;
    },
    isSuperAdmin() {
      const authUser = cloneDeep(this.authUser);

      return authUser.role === UserRole.SuperAdmin;
    },
    formDisabled() {
      const authUser = cloneDeep(this.authUser),
        detail = cloneDeep(this.detail);

      return authUser.role !== UserRole.SuperAdmin && detail.role === UserRole.SuperAdmin;
    },
    phoneCodeOptions() {
      const listCountry = cloneDeep(this.listCountry);

      return listCountry.map((e: any) => {
        return {
          value: `+${e.phoneCode}`,
          label: `+${e.phoneCode}`
        };
      });
    }
  }
})

export class UserEditor extends Vue {
  public $refs: {
    form: Form
  };
  public authUser: IUser;
  public roleOptions: any[];
  public listCentre: ICentre[];
  public form: IUserRegisterForm = userRegisterFormDefault();
  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        path: '/'
      },
      {
        label: 'User management',
        path: userRouterPath.user
      },
      {
        label: this.isUpdating ? this.$t('edit').toString() : this.$t('add.new').toString(),
        path: '',
        current: true
      }
    ];
  }
  public get cloneRoleOptions() {
    const roleOptions = cloneDeep(this.roleOptions);

    roleOptions.splice(0, 1);
    roleOptions.splice(roleOptions.length - 1, 1);
    if (!this.isUpdating) {
      return roleOptions.filter((e) => e.value !== UserRole.Student);
    }

    return roleOptions;
  }

  public get isShowCentre() {
    return this.form.role === UserRole.Teacher || this.form.role === UserRole.CentreManager
    || this.isUpdating && this.form.role === UserRole.Student;
  }

  public file: any = null;
  public loading: boolean = false;
  public detail: IUser;

  public get userId(): string {
    return this.$route.params.userId;
  }

  public get isUpdating(): boolean {
    return !!this.userId;
  }

  public get showGrade(): boolean {
    return this.isUpdating && this.detail.role === UserRole.Student;
  }

  public get rules() {
    return {
      required: ruleRequired(),
      rulePhoneNumber: rulePhoneNumber(),
      min: ruleMinLength(8),
      ruleEmail: ruleEmail(),
      compare: ruleCompare(this.form.password),
      number: ruleNumber()
    };
  }

  public changeRole(v) {
    this.form.role = v;
    if (v === UserRole.CentreManager) {
      this.form.centre = <any> [];
    }
  }

  public async handlePreviewAvatar(file: any) {
    this.form.avatar = window.URL.createObjectURL(file.raw);
    this.file = file;
  }

  public async submit() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        this.loading = true;
        try {
          const file = cloneDeep(this.file);
          let avatar: string = this.form.avatar;
          if (file) {
            avatar = await uploadToAWS('avatar', file.raw,
              {maxHeight: 500, maxWidth: 5000, quality: .5});
          }
          let form = cloneDeep(this.form);
          form.avatar = avatar;
          form.birthday = <any> moment(form.birthday).format('YYYY-MM-DD');
          form = <any> omit(form, ['retypePassword', 'id', 'status', 'point']);
          if (!form.phone.number) {
            form = <any> omit(form, 'phone');
          } else {
            form.phone.number = form.phone.number.toString();
          }
          if (form.role === UserRole.Admin) {
            form = <any> omit(form, 'centre');
          }
          if (form.role !== UserRole.Student) {
            form = <any> omit(form, 'grade');
          }
          if (form.role === UserRole.CentreManager) {
            form.centreManager = form.centre;
            form.centre = UserRole.Empty;
          }

          if (this.isUpdating) { // update user profile
            form = <any> omit(form, ['centre', 'role', 'username']);
            form.gender = form.gender ? form.gender : Gender.Empty;

            if (this.authUser.id.toString() === this.userId) {
              // TO DO
              if (!form.password) {
                form = <any> omit(form, ['password', 'username']);
              }
              cmaHttpClient.profile.updateProfile(form).then((profile) => {
                this.$store.commit(MutationType.Authenticated, profile);
                this.loading = false;
                this.file = null;
                this.$router.push(userRouterPath.user);
              }).catch((error) => {
                this.$store.dispatch(ActionType.CatchException, error);
                this.loading = false;
              });

              return;
            }
            // TO DO
            if (!form.password) {
              form = <any> omit(form, ['password', 'username']);
              this.$store.dispatch(ActionTypeUser.UserUpdate, {
                form,
                id: this.userId,
                onSuccess: () => {
                  this.loading = false;
                  this.file = null;
                  this.$router.push(userRouterPath.user);
                },
                onFailure: () => {
                  this.loading = false;
                }
              });

              return;
            }
            await this.$store.dispatch(ActionTypeUser.ChangePassword, {
              id: this.userId,
              password: form.password
            });
            form = <any> omit(form, ['email', 'centre', 'role', 'password', 'username']);
            form.gender = form.gender ? form.gender : Gender.Empty;

            this.$store.dispatch(ActionTypeUser.UserUpdate, {
              form,
              id: this.userId,
              onSuccess: () => {
                this.loading = false;
                this.file = null;
                this.$router.push(userRouterPath.user);
              },
              onFailure: () => {
                this.loading = false;
              }
            });
          } else {
            this.$store.dispatch(ActionTypeUser.UserCreate, {
              form: stripObject(form),
              onSuccess: () => {
                this.loading = false;
                this.file = null;
                this.$router.push(userRouterPath.user);
              },
              onFailure: () => {
                this.loading = false;
              }
            });
          }

        } catch (error) {
          this.loading = false;
          this.$store.dispatch(ActionType.CatchException, error);
        }
      }

      return;
    });
  }

  public back() {
    this.$router.push(userRouterPath.user);
  }

  public roleShow(model: UserRole) {
    switch (this.checkPermission()) {
    case UserRole.SuperAdmin:
      return true;
    case UserRole.Admin:
      return model === UserRole.Teacher || model === UserRole.CentreManager;
    case UserRole.Teacher:
      return model === UserRole.Student;
    case UserRole.CentreManager:
      return model === UserRole.Teacher;
    default:
      break;
    }
  }

  protected mounted() {
    this.$nextTick(async () => {
      const listCentre = cloneDeep(this.listCentre);
      const userId = this.userId;

      if (userId) {
        await this.$store.dispatch(ActionTypeUser.GetUserById, userId);
        const detail = cloneDeep(this.detail),
          centre = find(listCentre, (e) => e.name === detail.centre);

        this.allowRouter(detail);
        this.form = {
          ...detail,
          grade: this.converGrade(detail.grade),
          centre: centre ? centre.value : '',
          phone: detail.phone ? {
            code: detail.phone.code,
            number: Number(detail.phone.number)
          } : {
            code: phoneCodeDefault,
            number: ''
          }
        };
        if (detail.role === UserRole.CentreManager) {
          this.form.centre = detail.centreManager;
        }
      } else {
        this.allowRouter();
        const _centre = find(listCentre, (e) => e.name === this.authUser.centre);

        this.form.centre = _centre ? _centre.value : '';
      }

      return;
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeUser.ClearUserDetail);
  }

  private converGrade(model: any) {
    return {
      ab: find(model, (e) => e.type === 'ab').grade,
      ma: find(model, (e) => e.type === 'ma').grade,
      ama: find(model, (e) => e.type === 'ama').grade
    };
  }

  private checkPermission(): UserRole {
    const authUser = cloneDeep(this.authUser);

    return authUser.role;
  }

  private allowRouter(userDetail?: IUser): boolean {
    const role = this.checkPermission();

    if (!this.isUpdating
        && role !== UserRole.Admin
        && role !== UserRole.CentreManager
        && role !== UserRole.SuperAdmin) {  // create new
      this.$message.error(this.$t('permission_denied').toString());
      this.$router.replace(userRouterPath.user);

      return;
    }

    if (this.isUpdating) {
      const userDetailRole = userDetail.role;
      if (userDetail.id === this.authUser.id) {
        return;
      }
      if ((role === UserRole.Teacher && userDetailRole !== UserRole.Student)
        || (role === UserRole.CentreManager
          && userDetailRole !== UserRole.Student && userDetailRole !== UserRole.Teacher)
        || (role === UserRole.Admin && userDetailRole === UserRole.Admin)
      ) {
        this.$message.error(this.$t('permission_denied').toString());
        this.$router.replace(userRouterPath.user);

        return;
      }
      // if (((role === UserRole.Teacher || role === UserRole.CentreManager) && userDetailRole !== UserRole.Student)
      //   || (role === UserRole.Admin && userDetailRole === UserRole.Admin)) {
      //   this.$message.error(this.$t('permission_denied').toString());
      //   this.$router.replace(userRouterPath.user);

      //   return;
      // }
    }

    return;
  }
}
