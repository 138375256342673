import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { getFirstDay, getLastDay, parseTimeLocal } from 'root/helpers';
import { IStatisticsUser, IStatisticFilter, StatisticType } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import { Line } from 'vue-chartjs';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeDashboard, MutationTypeDashboard } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'line-chart': Line
  },
  computed: {
    ...mapState({
      statisticsUser: (state: IState) => state.dashboard.statisticsUser,
      paramsFilter: (state: IState) => state.dashboard.userFilter
    }),
    timePicker: {
      get() {
        return moment(this.paramsFilter.startDate).utc(true).tz(moment.tz.guess());
      },
      set(value) {
        this.paramsFilter.startDate = value;
      }
    }
  }
})

export class DashboardNewUsers extends Vue {
  public loading: boolean = false;
  public statisticsUser: IStatisticsUser[];
  public $refs: {
    lineChart: Line
  };
  public paramsFilter: IStatisticFilter;
  public showChart: boolean = false;
  public options: any = {
    responsive: true, maintainAspectRatio: false,
    drawOnChartArea: false,
    legend: false,
    scales: {
      xAxes: [{
        gridLines: {
          drawOnChartArea: false
        }
      }],
      yAxes: [{
        gridLines: {
          drawOnChartArea: false
        }
      }]
    },
    elements: {
      line: {
        tension: 0.000001
      }
    },
  };
  public moment = moment;
  public get getFormat(): string {
    if (this.paramsFilter.type === StatisticType.Month) {
      return 'MMM, yyyy';
    }
    if (this.paramsFilter.type === StatisticType.Week) {
      return 'week WW yyyy';
    }

    return 'dd/MM/yyyy';
  }
  public get getTimePickerType(): string {
    if (this.paramsFilter.type === StatisticType.Month || this.paramsFilter.type === StatisticType.Week) {
      return this.paramsFilter.type;
    }

    return 'date';
  }

  public get formatterTitle(): string {
    switch (this.paramsFilter.type) {
    case StatisticType.Day:
      return parseTimeLocal(this.paramsFilter.startDate).toString();
    case StatisticType.Week:
    case StatisticType.Month:
      if (!this.statisticsUser || this.statisticsUser.length <= 0) {
        return '';
      }
      const dataLength = this.statisticsUser.length;

      return `${parseTimeLocal(this.statisticsUser[0].period.startHours)}
        -${parseTimeLocal(this.statisticsUser[dataLength - 1].period.startHours)}`;
    default:
      break;
    }

    return '';
  }

  public async filter(value?: any, key?: string) {
    const paramsFilter = this.paramsFilter;
    if (paramsFilter.type && paramsFilter.startDate) {
      if (key === 'type') {
        this.paramsFilter.startDate = getFirstDay(value);
        this.paramsFilter.maxDate = value !== StatisticType.Day ? getLastDay(value, this.paramsFilter.startDate) : null;
      } else if (key === 'startDate') {
        if (this.paramsFilter.type === StatisticType.Week) {
          this.paramsFilter.startDate = getFirstDay('week', value);
        }
        this.paramsFilter.maxDate = paramsFilter.type === StatisticType.Day ? null
          : getLastDay(paramsFilter.type, this.paramsFilter.startDate);
      }

      await this.$store.dispatch(ActionTypeDashboard.DashboardFilterNewUsers, paramsFilter);
      const statisticsUser = cloneDeep(this.statisticsUser);
      const labels: string[] = [];
      const data: number[] = [];

      statisticsUser.map((e) => {
        let text = '';
        switch (paramsFilter.type) {
        case StatisticType.Day:
          // tslint:disable-next-line:max-line-length
          text = `${moment(e.period.startHours).utc(true).tz(moment.tz.guess()).format('H')}h-${moment(e.period.endHours).utc(true).tz(moment.tz.guess()).format('H')}h`;
          break;
        case StatisticType.Week:
          text = `${moment(e.period.startHours).utc(true).tz(moment.tz.guess()).format('DD MMM')}`;
          break;
        case StatisticType.Month:
          text = `${moment(e.period.startHours).utc(true).tz(moment.tz.guess()).format('DD MMM')}`;
          break;
        default:
          break;
        }
        labels.push(text);
        data.push(e.data);
      });
      this.showChart = true;
      this.$refs.lineChart.renderChart({
        labels,
        datasets: [{
          data,
          label: '',
          fill: false,
          backgroundColor: '#2ea2f8',
          borderColor: '#2ea2f8'
        }]
      }, this.options);
    }

    return;
  }
  protected mounted() {
    this.$nextTick(async () => {
      this.$store.commit(MutationTypeDashboard.DashboardClearUserFilter);
      this.filter();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationTypeDashboard.DashboardClearUserFilter);
  }
}
