import { cmaFormatDateFullTime } from 'root/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean,
    data: {
      type: Object,
      default: () => {
        return {
          createdAt: null,
          id: null,
          library: null,
          owner: {
            fullName: '',
            id: null
          },
          cases: [],
          title: '',
          desc: ''
        };
      }
    }
  }
})

export class UserAssignmentDialog extends Vue {
  public cmaFormatDateFullTime = cmaFormatDateFullTime;
  public beforeOpen() {
    //
  }
  public handleClickCloseDialog() {
    this.$emit('update:data', {
      createdAt: null,
      id: null,
      library: null,
      owner: {
        fullName: '',
        id: null
      },
      cases: [],
      title: '',
      desc: ''
    });
    this.$emit('update:visible', false);
  }
}
