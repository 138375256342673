import { HttpService, IHttpServiceOptions } from '../Core';

import { AssignmentHttpService, IAssignmentHttpService } from './Assignment';
import { DashboardHttpService, IDashboardHttpService } from './Dashboard';
import { ExaminationHttpService, IExaminationHttpService } from './Examination';
import { ExchangeRateHttpService, IExchangeRateHttpService } from './ExchangeRate';
import { GlobalHttpService, IGlobalHttpService } from './Global';
import { ILibraryHttpService, LibraryHttpService } from './Library';
import { INotificationHttpService, NotificationHttpService } from './Notification';
import { IPlanHttpService, PlanHttpService } from './Plan';
import { IProductHttpService, ProductHttpService } from './Product';
import { IProfileHttpService, ProfileHttpService } from './Profile';
import { IReportHttpService, ReportHttpService } from './Report';
import { ISubscriptionHttpService, SubscriptionHttpService } from './Subscription';
import { IUserHttpService, UserHttpService } from './User';

export interface ICMAHttpClient {
  global: IGlobalHttpService;
  user: IUserHttpService;
  profile: IProfileHttpService;
  library: ILibraryHttpService;
  assignment: IAssignmentHttpService;
  product: IProductHttpService;
  notification: INotificationHttpService;
  exchange: IExchangeRateHttpService;
  plan: IPlanHttpService;
  subscription: ISubscriptionHttpService;
  dashboard: IDashboardHttpService;
  report: IReportHttpService;
  examination: IExaminationHttpService;
}

export function CMAHttpClient(options: IHttpServiceOptions): ICMAHttpClient {

  const httpService = HttpService(options);

  return {
    global: GlobalHttpService({ httpService }),
    user: UserHttpService({ httpService }),
    profile: ProfileHttpService({ httpService }),
    library: LibraryHttpService({ httpService }),
    assignment: AssignmentHttpService({ httpService }),
    product: ProductHttpService({ httpService }),
    notification: NotificationHttpService({ httpService }),
    exchange: ExchangeRateHttpService({ httpService }),
    plan: PlanHttpService({ httpService }),
    subscription: SubscriptionHttpService({ httpService }),
    dashboard: DashboardHttpService({ httpService }),
    report: ReportHttpService({ httpService }),
    examination: ExaminationHttpService({ httpService })
  };
}
