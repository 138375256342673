import { CMAAvatar, CMAHeader } from 'root/components';
import { cmaFormatDateFullTime, cmaFormatDateTime, fullNameUser } from 'root/helpers';
import { IBreadcrumb } from 'root/models';
import { LibraryQuestion } from 'root/pages/Library/Components/LibraryQuestion';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeAssignment, MutationTypeAssignment } from '../Store/types';
// import { AssignmentRouterName } from '../index';
// import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'cma-avatar': CMAAvatar,
    'library-question': LibraryQuestion
  },
  computed: {
    ...mapState({
      detail: (state: IState) => state.assignment.assignmentSelected,
      loading: (state: IState) => state.assignment.loading
    })
  },
  // beforeRouteLeave(to, _, next) {
  //   if (to.name !== AssignmentRouterName.AssignmentTemplateEdit) {
  //     this.$store.commit(MutationTypeAssignment.AssignmentTemplateResetAssignmentSelected);
  //   }

  //   next();
  // }
})

export class AssignmentTemplateDetail extends Vue {
  public cmaFormatDateTime = cmaFormatDateTime;
  public cmaFormatDateFullTime = cmaFormatDateFullTime;
  public fullNameUser = fullNameUser;
  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Assignment',
      path: '/assignments'
    },
    {
      label: 'Assignment Template',
      path: '/assignments/template'
    },
    {
      path: '',
      current: true,
      label: 'View Assignment Template'
    }
  ];

  public get templateId() {
    return this.$route.params.templateId;
  }

  public goEdit() {
    this.$router.push(`/assignments/template/${this.templateId}/edit`);
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeAssignment.AssignmentTemplateGet, this.templateId);
    });
  }

  public beforeDestroy() {
    this.$store.commit(MutationTypeAssignment.AssignmentTemplateResetAssignmentSelected);
  }
}
