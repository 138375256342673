import { cloneDeep, findIndex } from 'lodash';
import { PaginationState } from 'root/components/Base';
import { IRedemption } from 'root/models';
import { IPaginationState } from 'root/store';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeProduct } from './types';

export interface IProductState extends ICRUDState<any> {
  loading: boolean;
  transaction: ICRUDState<IRedemption>;
  productTransaction: ICRUDState<IRedemption>;
}

export const defaultState: IProductState = {
  ...CRUDState(),
  loading: false,
  transaction: {
    ...CRUDState(),
  },
  productTransaction: {
    ...CRUDState()
  }
};

export const mutations = {
  ...crudMutations('product'),
  [MutationTypeProduct.ProductLoading](state: IProductState) {
    state.loading = true;
  },
  [MutationTypeProduct.ProductLoaded](state: IProductState) {
    state.loading = false;
  },

  [MutationTypeProduct.SetProductPagination](state: IProductState, payload: IPaginationState) {
    const pagination = cloneDeep(state.pagination);
    state.pagination = {
      ...pagination,
      ...payload
    };
  },

  [MutationTypeProduct.SetProductTransactionPagination](state: IProductState, payload: IPaginationState) {
    const pagination = cloneDeep(state.transaction.pagination);
    state.transaction.pagination = {
      ...pagination,
      ...payload
    };
  },

  [MutationTypeProduct.TransactionLoading](state: IProductState) {
    state.transaction.loading = true;
  },
  [MutationTypeProduct.TransactionLoaded](state: IProductState) {
    state.transaction.loading = false;
  },
  [MutationTypeProduct.TransactionSetData](state: IProductState, payload) {
    state.transaction = payload;
  },
  [MutationTypeProduct.TransactionClearData](state: IProductState) {
    state.transaction.data = [];
    state.transaction.pagination = PaginationState();
  },
  [MutationTypeProduct.TransactionUpdateModel](state: IProductState, payload) {
    const index = findIndex(state.transaction.data, (e) => e.id === payload.id);
    state.transaction.data[index] = payload;
  },
  [MutationTypeProduct.SetTransactionListOfProduct](state: IProductState, payload) {
    state.productTransaction.data = payload.data;
    state.productTransaction.pagination = payload.pagination;
  },
  [MutationTypeProduct.SetTransactionListOfProductLoading](state: IProductState) {
    state.productTransaction.loading = true;
  },
  [MutationTypeProduct.SetTransactionListOfProductLoaded](state: IProductState) {
    state.productTransaction.loading = false;
  }
};
