import { CMAAvatar } from 'root/components';
import { cmaFormatDateFullTime } from 'root/helpers';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeAssignment, MutationTypeAssignment } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-avatar': CMAAvatar
  },
  props: {
    assignmentId: [Number, String],
    userId: [Number, String],
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.assignment.users.detail,
      loading: (state: IState) => state.assignment.loading
    })
  }
})

export class AssignmentDetailSingle extends Vue {
  public cmaFormatDateFullTime = cmaFormatDateFullTime;

  protected mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeAssignment.AssignmentGetUserDetail, {
        assignmentId: this.$props.assignmentId,
        userId: this.$props.userId
      });
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeAssignment.AssignmentClearUserDetail);
  }
}
