export enum ExchangeLayout {
  View = 'view',
  Editor = 'editor'
}

export interface IExchangeRate {
  id: number;
  currency: number;
  point: number;
  owner?: any;
  updater?: any;
  createdAt: Date;
  updatedAt: Date;
}

export const exchangeRateDefault = (): IExchangeRate => {
  return {
    id: null,
    createdAt: null,
    currency: null,
    owner: null,
    point: null,
    updatedAt: null,
    updater: null
  };
};

export interface IExchangeRateForm {
  point: number;
  currency: number;
}

export const exchangeRateFormDefault = (): IExchangeRateForm => {
  return {
    currency: 0,
    point: 0
  };
};
