import { i18n } from 'root/locales';
import { AssignmentPushStatus, Gender, OrderType } from 'root/models';

export const getters = {
  levelOptions() {
    const result = [];
    for (let index = 1; index < 14; index++) {
      result.push({
        value: index,
        label: index
      });
    }

    return result;
  },
  ageOptions() {
    const result: any = [{
      value: null,
      label: 'Select'
    }];
    for (let index = 6; index < 20; index++) {
      result.push({
        value: index,
        label: index
      });
    }

    return result;
  },
  genderOptions() {
    return [{
      value: Gender.Female,
      label: Gender.Female
    }, {
      value: Gender.Male,
      label: Gender.Male
    }];
  },
  assignmentPushStatus: () => [
    {
      value: null,
      label: i18n().t('push.status')
    },
    {
      value: true,
      label: i18n().t(AssignmentPushStatus.Sent)
    },
    {
      value: false,
      label: i18n().t(AssignmentPushStatus.New)
    },
  ],
  sortType: () => [
    {
      value: false,
      label: i18n().t(OrderType.ASC)
    },
    {
      value: true,
      label: i18n().t(OrderType.DESC)
    }
  ]
};
// public levelOptions: any[];
// public centreOptions: any[];
// public genderOptions: any[];
// public ageOptions: any[];
