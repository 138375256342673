import { IPagingParams, IPagingResult } from 'root/models';
// import { ActionType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';

export interface IPaginationState {
  sortBy?: string;
  page?: number;
  rowsPerPage?: number;
  descending?: boolean;
  totalItems?: number;
}

export function PaginationState(params?: IPaginationState): IPaginationState {
  return {
    sortBy: 'updatedAt',
    page: 1,
    rowsPerPage: 50,
    descending: true,
    totalItems: 0,
    ...params,
  };
}

export interface IPaginationComponentData {
  pagination: IPaginationState;
  [k: string]: any;
}

@Component({
  computed: {
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val: number) {
        this.pagination = {
          ...this.pagination,
          page: val,
        };
      }
    }
  },
  watch: {
    pagination() {
      this.loadData();
    }
  }
})
export class PaginationComponent<T, F = any> extends Vue {

  public loading = false;
  public pagination = PaginationState();
  public selected: T[] = [];
  public items: T[] = [];

  protected filterFn: (filterParams: F, pagingParams: IPagingParams) => Promise<IPagingResult<T>>;

  public filterParams(): F {
    return <any> {};
  }

  public mounted() {
    this.$nextTick(() => {

      this.loadData();
    });
  }

  protected loadData() {
    if (this.loading) {
      return;
    }

    // tslint:disable
    const _this = this;
    // tslint:enable
    this.loading = true;
    this.filterFn(this.filterParams(), {
      size: this.pagination.rowsPerPage,
      page: this.pagination.page
    }).then((response) => {
      _this.loading = false;
      _this.items = response.data;
    }).catch((error) => {
      console.log(error);
    });
  }

}
