import { RouteConfig } from 'vue-router';
import { AssignmentContainer } from './AssignmentContainer';
import { AssignmentCreate } from './AssignmentCreate';
import { AssignmentDetail } from './AssignmentDetail';
import { AssignmentList } from './AssignmentList';
import { AssignmentTemplateDetail } from './AssignmentTemplateDetail';
import { AssignmentTemplateEditor } from './AssignmentTemplateEditor';
import { AssignmentTemplateList } from './AssignmentTemplateList';

export enum AssignmentRouterName {
  AssignmentEdit = 'AssignmentEdit',
  AssignmentTemplateEdit = 'AssignmentTemplateEdit'
}

export const AssignmentRouter: RouteConfig = {
  path: '/assignments',
  component: AssignmentContainer,
  children: [
    {
      path: '',
      component: AssignmentList
    },
    {
      path: 'new',
      component: AssignmentCreate
    },
    {
      path: 'template',
      component: AssignmentTemplateList
    },
    {
      path: 'template/create',
      component: AssignmentTemplateEditor
    },
    {
      path: 'template/:assignmentId/edit',
      component: AssignmentTemplateEditor,
      name: AssignmentRouterName.AssignmentTemplateEdit
    },
    {
      path: 'template/:templateId/detail',
      component: AssignmentTemplateDetail
    },
    {
      path: ':assignmentId',
      component: AssignmentDetail
    },
    {
      path: ':assignmentId/edit',
      component: AssignmentCreate,
      name: AssignmentRouterName.AssignmentEdit
    }
  ]
};
