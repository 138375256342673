import { ElInputNumber } from 'element-ui/types/input-number';
import { cloneDeep, isEmpty, isNaN, isNumber } from 'lodash';
import * as math from 'mathjs';

// const mathConfig = {
//   number: 'BigNumber',
//   precision: 30
// };
// console.log(math);
const mathjs = math.create(math.all, {
  number: 'BigNumber',
  precision: 30
});

import { forceResultToDecimal, generateUnitKey } from 'root/helpers';
import { ILibraryCaseConfig, MethodType, Operator } from 'root/models/Library';
import Vue from 'vue';
import Component from 'vue-class-component';

// const mathjs = math.create(math.all);

@Component({
  template: require('./view.html'),
  props: {
    grade: [Number, String],
    method: String,
    config: {
      type: Object,
      default: {
        operator: Operator.Plus,
        number: 1,
        digit: 1
      }
    },
    index: Number,
    disabled: Boolean,
    data: {
      type: Object,
      default: () => {
        return {
          expression: []
        };
      }
    },
    isUpdating: Boolean
  },
  computed: {
    numbersArr() {
      const numbers = cloneDeep(this.config).numbers,
        expression = this.data.expression,
        currentIndex = this.currentIndex || 0,
        result = [];
      if (!numbers) {
        return result;
      }

      for (let index = 0; index < numbers; index++) {
        let disabled: boolean = index !== currentIndex;

        if (isEmpty(expression) && index === 0) {
          disabled = false;
        }
        result.push({
          disabled,
          key: generateUnitKey(),
          value: index
        });
      }

      return result;
    },
  },
  watch: {
    currentIndex(newValue) {
      if (newValue || newValue === 0) {
        setTimeout(async () => {
          const valid = await this.validateExpression();
          const inputs: ElInputNumber[] = this.$refs.inputs;

          if (valid !== '') {
            this.currentIndex = this.errorIndex;
            inputs[this.errorIndex].focus();
          } else {
            inputs[newValue].focus();
          }
        }, 0);
      }

      return;
    }
  }
})

export class LibraryExpression extends Vue {
  public data: any;
  public config: ILibraryCaseConfig;
  public result: any = 'Result';
  public errorIndex: number = null;
  public isNumber = isNumber;
  public $refs: {
    inputs: ElInputNumber[]
  };
  public numbersArr: any[];
  public isUpdating: boolean;
  private currentIndex: number = 0;

  public get decimalRound(): number {
    const { grade, method } = this.$props;
    switch (true) {
    case (method === MethodType.AB && (
      [1, 2, 3, 4, 5, 6, 7].includes(grade) || grade.toString() === '0'
    ) && this.$props.config.isCurrency):
      return 2;
    case (method === MethodType.MA && (
      grade === 1 || grade === 2 || grade.toString() === '0'
    )):
      return 2;
    case grade === 3 && method === MethodType.AB:
      return 3;
    case grade === 2 && method === MethodType.AB:
      return 4;
    case (grade === 1 || grade.toString() === '0') && method === MethodType.AB:
      return 5;
    default:
      return null;
    }
  }

  public getValue(expression: number[]) {
    const operator = this.config.operator;
    if (expression.length > 0) {
      let result = Number(expression[0]);
      for (let index = 1; index < this.config.numbers; index++) {
        const element = Number(expression[index]);

        switch (operator) {
        case Operator.PlusAndSub:
        case Operator.Plus:
          result = mathjs.evaluate(`${result} + ${element}`).toString();
            // result = result + element;
          break;
        case Operator.Multi:
          result = mathjs.multiply(result, element);
          // result = result * element;
          break;
        case Operator.Div:
          result = result / element;
        default:
          break;
        }
      }

      return isNumber(mathjs.number(result)) && !isNaN(result) ? this.convertResult(result) : 'Result';
    }

    return 'Result';
  }

  public convertResult(result: number): number {
    // keepZeroDecimal
    if (this.decimalRound && !isNaN(Number(result))) {
      // if (this.config.isCurrency) {
        // return forceResultToDecimal(result, 2);
      // }
      return forceResultToDecimal(result, this.decimalRound);
      // return mathjs.round(result, this.decimalRound);
    }

    return result;
  }

  public handleDelete() {
    this.$emit('delete');
    this.result = this.getValue(this.data.expression);
  }

  public handleChange(index: number, newValue: number | string) {
    this.$emit('changeExpression', {index, newValue});
    setTimeout(async () => {
      this.result = this.getValue(this.data.expression);
      const valid = await this.validateExpression();
      if (!valid) {
        this.submit();
      }
    }, 0);
  }

  public validateExpression(): Promise<string> {
    return new Promise((resolve) => {
      const config = this.config,
        { operator, digit, digit1, digit2 } = config,
        expression = this.data.expression;
      let error = '',
        errorIndex = null;

      if (!digit && !digit1 && !digit2) {
        error = 'Please choose digit';
        this.$emit('error', error);

        return resolve(error);
      }
      if (!config.numbers) {
        error = 'Please choose number';
        this.$emit('error', error);

        return resolve(error);
      }
    // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < expression.length; i++) {
        const value: number = expression[i];
        if (!value) {
          this.errorIndex = i;
          error = 'Number is required!';
          this.$emit('error', error);

          resolve(error);
        }
        const valueToArr = value.toString().replace('-', '').split('.');
        const digitLength = valueToArr.reduce((e, current) => {
          return e + current.length;
        }, 0);
        const decimalLength = valueToArr[1] ? valueToArr[1].length : 0;

        switch (true) {
        case value === 0:
          error = 'Type value different from 0';
          errorIndex = i;
          break;
        case !isNumber(Number(value)):
          error = 'Should type a number!';
          errorIndex = i;
          break;
        case ((operator === Operator.Plus || operator === Operator.Multi || operator === Operator.Div) && value < 0):
          error = `Operator ${operator} selected, type a value more than 0`;
          errorIndex = i;
          break;
        case ((operator === Operator.Plus || Operator.PlusAndSub) && digit && isNumber(digit) && digitLength !== digit):
          error = `${digit} Digits Setected! Choose again to keep inputing`;
          errorIndex = i;
          break;
        case ((operator === Operator.Plus || Operator.PlusAndSub) && digit && digit === 'Mixed' && digitLength > 10):
          error = `${digit} Digits Setected! Length is less or equal 10`;
          errorIndex = i;
          break;
        case (operator === Operator.Multi || operator === Operator.Div):
          if (i === 0) {
            if (config.digit1 === 'Mixed' && digitLength > 10) {
              error = `Digit 01 Setected mixed! Length is less or equal 10`;
              errorIndex = i;
            } else if (config.digit1 !== 'Mixed' && digitLength !== digit1) {
              error = `${digit1} Digit 01 Setected! Choose again to keep inputing`;
              errorIndex = i;
            }
          }
          if (i === 1) {
            if (config.digit2 === 'Mixed' && digitLength > 10) {
              error = `Digit 02 Setected mixed! Length is less or equal 10`;
              errorIndex = i;
            } else if (config.digit2 !== 'Mixed' && digitLength !== digit2) {
              error = `${digit2} Digit 02 Setected! Choose again to keep inputing`;
              errorIndex = i;
            }
          }
          if (!digit1) {
            error = `Please choose digit 1`;
            errorIndex = i;
          }
          if (!digit2) {
            error = `Please choose digit 2`;
            errorIndex = i;
          }
          break;
        case !!(config.decimal):
          if (config.decimal === 'Mixed') {
            if (decimalLength <= 0) {
              error = `${config.decimal} decimals selected!`;
              errorIndex = i;
            }
          } else if (decimalLength !== config.decimal) {
            error = `${config.decimal} decimals selected!`;
            errorIndex = i;
          }
          break;
        case !config.decimal:
          if (value.toString().indexOf('.') > -1) {
            error = `Not allow decimal`;
            errorIndex = i;
          }
          break;
        default:
          error = '';
          break;
        }
      }
      this.$emit('error', error);
      this.errorIndex = errorIndex;

      resolve(error);
    });
  }

  public submit() {
    this.$emit('submit', {
      index: this.currentIndex,
      handler: () => {
        const nextIndex = this.currentIndex + 1;
        this.setNumbersArr(nextIndex);
      }
    });
  }

  public async keydownTab(e) {
    if (e.shiftKey) {
      e.preventDefault();
      this.setNumbersArr(this.currentIndex - 1);
    } else {
      this.submit();
    }

    return;
  }

  protected mounted() {
    this.$nextTick(() => {
      this.result = this.getValue(this.data.expression);
      setTimeout(() => {
        const inputs = this.$refs.inputs;
        if (inputs) {
          inputs[0].focus();
        }
      }, 500);
    });
  }

  protected beforeDestroy() {
    this.result = 'Result';
  }

  private async setNumbersArr(index: number) {
    const numbers = cloneDeep(this.config.numbers),
      valid = await this.validateExpression();

    if (!valid) {
      this.currentIndex = numbers > index && index >= 0 ? index : this.currentIndex;
    }

    return;
  }
}
