import { cmaHttpClient } from 'root/api/http';
import { crudActions } from 'root/store/helpers';

const { filter } = cmaHttpClient.examination;

export const actions = {
  ...crudActions('examination', {
    filter
  })
};
