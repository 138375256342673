import { ISubscription } from 'root/models';
import { IPaginationResult } from 'root/store/helpers';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface ISubscriptionHttpService
  extends ICRUDHttpService<ISubscription> {
  filterSubscriptionByUser(userId: number, paramFilter):
    Promise<{data: ISubscription[], pagination: IPaginationResult}>;
  filterTransactions(id: number, paramFilter: any): Promise<any>;
}

export function SubscriptionHttpService(options: IHttpServiceClientOptions): ISubscriptionHttpService {

  const basePath = '/admin/subscriptions';

  function filterSubscriptionByUser(userId: number, paramFilter):
    Promise<{data: ISubscription[], pagination: IPaginationResult}> {
    const userPath = options.httpService.getUrl(`${basePath}/users/${userId}`);

    return options.httpService.get(userPath, {
      ...paramFilter
    });
  }

  function filterTransactions(id: number, paramFilter: any): Promise<any> {
    const order = !paramFilter || !paramFilter.order ? null
    : (paramFilter.descending ? '-' + paramFilter.order : paramFilter.order);

    paramFilter.order = order;

    const path = options.httpService.getUrl(`/admin/transactions/${id}`);

    return options.httpService.get(path, {
      params: paramFilter
    });
  }

  return {
    ...CRUDHttpService<ISubscription>({
      ...options,
      basePath
    }),
    filterSubscriptionByUser,
    filterTransactions
  };
}
