import moment from 'moment-timezone';
import { cmaHttpClient } from 'root/api/http';
import { ActionType, IState } from 'root/store';
import { ActionContext } from 'vuex';
import { IReportState } from './mutations';
import { ActionTypeReport, MutationTypeReport } from './types';

const { filterLeaderBoard, filterTransactions, filterProducts,
  filterSubscription, filterSmarterGame, filterIndividualGame,
  filterStatisticUser, filterAssignment, filterAssignmentIndividual } = cmaHttpClient.report;

export const actions = {
  async [ActionTypeReport.ReportLeaderBoardFilter](
    { commit, dispatch }: ActionContext<IReportState, IState>, paramFilter
  ) {
    commit(MutationTypeReport.ReportLeaderBoardLoading);
    try {
      convertParamFilter(paramFilter);
      const data = await filterLeaderBoard(paramFilter);
      commit(MutationTypeReport.ReportLeaderBoardSetData, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeReport.ReportLeaderBoardLoaded);
  },
  async [ActionTypeReport.ReportRedemptionTransaction](
    { commit, dispatch }: ActionContext<IReportState, IState>, {
      filterParams,
      filterBody,
    }
  ) {
    commit(MutationTypeReport.ReportTransactionLoading);
    try {
      convertParamFilter(filterBody);
      const data = await filterTransactions(filterBody, filterParams);
      commit(MutationTypeReport.ReportTransactionSetData, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeReport.ReportTransactionLoaded);
  },
  /**
   * 
   * filter products
   */
  async [ActionTypeReport.ReportProductsFilter](
    { commit, dispatch }: ActionContext<IReportState, IState>, {
      filterParams,
      filterBody,
    }
  ) {
    commit(MutationTypeReport.ReportProductsLoading);
    try {
      convertParamFilter(filterBody);
      const data = await filterProducts(filterBody, filterParams);
      commit(MutationTypeReport.ReportProductsSetData, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeReport.ReportProductsLoaded);
  },
  /**
   * filter subscription
   */
  async [ActionTypeReport.ReportSubscriptionFilter](
    { commit, dispatch }: ActionContext<IReportState, IState>, {
      filterParams,
      filterBody,
    }
  ) {
    commit(MutationTypeReport.ReportSubscriptionLoading);
    try {
      convertParamFilter(filterBody);
      const data = await filterSubscription(filterBody, filterParams);
      commit(MutationTypeReport.ReportSubscriptionSetData, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeReport.ReportSubscriptionLoaded);
  },
  /**
   * filter smarter game
   */
  async [ActionTypeReport.ReportGameFilter](
    { commit, dispatch }: ActionContext<IReportState, IState>, {
      filterParams,
      filterBody,
    }
  ) {
    commit(MutationTypeReport.ReportGameLoading);
    try {
      convertParamFilter(filterBody);
      const data = await filterSmarterGame(filterBody, filterParams);
      commit(MutationTypeReport.ReportGameSetData, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeReport.ReportGameLoaded);
  },
  /**
   * filter individual game
   */
  async [ActionTypeReport.ReportGameIndividualFilter](
    { commit, dispatch }: ActionContext<IReportState, IState>, {
      filterParams,
      filterBody,
    }
  ) {
    commit(MutationTypeReport.ReportGameIndividualLoading);
    try {
      convertParamFilter(filterBody);
      const data = await filterIndividualGame(filterBody, filterParams);
      commit(MutationTypeReport.ReportGameIndividualSetData, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeReport.ReportGameIndividualLoaded);
  },
  /**
   * filter user statistic
   */
  async [ActionTypeReport.ReportUserStatisticFilter](
    { commit, dispatch }: ActionContext<IReportState, IState>, {
      filterParams,
      filterBody,
    }
  ) {
    commit(MutationTypeReport.ReportUserStatisticLoading);
    try {
      convertParamFilter(filterBody);
      const data = await filterStatisticUser(filterBody, filterParams);
      commit(MutationTypeReport.ReportUserStatisticSetData, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeReport.ReportUserStatisticLoaded);
  },
  /**
   * filter assignment
   */
  async [ActionTypeReport.ReportAssignmentFilter](
    { commit, dispatch }: ActionContext<IReportState, IState>, {
      filterParams,
      filterBody,
    }
  ) {
    commit(MutationTypeReport.ReportAssignmentLoading);
    try {
      convertParamFilter(filterBody);
      const data = await filterAssignment(filterBody, filterParams);
      commit(MutationTypeReport.ReportAssignmentSetData, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeReport.ReportAssignmentLoaded);
  },
  async [ActionTypeReport.ReportAssignmentIndividualFilter](
    { commit, dispatch }: ActionContext<IReportState, IState>, {
      filterParams,
      filterBody,
    }
  ) {
    commit(MutationTypeReport.ReportAssignmentIndividualLoading);
    try {
      convertParamFilter(filterBody);
      const data = await filterAssignmentIndividual(filterBody, filterParams);
      commit(MutationTypeReport.ReportAssignmentIndividualSetData, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeReport.ReportAssignmentIndividualLoaded);
  },
};

function convertParamFilter(paramFilter) {
  paramFilter.startDate = moment(paramFilter.startDate).tz(moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
  paramFilter.endDate = paramFilter.endDate
    ? moment(paramFilter.endDate).add(1, 'days').tz(moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss')
    : null;
}
