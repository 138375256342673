import { stripObject } from '@hgiasac/helper';
import { cloneDeep } from 'lodash';
import { CMAListData, CMASelect } from 'root/components';
import { cmaFormatDateFullTime, formaterListCounter } from 'root/helpers';
import { IRedemption } from 'root/models';
import { ProductDetailView } from 'root/pages/Product/Components/ProductDetailView';
import { ActionTypeProduct, GetterTypeProduct } from 'root/pages/Product/Store/types';
import { IState } from 'root/store';
import { IPaginationResult } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-select': CMASelect,
    'cma-list-data': CMAListData,
    'product-detail-view': ProductDetailView
  },
  props: {
    userId: [Number, String]
  },
  computed: {
    ...mapGetters(['sortType']),
    ...mapState({
      pagination: (state: IState) => state.product.transaction.pagination,
      loading: (state: IState) => state.product.transaction.loading,
      data: (state: IState) => state.product.transaction.data,
    })
  }
})

export class UserRedemptionList extends Vue {
  public data: IRedemption[];
  public pagination: any;
  public visibleRedemption: boolean = false;
  public redemptionDetail: IRedemption = null;
  public redemptionId: number = null;

  public paramsFilter: any = {
    text: null,
    qty: null,
    order: null,
    descending: false
  };

  public get redemptionSort() {
    return [
      {
        label: this.$t('none'),
        value: null
      },
      {
        label: 'Redeemed At',
        value: 'createdAt'
      },
      {
        label: this.$t('qty'),
        value: 'qty'
      },
    ];
  }

  public get formaterListCounter() {
    return formaterListCounter(this.pagination, this.data.length);
  }

  public get columns() {
    return [
      {
        prop: 'title',
        label: 'Product',
        avatar: {
          formatter: (model: IRedemption) => {
            return model ? {
              avatar: model.product.images.find((e) => e.isPrimary).src
            } : null;
          }
        },
        formatter: (model: IRedemption) => model.product.title
      },
      {
        prop: 'qty',
        align: 'center',
        label: 'QTY',
        width: 100
      },
      {
        prop: 'point',
        label: 'Point',
        formatter: (model: IRedemption) => model.product.point,
        width: 100
      },
      {
        prop: 'time',
        label: 'Redeemed at',
        align: 'center',
        formatter: (model: IRedemption) => {
          return cmaFormatDateFullTime(model.createdAt);
        },
        width: 170
      }, {
        prop: 'progress',
        label: 'status',
        labelStyle: (model: any) => {
          return `redemption__status outline-none ${model.progress}`;
        },
        width: 150
      }];
  }

  public clickDetail(model: IRedemption) {
    this.redemptionId = model.id;
    this.visibleRedemption = true;
  }
  public beforeOpen() {
    this.redemptionDetail = this.$store.getters[GetterTypeProduct.GetRedemption](this.redemptionId);
  }

  public changePagination() {
    this.fetchData();
  }

  public changeSort() {
    this.fetchData();
  }
  public applyFilter() {
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      this.fetchData();
    });
  }
  // protected beforeDestroy() {
  //   this.$store.commit(MutationTypeProduct.ProductFilterChange, {});
  // }

  private fetchData(pagination?: IPaginationResult) {
    const filter = cloneDeep(this.paramsFilter);
    const page = pagination ? pagination : this.pagination;
    const formConverted: any = {
      params: {
        ...stripObject(filter),
        ...page
      }
    };

    this.$store.dispatch(ActionTypeProduct.TransactionUserFilter, {
      id: this.$props.userId,
      form: formConverted
    });
  }
}
