import { Form } from 'element-ui';
import { cloneDeep, omit } from 'lodash';
import { CMAHeader } from 'root/components';
import { confirmAction, generateUnitKey, ruleRequired } from 'root/helpers';
import { IBreadcrumb } from 'root/models';
import { defaultLibraryFormIutput, defaultLibraryFormOutput,
  libraryCaseConfigDefault, ILibrary, ILibraryFormOutputItem, ILibraryInput, Operator } from 'root/models/Library';
import { ActionType, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { LibraryFormItem } from '../Components/LibraryFormItem';
import { ActionTypeLibrary, MutationTypeLibrary } from '../Store/types';
import { LibraryRouterName, LibraryRouterPath } from '../index';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'library-form-item': LibraryFormItem
  },
  computed: {
    ...mapState({
      loading: (state: IState) => state.library.loading,
      detail: (state: IState) => state.library.detail,
    }),
    ...mapGetters(['methodOptions', 'gradeOptions'])
  },
  beforeRouteLeave(to, _, next) {
    if (to.name !== LibraryRouterName.LibraryEdit) {
      this.$store.commit(MutationTypeLibrary.ResetLibraryDetail);
    }

    next();
  }
})

export class LibraryEditor extends Vue {
  public $refs: {
    form: Form,
    libraryFormItem: LibraryFormItem[]
  };
  public detail: ILibrary;
  public rules: any = {
    required: ruleRequired()
  };
  public generateUnitKey = generateUnitKey;
  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        path: '/'
      },
      {
        label: 'Question Bank',
        path: LibraryRouterPath.Library,
      },
      {
        label: this.isUpdateing ? 'Edit Record' : 'Add New',
        path: '',
        current: true
      }
    ];
  }

  public get libraryId() {
    return this.$route.params.libraryId;
  }

  public get isUpdateing(): boolean {
    return !!this.libraryId;
  }

  public formInput: ILibraryInput = defaultLibraryFormIutput();
  public formOutput: ILibraryFormOutputItem[] = defaultLibraryFormOutput();
  private deleteQuestionIds: number[] = [];

  public confirmChangeConfig(newValue: number, field: string) {
    if ((this.formInput[field] || this.formInput[field] === 0) && this.formOutput.length > 0) {
      confirmAction(this, {
        title: 'Confirm your change',
        message: 'Changes you made may not be saved.',
        handleFunction: () => {
          this.formInput[field] = newValue;
          this.formOutput = [];
        }
      });

      return;
    }
    this.formInput[field] = newValue;
  }
  public addNewCase() {
    let formOutput = cloneDeep(this.formOutput);
    formOutput = [
      ...formOutput,
      {
        key: generateUnitKey(),
        name: '',
        questions: [],
        config: {
          ...libraryCaseConfigDefault(),
          numbers: null,
          isCurrency: null
        }
      }
    ];
    this.formOutput = formOutput;
  }
  public changeCaseName(newValue: string, index: number) {
    this.formOutput[index].name = newValue;
  }

  public updateExpression(newValue: any, index: number) {
    const current = cloneDeep(this.formOutput[index]),
      key = current.key;

    this.formOutput[index] = {
      ...this.formOutput[index],
      key,
      config: newValue.config,
      questions: newValue.expressions
    };
  }
  public clickCancel() {
    this.$router.push(LibraryRouterPath.Library);
  }
  public clickSave() {
    this.$refs.form.validate(async (valid) => {
      const validItems = await this.validFormItem();
      if (valid && validItems) {
        let formOutput = cloneDeep(this.formOutput);
        const { method, grade } = cloneDeep(this.formInput);
        // "digitCfg":[{
        //   "digits":3,
        //   "numbers":1
        // },{
        //   "digits":2,
        //   "numbers":1
        // }

        formOutput = <any> formOutput.map((element) => {
          if (element && element.questions) {
            const _config = element.config,
              mixed = !!(_config && _config.digit && _config.digit === 'Mixed'),
              mixedDecimal = !!(_config && _config.decimal && _config.decimal === 'Mixed');
            let operators = _config ? [_config.operator] : [];
            let digitCfg = [];

            if (_config && _config.operator === Operator.PlusAndSub) {
              operators = [
                Operator.Plus,
                Operator.Minus
              ];
            }
            const convertedConfig: any = {
              mixed,
              mixedDecimal,
              operators,
              decimal: _config.decimal === 'Mixed' ? 0 : _config.decimal,
              isCurrency: !!_config.isCurrency
            };

            if (_config && (_config.operator === Operator.Multi || _config.operator === Operator.Div)) {
              digitCfg = [
                {
                  numbers: 1,
                  digits: _config.digit1 === 'Mixed' ? 0 : _config.digit1
                },
                {
                  numbers: 1,
                  digits: _config.digit2 === 'Mixed' ? 0 : _config.digit2
                }
              ];
              convertedConfig.digitCfg = digitCfg;
            }

            element.questions = <any> element.questions
              .filter((e) => e.expression)
              .map((e) => {
                return {
                  ...omit(e, ['status', 'key', 'result'])
                };
              });

            return {
              ...omit(element, ['key', 'status', 'result']),
              config: convertedConfig
              // config: {
              //   mixed,
              //   mixedDecimal,
              //   operators,
              //   decimal: _config.decimal === 'Mixed' ? 0 : _config.decimal,
              //   isCurrency: !!_config.isCurrency
              // },
            };
          }

          return;
        });

        formOutput = formOutput.filter((o) => o.questions.length > 0);

        if (this.isUpdateing) {
          this.$store.dispatch(ActionTypeLibrary.LibraryUpdate, {
            id: this.libraryId,
            form: {
              method,
              grade,
              cases: formOutput,
              deleteQuestionIds: this.deleteQuestionIds
            },
            onSuccess: () => {
              this.$message({
                message: 'Update successfully!',
                type: 'success'
              });
              this.$router.push(LibraryRouterPath.Library);
            },
            onFailure: (error) => {
              console.log('formOutput', formOutput);
              this.$store.dispatch(ActionType.CatchException, error);
            }
          });
        } else {
          this.$store.dispatch(ActionTypeLibrary.LibraryCreate, {
            form: {
              method,
              cases: formOutput,
              grade: Number(grade),
            },
            onSuccess: () => {
              this.$router.push(LibraryRouterPath.Library);
            },
            onFailure: (error) => {
              this.$store.dispatch(ActionType.CatchException, error);
            }
          });
        }

      }

      return;
    });
  }

  public deleteCase(index: number) {
    confirmAction(this, {
      title: 'Confirm your change',
      message: 'Changes you made may not be saved.',
      handleFunction: () => {
        const formOutput = cloneDeep(this.formOutput),
          current = <any> formOutput[index],
          expressions = current.expressions || current.questions;

        expressions.map((e: any) => {
          if (e.id) {
            this.deleteQuestionIds.push(e.id);
          }
        });
        this.formOutput.splice(index, 1);
      }
    });
  }

  public deleteExpression(id: number) {
    if (id) {
      this.deleteQuestionIds.push(id);
    }
  }

  public mounted() {
    const detail = cloneDeep(this.detail);

    if (detail && detail.id) {
      this.formInput = {
        grade: detail.grade,
        method: detail.method
      };
      this.formOutput = <any> detail.cases.map((e) => {
        return {
          ...e,
          key: generateUnitKey()
        };
      });
    } else if (this.libraryId) {
      this.$store.dispatch(ActionTypeLibrary.getLibraryId, {
        id: this.libraryId
      }).then(() => {
        const _detail = cloneDeep(this.detail);
        this.formInput = {
          grade: _detail.grade,
          method: _detail.method
        };
        this.formOutput = <any> _detail.cases.map((e) => {
          return {
            ...e,
            key: generateUnitKey()
          };
        });
      });
    }

    return;

  }

  private validFormItem() {
    return new Promise(async (resolve) => {
      const libraryFormItems: LibraryFormItem[] = this.$refs.libraryFormItem;

      await Promise.all(libraryFormItems.map(async (e) => {
        console.log(e.validateForm());
        e.$refs.form.validate((valid) => {
          if (!valid || e.errorTxt !== '') {
            resolve(false);
          }
        });
      }));
      console.log('true');
      resolve(true);
    });
  }
}
