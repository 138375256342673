import {stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { cloneDeep, find, omit } from 'lodash';
import { CMAHeader } from 'root/components';
import { ruleMaxLength, ruleMaxValue, ruleNumber, ruleRequired } from 'root/helpers';
import { IBreadcrumb, IExchangeRate, UserRole } from 'root/models';
import { productCreateDefault, IProduct, IProductCreate } from 'root/models/Product';
import { ActionTypeExchangeRate } from 'root/pages/ExchangeRate/Store/types';
import { uploadToAWS } from 'root/services';
import { GetterTypeUser, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeProduct, MutationTypeProduct } from '../Store/types';
import { ProductRouterPath } from '../index';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader
  },
  computed: {
    ...mapState({
      loading: (state: IState) => state.product.loading,
      currentExchangeRate: (state: IState) => state.exchange.current
    })
  },
  created() {
    if (this.$store.getters[GetterTypeUser.GetAuthuserRole] === UserRole.Teacher) {
      this.$router.push(ProductRouterPath.Redemption);

    }

    return;
  }
})

export class ProductCreate extends Vue {

  public $refs: {
    form: Form
  };
  public currentExchangeRate: IExchangeRate;
  public firstTime: boolean = true;
  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        path: '/'
      },
      {
        label: 'Redemption',
        path: '/redemption'
      },
      {
        label: 'Product List',
        path: '/redemption/product-list'
      },
      {
        label: this.productId ? 'Edit' : 'Add new',
        path: '',
        current: true
      }
    ];
  }

  public get getRateText(): string {
    const { currency, point } = this.currentExchangeRate;

    return `${currency}/${point}`;
  }
  public get currentPoint(): string {
    const currentExchangeRate = this.currentExchangeRate;

    return this.form.currency <= 999
      ? `${currentExchangeRate.point * this.form.currency / currentExchangeRate.currency}` : '0';
  }

  public form: IProductCreate = productCreateDefault();
  public rules: any = {
    required: ruleRequired(),
    ruleNumber: ruleNumber(),
    ruleMaxValue: ruleMaxValue(999, 'max.value.product'),
    ruleMaxLength: ruleMaxLength(3, 'max.length.product')
  };
  public get productId(): string {
    return this.$route.params.productId;
  }

  public get productDetail(): IProduct {
    return this.$store.getters.getProductById(this.productId);
  }

  public get statusImage(): boolean {
    const images = this.form.images;

    return this.firstTime || images && images.length === 3;
  }

  public get primaryImageURL(): string {
    const images = cloneDeep(this.form).images;

    const primaryImage = find(images, (e) => e.isPrimary);

    return this.formaterFileToURL(primaryImage);
  }
  public formaterFileToURL(file: any) {
    if (!file) {
      return '';
    }

    return file.src ? file.src : window.URL.createObjectURL(file.raw);
  }

  public changeImage(file: any, index: number) {
    const images = cloneDeep(this.form).images;
    const imageTemp = images[index];
    if (imageTemp) { // replace images
      this.form.images.splice(index, 1 , {
        ...file,
        isPrimary: imageTemp.isPrimary
      });
    } else {
      if (this.form.images.length === 0) {
        file = {
          ...file,
          isPrimary: true
        };
      }
      this.form.images.push(file);

    }
  }

  public markePrimaryAttachment(index: number) {
    const images = cloneDeep(this.form).images;

    this.form.images = images.map((e, i: number) => {
      return {
        ...e,
        isPrimary: index === i
      };
    });
  }

  public deleteAttachment(index: number) {
    const images = cloneDeep(this.form).images,
      isDeletedPrimary = images[index].isPrimary;

    images.splice(index, 1);
    if (isDeletedPrimary && images.length > 0) {
      images[0].isPrimary = true;
    }

    this.form.images = images;
  }

  public async clickSave() {
    this.firstTime = false;
    this.$refs.form.validate(async (valid) => {
      if (valid && this.statusImage) {
        this.$store.commit(MutationTypeProduct.ProductLoading);
        const form = cloneDeep(this.form),
          files = form.images,
          images = await this.uploadImages(files);

        form.images = <any> images;
        if (this.productId) { // updating
          this.$store.dispatch(ActionTypeProduct.ProductUpdate, {
            id: this.productId,
            form: omit(form, ['id', 'point', 'exchangeRate']),
            onSuccess: () => {
              this.$message.success(this.$t('update.successfully').toString());
            }
          });
        } else {
          this.$store.dispatch(ActionTypeProduct.ProductCreate, {
            form: stripObject(form),
            onSuccess: () => {
              this.$router.push('/redemption/product-list');
            }
          });
        }
      }

      return;
    });
  }
  public clickCancel() {
    this.$router.push('/redemption/product-list');
  }

  public cancel() {
    this.$router.push('/redemption/product-list');
  }

  protected mounted() {
    this.$nextTick(async () => {
      await this.$store.dispatch(ActionTypeExchangeRate.GetCurrentExchangeRate);
      if (this.productId) {
        await this.$store.dispatch(ActionTypeProduct.ProductFindById, {
          id: this.productId
        });

        const detail = cloneDeep(this.productDetail);
        this.form = {
          ...detail,
          currency: detail.point * this.currentExchangeRate.currency / this.currentExchangeRate.point,
        };
        setTimeout(() => {
          this.$refs.form.clearValidate();
        }, 500);
      }

      return;
    });
  }

  private uploadImages(images: any[]) {
    return new Promise(async (resolve, reject) => {
      const result = await Promise.all(images.map(async (image) => {
        try {
          const src = image.src ? image.src : await uploadToAWS('product', image.raw, {
            maxHeight: 355,
            maxWidth: 540,
            quality: 0.5
          });

          return {
            src,
            isPrimary: !!image.isPrimary
          };
        } catch (error) {
          reject(error);
        }
      }));

      resolve(result);
    });
  }
}
