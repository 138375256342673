import { stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { cloneDeep } from 'lodash';
import { confirmAction,
  expresstionToArray,
  generateUnitKey,
  ruleRequired} from 'root/helpers';
import { ILibraryCase, ILibraryCaseConfig, Operator } from 'root/models/Library';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import { LibraryExpression } from '../LibraryExpression';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'library-expression': LibraryExpression
  },
  props: {
    grade: [Number, String],
    method: String,
    index: Number,
    data: {
      type: Object,
      default: () => {
        return {
          configBase: {},
          questions: []
        };
      }
    },
    isUpdating: Boolean
  },
  computed: {
    ...mapGetters(['digitOptions', 'numberOptions', 'decimalOptions'])
  },
  watch: {
    'config.operator'(newValue) {
      if (newValue === Operator.Multi || newValue === Operator.Div) {
        this.config.numbers = 2;
        this.config.digit = null;
        this.config.decimal = 0;
      }

      return;
    },
    'config.digit1'(newValue) {
      if (this.config.operator === Operator.Div && newValue < this.config.digit2) {
        this.setError('Digit 01 must be more than digit 02');
      } else {
        this.setError('');
      }
    },
    'config.digit2'(newValue) {
      if (this.config.operator === Operator.Div && newValue > this.config.digit1) {
        this.setError('Digit 02 must be less than digit 01');
      } else {
        this.setError('');
      }
    },
    'data.name'(newValue) {
      this.$emit('changeCaseName', newValue);
    }
  }
})

export class LibraryFormItem extends Vue {

  public get disabledDigit(): boolean {
    return this.config.operator === Operator.Multi || this.config.operator === Operator.Div;
  }
  public get disabledDigitMore(): boolean {
    return this.config.operator === Operator.Plus || this.config.operator === Operator.PlusAndSub;
  }

  public get disabledNumber(): boolean {
    return this.config.operator === Operator.Multi || this.config.operator === Operator.Div;
  }
  public get disabledDecimal(): boolean {
    return this.config.operator === Operator.Multi || this.config.operator === Operator.Div;
  }
  public $refs: {
    libraryFormExpression: LibraryExpression[],
    form: Form
  };
  public rules: any = {
    required: ruleRequired()
  };
  public data: ILibraryCase;
  public errorTxt: string = '';
  public isUpdating: boolean;
  public operatorOptions = [
    {
      label: Operator.Plus,
      value: Operator.Plus
    },
    {
      label: Operator.PlusAndSub,
      value: Operator.PlusAndSub
    },
    {
      label: Operator.Multi,
      value: Operator.Multi
    },
    {
      label: Operator.Div,
      value: Operator.Div
    }
  ];

  public config: ILibraryCaseConfig = {
    operator: Operator.Plus,
    numbers: null,
    digit: null,
    digit1: null,
    digit2: null,
    isCurrency: false,
    decimal: 0,
  };

  public expressions = [
    {
      key: '',
      expression: [],
      id: null
    }
  ];

  public changeConfig(newValue: any, key: string) {
    const _length = this.expressions.length,
      lastExpression = this.expressions[_length - 1];
    if (key !== 'isCurrency' && (_length > 1 || lastExpression && lastExpression.expression.length > 0)) {
      confirmAction(this, {
        title: 'Confirm your change',
        message: 'Changes you made may not be saved.',
        handleFunction: () => {
          const expressions = cloneDeep(this.expressions);
          expressions.map((e) => {
            if (e.id) {
              this.$emit('deleteExpression', e.id);
            }
          });
          this.config[key] = newValue;
          this.expressions = [];
          setTimeout(() => {
            this.addNewExpression();
          }, 0);
        }
      });

      return;
    }
    this.config[key] = newValue;
    if (key === 'isCurrency') {
      this.updateExpression();
    }
  }

  public setError(error: string) {
    this.errorTxt = error;
  }
  public changeExpression(data: any, index: number) {
    const expressions = cloneDeep(this.expressions),
      current = expressions[index],
      currentExpression = current.expression;

    currentExpression[data.index] = data.newValue;
    current.expression = currentExpression;
    expressions[index] = current;

    this.expressions = expressions;
    this.updateExpression();
  }
  public deleteExpression(index: number) {
    const expressions = cloneDeep(this.expressions),
      itemDeleted = expressions[index];

    expressions.splice(index, 1);

    this.expressions = expressions.length > 0 ? expressions : [{ expression: [], key: generateUnitKey(), id: null }];
    this.$emit('deleteExpression', itemDeleted.id);
    this.updateExpression();
  }

  public clickDelete() {
    this.$emit('deleteCase');
  }

  public validateForm() {
    return this.$refs.form.validate((valid) => valid);
  }

  public mounted() {
    const isUpdating = this.isUpdating;
    const data = cloneDeep(this.data),
      configBase = data.config,
      digitCfg = configBase && configBase.digitCfg,
      ques = data.questions;
    if (isUpdating && configBase) {

      this.config = {
        numbers: configBase ? configBase.numbers : null,
        operator: configBase.operators.length > 1 ? Operator.PlusAndSub : configBase.operators[0],
        digit: configBase.mixed ? 'Mixed' : digitCfg && digitCfg.length === 1 ? digitCfg[0].digits : null,
        digit1: digitCfg && digitCfg.length === 2 ? digitCfg[0].digits === 0 ? 'Mixed' : digitCfg[0].digits : null,
        digit2: digitCfg && digitCfg.length === 2 ? digitCfg[1].digits === 0 ? 'Mixed' : digitCfg[1].digits : null,
        isCurrency: configBase.isCurrency || (ques.length > 0 && ques[0].isCurrency),
        decimal: configBase.mixedDecimal ? 'Mixed' : configBase.decimal
      };
      const questions = <any> data.questions,
        _questions = questions.map((e) => {
          const key = e.key ? e.key : generateUnitKey();

          return {
            ...e,
            key,
            expression: expresstionToArray(e.expression)
          };
        });
      this.expressions = _questions.length > 0 ? _questions : [
        {
          key: '',
          expression: [],
          id: null
        }
      ];
      this.updateExpression();
    }

    return;
  }
  protected async done(payload: any, index: number) { // check expression validate and add new expression
    const expressions = cloneDeep(this.expressions),
      expLength = expressions.length,
      component = this.$refs.libraryFormExpression[index];

    if (!component) {
      this.updateExpression();
      this.addNewExpression();

      return;
    }
    const error = await component.validateExpression(),
      expressionLength = expressions[index].expression.length,
      valid = (expressionLength > 0)
        && error === '';
    if (valid) {
      this.updateExpression();
      const _error = await component.validateExpression();
      if (_error === '') {
        if (index === expLength - 1 && payload.index === this.config.numbers - 1) {
          if (expressions[index].expression[payload.index]) {
            this.addNewExpression();
          }
        } else if (expressions[index].expression[payload.index]) {
          payload.handler();
        }
      }
    }

    return;
  }

  private convertArrayToExpression(expressions: any) {
    const _expressions = cloneDeep(expressions),
      operator = this.config.operator;
    const result = [];

    _expressions.forEach((element: any) => {
      let expression = '';
      element.expression.forEach((e: number, _i: number) => {
        // tslint:disable-next-line:prefer-conditional-expression
        if (_i === 0) {
          expression = expression + e;
        } else {
          expression = `${expression}${operator}${e}`;
        }
      });

      result.push(stripObject({
        ...element,
        expression: expression.replace(/\+\/-/g, '+').replace(/\+-/g, '-'),
        id: element.id,
        isCurrency: !!this.config.isCurrency
      }));
    });

    return result;
  }

  private updateExpression() {
    const config = cloneDeep(this.config);

    this.$emit('updateExpression', {
      config,
      expressions: this.convertArrayToExpression(this.expressions)
    });
  }

  private addNewExpression() {
    this.expressions.push({
      key: generateUnitKey(),
      expression: [],
      id: null
    });
  }
}
