import * as js2excel from 'js2excel';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { CMAButtonFilter, CMAHeader, CMAListData, CMASelect } from 'root/components';
import { formatBirthday } from 'root/helpers';
import { AssignmentStatus, IBreadcrumb, ISubscription } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ReportRouterPath } from '..';
import { ActionTypeReport, MutationTypeReport } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-list-data': CMAListData,
    'cma-btn-filter': CMAButtonFilter,
    'cma-header': CMAHeader,
    'cma-select': CMASelect
  },
  computed: {
    ...mapGetters(['statusOptions']),
    ...mapState({
      data: (state: IState) => state.report.assignmentIndividual,
      loading: (state: IState) => state.report.assignmentIndividualLoading
    }),
    cloneStatusOptions() {
      return [
        {
          label: this.$t('all.status'),
          value: null
        },
        {
          label: this.$t(AssignmentStatus.New),
          value: AssignmentStatus.New
        },
        {
          label: this.$t(AssignmentStatus.Completed),
          value: AssignmentStatus.Completed
        },
        {
          label: this.$t(AssignmentStatus.Overdue),
          value: AssignmentStatus.Overdue
        }
      ];
    },
    startDatePicker: {
      get() {
        return this.filterBody.startDate ? moment(this.filterBody.startDate).utc(true).tz(moment.tz.guess()) : null;
      },
      set(value) {
        this.filterBody.startDate = value;
      }
    },
    endDatePicker: {
      get() {
        return this.filterBody.endDate ? moment(this.filterBody.endDate).utc(true).tz(moment.tz.guess()) : null;
      },
      set(value) {
        this.filterBody.endDate = value;
      }
    }
  }
})

export class ReportAssignmentIndividual extends Vue {
  public filterBody = {
    startDate: null,
    endDate: null
  };
  public filterParams = {
    status: null,
    text: ''
  };
  public filterEnable: boolean = false;
  public data: ISubscription[];

  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        path: '/'
      },
      {
        label: 'Report',
        path: '',
      },
      {
        label: 'User',
        path: '',
        current: true
      }
    ];
  }

  public reportRedemptionNavigation: any[] = [
    {
      label: 'Assignment',
      path: ReportRouterPath.Assignment
    },
    {
      label: 'Individual',
      path: ReportRouterPath.AssignmentIndividual
    }
  ];

  public get columns() {
    return [
      {
        label: 'Name',
        prop: 'name',
        formatter: (model: any) => model.fullName,
        avatar: {
          formatter: (model: any) => model
        },
        width: 200,
      },
      {
        label: 'title',
        prop: 'title',
        formatter: (model: any) => model.assignment.title,
        width: 170
      },
      {
        label: 'target',
        prop: 'target',
        formatter: (model: any) => model.assignment.target,
        width: 100,
      },
      {
        prop: 'grade',
        label: 'Grade',
        formatter: (model) => this.grade(model.assignment.library, model.assignment.library.method),
        width: 100,
      },
      {
        prop: 'method',
        label: 'Method',
        formatter: (model: any) => this.$t(model.assignment.library.method),
        width: 100,
      },
      {
        prop: 'totalQuestion',
        label: 'Total question',
        formatter: (model: any) => model.assignment.totalQuestion,
        width: 100,
      },
      {
        prop: 'correctQuestion',
        label: 'Total correct answer',
        formatter: (model: any) => model.historyGame ? model.historyGame.correctQuestion : '',
        width: 100,
      },
      {
        prop: 'totalWrongAnswer',
        label: 'Total wrong answer',
        formatter: (model: any) => model.historyGame ? model.historyGame.wrongQuestion : '',
        width: 100,
      },
      {
        prop: 'totalPoint',
        label: 'total Points',
        formatter: (model: any) => model.assignment.totalPoint,
        width: 100,
      },
      {
        prop: 'earnedPoint',
        label: 'Earned Points',
        formatter: (model: any) => model.historyGame ? model.historyGame.earnedPoint : '',
        width: 100,
      },
      {
        prop: 'startTime',
        label: 'Start time',
        formatter: (model: any) => formatBirthday(model.assignment.startTime),
        width: 200,
      },
      {
        prop: 'endTime',
        label: 'End time',
        formatter: (model: any) => formatBirthday(model.assignment.endTime),
        width: 200,
      },
      {
        prop: 'createdBy',
        label: 'Created by',
        formatter: (model: any) => model.assignment.owner.fullName,
        width: 200,
      },
      {
        prop: 'createdAt',
        label: 'Created time',
        formatter: (model: any) => formatBirthday(model.assignment.createdAt),
        width: 200,
      },
      {
        prop: 'status',
        label: 'Status',
        formatter: (model: any) => this.$t(model.userStatusAssignment),
        labelStyle: (model) => model.userStatusAssignment
      }
    ];
  }

  public grade(grade: any, key: string) {
    // const grade = <any> find(user.grade, (e: any) => e && e.type === key);

    if (!grade) {
      return '';
    }
    if (grade.grade === 0) {
      return 'DAN';
    }
    if (grade.grade === 14) {
      return 'Foundation';
    }

    if (key === 'ama') {
      return `${grade.grade.number} ${grade.grade.number > 1 ? 'numbers' : 'number'}
        ${grade.grade.digit} ${grade.grade.digit > 1 ? 'digits' : 'digit'}`;
    }

    return grade.grade;
  }
  public changeFilter(value, key) {
    this.filterParams[key] = value;
    this.filterEnable = true;
  }

  public exportToExcel() {
    const columns: any = cloneDeep(this.columns);

    const data = cloneDeep(this.data).map((redemption) => {
      const result: any = {};
      columns.forEach((column) => {
        result[column.label.toUpperCase()] = typeof column.formatter === 'function'
          ? column.formatter(redemption) : redemption[column.prop];
      });

      return result;
    });

    const startDate = moment(this.filterBody.startDate).utc(true).tz(moment.tz.guess()).format('DDMMYYYY'),
      endDate = moment(this.filterBody.endDate).utc(true).tz(moment.tz.guess()).format('DDMMYYYY');

    try {
      js2excel.default.json2excel({
        data,
        // tslint:disable-next-line:max-line-length
        name: `asmindividual${startDate}_${endDate}`
      });
    } catch (error) {
      console.error('export error', error);
    }
  }
  public applyFilter() {
    this.filterEnable = false;
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      // this.$store.dispatch(ActionTypePlan.planFetchMany);
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeReport.ReportAssignmentIndividualSetData, []);
    // this.$store.commit(MutationTypePlan.PlanRefreshModels, []);
  }

  private fetchData() {
    this.$store.commit(MutationTypeReport.ReportAssignmentIndividualSetData, []);
    this.$store.dispatch(ActionTypeReport.ReportAssignmentIndividualFilter, {
      filterBody: cloneDeep(this.filterBody),
      filterParams: this.filterParams
    });
  }
}
