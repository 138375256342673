import { CMAHeader } from 'root/components';
import { IBreadcrumb } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
  }
})

export class StripeConfig extends Vue {
  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Subscription',
      path: '/subscription',
    },
    {
      label: 'Stripe Configuration',
      current: true,
      path: ''
    }
  ];
}
