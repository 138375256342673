import { RouteConfig } from 'vue-router';
import { PlanDetail } from './PlanDetail';
import { PlanManagementEditor } from './PlanManagementEditor';
import { PlanManagementList } from './PlanManagementList';
import { StripeConfig } from './StripeConfig';
import { SubscriptionContainer } from './SubscriptionContainer';
import { SubscriptionList } from './SubscriptionList';

export enum SubscriptionRouterPath {
  Default = '/subscription',
  PlanManagement = '/subscription/plan-management',
  PlanEdit = '/subscription/plan/:planId/edit',
  PlanDetail = '/subscription/plan/:planId/detail',
  PlanManagementAddNew = '/subscription/plan-management/add-new',
  SubscriptionList = '/subscription/list',
  StripeConfig = '/subscription/stripe-config'
}

export enum SubscriptionRouterName {
  PlanEdit = 'plan-edit',
  PlanDetail = 'plan-name'
}

export const SubscriptionRouter: RouteConfig = {
  path: SubscriptionRouterPath.Default,
  component: SubscriptionContainer,
  children: [
    {
      path: '',
      redirect: SubscriptionRouterPath.PlanManagement
    },
    {
      path: SubscriptionRouterPath.PlanManagement,
      component: PlanManagementList
    },
    {
      path: SubscriptionRouterPath.PlanManagementAddNew,
      component: PlanManagementEditor
    },
    {
      path: SubscriptionRouterPath.PlanEdit,
      component: PlanManagementEditor,
      name: SubscriptionRouterName.PlanEdit
    },
    {
      path: SubscriptionRouterPath.SubscriptionList,
      component: SubscriptionList
    },
    {
      path: SubscriptionRouterPath.PlanDetail,
      component: PlanDetail,
      name: SubscriptionRouterName.PlanDetail
    },
    {
      path: SubscriptionRouterPath.StripeConfig,
      component: StripeConfig
    }
  ]
};
