import { RouteConfig } from 'vue-router';
import { ReportGame } from './Game';
import { LeaderBoard } from './LeaderBoard';
import { ReportRedemption } from './Redemption';
import { ReportAssignment } from './ReportAssignment';
import { ReportAssignmentGeneral } from './ReportAssignmentGeneral';
import { ReportAssignmentIndividual } from './ReportAssignmentIndividual';
import { ReportContainer } from './ReportContainer';
import { ReportIndividualGame } from './ReportIndividualGame';
import { ReportRedemptionProduct } from './ReportRedemptionProduct';
import { ReportRedemptionTransaction } from './ReportRedemptionTransaction';
import { ReportSmarterGame } from './ReportSmarterGame';
import { ReportSubscription } from './ReportSubscription';
import { ReportUser } from './ReportUser';

export enum ReportRouterPath {
  Default = '/report',
  LeaderBoard = '/report/leader-board',
  Redemption = '/report/redemption',
  RedemptionTransaction = '/report/redemption/transaction',
  RedemptionProduct = '/report/redemption/product',
  User = '/report/user',
  Subscription = '/report/subscription',
  Game = '/report/game',
  SmarterGame = '/report/game/smarter-game',
  IndividualGame = '/report/game/individual-game',
  Assignment = '/report/assignment',
  AssignmentIndividual = '/report/assignment-individual',
}

export const ReportRouter: RouteConfig = {
  path: ReportRouterPath.Default,
  component: ReportContainer,
  children: [
    {
      path: ReportRouterPath.LeaderBoard,
      component: LeaderBoard,
      meta: {
        label: 'LeaderBoard'
      }
    },
    {
      path: ReportRouterPath.Redemption,
      component: ReportRedemption,
      children: [
        {
          path: '',
          redirect: ReportRouterPath.RedemptionTransaction
        },
        {
          path: ReportRouterPath.RedemptionTransaction,
          component: ReportRedemptionTransaction
        },
        {
          path: ReportRouterPath.RedemptionProduct,
          component: ReportRedemptionProduct
        }
      ]
    }, {
      path: ReportRouterPath.User,
      component: ReportUser
    }, {
      path: ReportRouterPath.Subscription,
      component: ReportSubscription
    },
    {
      path: ReportRouterPath.Game,
      component: ReportGame,
      children: [
        {
          path: '',
          redirect: ReportRouterPath.SmarterGame
        },
        {
          path: ReportRouterPath.SmarterGame,
          component: ReportSmarterGame
        },
        {
          path: ReportRouterPath.IndividualGame,
          component: ReportIndividualGame
        }
      ]
    },
    {
      path: ReportRouterPath.Assignment,
      component: ReportAssignment,
      children: [
        {
          path: '',
          component: ReportAssignmentGeneral
        },
        {
          path: ReportRouterPath.AssignmentIndividual,
          component: ReportAssignmentIndividual
        }
      ]
    }
  ]
};
