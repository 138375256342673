import { cloneDeep, find, omit } from 'lodash';
import { CMAHeader, CMAListData, CMASelect } from 'root/components';
import { formaterListCounter, formatBirthday } from 'root/helpers';
import { IBreadcrumb, IPagingParams, IPlan, PlanStatus, PlanType } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypePlan, MutationTypePlan } from '../PlanStore/types';
import { SubscriptionRouterName, SubscriptionRouterPath } from '../index';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'cma-select': CMASelect,
    'cma-list-data': CMAListData
  },
  computed: {
    ...mapGetters(['paginationOption', 'sortType', 'actionOption']),
    ...mapState({
      pagination: (state: IState) => state.plan.pagination,
      data: (state: IState) => state.plan.data,
      loading: (state: IState) => state.plan.loading,
    })
  },
  watch: {
    'orderForm.value'(newValue: any) {
      this.$store.commit(MutationTypePlan.PlanPaginationChange, {
        ...omit(this.pagination, ['totalPerPage', 'total', 'totalPages']),
        page: 1,
        order: newValue,
        descending: this.orderForm.desc
      });
      this.fetchData();
    },
    'orderForm.desc'(newValue: any) {
      this.$store.commit(MutationTypePlan.PlanPaginationChange, {
        ...omit(this.pagination, ['totalPerPage', 'total', 'totalPages']),
        page: 1,
        order: this.orderForm.value,
        descending: newValue
      });
      this.fetchData();
    }
  }
})

export class PlanManagementList extends Vue {
  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Subscription',
      path: '/subscription'
    },
    {
      label: 'Plan Management',
      path: '',
      current: true
    }
  ];
  public paginationSize: number = 20;
  public orderForm = {
    value: null,
    desc: false
  };
  public detailVisible: boolean = false;
  public detailId: number = null;
  public pagination: IPagingParams;
  public data: IPlan[];

  public get formaterListCounter() {
    return formaterListCounter(this.pagination, this.data.length);
  }
  public get planSort() {
    return [
      {
        label: this.$t('none'),
        value: null
      },
      {
        label: this.$t('price'),
        value: 'price'
      },
      // {
      //   label: this.$t('method'),
      //   value: 'method'
      // }
    ];
  }

  public get columns() {
    return [
      {
        prop: 'name',
        label: `plan's name`
      },
      {
        prop: 'desc',
        label: 'description'
      },
      {
        prop: 'type',
        label: 'type',
        width: 66,
        labelStyle: (model) => {
          return model.type === PlanType.Free ? 'link' : '';
        }
      },
      {
        prop: 'price',
        label: 'price',
        width: 92
      },
      {
        label: 'duration',
        prop: 'duration'
      },
      {
        prop: 'accessibility',
        label: 'Availability',
        width: 120
      },
      {
        prop: 'from',
        label: 'from',
        width: 120,
        formatter: (model) => {
          return model.from ? formatBirthday(model.from) : '--';
        }
      }, {
        prop: 'to',
        label: 'to',
        width: 120,
        formatter: (model) => {
          return model.to ? formatBirthday(model.to) : '--';
        }
      }, {
        prop: 'status',
        label: 'Status',
        width: 80,
        formatter: (model) => {
          return this.$t(model.status);
        },
        labelStyle: (model) => {
          return model.status;
        }
      }];
  }

  public goAddNewRouter() {
    this.$router.push({
      path: SubscriptionRouterPath.PlanManagementAddNew
    });
  }

  public async changePaginationFilter(newValue) {
    await this.$store.commit(MutationTypePlan.PlanPaginationChange, {
      ...omit(this.pagination, ['totalPerPage', 'total', 'totalPages']),
      size: newValue,
      page: 1
    });

    this.fetchData();
  }
  public clickDetail(id: number) {
    this.$router.push({
      name: SubscriptionRouterName.PlanDetail,
      params: {
        planId: id.toString()
      }
    });
  }
  public handleUpdate(id: string| number) {
    this.$router.push({
      name: SubscriptionRouterName.PlanEdit,
      params: {
        planId: id.toString()
      }
    });
  }

  public handleDisabled(model: any) {
    const modelTarget = find(cloneDeep(this.data), (e) => e.id.toString() === model.id);

    this.$store.dispatch(ActionTypePlan.PlanUpdate, {
      id: model.id,
      form: {
        status: modelTarget.status === PlanStatus.Active ? PlanStatus.Disabled : PlanStatus.Active
      },
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();

        this.$message({
          type: modelTarget.status === PlanStatus.Active ? 'info' : 'success',
          message: this.$t(
            modelTarget.status === PlanStatus.Active ? 'disabled_successfully' :  'active_successfully').toString()
        });
        this.fetchData();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }

  public changePagination() {
    this.fetchData();
  }

  public clickDropdown() {
    //
  }

  public formatterActionLabel(a: any) {
    return a.label;
  }

  protected mounted() {
    this.$nextTick(() => {
      this.fetchData();
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypePlan.PlanPaginationReset);
  }

  private fetchData() {
    const pagination = this.pagination;
    const formConverted = {
      params: {
        ...omit(pagination, ['totalPerPage', 'total', 'totalPages'])
      }
    };

    this.$store.dispatch(ActionTypePlan.PlanFilterNoCache, formConverted);
  }
}
