import { safeParseInt, stripObject } from '@hgiasac/helper';
import { Form } from 'element-ui';
import { cloneDeep, isArray, isEmpty, omit } from 'lodash';
import { cmaHttpClient } from 'root/api/http';
import { CMAHeader } from 'root/components';
import { confirmAction, formatCurrency, ruleRequired } from 'root/helpers';
import { assignmentTemplateForm,
  IAssignment, IAssignmentCase,
  IAssignmentTemplateForm, IAssignmentWarning, IBreadcrumb, ICase, Operator, QuestionStatus } from 'root/models';
import { ActionType, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { SearchFollowCase } from '../Components';
import { ActionTypeAssignment, MutationTypeAssignment } from '../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'search-follow-case': SearchFollowCase
  },
  computed: {
    ...mapGetters(['gradeOptions', 'methodOptions']),
    ...mapState({
      loading: (state: IState) => state.assignment.template.loading,
      detail: (state: IState) => state.assignment.detail,
      assignmentSelected: (state: IState) => state.assignment.assignmentSelected
    })
  }
})

export class AssignmentTemplateEditor extends Vue {

  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        path: '/'
      },
      {
        label: 'Assignment',
        path: '/assignments'
      },
      {
        label: 'Assignment Template',
        path: '/assignments/template'
      },
      {
        label: this.isUpdating ? this.$t('edit').toString() : 'Add new assignment template',
        path: '',
        current: true
      }
    ];
  }

  // public get isViewOnly(): boolean {
  //   return this.isUpdating;
  // }

  public get assignmentId() {
    return this.$route.params.assignmentId;
  }

  public get isUpdating(): boolean {
    return !!this.assignmentId;
  }
  public $refs: {
    form: Form,
    searchFollowCase: SearchFollowCase
  };
  public assignmentSelected: IAssignment;
  public detail: IAssignment;
  public data = [];
  public form: IAssignmentTemplateForm = assignmentTemplateForm();
  public rules: any = {
    required: ruleRequired()
  };

  public cases: ICase[] = [];
  public isShowCase: boolean = false;
  public formatCurrency = formatCurrency;

  private warning: IAssignmentWarning = {
    isWarning: false,
    message: '',
    index: null
  };

  public setWarning(data: IAssignmentWarning) {
    this.warning = data;
    this.$refs.searchFollowCase.setErrorTotalQuestionIndex(data.index);
  }

  public generateQuestion(cases) {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        try {
          const casesConverted = cloneDeep(cases).map((e) => {
            if ([Operator.Plus, Operator.PlusAndSub].includes(e.operators)) {
              e.digitOne = null;
              e.digitTwo = null;
              if (e.digits === 'Mixed') {
                e.mixed = true;
                e.digits = null;
              }
              if (e.operators === Operator.PlusAndSub) {
                e.operators = ['+', '-'];
              }
            } else {
              e.digits = null;
              e.mixed = null;
            }

            return stripObject({
              method: this.form.method,
              grade: Number(this.form.grade),
              ...omit(e, ['caseId', 'maximumQuestion']),
              totalQuestion: safeParseInt(e.totalQuestion),
              operators: Array.isArray(e.operators) ? e.operators : [e.operators],
            });
          });

          const data = await this.fetchQuestions(casesConverted);

          this.setWarning({
            index: null,
            isWarning: false,
            message: ''
          });

          this.data = data.reduce((a: any, b: any, i) => a.concat(b.map((e) => ({
            ...e,
            case: `Case ${i + 1}`
          }))), []).map((e, i) => ({
            ...e,
            index: i
          }));
        } catch (error) {
          this.data = [];
          if (error.isWarning) {
            this.setWarning(error);
          }
        }
      }

      return;
    });
  }

  public clickSave() {
    const questionIds = this.data.map((e) => e.id).filter((e) => e);
    const form = cloneDeep(this.form),
      { title, desc } = form;

    const handler = {
      form: {
        title,
        desc,
        questionIds,
      },
      onSuccess: () => {
        this.$router.push('/assignments/template');
      }
    };

    this.$refs.form.validate(async (valid) => {
      if (valid && !this.warning.isWarning) {
        if (questionIds.length === 0) {
          this.$store.dispatch(ActionType.CatchException, {
            message: `Generate questions before ${this.isUpdating ? 'update' : 'add new'} this assignment!`
          });

          return;
        }
        if (this.isUpdating) {
          this.$store.dispatch(ActionTypeAssignment.AssignmentTemplateUpdate, {
            ...handler,
            id: this.assignmentId
          });
        } else {
          this.$store.dispatch(ActionTypeAssignment.AssignmentTemplateCreate, handler);
        }
      }

      return;
    });

  }

  public clickCancel() {
    this.$router.push('/assignments/template');
  }

  public mounted() {
    this.$nextTick(async () => {
      const id = this.assignmentId || (this.assignmentSelected ? this.assignmentSelected.id : null);
      if (id) {
        // this.setData(cloneDeep(this.assignmentSelected));
        let _detail = null;
        try {
          if (this.isUpdating) {
            await this.$store.dispatch(ActionTypeAssignment.AssignmentTemplateGet, id);
            _detail = cloneDeep(this.assignmentSelected);
          } else {
            await this.$store.dispatch(ActionTypeAssignment.GetAssignmentDetail, {id});
            _detail = cloneDeep(this.detail);
          }
          // const _detail = cloneDeep(this.detail),
          const cases = <any> _detail.cases;
          await this.checkCaseStatus(cases);
          this.setData(_detail);
          this.isShowCase = true;
        } catch (error) {
          console.log(error);
        }
      } else {
        this.isShowCase = true;
      }

      return;

    });

  }

  public confirmChangeGradeMethod(value, field) {
    if (this.cases.length >= 0 && this.form.grade && this.form.method) {
      confirmAction(this, {
        title: 'Confirm your change',
        message: 'Changes you made may not be saved.',
        handleFunction: () => {
          this.form[field] = value;
          this.cases = [];
          this.isShowCase = false;
          setTimeout(() => {
            this.isShowCase = true;
          }, 0);
        }
      });

      return;
    }
    this.form[field] = value;
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeAssignment.ResetAssignmentDetail);
    this.$store.commit(MutationTypeAssignment.AssignmentTemplateResetAssignmentSelected);
  }

  private checkCaseStatus(cases: IAssignmentCase[]): Promise<boolean | IAssignmentWarning> {
    return new Promise(async (resolve, reject) => {
      if (!isArray(cases) || isEmpty(cases)) {
        resolve(true);
      }

      await Promise.all(cases.map((e, index) => {
        if (e.status === QuestionStatus.Deleted) {
          reject({
            index,
            isWarning: true,
            message: 'Any case was deleted, please add other case to generate questions'
          });
        }
        e = omit(e, 'status');
        const questions = cloneDeep(e.questions).filter((question) => {
          return question.status === QuestionStatus.Deleted || question.status === QuestionStatus.PendingDelete;
        });

        if (questions.length === e.questions.length) {
          reject({
            index,
            isWarning: true,
            message: 'Any questions was deleted, please generate questions again before update!'
          });
        }

        return;

      }));

      resolve(true);

    });
  }

  private fetchQuestions(cases: any[]): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      const data = await Promise.all(cases.map(async (e, i: number) => {
        if (e.status === QuestionStatus.Deleted) {
          return [];
        }
        e = omit(e, 'status');
        e.mixedDecimal = !!(e.decimal && e.decimal === 'Mixed');
        e.decimal = e.decimal && e.decimal === 'Mixed' ? 0 : e.decimal;
        const dataEach = await cmaHttpClient.assignment.filterQuestion(e);
        if (dataEach.length !== e.totalQuestion) {
          reject({
            isWarning: true,
            message: 'Questions bank is not enough to generate assignment',
            index: i
          });
        }

        return dataEach;
      }));

      resolve(data);
    });
  }

  private setData(data: any) {
    this.cases = data.cases;
    this.form = {
      grade: data.library.grade,
      method: data.library.method,
      title: data.title,
      desc: data.desc,
    };
  }
}
