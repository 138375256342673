import { i18n } from 'root/locales';
import { ProcessingSubscription } from 'root/models';

export const getters = {
  subscriptionStatusSelection() {
    return [
      {
        value: null,
        label: i18n().t('all.status')
      },
      {
        value: ProcessingSubscription.Success,
        label: i18n().t(ProcessingSubscription.Success)
      },
      {
        value: ProcessingSubscription.Processing,
        label: i18n().t(ProcessingSubscription.Processing)
      },
      {
        value: ProcessingSubscription.CancelPeriodEndAt,
        label: i18n().t(ProcessingSubscription.CancelPeriodEndAt)
      },
      {
        value: ProcessingSubscription.Cancel,
        label: i18n().t(ProcessingSubscription.Cancel)
      },
      {
        value: ProcessingSubscription.Fail,
        label: i18n().t(ProcessingSubscription.Fail)
      },
    ];
  }
};
