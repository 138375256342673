import { StatusCode } from './Common';
import { IExchangeRate } from './ExchangeRate';
import { ISimpleUser } from './User';

export interface IProductCreate {
  level: number | string;
  currency: number;
  title: string;
  desc: string;
  images: IProductAttchment[];
}

export interface IProductAttchment {
  src: string;
  isPrimary?: boolean;
}

export const productCreateDefault = (): IProductCreate => {
  return {
    desc: '',
    level: null,
    currency: null,
    title: '',
    images: []
  };
};

export interface IProduct extends IProductCreate {
  id: number;
  status?: StatusCode;
  exchangeRate?: IExchangeRate;
  point?: number;
}

export interface IProductParamsFilter {
  text: string;
  progress?: RedemptionStatus;
}

export enum RedemptionStatus {
  Processing = 'processing',
  Completed = 'completed',
  Rejected = 'rejected',
  Ready = 'ready'
}

export interface IRedemption {
  id: number;
  owner?: ISimpleUser;
  centre: string;
  product: IProduct;
  qty: number;
  progress: RedemptionStatus;
  createdAt: Date;
}

export interface ITransactionHistoryParams {
  centre?: string;
  from?: Date;
  to?: Date;
}

export const transactionHistoryParamsDefault = (): ITransactionHistoryParams => {
  return {
    centre: null,
    from: null,
    to: null
  };
};
