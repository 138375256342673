import { omit } from 'lodash';
import { cmaHttpClient } from 'root/api/http';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IAssignmentState } from './mutations';
import { ActionTypeAssignment, MutationTypeAssignment } from './types';

const { filter, list, create, getById,
  deleteById, update,
  getLibraryQuestionById,
  createAssignmentTemplate,
  assignmentTemplateFilter,
  deleteAssignmentTemplate,
  getAssignmentTemplate,
  updateAssignmentTemplate,
  getAssignmentStatistic,
  filterUsersByAssignment,
  getUserByAssignment,
  getAssignmentByUserId } = cmaHttpClient.assignment;
export const actions = {
  ...crudActions('assignment', {
    filter, list, create, update, deleteById
  }),
  async [ActionTypeAssignment.GetAssignmentDetail](
    { commit, dispatch }: ActionContext<IAssignmentState, IState>, {id}
  ) {
    commit(MutationTypeAssignment.AssignmentLoading);
    try {
      const data = await getById(id);
      commit(MutationTypeAssignment.SetAssignmentDetail, data);
      commit(MutationTypeAssignment.AssignmentLoaded);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      commit(MutationTypeAssignment.AssignmentLoaded);
    }
  },
  async [ActionTypeAssignment.GetAssignmentQuestions](
    { commit, dispatch}: ActionContext<IAssignmentState, IState>, {id}
  ) {
    commit(MutationTypeAssignment.AssignmentQuestionLoading, true);
    try {
      const data = await getLibraryQuestionById(id);
      commit(MutationTypeAssignment.AssignmentQuestionLoading, false);
      commit(MutationTypeAssignment.SetAssignmentQuestions, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      commit(MutationTypeAssignment.AssignmentQuestionLoading, false);
    }
  },
  async [ActionTypeAssignment.GetAssignmentByUser](
    { commit, dispatch}: ActionContext<IAssignmentState, IState>, {id, _filter = <any> {params: {}}}
  ) {
    commit(MutationTypeAssignment.AssignmentQuestionLoading, true);
    const order = !_filter || !_filter.params || !_filter.params.order ? undefined
          : (_filter.params.descending ? '-' + _filter.params.order : _filter.params.order);
    _filter.params.order = order;
    _filter.params = {
      ...omit(_filter.params, ['totalPerPage', 'total', 'totalPages'])
    };

    try {
      const data = await getAssignmentByUserId(id, _filter);
      commit(MutationTypeAssignment.AssignmentQuestionLoading, false);
      commit(MutationTypeAssignment.SetAssignmentByUser, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      commit(MutationTypeAssignment.AssignmentQuestionLoading, false);
    }
  },
  async [ActionTypeAssignment.AssignmentGetUsers](
    { commit, dispatch, state}: ActionContext<IAssignmentState, IState>, {id, params}
  ) {
    commit(MutationTypeAssignment.AssignmentLoading);
    try {
      const data = await filterUsersByAssignment(id, {
        ...params,
        ...state.users.pagination
      });
      commit(MutationTypeAssignment.AssignmentSetUsers, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeAssignment.AssignmentLoaded);
  },
  async [ActionTypeAssignment.AssignmentGetUserDetail](
    { commit, dispatch}: ActionContext<IAssignmentState, IState>, {assignmentId, userId}
  ) {
    commit(MutationTypeAssignment.AssignmentLoading);
    try {
      const data = await getUserByAssignment(assignmentId, userId);

      commit(MutationTypeAssignment.AssignmentSetUserDetail, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeAssignment.AssignmentLoaded);
  },

  async [ActionTypeAssignment.AssignmentTemplateCreate](
    { commit, dispatch}: ActionContext<IAssignmentState, IState>, {form, onSuccess}
  ) {
    commit(MutationTypeAssignment.AssignmentTemplateLoading);
    try {
      const data = await createAssignmentTemplate(form);
      commit(MutationTypeAssignment.AssignmentTemplateAddModel, data);
      onSuccess();
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeAssignment.AssignmentTemplateLoaded);
  },
  async [ActionTypeAssignment.AssignmentTemplateUpdate](
    { commit, dispatch}: ActionContext<IAssignmentState, IState>, {form, onSuccess, id}
  ) {
    commit(MutationTypeAssignment.AssignmentTemplateLoading);
    try {
      const data = await updateAssignmentTemplate(id, form);
      commit(MutationTypeAssignment.AssignmentTemplateAddModel, data);
      onSuccess();
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeAssignment.AssignmentTemplateLoaded);
  },

  async [ActionTypeAssignment.AssignmentTemplateFilter](
    { commit, dispatch}: ActionContext<IAssignmentState, IState>, _filter = <any> {
      params: {}
    }
  ) {
    commit(MutationTypeAssignment.AssignmentTemplateLoading);
    const order = !_filter || !_filter.params || !_filter.params.order ? undefined
          : (_filter.params.descending ? '-' + _filter.params.order : _filter.params.order);
    _filter.params.order = order;
    try {
      const data = await assignmentTemplateFilter(_filter);
      commit(MutationTypeAssignment.AssignmentTemplateSetModels, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeAssignment.AssignmentTemplateLoaded);
  },

  async [ActionTypeAssignment.AssignmentTemplateDelete](
    { commit, dispatch}: ActionContext<IAssignmentState, IState>, payload: any
  ) {
    commit(MutationTypeAssignment.AssignmentTemplateLoading);
    try {
      await deleteAssignmentTemplate(payload.id);
      payload.onSuccess();
      // commit(MutationTypeAssignment.AssignmentTemplateSetModels, data);
    } catch (error) {
      payload.onFailure();
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeAssignment.AssignmentTemplateLoaded);
  },

  async [ActionTypeAssignment.AssignmentTemplateGet](
    { commit, dispatch}: ActionContext<IAssignmentState, IState>, id
  ) {
    commit(MutationTypeAssignment.AssignmentTemplateLoading);
    try {
      const data = await getAssignmentTemplate(id);
      commit(MutationTypeAssignment.AssignmentTemplateSelect, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeAssignment.AssignmentTemplateLoaded);
  },

  async [ActionTypeAssignment.AssignmentGetStatistic](
    { commit, dispatch}: ActionContext<IAssignmentState, IState>, id
  ) {
    try {
      const data = await getAssignmentStatistic(id);
      commit(MutationTypeAssignment.AssignmentSetStatistic, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  }
};
