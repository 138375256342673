export enum ActionTypeExchangeRate {
  ExchangeRateGetData = 'ExchangeRateGetData',
  ExchangeRateUpdateRate = 'ExchangeRateUpdateRate',
  GetCurrentExchangeRate = 'getCurrentExchangeRate'
}

export enum MutationTypeExchangeRate {
  ExchangeRateSetData = 'ExchangeRateSetData',
  ExchangeRateSetLayout = 'ExchangeRateSetLayout',
  ExchangeRateLoading = 'ExchangeRateLoading',
  ExchangeRateLoaded = 'ExchangeRateLoaded',
  SetCurrentExchangeRate = 'SetCurrentExchangeRate'
}
