import moment from 'moment-timezone';
import { cmaHttpClient } from 'root/api/http';
import { IStatisticFilter } from 'root/models';
import { ActionType, IState } from 'root/store';
import { ActionContext } from 'vuex';
import { IDashboardState } from './mutations';
import { ActionTypeDashboard, MutationTypeDashboard } from './types';

const { filterDashboardMostActiveUser, filterDashboardNewUser, filterDashboardCoolest,
  filterDashboardSubscription, filterDashboardAssignment,
  filterDashboardRedemption } = cmaHttpClient.dashboard;
export const actions = {
  async [ActionTypeDashboard.DashboardFilterMostActiveUsers](
    { commit, dispatch }: ActionContext<IDashboardState, IState>, paramFilter: IStatisticFilter
  ) {
    // convertParamFilter(paramFilter);
    try {
      const data = await filterDashboardMostActiveUser(paramFilter);
      commit(MutationTypeDashboard.DashboardSetMostActiveUsers, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeDashboard.DashboardFilterNewUsers](
    { commit, dispatch }: ActionContext<IDashboardState, IState>, paramFilter: IStatisticFilter
  ) {
    convertParamFilter(paramFilter);
    try {
      const data = await filterDashboardNewUser(paramFilter);
      commit(MutationTypeDashboard.DashboardSetNewUsers, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  async [ActionTypeDashboard.DashboardFilterCoolest](
    { commit, dispatch }: ActionContext<IDashboardState, IState>, paramFilter: IStatisticFilter
  ) {
    convertParamFilter(paramFilter);
    commit(MutationTypeDashboard.DashboardCoolestLoading);
    try {
      const data = await filterDashboardCoolest(paramFilter);
      commit(MutationTypeDashboard.DashboardSetCoolest, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeDashboard.DashboardCoolestLoaded);
  },
  /**
   * 
   * subscription
   */
  async [ActionTypeDashboard.DashboardFilterSubscription](
    { commit, dispatch, state }: ActionContext<IDashboardState, IState>
  ) {
    const paramFilter: IStatisticFilter = state.subscriptionFilter;
    convertParamFilter(paramFilter);
    commit(MutationTypeDashboard.DashboardCoolestLoading);
    try {
      const data = await filterDashboardSubscription(paramFilter);
      commit(MutationTypeDashboard.DashboardSetSubscription, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeDashboard.DashboardCoolestLoaded);
  },
  /**
   * assignment
   */
  async [ActionTypeDashboard.DashboardFilterAssignment](
    { commit, dispatch, state }: ActionContext<IDashboardState, IState>
  ) {
    const paramFilter: IStatisticFilter = state.assignmentFilter;
    convertParamFilter(paramFilter);
    try {
      const data = await filterDashboardAssignment(paramFilter);
      commit(MutationTypeDashboard.DashboardSetAssignment, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  },
  /**
   * redemption
   */
  async [ActionTypeDashboard.DashboardFilterRedemption](
    { commit, dispatch, state }: ActionContext<IDashboardState, IState>
  ) {
    const paramFilter: IStatisticFilter = state.redemptionFilter;
    convertParamFilter(paramFilter);
    try {
      const data = await filterDashboardRedemption(paramFilter);
      commit(MutationTypeDashboard.DashboardSetRedemption, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  }
};

function convertParamFilter(paramFilter) {
  paramFilter.startDate = moment(paramFilter.startDate).tz(moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
  paramFilter.maxDate = paramFilter.maxDate
    ? moment(paramFilter.maxDate).tz(moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss')
    : null;
}
