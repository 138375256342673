import { RouteConfig } from 'vue-router';
import { NotificationContainer } from './NotificationContainer';
import { NotificationEditor } from './NotificationEditor';
import { NotificationList } from './NotificationList';
import { NotificationView } from './NotificationView';

export enum NotificationRouterPath {
  Default = '/notifications',
  Create = '/notifications/create',
  Edit = '/notifications/:notifyId/edit',
  List = '/notifications/list',
  View = '/notifications/:notifyId/view',
}

export enum NotificationRouterName {
  View = 'notificationView',
  Edit = 'notificationEdit'
}

export const NotificationRouter: RouteConfig = {
  path: NotificationRouterPath.Default,
  component: NotificationContainer,
  children: [
    {
      path: '',
      redirect: NotificationRouterPath.List
    },
    {
      path: NotificationRouterPath.List,
      component: NotificationList
    },
    {
      path: NotificationRouterPath.Create,
      component: NotificationEditor
    },
    {
      path: NotificationRouterPath.Edit,
      component: NotificationEditor,
      name: NotificationRouterName.Edit
    },
    {
      path: NotificationRouterPath.View,
      component: NotificationView,
      name: NotificationRouterName.View
    },
    {
      path: '*',
      redirect: NotificationRouterPath.List
    }
  ]
};
