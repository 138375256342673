export enum ActionTypeExamination {
  FilterNoCache = 'examinationFilterNoCache'
}

export enum MutationTypeExamination {
  Loading = 'examinationLoading',
  Loaded = 'examinationLoaded',
  SetData = 'examinationSetData',
  ClearData = 'examinationClearData',
}
