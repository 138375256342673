import * as js2excel from 'js2excel';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { CMAButtonFilter, CMAHeader, CMAListData, CMASelect } from 'root/components';
import { formaterPhone, formatBirthday } from 'root/helpers';
import { IBreadcrumb, ISubscription } from 'root/models';
import { ActionTypePlan, MutationTypePlan } from 'root/pages/Subscription/PlanStore/types';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypeReport, MutationTypeReport } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-list-data': CMAListData,
    'cma-btn-filter': CMAButtonFilter,
    'cma-header': CMAHeader,
    'cma-select': CMASelect
  },
  computed: {
    ...mapGetters(['RedemptionStatusOptions']),
    ...mapState({
      listCentre: (state: IState) => state.global.globalConfig.listCentre,
      data: (state: IState) => state.report.subscription,
      plans: (state: IState) => state.plan.data,
      loading: (state: IState) => state.report.subscriptionLoading
    }),
    cloneCentreOptions() {
      const listCentre = cloneDeep(this.listCentre);

      return [
        {
          value: null,
          name: this.$t('all.centre')
        },
        ...listCentre
      ];
    },
    cloneStatusOptions() {
      const statusOptions = cloneDeep(this.RedemptionStatusOptions);
      statusOptions[0].label = this.$t('all.status');

      return statusOptions;
    },
    clonePlansOptions() {
      const plans: any[] = cloneDeep(this.plans);

      const result: any[] = [{
        value: null,
        name: this.$t('all')
      }];
      plans.map((e) => {
        result.push({
          value: e.id,
          name: e.name
        });
      });

      return result;
    },
    startDatePicker: {
      get() {
        return this.filterBody.startDate ? moment(this.filterBody.startDate).utc(true).tz(moment.tz.guess()) : null;
      },
      set(value) {
        this.filterBody.startDate = value;
      }
    },
    endDatePicker: {
      get() {
        return this.filterBody.endDate ? moment(this.filterBody.endDate).utc(true).tz(moment.tz.guess()) : null;
      },
      set(value) {
        this.filterBody.endDate = value;
      }
    }
  }
})

export class ReportSubscription extends Vue {
  public filterBody = {
    startDate: null,
    endDate: null
  };
  public filterParams = {
    planId: null,
    centre: null
  };
  public filterEnable: boolean = false;
  public data: ISubscription[];

  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        path: '/'
      },
      {
        label: 'Report',
        path: '',
      },
      {
        label: 'Subscription',
        path: '',
        current: true
      }
    ];
  }

  public columns = [
    {
      label: 'Name',
      prop: 'name',
      formatter: (model: ISubscription) => {
        return model.owner.fullName;
      },
      avatar: {
        formatter: (model: ISubscription) => {
          return model ? model.owner : null;
        }
      },
      width: 160
    },
    {
      label: 'centre',
      prop: 'centre',
      width: 170,
      formatter: (model: ISubscription) => model.owner.centre,
    },
    {
      label: 'gender',
      prop: 'gender',
      formatter: (model: ISubscription) => model.owner.gender,
    },
    {
      prop: 'ownerId',
      label: 'ID',
      formatter: (model: ISubscription) => `ID ${model.owner.id}`,
      width: 100,
    },
    {
      prop: 'email',
      label: 'Email',
      width: 200,
      formatter: (model: ISubscription) => model.owner.email
    },
    {
      prop: 'phone',
      label: 'Phone',
      width: 170,
      formatter: (model: any) => model.owner ? formaterPhone(model.owner.phone) : ''
    },
    {
      prop: 'address',
      label: 'address',
      width: 200,
      formatter: (model: any) => model.owner ? model.owner.address : ''
    },
    {
      prop: 'title',
      label: 'subscribed plan',
      formatter: (model: ISubscription) => model.plan.name,
      width: 200
    },
    {
      prop: 'createdAt',
      label: 'Subscribed Date',
      width: 100,
      formatter: (model: ISubscription) => {
        return model.createdAt ? formatBirthday(model.createdAt) : '__';
      },
    }];

  public changeFilter(value, key) {
    this.filterParams[key] = value;
    this.filterEnable = true;
  }

  public exportToExcel() {
    const columns: any = cloneDeep(this.columns);

    const data = cloneDeep(this.data).map((redemption) => {
      const result: any = {};
      columns.forEach((column) => {
        result[column.label.toUpperCase()] = typeof column.formatter === 'function'
          ? column.formatter(redemption) : redemption[column.prop];
      });

      return result;
    });

    const startDate = moment(this.filterBody.startDate).utc(true).tz(moment.tz.guess()).format('DD-MM-YYYY'),
      endDate = moment(this.filterBody.endDate).utc(true).tz(moment.tz.guess()).format('DD-MM-YYYY');

    try {
      js2excel.default.json2excel({
        data,
        // tslint:disable-next-line:max-line-length
        name: `sub_${startDate}_${endDate}`
      });
    } catch (error) {
      console.error('export error', error);
    }
  }
  public applyFilter() {
    this.filterEnable = false;
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypePlan.planFetchMany);
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeReport.ReportSubscriptionSetData, []);
    this.$store.commit(MutationTypePlan.PlanRefreshModels, []);
  }

  private fetchData() {
    this.$store.commit(MutationTypeReport.ReportSubscriptionSetData, []);
    this.$store.dispatch(ActionTypeReport.ReportSubscriptionFilter, {
      filterBody: cloneDeep(this.filterBody),
      filterParams: this.filterParams
    });
  }
}
