import { CMAHeader } from 'root/components';
import { IBreadcrumb } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { DashboardRouterPath } from './index';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader
  }
})

export class DashboardContainer extends Vue {
  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Dashboard',
      path: '',
      current: true
    }
  ];

  public dashboardNavigation: any[] = [
    {
      label: 'user',
      path: DashboardRouterPath.User
    },
    {
      label: 'subscription',
      path: DashboardRouterPath.Subscription
    },
    {
      label: 'assignment',
      path: DashboardRouterPath.Assignment
    },
    {
      label: 'redemption',
      path: DashboardRouterPath.Redemption
    },
    {
      label: 'download',
      path: DashboardRouterPath.Download
    }
  ];
}
