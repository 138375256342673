import Vue from 'vue';
import Component from 'vue-class-component';
import { DashboardCoolest } from '../Components/DashboardCoolest';
import { DashboardMostActiveUser } from '../Components/DashboardMostActiveUser';
import { DashboardNewUsers } from '../Components/DashboardNewUsers';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'dashboard-new-users': DashboardNewUsers,
    'dashboard-most-active-user': DashboardMostActiveUser,
    'dashboard-coolest-kid': DashboardCoolest
  }
})

export class DashboardUser extends Vue {
  //
}
