import { formatCurrency } from 'root/helpers';
import { ILibraryConfig, Operator } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    index: Number,
    data: {
      type: Object,
      default: () => {
        return {
          configCase: {},
          questions: []
        };
      }
    }
  }
})

export class LibraryQuestion extends Vue {
  public data: any;
  public formatCurrency = formatCurrency;

  public get config() {
    if (!this.data || !this.data.config) {
      return {
        operator: null,
        number: null,
        digit: null,
        digit1: null,
        digit2: null,
        totalQuestion: null
      };
    }

    const config = this.data.config,
      { operator, digit, digit2, digit1, totalQuestion, isCurrency} = config;

    return {
      operator,
      digit,
      digit1,
      digit2,
      totalQuestion,
      isCurrency,
      number: config.number
    };
  }

  public get getIsCurrencyText() {
    // const data = this.data;

    return this.config.isCurrency ? 'Yes' : 'No';
    // return data.questions && data.questions[0] & data.questions[0].isCurrency ? 'Yes' : 'No';
  }

  public getDigitFirst(model: ILibraryConfig, first?: boolean) {
    const digitCfg: any[] = model.digitCfg;

    if (!digitCfg || digitCfg.length < 2) {
      return '__';
    }

    if (first) {
      return digitCfg[0].digits === 0 ? 'Mixed' : digitCfg[0].digits;
    }

    return digitCfg[1].digits === 0 ? 'Mixed' : digitCfg[1].digits;
    // return first ? digitCfg[0].digits : digitCfg[1].digits;
  }
  public getDigit(model: ILibraryConfig) {
    const digitCfg: any[] = model.digitCfg;

    if (model.operators && (model.operators.length > 1 || model.operators[0] === Operator.Plus) && model.mixed) {
      return 'Mixed';
    }
    if (digitCfg && digitCfg.length === 1) {
      return digitCfg[0].digits;
    }

    return '__';
  }
  public getDecimal(model: ILibraryConfig) {
    return model.mixedDecimal ? 'Mixed' : model.decimal;
  }

}
