import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  template: '<router-view></router-view>'
})

export class SubscriptionContainer extends Vue {

}
