export enum MutationTypeUser {
  UserLoading = 'userLoading',
  UserLoaded = 'userLoaded',
  UserResetStore = 'UserResetStore',
  SetUserDetail = 'SetUserDetail',
  ClearUserDetail = 'clearUserDetail',
  UserPaginationReset = 'userPaginationReset',
  SetUserPaginationSort = 'SetUserPaginationSort',
  SetUserPagination = 'SetUserPagination',
  UserFilterChange = 'userFilterChange'
}

export enum ActionTypeUser {
  UserFilterNoCache = 'userFilterNoCache',
  UserCreate = 'userCreate',
  UserUpdate = 'userUpdate',
  UserDelete = 'userDelete',
  UserFilterChange = 'userFilterChange',
  GetUserById = 'GetUserById',
  ChangePassword = 'ChangePassword',
  SoftDelete = 'userSoftDelete'
}
