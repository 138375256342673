import { cmaFormatDateFullTime } from 'root/helpers';
import { IPaginationState, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeExchangeRate } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.exchange.data,
      pagination: (state: IState) => state.exchange.pagination,
      loading: (state: IState) => state.exchange.loading,
    })
  }
})

export class ExchangeHistory extends Vue {
  public pagination: IPaginationState;
  public cmaFormatDateFullTime = cmaFormatDateFullTime;

  public currentChange(newValue) {
    this.pagination.page = newValue;
    window.scrollTo(0, 0);
    this.$store.dispatch(ActionTypeExchangeRate.ExchangeRateGetData, {
      params: {
        ...this.pagination
      }
    });
  }
}
