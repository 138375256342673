import { find } from 'lodash';
import { i18n } from 'root/locales';
import { RedemptionStatus } from 'root/models';
import { IProductState } from './mutations';
import { GetterTypeProduct } from './types';

export const getters = {
  getProductById(state: IProductState) {
    return (id: number) => {

      return find(state.data, (e) => e.id.toString() === id.toString());
    };

  },
  RedemptionStatusOptions: () => [
    {
      value: null,
      label: i18n().t('status')
    },
    {
      value: RedemptionStatus.Rejected,
      label: i18n().t(RedemptionStatus.Rejected)
    },
    {
      value: RedemptionStatus.Ready,
      label: i18n().t(RedemptionStatus.Ready)
    },
    {
      value: RedemptionStatus.Completed,
      label: i18n().t(RedemptionStatus.Completed)
    },
    {
      value: RedemptionStatus.Processing,
      label: i18n().t(RedemptionStatus.Processing)
    },
  ],
  [GetterTypeProduct.GetRedemption](state: IProductState) {
    return (id: number) => {
      return find(state.transaction.data, (e) => e.id.toString() === id.toString());
    };
  }
};
