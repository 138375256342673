import { cmaHttpClient } from 'root/api/http';
import { crudActions } from 'root/store/helpers';
import { ActionTypeLibrary, MutationTypeLibrary } from './types';

import { ActionType, IState } from 'root/store/types';
import { ActionContext } from 'vuex';
import { ILibraryState } from './mutations';
const { filter, list, update, create, deleteById, getById,
  getLibraryQuestionById, getAllCases } = cmaHttpClient.library;
export const actions = {
  ...crudActions('library', {
    filter, list, create, update,
    deleteById
  }),
  async [ActionTypeLibrary.getLibraryId](
    { commit, dispatch }: ActionContext<ILibraryState, IState>, {id}
  ) {
    commit(MutationTypeLibrary.LoadingLibrary, true);
    try {
      const data = await getById(id);
      commit(MutationTypeLibrary.SetLibraryDetail, data);
      commit(MutationTypeLibrary.LoadingLibrary, false);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      commit(MutationTypeLibrary.LoadingLibrary, false);
    }
  },
  async [ActionTypeLibrary.getLibraryQuestions](
    { commit, dispatch}: ActionContext<ILibraryState, IState>, {id}
  ) {
    commit(MutationTypeLibrary.SetLibraryQuestionsLoading, true);
    try {
      const data = await getLibraryQuestionById(id);
      commit(MutationTypeLibrary.SetLibraryQuestionsLoading, false);
      commit(MutationTypeLibrary.SetLibraryQuestions, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      commit(MutationTypeLibrary.SetLibraryQuestionsLoading, false);
    }
  },
  async [ActionTypeLibrary.getLibraryAllCases](
    { commit, dispatch}: ActionContext<ILibraryState, IState>, {grade, method}
  ) {
    try {
      const data = await getAllCases(method, grade);

      commit(MutationTypeLibrary.setLibraryAllCases, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
  }
};
