import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue';
import { Form } from 'element-ui';
import { cloneDeep, pick } from 'lodash';
import * as moment from 'moment';
import { CMAHeader, CMASelect } from 'root/components';
import { ruleRequired } from 'root/helpers';
import { notificationFormDefault, IBreadcrumb, INotification, INotificationForm } from 'root/models';
import { EditorUploadAdapterPlugin } from 'root/services/upload/EditorUploadAdapter';
import { GetterTypeGlobal, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { NotificationRouterName, NotificationRouterPath } from '..';
import { ActionTypeNotification, GetterTypeNotification } from '../Store/types';
import './styles.scss';

Vue.use(CKEditor);
@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'cma-select': CMASelect
  },
  computed: {
    ...mapState({
      listCentre: (state: IState) => state.global.globalConfig.listCentre,
      loading: (state: IState) => state.notification.loading
    })
    // cloneListCentre() {
    //   const listCentre = cloneDeep(this.listCentre);

    //   return [{
    //     value: 'all_centre',
    //     name: 'All Centre'
    //   }, ...listCentre];
    // }
  }
})

export class NotificationEditor extends Vue {
  public $refs: {
    form: Form
  };
  public rules: any = {
    required: ruleRequired()
  };
  public form: INotificationForm = notificationFormDefault();
  public listCentre: any[];

  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        path: '/'
      },
      {
        label: 'Notification',
        path: '/notifications'
      },
      {
        label: this.notifyId ? 'Edit' : 'Add New',
        path: '',
        current: true
      }
    ];
  }
  public get notifyId(): string {
    return this.$route.params.notifyId;
  }

  public get notification(): INotification {
    return this.$store.getters[GetterTypeNotification.GetNotificationById](this.notifyId);
  }
  public editor = ClassicEditor;
  // tslint:disable-next-line:max-line-length
  public editorData = '';
  public editorConfig = {
    //
  };
  public pickerOptions = {
    disabledDate(time) {
      return time.getTime() < moment().add(-1, 'days');
    },
  };

  public selectALl() {
    const values = this.listCentre.map((e) => e.value);
    this.form.target = values;
  }

  public save() {
    const data = this.editor.getData();
    this.$refs.form.validate((vaild) => {
      if (vaild) {
        const handler: any = {
          form: {
            ...this.form,
            sendTime: moment(this.form.sendTime).utc().format('YYYY-MM-DD HH:mm:ss'),
            content: data
          },
          onSuccess: () => {
            this.$message.success(`${this.notifyId ? 'Update' : 'Create a'} notification successfully!`);
            this.$router.push(NotificationRouterPath.Default);
          }
        };

        if (this.notifyId) { // call update api
          this.$store.dispatch(ActionTypeNotification.NotificationUpdate, {
            ...handler,
            id: this.notifyId
          });
        } else {
          this.$store.dispatch(ActionTypeNotification.NotificationCreate, {
            ...handler
          });
        }
      }

      return;
    });
  }
  public clickCancel() {
    this.$router.push(NotificationRouterPath.Default);
  }

  protected mounted() {
    this.$nextTick(async () => {
      if (this.notifyId) { // updating
        await this.$store.dispatch(ActionTypeNotification.NotificationFindById, {
          id: this.notifyId
        });
        const notification = cloneDeep(this.notification);
        if (notification && notification.isStarted) {
          this.$message.info(`Can not edit a sent notification!`);
          this.$router.push({
            name: NotificationRouterName.View,
            params: {
              notifyId: this.notifyId
            }
          });

          return;
        }
        this.editorData = notification.content;
        this.form = <any> {
          ...pick(notification, ['title', 'content', 'sendTime', 'target']),
          target: this.$store.getters[GetterTypeGlobal.GetCentreValue](notification.target)
        };
      }
      ClassicEditor
          .create(document.querySelector('#editor'), {
            extraPlugins: [EditorUploadAdapterPlugin],
            removePlugins: ['MediaEmbedEditing', 'MediaEmbed'],
            toolbar: ['bold', 'italic', 'link',
              'bulletedList', 'numberedList', 'imageUpload',
              'blockQuote', 'undo', 'redo']
          })
          .then((editor) => {
            editor.setData(this.editorData);
            this.editor = editor;
          })
          .catch((error) => {
            console.log(error);
          });
    });
  }
}
