export enum ActionTypeNotification {
  NotificationCreate = 'notificationCreate',
  NotificationUpdate = 'notificationUpdate',
  NotificationFilter = 'notificationFilter',
  NotificationFilterNoCache = 'notificationFilterNoCache',
  NotificationFindById = 'notificationFindById'
}

export enum MutationTypeNotification {
  NotificationLoading = 'notificationLoading',
  NotificationLoaded = 'notificationLoaded',
  NotificationUpdateModels = 'notificationUpdateModels',
  SetNotificationPagination = 'setNotificationPagination'
}

export enum GetterTypeNotification {
  GetNotificationById = 'getNotificationById'
}
