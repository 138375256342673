import * as js2excel from 'js2excel';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { CMAButtonFilter, CMAHeader, CMAListData, CMASelect } from 'root/components';
import { formaterPhone, formatBirthday } from 'root/helpers';
import { i18n } from 'root/locales';
import { IBreadcrumb, IRedemption, UserRole } from 'root/models';
import { MutationTypeProduct } from 'root/pages/Product/Store/types';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypeReport, MutationTypeReport } from '../Store/types';
import { ReportRouterPath } from '../index';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-list-data': CMAListData,
    'cma-btn-filter': CMAButtonFilter,
    'cma-header': CMAHeader,
    'cma-select': CMASelect
  },
  computed: {
    ...mapGetters(['RedemptionStatusOptions']),
    ...mapState({
      listCentre: (state: IState) => state.global.globalConfig.listCentre,
      data: (state: IState) => state.report.transactions,
      products: (state: IState) => state.product.data,
      loading: (state: IState) => state.report.transactionsLoading,
      authUser: (state: IState) => state.global.authUser
    }),
    cloneCentreOptions() {
      let listCentre = cloneDeep(this.listCentre);
      const authUser = cloneDeep(this.authUser);

      if (authUser.role === UserRole.CentreManager) {
        listCentre = listCentre.filter((e) => authUser.centreManager.indexOf(e.value) > -1);
      }

      return [
        {
          value: null,
          name: this.$t('all.centre')
        },
        ...listCentre
      ];
    },
    cloneStatusOptions() {
      const statusOptions = cloneDeep(this.RedemptionStatusOptions);
      statusOptions[0].label = this.$t('all.status');

      return statusOptions;
    },
    cloneProductOptions() {
      const products: any[] = cloneDeep(this.products);

      const result: any[] = [{
        value: null,
        name: this.$t('all.product')
      }];
      products.map((e) => {
        result.push({
          value: e.id,
          name: e.title
        });
      });

      return result;
    },
    startDatePicker: {
      get() {
        return this.filterBody.startDate ? moment(this.filterBody.startDate).utc(true).tz(moment.tz.guess()) : null;
      },
      set(value) {
        this.filterBody.startDate = value;
      }
    },
    endDatePicker: {
      get() {
        return this.filterBody.endDate ? moment(this.filterBody.endDate).utc(true).tz(moment.tz.guess()) : null;
      },
      set(value) {
        this.filterBody.endDate = value;
      }
    }
  }
})

export class ReportRedemptionTransaction extends Vue {
  public filterBody = {
    startDate: null,
    endDate: null
  };
  public filterParams = {
    productId: null,
    progress: null,
    text: '',
    centre: null
  };
  public filterEnable: boolean = false;
  public data: IRedemption[];
  public reportRedemptionNavigation: any[] = [
    {
      label: 'Product',
      path: ReportRouterPath.RedemptionProduct
    },
    {
      label: 'Transaction',
      path: ReportRouterPath.RedemptionTransaction
    }
  ];
  public searchMode: string = 'student';

  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        path: '/'
      },
      {
        label: 'Report',
        path: '',
      },
      {
        label: 'Redemption',
        path: '',
        current: true
      }
    ];
  }

  public columns = [
    {
      label: 'Name',
      prop: 'name',
      formatter: (model: IRedemption) => {
        return model.owner.fullName;
      },
      avatar: {
        formatter: (model: IRedemption) => {
          return model ? model.owner : null;
        }
      },
      width: 160
    },
    {
      label: 'centre',
      prop: 'centre',
      width: 170,
      formatter: (model: IRedemption) => model.owner.centre,
    },
    {
      label: 'gender',
      prop: 'gender',
      formatter: (model: IRedemption) => model.owner.gender,
    },
    {
      prop: 'ownerId',
      label: 'ID',
      formatter: (model: IRedemption) => `ID ${model.owner.id}`,
      width: 100,
    },
    {
      prop: 'email',
      label: 'Email',
      width: 200,
      formatter: (model: IRedemption) => model.owner.email
    },
    {
      prop: 'phone',
      label: 'Phone',
      width: 170,
      formatter: (model: any) => model.owner ? formaterPhone(model.owner.phoneNumber) : ''
    },
    {
      prop: 'address',
      label: 'address',
      width: 200,
      formatter: (model: any) => model.owner ? model.owner.address : ''
    },
    {
      prop: 'createdAt',
      label: 'Created at',
      width: 100,
      formatter: (model: any) => {
        return model.createdAt ? formatBirthday(model.createdAt) : '__';
      },
    },
    {
      prop: 'productName',
      label: 'product name',
      formatter: (model: IRedemption) => model.product.title,
      avatar: {
        formatter: (model: IRedemption) => {
          const product = model.product,
            image = product.images.find((e) => e.isPrimary);

          return {
            avatar: image.src
          };
        }
      },
      width: 200
    },
    {
      prop: 'productId',
      label: 'product id',
      formatter: (model: IRedemption) => model.product.id,
      width: 200
    },
    {
      prop: 'qty',
      label: 'Order QTY',
      width: 100,
    },
    {
      prop: 'redemmedAt',
      label: 'Redeemed Date',
      formatter: (model: IRedemption) => formatBirthday(model.createdAt),
      width: 200,
    },
    {
      prop: 'id',
      label: 'Transaction ID',
    },
    {
      prop: 'progress',
      label: `status`,
      formatter: (model) => i18n().t(model.progress) || '__',
      labelStyle: (model) => model.progress,
      width: 150
    }];

  public changeFilter(value, key) {
    this.filterParams[key] = value;
    this.filterEnable = true;
  }
  public changeSearchMode() {
    this.changeFilter('', 'text');
    this.changeFilter('', 'textProduct');
  }

  public exportToExcel() {
    const columns: any = cloneDeep(this.columns);

    const data = cloneDeep(this.data).map((redemption) => {
      const result: any = {};
      columns.forEach((column) => {
        result[column.label.toUpperCase()] = typeof column.formatter === 'function'
          ? column.formatter(redemption) : redemption[column.prop];
      });

      return result;
    });

    const startDate = moment(this.filterBody.startDate).utc(true).tz(moment.tz.guess()).format('DD-MM-YYYY'),
      endDate = moment(this.filterBody.endDate).utc(true).tz(moment.tz.guess()).format('DD-MM-YYYY');
    try {
      js2excel.default.json2excel({
        data,
        // tslint:disable-next-line:max-line-length
        name: `trans_${startDate}_${endDate}`
      });
    } catch (error) {
      console.error('export error', error);
    }
  }
  public applyFilter() {
    this.filterEnable = false;
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      // this.$store.dispatch(ActionTypeProduct.ProductFetchMany);
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeReport.ReportTransactionSetData, []);
    this.$store.commit(MutationTypeProduct.ProductRefreshModels, []);
  }

  private fetchData() {
    this.$store.commit(MutationTypeReport.ReportTransactionSetData, []);
    this.$store.dispatch(ActionTypeReport.ReportRedemptionTransaction, {
      filterBody: cloneDeep(this.filterBody),
      filterParams: this.filterParams
    });
  }
}
