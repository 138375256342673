import { stripObject } from '@hgiasac/helper';
import { cloneDeep, find } from 'lodash';
import { CMAHeader, CMAListData, CMASelect } from 'root/components';
import { cmaFormatDateFullTime, formaterListCounter } from 'root/helpers';
import { IBreadcrumb, IPagingParams, IProduct, IProductParamsFilter, StatusCode, UserRole } from 'root/models';
import { ActionTypeExchangeRate } from 'root/pages/ExchangeRate/Store/types';
import { GetterTypeUser, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypeProduct, MutationTypeProduct } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-select': CMASelect,
    'cma-list-data': CMAListData,
    'cma-header': CMAHeader
  },
  computed: {
    ...mapState({
      pagination: (state: IState) => state.product.pagination,
      data: (state: IState) => state.product.data,
      loading: (state: IState) => state.product.loading,
      exchangeRate: (state: IState) => state.exchange.current
    }),
    ...mapGetters(['paginationOption', 'sortType', GetterTypeUser.GetAuthuserRole]),
    cloneActionOption() {
      if (this[GetterTypeUser.GetAuthuserRole] === UserRole.Teacher) {
        return ['view'];
      }

      return [
        'view',
        'edit',
        'delete',
        'disabled'
      ];
    }
  },
  watch: {
    paginationSize(newValue) {
      if (newValue) {
        this.$store.commit(MutationTypeProduct.SetProductPagination, {
          size: newValue,
          page: 1
        });
        this.fetchData();
      }

      return;
    },
    'orderForm.value'(newValue: any) {
      if (newValue) {
        this.$store.commit(MutationTypeProduct.SetProductPagination, {
          page: 1,
          order: newValue,
          descending: this.orderForm.desc
        });
        this.fetchData();
      }

      return;
    },
    'orderForm.desc'(newValue: any) {
      this.$store.commit(MutationTypeProduct.SetProductPagination, {
        page: 1,
        order: this.orderForm.value,
        descending: newValue
      });
      this.fetchData();
    }
  }
})

export class ProductList extends Vue {
  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Redemption',
      path: '/redemption'
    },
    {
      label: 'Product List',
      path: '',
      current: true
    }
  ];
  public paramsFilter: IProductParamsFilter = {
    text: ''
  };
  public pagination: IPagingParams;
  public data: IProduct[];
  public paginationSize: number = 20;
  public orderForm = {
    value: null,
    desc: false
  };
  public cmaFormatDateFullTime = cmaFormatDateFullTime;
  public get columns() {
    return [
      {
        prop: 'title',
        label: 'Product',
        avatar: {
          formatter: (model: IProduct) => {
            return model ? {
              avatar: model.images.find((e) => e.isPrimary).src
            } : null;
          }
        }
      },
      {
        prop: 'point',
        label: 'Point',
        width: 200
      },
      {
        prop: 'currency',
        label: 'SGD(*)',
        width: 200
      },
      {
        prop: 'status',
        label: 'status',
        formatter: (model: any) => model.status ? this.$t(model.status) : '',
        labelStyle: (model: any) => {
          return model.status;
        },
        width: 200
      }];
  }

  public get productSort() {
    return [
      {
        label: this.$t('none'),
        value: null
      },
      {
        label: this.$t('level'),
        value: 'level'
      }
    ];
  }

  public get formaterListCounter() {
    return formaterListCounter(this.pagination, this.data.length);
  }

  public applyFilter() {
    this.fetchData();
  }

  public clickDetail(id: string) {
    this.$router.push(`/redemption/${id}/detail`);
  }
  public changePagination() {
    this.$store.dispatch(ActionTypeProduct.ProductFilterNoCache);
  }

  public handleDelete(model: any) {
    this.$store.dispatch(ActionTypeProduct.ProductDelete, {
      id: model.id,
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();

        this.$message({
          type: 'info',
          message: this.$t('delete_successfully').toString()
        });
        this.fetchData();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public handleDisabled(model: any) {
    const modelTarget = find(cloneDeep(this.data), (e) => e.id.toString() === model.id);

    this.$store.dispatch(ActionTypeProduct.ProductUpdate, {
      id: model.id,
      form: {
        status: modelTarget.status === StatusCode.Active ? StatusCode.Suspended : StatusCode.Active
      },
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();

        this.$message({
          type: modelTarget.status === StatusCode.Active ? 'info' : 'success',
          message: this.$t(
            modelTarget.status === StatusCode.Active ? 'disabled_successfully' :  'active_successfully').toString()
        });
        this.fetchData();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }
  public clickUpdate(id: number) {
    this.$router.push(`/redemption/${id}/edit`);
  }

  protected mounted() {
    this.$nextTick(() => {
      const data = cloneDeep(this.$data);
      this.$store.dispatch(ActionTypeExchangeRate.ExchangeRateGetData, {
        params: {
          page: 1
        }
      });

      if (data && data.length > 0) {
        this.$store.dispatch(ActionTypeProduct.ProductFilterNoCacheNoLoading);
      } else {
        this.$store.dispatch(ActionTypeProduct.ProductFilterNoCache);
      }
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeProduct.ProductFilterChange, {});
  }

  private fetchData() {
    const filter = cloneDeep(this.paramsFilter);
    const formConverted: any = {
      params: {
        filterParams: stripObject(filter)
      }
    };

    this.$store.dispatch(ActionTypeProduct.ProductFilterNoCache, formConverted);
  }
}
