import { IRedemptionStatistic, IStatisticsUser, IStatisticCoolest,
  IStatisticFilter, ISubscriptionStatistic } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IDashboardHttpService
  extends ICRUDHttpService<any> {
  filterDashboardMostActiveUser(paramFilter: IStatisticFilter):
    Promise<IStatisticsUser[]>;
  filterDashboardNewUser(paramFilter: IStatisticFilter):
    Promise<IStatisticsUser[]>;
  filterDashboardCoolest(paramFilter: IStatisticFilter): Promise<IStatisticCoolest[]>;
  filterDashboardSubscription(paramFilter: IStatisticFilter): Promise<ISubscriptionStatistic[]>;
  filterDashboardAssignment(paramFilter: IStatisticFilter): Promise<IStatisticsUser[]>;
  filterDashboardRedemption(paramFilter: IStatisticFilter): Promise<IRedemptionStatistic[]>;
}

export function DashboardHttpService(options: IHttpServiceClientOptions): IDashboardHttpService {

  const basePath = '/admin/dashboards';
  function filterDashboardMostActiveUser(paramFilter: IStatisticFilter):
    Promise<IStatisticsUser[]> {
    const userPath = options.httpService.getUrl(`${basePath}/daily-active-users`);

    return options.httpService.post(userPath, {
      ...paramFilter
    });
  }

  function filterDashboardNewUser(paramFilter: IStatisticFilter):
    Promise<IStatisticsUser[]> {
    const userPath = options.httpService.getUrl(`${basePath}/new-users`);

    return options.httpService.post(userPath, {
      ...paramFilter
    });
  }
  function filterDashboardAssignment(paramFilter: IStatisticFilter):
    Promise<IStatisticsUser[]> {
    const userPath = options.httpService.getUrl(`${basePath}/assignment`);

    return options.httpService.post(userPath, {
      ...paramFilter
    });
  }
  function filterDashboardRedemption(paramFilter: IStatisticFilter):
    Promise<IRedemptionStatistic[]> {
    const redemptionPath = options.httpService.getUrl(`${basePath}/redemption`);

    return options.httpService.post(redemptionPath, {
      ...paramFilter
    });
  }

  function filterDashboardCoolest(paramFilter: IStatisticFilter): Promise<IStatisticCoolest[]> {
    const coolestPath = options.httpService.getUrl(`${basePath}/coolest`);

    return options.httpService.post(coolestPath, {
      ...paramFilter
    });
  }
  function filterDashboardSubscription(paramFilter: IStatisticFilter): Promise<ISubscriptionStatistic[]> {
    const subscriptionPath = options.httpService.getUrl(`${basePath}/subscriptions`);

    return options.httpService.post(subscriptionPath, {
      ...paramFilter
    });
  }

  return {
    ...CRUDHttpService<any>({
      ...options,
      basePath
    }),
    filterDashboardMostActiveUser,
    filterDashboardNewUser,
    filterDashboardCoolest,
    filterDashboardSubscription,
    filterDashboardAssignment,
    filterDashboardRedemption
  };
}
