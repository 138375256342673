import { stripObject } from '@hgiasac/helper';
import { IOptionsHTTP } from 'api/http/Core';
import { ActionContext } from 'vuex';
import { ActionType, IState, PaginationState } from '../types';
// import { IPaginationState } from '../types';
import { ICRUDFilterState, ICRUDState, IOptionCRUD } from './mutations';

export interface ICRUDActionOptions<T, F> {
  filter?(params: F, pagingParams: any, optionsHTTP?: IOptionsHTTP): Promise<any>;
  list?(params?: any, optionsHTTP?: IOptionsHTTP): Promise<T[]>;
  getById?(id: number | string, optionsHTTP?: IOptionsHTTP): Promise<T>;
  create?(form: any, optionsHTTP?: IOptionsHTTP): Promise<T>;
  update?(id: number | string, form: any, optionsHTTP?: IOptionsHTTP): Promise<T>;
  deleteById?(id: number | string, optionsHTTP?: IOptionsHTTP): Promise<number>;
}

export interface IOptionFunctionFilterCRUD {
  disableLoading?: boolean;
}

export function crudActions<T extends object, F = any>(
  name: string, options: ICRUDActionOptions<T, F>, optionCRUD?: IOptionCRUD) {

  let keyState;
  if (optionCRUD && optionCRUD.keyState) {
    keyState = optionCRUD.keyState;
  }
  function getDataState(state: ICRUDState<T>, key: string) {
    if (keyState) {
      return state[keyState][key];
    }

    return state[key];
  }

  function filter(
    { commit, dispatch, state, params },
    opts: IOptionFunctionFilterCRUD = {},
    optionsHTTP?: IOptionsHTTP
  ) {
    if (!opts.disableLoading) {
      commit(`${name}Loading`);
    }

    if (params.filterParams) {
      commit(`${name}FilterChange`, params.filterParams);
    } else {
      params.filterParams = getDataState(state, 'filterParams');
    }

    const pagination = getDataState(state, 'pagination');
    options.filter(params.filterParams, {
      ...pagination,
      page: pagination.page,
      size: pagination.size
    }, optionsHTTP).then((results) => {
      if (!opts.disableLoading) {
        commit(`${name}Loaded`);
      }
      const data = results.data,
        filterResults: ICRUDFilterState<T> = results;

      if (params.onSuccess) {
        params.onSuccess(data);
      }
      commit(`${name}UpdateModels`, data);
      commit(`${name}FilterModels`, filterResults);
    }).catch((e) => {
      if (!opts.disableLoading) {
        commit(`${name}Loaded`);
      }
      dispatch(ActionType.CatchException, e);
    });
  }

  return stripObject({
    [`${name}Filter`]: !options.filter ? undefined : (
      { commit, dispatch, state }: ActionContext<ICRUDState<T>, IState>,
      { params = null, optionsHTTP = null } = {}
    ) => {
      if (getDataState(state, 'data').length && !params) { return; }
      params = { ...params };

      return filter({ commit, dispatch, state, params }, {}, optionsHTTP);
    },
    // [`${name}FilterHard`]: !options.filter ? undefined : (
    //   { commit, dispatch, state }: ActionContext<ICRUDState<T>, IState>,
    //   params
    // ) => {
    //   params = { ...params };

    //   return filter({ commit, dispatch, state, params });
    // },
    [`${name}FilterNoCache`]: !options.filter ? undefined : (
      { commit, dispatch, state }: ActionContext<ICRUDState<T>, IState>,
      { params = {}, optionsHTTP = null } = {}
    ) => {
      params = { ...params };

      return filter({ commit, dispatch, state, params }, {}, optionsHTTP);
    },
    [`${name}FilterNoCacheNoLoading`]: !options.filter ? undefined : (
      { commit, dispatch, state }: ActionContext<ICRUDState<T>, IState>,
      { params = {}, optionsHTTP = null } = {}
    ) => {
      params = { ...params };

      return filter({ commit, dispatch, state, params }, {
        disableLoading: true
      }, optionsHTTP);
    },
    [`${name}FetchMany`]: !options.list ? undefined : (
      { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
      { params = {}, optionsHTTP = null } = {}
    ) => {
      commit(`${name}Loading`);
      options.list(params, optionsHTTP).then((results: any) => {
        commit(`${name}Loaded`);
        commit(`${name}RefreshModels`, results.data);
      }).catch((e) => {
        commit(`${name}Loaded`);
        dispatch(ActionType.CatchException, e);
      });
    },
    // [`${name}FetchAll`]: !options.list ? undefined : (
    //   { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
    //   params
    // ) => {
    //   commit(`${name}Loading`);
    //   options.list(params).then((results) => {
    //     commit(`${name}Loaded`);
    //     commit(`${name}RefreshModels`, results);
    //   }).catch((e) => {
    //     commit(`${name}Loaded`);
    //     dispatch(ActionType.CatchException, e);
    //   });
    // },
    [`${name}FindById`]: !options.getById ? undefined : (
      { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
      { id, optionsHTTP }
    ) => {
      commit(`${name}Loading`);

      return options.getById(id, optionsHTTP)
        .then((model) => {
          commit(`${name}Loaded`);
          commit(`${name}UpdateModels`, [model]);

        }).catch((e) => {
          commit(`${name}Loaded`);
          dispatch(ActionType.CatchException, e);
        });
    },
    [`${name}Update`]: !options.update ? undefined : (
      { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
      { id, form, router, redirectPath, onSuccess, onFailure, optionsHTTP },
    ) => {
      commit(`${name}Loading`);

      return options.update(id, form, optionsHTTP)
        .then((result) => {
          commit(`${name}Loaded`);
          commit(`${name}UpdateModels`, [result]);

          if (onSuccess) {
            onSuccess(result);
          }

          if (router && redirectPath) {
            router.replace(redirectPath);
          }
        }).catch((e) => {
          commit(`${name}Loaded`);
          dispatch(ActionType.CatchException, e);
          if (onFailure) {
            onFailure(e);
          }
        });
    },
    [`${name}Create`]: !options.create ? undefined : (
      { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
      { form, router, redirectPath, onSuccess, onFailure, optionsHTTP }
    ) => {
      commit(`${name}Loading`);

      return options.create(form, optionsHTTP)
        .then((result) => {
          commit(`${name}Loaded`);
          commit(`${name}UpdateModels`, [result]);
          if (onSuccess) {
            onSuccess(result);
          }

          if (router && redirectPath) {
            router.replace(redirectPath);
          }
        }).catch((e) => {
          commit(`${name}Loaded`);
          dispatch(ActionType.CatchException, e);
          if (onFailure) {
            onFailure(e);
          }
        });
    },
    [`${name}Delete`]: !options.deleteById ? undefined : (
      { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
      { id, onSuccess, onFailure, optionsHTTP }
    ) => {
      // commit(`${name}Loading`);

      return options.deleteById(id, optionsHTTP)
        .then(() => {
          // commit(`${name}Loaded`);
          commit(`${name}RemoveModelByIDs`, [id]);
          if (onSuccess) {
            onSuccess();
          }
        }).catch((e) => {
          // commit(`${name}Loaded`);
          dispatch(ActionType.CatchException, e);
          if (onFailure) {
            onFailure(e);
          }
        });
    },
    [`${name}FilterChange`](
      { commit, dispatch, state }: ActionContext<ICRUDState<T>, IState>,
      { params = {}, optionsHTTP = null } = {}
    ) {
      commit(`${name}FilterChange`, params);
      const pagination = getDataState(state, 'pagination');
      commit(`${name}PaginationChange`, {
        ...pagination,
        page: pagination.page = 1,
      });
      dispatch(`${name}Filter`, { params, optionsHTTP });
    },
    [`${name}PaginationChange`](
      { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
      { pagination = PaginationState(), optionsHTTP = null } = {}
    ) {
      commit(`${name}PaginationChange`, pagination);
      const params = {};
      dispatch(`${name}Filter`, { params, optionsHTTP });
    },
  });
}
