import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    filterEnable: Boolean
  }
})

export class CMAButtonFilter extends Vue {
  public click() {
    this.$emit('click');
    this.$emit('update:filterEnable', false);
  }

  public get label() {
    return this.$props.filterEnable ? 'Apply' : 'Applied Filter';
  }
}
