import { cloneDeep, isArray, isBoolean, isEmpty, omit } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import { safeParseInt, stripObject } from '@hgiasac/helper';
import { Form, Select } from 'element-ui';
import * as moment from 'moment';
import { cmaHttpClient } from 'root/api/http';
import { CMAHeader, CMAListData, CMASelect } from 'root/components';
import { confirmAction, formatCurrency, ruleRequired } from 'root/helpers';
import { defaultAssignmentForm, AssignmentTarget, IAssignment,
  IAssignmentCase, IAssignmentForm, IAssignmentWarning,
  IBreadcrumb, ICase, ISimpleUser, Operator, QuestionStatus, UserRole } from 'root/models';
import { ActionType, GetterTypeGlobal, GetterTypeUser, IState } from 'root/store';
import { SearchFollowCase } from '../Components';
import { AssignmentTargetDialog } from '../Components/AssignmentTargetDialog';
import { ActionTypeAssignment, MutationTypeAssignment } from '../Store/types';
// import { IState } from 'root/store';
import './style.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      // pagination: (state: IState) => state.assignment.pagination,
      // data: (state: IState) => state.assignment.data,
      listCentre: (state: IState) => state.global.globalConfig.listCentre,
      assignmentSelected: (state: IState) => state.assignment.assignmentSelected,
      detail: (state: IState) => state.assignment.detail,
      authUser: (state: IState) => state.global.authUser,
      loading: (state: IState) => state.assignment.loading
    }),
    ...mapGetters(['gradeOptions', 'methodOptions', GetterTypeUser.PermissionViewAllCentre,
      GetterTypeUser.GetAuthuserRole]),
    cloneCentreOptions() {
      let listCentre = cloneDeep(this.listCentre);
      const authUser = cloneDeep(this.authUser);

      if (authUser.role === UserRole.CentreManager) {
        listCentre = listCentre.filter((e) => authUser.centreManager.indexOf(e.value) > -1);
      }

      return listCentre;

    },
  },
  components: {
    'cma-header': CMAHeader,
    'cma-select': CMASelect,
    'cma-list-data': CMAListData,
    'search-follow-case': SearchFollowCase,
    'assignment-target-dialog': AssignmentTargetDialog
  },
  watch: {
    'warning.index'(newValue) {
      this.$refs.searchFollowCase.setErrorTotalQuestionIndex(newValue);
    }
  }
})

export class AssignmentCreate extends Vue {
  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        path: '/'
      },
      {
        label: 'Assignment',
        path: '/assignments'
      },
      {
        label: this.isUpdating ? this.$t('edit').toString() : this.$t('add.new').toString(),
        path: '',
        current: true
      }
    ];
  }

  public get isViewOnly(): boolean {
    return this.isUpdating && this.detail.isStarted;
  }

  public get assignmentId() {
    return this.$route.params.assignmentId;
  }

  public get isUpdating(): boolean {
    return !!this.assignmentId;
  }

  public get getValueTarget() {
    if (this.form.centre) {
      return this.$store.getters[GetterTypeGlobal.GetCentreName](this.form.centre[0]);
    }

    return this.form.target;
  }
  public $refs: {
    form: Form,
    select: Select,
    searchFollowCase: SearchFollowCase
  };

  public data = [];
  public form: IAssignmentForm = defaultAssignmentForm();
  public assigneeTemp: ISimpleUser[] = []; // virtual value for assignee[] dialog, need to reset when open/close dialog
  public previousTarget: AssignmentTarget = null;
  public rules: any = {
    required: ruleRequired()
  };
  public formatCurrency = formatCurrency;

  public detail: IAssignment;
  public assignmentSelected: IAssignment;
  public cases: ICase[] = [];
  public isShowCase: boolean = false;
  public targetOptions = [
    {
      label: AssignmentTarget.Single,
      value: AssignmentTarget.Single
    },
    {
      label: AssignmentTarget.Group,
      value: AssignmentTarget.Group
    },
    {
      label: AssignmentTarget.Broadcast,
      value: AssignmentTarget.Broadcast
    },
    {
      label: AssignmentTarget.Centre,
      value: AssignmentTarget.Centre
    }
  ];
  public visibleTargetDialog: boolean = false;

  private warning: IAssignmentWarning = {
    isWarning: false,
    message: '',
    index: null
  };

  public setWarning(data: IAssignmentWarning) {
    this.warning = data;
    this.$refs.searchFollowCase.setErrorTotalQuestionIndex(data.index);
  }

  public showIconSearch(model: AssignmentTarget) {
    return model === AssignmentTarget.Group || model === AssignmentTarget.Single;
  }

  public generateQuestion(cases) {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        try {
          const casesConverted = cloneDeep(cases).map((e) => {
            if ([Operator.Plus, Operator.PlusAndSub].includes(e.operators)) {
              e.digitOne = null;
              e.digitTwo = null;
              if (e.digits === 'Mixed') {
                e.mixed = true;
                e.digits = null;
              }
              if (e.operators === Operator.PlusAndSub) {
                e.operators = ['+', '-'];
              }
            } else {
              e.digits = null;
              e.mixed = null;
            }

            return stripObject({
              method: this.form.method,
              grade: Number(this.form.grade),
              ...omit(e, ['caseId', 'maximumQuestion']),
              totalQuestion: safeParseInt(e.totalQuestion),
              operators: Array.isArray(e.operators) ? e.operators : [e.operators],
            });
          });

          const data = await this.fetchQuestions(casesConverted);

          this.setWarning({
            index: null,
            isWarning: false,
            message: ''
          });

          this.data = data.reduce((a: any, b: any, i) => a.concat(b.map((e) => ({
            ...e,
            case: `Case ${i + 1}`
          }))), []).map((e, i) => ({
            ...e,
            index: i
          }));
          this.$message.success('Generate question successfully!');
        } catch (error) {
          this.data = [];
          if (error.isWarning) {
            this.setWarning(error);
          }
        }
      }

      window.scrollTo(0, 0);

      return;
    });
  }

  public clickSave() {
    const questionIds = this.data.map((e) => e.id).filter((e) => e);
    const form = cloneDeep(this.form),
      { title, desc, assignees, centre, target } = form;

    const assigneeIds = assignees ? assignees.map((e) => e.id) : [];
    const convertedForm: any = {
      title,
      desc,
      questionIds,
      target,
      centre,
      assigneeIds: assigneeIds.length > 0 ? assigneeIds : null,
      endTime: moment(this.form.endTime).utc().format('YYYY-MM-DD HH:mm:ss'),
      startTime: moment(this.form.startTime).utc().format('YYYY-MM-DD HH:mm:ss')
    };

    const handler = {
      form: convertedForm,
      onSuccess: () => {
        this.$router.push('/assignments');
      },
      onFailure: (error) => {
        this.$store.dispatch(ActionType.CatchException, error);
      }
    };

    this.$refs.form.validate(async (valid) => {
      if (valid && !this.warning.isWarning) {
        if (questionIds.length === 0) {
          this.$store.dispatch(ActionType.CatchException, {
            message: `Generate questions before ${this.isUpdating ? 'update' : 'add new'} this assignment!`
          });

          return;
        }
        if (this.isUpdating) {
          this.$store.dispatch(ActionTypeAssignment.AssignmentUpdate, {
            ...handler,
            id: this.assignmentId
          });
        } else {
          this.$store.dispatch(ActionTypeAssignment.AssignmentCreate, handler);
        }
      }

      return;
    });

  }

  public showUserPicker(isNewValueTarget?: boolean) {
    if (!isBoolean(isNewValueTarget)) {
      this.assigneeTemp = this.form.assignees;
    }
    setTimeout(() => {
      this.visibleTargetDialog = true;
    }, 0);
  }
  public clickCancel() {
    this.$router.push('/assignments');
  }

  public handleChangeTarget(newValue: AssignmentTarget) {
    const previousTarget = cloneDeep(this.form).target;
    this.previousTarget = previousTarget;
    this.form.target = newValue;
    switch (newValue) {
    case AssignmentTarget.Broadcast:
      this.assigneeTemp = [];
      this.form.centre = null;
      break;
    case AssignmentTarget.Group:
    case AssignmentTarget.Single:
      this.form.centre = null;
      this.assigneeTemp = [];
      this.showUserPicker(true);
      break;
    case AssignmentTarget.Centre:
      this.form.assignees = [];
      this.assigneeTemp = [];
      this.form.centre = null;
      break;
    default:
      break;
    }
  }

  public cancelChangeUserSelected() {
    this.visibleTargetDialog = false;
    this.form.target = this.previousTarget;
  }

  public changeUserSeleted(users: ISimpleUser[]) {
    this.form.assignees = users;
    this.assigneeTemp = users;
    if (users.length === 0) {
      this.form.target = AssignmentTarget.Broadcast;
    }
    this.previousTarget = this.form.target;
  }

  public changeCentre(model: string) {
    this.form.centre = [model];
    this.form.target = AssignmentTarget.Centre;
    this.form.assignees = [];
    this.assigneeTemp = [];
    this.$refs.select.blur();
  }

  public mounted() {
    this.$nextTick(async () => {
      const id = this.assignmentId || (this.assignmentSelected ? this.assignmentSelected.id : null);

      if (id) {
        let _detail = null;
        try {
          if (this.isUpdating) {
            await this.$store.dispatch(ActionTypeAssignment.GetAssignmentDetail, {id: this.assignmentId});
            _detail = cloneDeep(this.detail);
            if (this.$store.getters[GetterTypeUser.GetAuthuserRole] === UserRole.Teacher
              && this.detail.target === AssignmentTarget.Broadcast) {
              this.$message.error(this.$t('permission_denied').toString());
              this.$router.replace(`/assignments/${this.assignmentId}`);

              return;
            }
          } else {
            await this.$store.dispatch(ActionTypeAssignment.AssignmentTemplateGet, id);
            _detail = cloneDeep(this.assignmentSelected);
          }
          const cases = <any> _detail.cases;
          await this.checkCaseStatus(cases);

          if (_detail.isStarted && this.isUpdating) {
            this.$message(`Assignment is view only because it's push status is SENT!`);
            this.$router.replace(`/assignments/${_detail.id}`);

            return;
          }
          this.setData(_detail);
          this.isShowCase = true;
        } catch (error) {
          console.log(error);
        }
      } else {
        this.isShowCase = true;
      }

      return;

    });

  }

  public confirmChangeGradeMethod(value, field) {
    if (this.cases.length >= 0 && this.form.grade && this.form.method) {
      confirmAction(this, {
        title: 'Confirm your change',
        message: 'Changes you made may not be saved.',
        handleFunction: () => {
          this.form[field] = value;
          this.cases = [];
          this.data = [];
          this.isShowCase = false;
          setTimeout(() => {
            this.isShowCase = true;
          }, 0);
        }
      });

      return;
    }
    this.form[field] = value;
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeAssignment.ResetAssignmentDetail);
    this.$store.commit(MutationTypeAssignment.AssignmentTemplateResetAssignmentSelected);
  }

  private checkCaseStatus(cases: IAssignmentCase[]): Promise<boolean | IAssignmentWarning> {
    return new Promise(async (resolve, reject) => {
      if (!isArray(cases) || isEmpty(cases)) {
        resolve(true);
      }

      await Promise.all(cases.map((e, index) => {
        if (e.status === QuestionStatus.Deleted) {
          reject({
            index,
            isWarning: true,
            message: 'Any case was deleted, please add other case to generate questions'
          });
        }
        e = omit(e, 'status');
        const questions = cloneDeep(e.questions).filter((question) => {
          return question.status === QuestionStatus.Deleted || question.status === QuestionStatus.PendingDelete;
        });

        if (questions.length === e.questions.length) {
          reject({
            index,
            isWarning: true,
            message: 'Any questions was deleted, please generate questions again before update!'
          });
        }

        return;

      }));

      resolve(true);

    });
  }

  private fetchQuestions(cases: any[]): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      const data = await Promise.all(cases.map(async (e, i: number) => {
        if (e.status === QuestionStatus.Deleted) {
          return [];
        }
        e = omit(e, 'status');
        e.mixedDecimal = !!(e.decimal && e.decimal === 'Mixed');
        e.decimal = e.decimal && e.decimal === 'Mixed' ? 0 : e.decimal;
        const dataEach = await cmaHttpClient.assignment.filterQuestion(e);
        if (dataEach.length !== e.totalQuestion) {
          reject({
            isWarning: true,
            message: 'Questions bank is not enough to generate assignment',
            index: i
          });
        }

        return dataEach;
      }));

      resolve(data);
    });
  }

  private setData(data: any) {
    this.cases = data.cases;
    this.form = {
      grade: data.library.grade,
      method: data.library.method,
      startTime: data.startTime,
      endTime: data.endTime,
      title: data.title,
      desc: data.desc,
      target: data.target,
      assigneeIds: data.assigneeIds,
      assignees: data.assignees,
      centre: data.centre
    };
  }
}
