import * as js2excel from 'js2excel';
import { cloneDeep, omit } from 'lodash';
import moment from 'moment-timezone';
import { CMAButtonFilter, CMAHeader, CMAListData } from 'root/components';
import { formaterPhone, fullNameUser, parseTimeLocal } from 'root/helpers';
import { IBreadcrumb, ISimpleUser, IUser } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeReport, MutationTypeReport } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-list-data': CMAListData,
    'cma-btn-filter': CMAButtonFilter,
    'cma-header': CMAHeader
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.report.leaderBoard,
      loading: (state: IState) => state.report.leaderBoardLoading
    }),
    cloneDataTable() {
      const data = this.data;
      if (!data) {
        return [];
      }

      return Array.isArray(data) ? data.map((e) => {
        return {
          ...e.data,
          date: e.date
        };
      }) : [];
    },
    startDatePicker: {
      get() {
        return this.paramsFilter.startDate ? moment(this.paramsFilter.startDate).utc(true).tz(moment.tz.guess()) : null;
      },
      set(value) {
        this.paramsFilter.startDate = value;
      }
    },
    endDatePicker: {
      get() {
        return this.paramsFilter.endDate ? moment(this.paramsFilter.endDate).utc(true).tz(moment.tz.guess()) : null;
      },
      set(value) {
        this.paramsFilter.endDate = value;
      }
    }
  }
})

export class LeaderBoard extends Vue {
  public paramsFilter = {
    startDate: null,
    endDate: null
  };
  public filterEnable: boolean = false;
  public cloneDataTable: any[];

  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        path: '/'
      },
      {
        label: 'Report',
        path: '',
      },
      {
        label: this.$route.meta.label,
        path: '',
        current: true
      }
    ];
  }

  public exportToExcel() {
    const startDate = moment(this.paramsFilter.startDate).utc(true).tz(moment.tz.guess()).format('DD-MM-YYYY'),
      endDate = moment(this.paramsFilter.endDate).utc(true).tz(moment.tz.guess()).format('DD-MM-YYYY');
    try {
      js2excel.default.json2excel({
        data: cloneDeep(this.cloneDataTable).map((e) => {
          let _e: any = Object.keys(e).reduce((c, k) =>
          // tslint:disable-next-line:ban-comma-operator
            (c[k === 'pointDaily' ? `TODAY'S POINT` : k.toUpperCase()] = e[k], c), {});
          _e = omit(_e, ['AVATAR', 'GRADE', 'AGE', 'POINTDAILY']);

          return {
            ..._e,
            ID: _e.ID ? `ID ${_e.ID}` : '',
            DATE: parseTimeLocal(_e.DATE.startHours),
            BIRTHDAY: parseTimeLocal(_e.BIRTHDAY),
          };
        }),
        // tslint:disable-next-line:max-line-length
        name: `coolest_${startDate}_${endDate}`
      });
    } catch (error) {
      console.error('export error', error);
    }
  }

  public get columns() {
    return [
      {
        prop: 'date',
        label: 'date',
        formatter: (model: any) => parseTimeLocal(model.date.startHours),
        width: 100
      },
      {
        label: 'Name',
        formatter: (model) => model ? fullNameUser(model) : '__',
        avatar: {
          formatter: (model: any) => {
            return model ? model : null;
          }
        },
        width: 160
      },
      {
        prop: 'centre',
        label: 'centre',
        width: 170,
        formatter: (model) => model.centre || '__'
      },
      {
        prop: 'gender',
        label: 'gender',
        width: 170,
        formatter: (model) => model.gender || '__'
      },
      {
        prop: 'id',
        label: 'id',
        width: 100,
        formatter: (model: ISimpleUser) => {
          return model.id ? `ID ${model.id}` : '__';
        },
      },
      {
        prop: 'email',
        label: 'email',
        width: 200,
        formatter: (model) => model.email || '__',
        labelStyle: () => 'lower-case'
      }, {
        prop: 'phone',
        label: 'phone',
        formatter: (model: IUser) => model.phone && model.phone.number ? formaterPhone(model.phone) : '__',
        width: 150
      }, {
        prop: 'address',
        label: 'address',
        width: 320,
        formatter: (model) => model.address || '__'
      }, {
        prop: 'level',
        label: 'level',
        formatter: (model) => model.level || '__'
      }, {
        prop: 'point',
        label: 'Total Points',
        labelStyle: () => 'link',
        formatter: (model) => model.point || '__'
      }, {
        prop: 'pointDaily',
        label: `today's points`,
        labelStyle: () => 'link',
        formatter: (model) => model.pointDaily || '__'
      }];
  }

  // public formaterPhone(model: IPhoneInput) {
  //   if (!model) {
  //     return '';
  //   }

  //   return `${model.code ? model.code : ''} ${model.number ? model.number : '__'}`;
  // }

  public applyFilter() {
    this.fetchData();
  }

  protected mounted() {
    this.$nextTick(() => {
      this.fetchData();
    });
  }

  private fetchData() {
    const paramsFilter = cloneDeep(this.paramsFilter);
    this.filterEnable = false;
    if (paramsFilter.endDate && paramsFilter.startDate) {
      this.$store.dispatch(ActionTypeReport.ReportLeaderBoardFilter, paramsFilter);
    } else {
      this.$store.commit(MutationTypeReport.ReportLeaderBoardSetData, []);
    }
  }
}
