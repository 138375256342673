import { stripObject } from '@hgiasac/helper';
import { cloneDeep } from 'lodash';
import { CMAButtonFilter, CMAHeader, CMAListData, CMASelect } from 'root/components';
import { formaterListCounter } from 'root/helpers';
import { IAssignment, IBreadcrumb } from 'root/models';
import { IState } from 'root/store';
import { IPaginationResult } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypeAssignment, MutationTypeAssignment } from '../Store/types';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'cma-select': CMASelect,
    'cma-list-data': CMAListData,
    'cma-btn-filter': CMAButtonFilter
  },
  computed: {
    ...mapGetters(['actionOption', 'gradeOptions', 'sortType',
      'methodOptions', 'paginationOption']),
    ...mapState({
      pagination: (state: IState) => state.assignment.template.pagination,
      data: (state: IState) => state.assignment.template.data,
      loading: (state: IState) => state.assignment.template.loading
    }),
    cloneMethodOptions() {
      const methodOptions = cloneDeep(this.methodOptions);

      return [
        {
          label: this.$t('all.method'),
          value: null
        },
        ...methodOptions
      ];
    },
    cloneGradeOptions() {
      const gradeOptions = cloneDeep(this.gradeOptions);

      return [
        {
          label: this.$t('all.grade'),
          value: null
        },
        ...gradeOptions
      ];
    },
    cloneActionOption() {
      const actionOption: any[] = cloneDeep(this.actionOption);

      actionOption.splice(2, 1, 'delete');

      return actionOption;
    }
  },
  watch: {
    paginationSize(newValue) {
      if (newValue) {
        this.$store.commit(MutationTypeAssignment.AssignmentTemplateSetPagination, {
          size: newValue,
          page: 1
        });
        this.fetchData();
      }

      return;
    },
    'paramsFilter.descending'() {
      this.fetchData();
    },
    'paramsFilter.order'() {
      this.fetchData();
    },
  }
})

export class AssignmentTemplateList extends Vue {
  public cloneGradeOptions: any;
  public cloneMethodOptions: any;
  public data: any[];
  public pagination: any;
  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Assignment',
      path: '/assignments'
    },
    {
      label: 'Assignment Template',
      path: '',
      current: true
    }
  ];

  public paginationSize: number = 20;
  public filterEnable: boolean = false;
  public paramsFilter: any = {
    grade: null,
    method: null,
    text: null,
    order: null,
    descending: false
  };
  public get filterOptions() {
    return {
      grade: this.cloneGradeOptions,
      method: this.cloneMethodOptions,
    };
  }

  public get templateSort() {
    return [
      {
        label: this.$t('none'),
        value: null
      },
      {
        label: this.$t('grade'),
        value: 'grade'
      },
      {
        label: this.$t('method'),
        value: 'method'
      }
    ];
  }

  public get columns() {
    return [
      {
        prop: 'title',
        label: 'Title'
      },
      {
        prop: 'desc',
        label: 'description'
      },
      {
        prop: 'grade',
        label: 'Grade',
        formatter: (model: IAssignment) => {
          return model.library ? model.library.grade === 0 ? 'DAN' : model.library.grade : null;
        },
        width: 72
      },
      {
        prop: 'method',
        label: 'Method',
        formatter: (model: IAssignment) => {
          return model.library ? this.$t(model.library.method) : null;
        },
        width: 82
      },
      {
        prop: 'totalCase',
        label: 'Total Cases',
        width: 92
      },
      {
        prop: 'totalQuestion',
        label: 'Total Question',
        width: 92
      }];
  }

  public get formaterListCounter() {
    return formaterListCounter(this.pagination, this.data.length);
  }

  public applyFilter() {
    // this.$store.commit(MutationTypeAssignment.AssignmentPaginationReset);
    this.filterEnable = false;
    this.fetchData();
  }

  public changePagination(pagination) {
    this.fetchData(pagination);
  }

  public handleDelete(model) {
    this.$store.dispatch(ActionTypeAssignment.AssignmentTemplateDelete, {
      id: model.id,
      onSuccess: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
        model.done();

        this.$message({
          type: 'info',
          message: this.$t('delete_successfully').toString()
        });
        this.fetchData();
      },
      onFailure: () => {
        model.instance.confirmButtonLoading = false;
        model.instance.confirmButtonText = 'Confirm';
      }
    });
  }

  public handleUpdate(id: number) {
    this.$router.push(`/assignments/template/${id}/edit`);
  }
  public clickDetail(id: number) {
    this.$router.push(`/assignments/template/${id}/detail`);
  }

  public changeFilter(v, name) {
    this.filterEnable = true;
    this.paramsFilter[name] = v;
  }

  protected mounted() {
    this.$nextTick(() => {
      this.fetchData();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationTypeAssignment.AssignmentTemplateSetPagination, {
      page: 1,
      size: 20
    });
  }

  private fetchData(pagination?: IPaginationResult) {
    const filter = cloneDeep(this.paramsFilter);
    const page = pagination ? pagination : this.pagination;
    const formConverted: any = {
      params: {
        ...stripObject(filter),
        ...page
      }
    };
    this.$store.dispatch(ActionTypeAssignment.AssignmentTemplateFilter, formConverted);
  }
}
