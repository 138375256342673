import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { getFirstDay, getLastDay, parseTimeLocal } from 'root/helpers';
import { IStatisticsUser, IStatisticFilter, StatisticType } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import { Bar } from 'vue-chartjs';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeDashboard, MutationTypeDashboard } from '../../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'bar-chart': Bar
  },
  computed: {
    ...mapState({
      assignment: (state: IState) => state.dashboard.assignment,
      paramsFilter: (state: IState) => state.dashboard.assignmentFilter
    }),
    convertedData() {
      const assignment: IStatisticsUser[] = cloneDeep(this.assignment);
      const paramsFilter: IStatisticFilter = cloneDeep(this.paramsFilter);
      const labels: string[] = [];
      const data: number[] = [];

      assignment.map((e) => {
        let text = '';
        switch (paramsFilter.type) {
        case StatisticType.Day:
          // tslint:disable-next-line:max-line-length
          text = `${moment(e.period.startHours).utc(true).tz(moment.tz.guess()).format('H')}h-${moment(e.period.endHours).utc(true).tz(moment.tz.guess()).format('H')}h`;
          break;
        case StatisticType.Week:
        case StatisticType.Month:
          text = `${moment(e.period.startHours).utc(true).tz(moment.tz.guess()).format('DD MMM')}`;
          break;
        default:
          break;
        }
        labels.push(text);
        data.push(e.data);
      });

      return {
        labels,
        datasets: [{
          data,
          label: 'Assignment Staus: Sent',
          fill: false,
          backgroundColor: '#2ea2f8',
          borderColor: '#2ea2f8'
        }]
      };
    },
    timePicker: {
      get() {
        return moment(this.paramsFilter.startDate).utc(true).tz(moment.tz.guess());
      },
      set(value) {
        this.paramsFilter.startDate = value;
      }
    }
  }
})

export class DashboardAssignmentActivities extends Vue {
  public $refs: {
    barChart: Bar
  };

  public loading: boolean = false;
  public paramsFilter: IStatisticFilter;
  public assignment: any;
  private convertedData: any;

  private options: any = {
    responsive: true, maintainAspectRatio: false,
    drawOnChartArea: false,
    legend: {
      position: 'bottom',
    },
    scales: {
      xAxes: [{
        barPercentage: 0.4,
        gridLines: {
          drawOnChartArea: false
        }
      }],
      yAxes: [{
        gridLines: {
          drawOnChartArea: false
        }
      }]
    },
    elements: {
      line: {
        tension: 0.000001
      }
    },
  };

  public get getFormat(): string {
    if (this.paramsFilter.type === StatisticType.Month) {
      return 'MMM, yyyy';
    }
    if (this.paramsFilter.type === StatisticType.Week) {
      return 'Week WW yyyy';
    }

    return 'dd/MM/yyyy';
  }
  public get getTimePickerType(): string {
    if (this.paramsFilter.type === StatisticType.Month || this.paramsFilter.type === StatisticType.Week) {
      return this.paramsFilter.type;
    }

    return 'date';
  }

  public get formatterTitle(): string {
    switch (this.paramsFilter.type) {
    case StatisticType.Day:
      return parseTimeLocal(this.paramsFilter.startDate).toString();
    case StatisticType.Week:
    case StatisticType.Month:
      if (!this.assignment || this.assignment.length <= 0) {
        return '';
      }
      const dataLength = this.assignment.length;

      return `${parseTimeLocal(this.assignment[0].period.startHours)}
        -${parseTimeLocal(this.assignment[dataLength - 1].period.startHours)}`;
    default:
      break;
    }

    return '';
  }

  public async change(value: any, key: string) {
    const paramsFilter = this.paramsFilter;
    if (key === 'type') {
      this.paramsFilter.startDate = getFirstDay(value);
      this.paramsFilter.maxDate = value !== StatisticType.Day ? getLastDay(value, this.paramsFilter.startDate) : null;
    } else if (key === 'startDate') {
      if (this.paramsFilter.type === StatisticType.Week) {
        this.paramsFilter.startDate = getFirstDay('week', value);
      }
      this.paramsFilter.maxDate = paramsFilter.type === StatisticType.Day ? null
        : getLastDay(paramsFilter.type, this.paramsFilter.startDate);
    }
    this.renderChart();
  }

  protected mounted() {
    this.$nextTick(async () => {
      this.$store.commit(MutationTypeDashboard.DashboardClearAssignment);
      this.renderChart();
    });
  }
  protected beforeDestroy() {
    this.$store.commit(MutationTypeDashboard.DashboardClearAssignment);
  }

  private async renderChart() {
    const paramsFilter = cloneDeep(this.paramsFilter);
    if (paramsFilter.startDate && paramsFilter.type) {
      await this.$store.dispatch(ActionTypeDashboard.DashboardFilterAssignment);
      this.$refs.barChart.renderChart(this.convertedData, this.options);
    }
  }
}
