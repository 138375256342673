import Vue from 'vue';
import Component from 'vue-class-component';
import { DashboardAssignmentActivities } from '../Components/DashboardAssignmentActivities';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'dashboard-assignment-activities': DashboardAssignmentActivities
  }
})

export class DashboardAssignment extends Vue {
  //
}
