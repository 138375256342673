// import { CMAHeader } from 'root/components';
// import { IBreadcrumb } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  template: require('./view.html'),
  // components: {
  // },
})

export class ReportContainer extends Vue {}
