// register the plugin
import { isNil } from 'lodash';
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

// import page
import { IUser, UserRole } from 'root/models';
import { AssignmentRouter } from 'root/pages/Assignment';
import { DashboardRouter } from 'root/pages/Dashboard';
import { ExaminationRouter } from 'root/pages/Examination';
import { ExchangeRateRouter } from 'root/pages/ExchangeRate';
import { LibraryRouter } from 'root/pages/Library';
import { LoginContainer } from 'root/pages/Login';
import { NotificationRouter } from 'root/pages/Notification';
import { ProductRouter } from 'root/pages/Product';
import { ReportRouter } from 'root/pages/Reports';
import { SubscriptionRouter } from 'root/pages/Subscription';
import { userRouter, userRouterPath } from 'root/pages/Users';
import store from 'root/store';
import { RouteDictionary } from './types';

// export * from "./link";
export * from './types';

// register the plugin
Vue.use(VueRouter);

function getUserState(): Promise<IUser> {
  return new Promise((resolve) => {
    if (isNil(store.state.global.authUser)) {
      const unwatch = store.watch(
        () => store.state.global.authUser,
        (value) => {
          unwatch();
          resolve(value);
        }
      );
    } else {
      resolve(store.state.global.authUser);
    }

    return;
  });
}
async function redirectSuperAdmin(_, __, next) {
  const user = await getUserState();
  const role = user.role;

  if (role === UserRole.SuperAdmin) {
    next(userRouterPath.user);
  } else {
    next();
  }
}

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '',
      redirect: '/users'
    },
    {
      path: RouteDictionary.Login,
      component: LoginContainer,
      meta: {
        isGuest: true
      }
    },
    {
      ...AssignmentRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...LibraryRouter,
      beforeEnter: redirectSuperAdmin
    },
    userRouter,
    {
      ...ProductRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...NotificationRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...ExchangeRateRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...SubscriptionRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...DashboardRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...ReportRouter,
      beforeEnter: redirectSuperAdmin
    },
    {
      ...ExaminationRouter,
      beforeEnter: redirectSuperAdmin
    }
  ]});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
