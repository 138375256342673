import { stripObject } from '@hgiasac/helper';
import { cloneDeep, find } from 'lodash';
import { CMAButtonFilter, CMAHeader, CMAListData, CMASelect } from 'root/components';
import { cmaFormatDateFullTime, formaterListCounter } from 'root/helpers';
import { IBreadcrumb, IPagingParams, IProductParamsFilter, IRedemption, RedemptionStatus, UserRole } from 'root/models';
import { GetterTypeUser, IState } from 'root/store';
import { IPaginationResult } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ProductDetailView } from '../Components/ProductDetailView';
import { ActionTypeProduct, GetterTypeProduct, MutationTypeProduct } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'cma-select': CMASelect,
    'cma-list-data': CMAListData,
    'product-detail-view': ProductDetailView,
    'cma-btn-filter': CMAButtonFilter
  },
  computed: {
    ...mapGetters(['RedemptionStatusOptions',
      'paginationOption', 'sortType', GetterTypeUser.GetAuthuserRole]),
    ...mapState({
      data: (state: IState) => state.product.transaction.data,
      pagination: (state: IState) => state.product.transaction.pagination,
      loading: (state: IState) => state.product.transaction.loading
    }),
    cloneRedemptionStatusOptions() {
      const RedemptionStatusOptions = cloneDeep(this.RedemptionStatusOptions);

      RedemptionStatusOptions.splice(0, 1);
      RedemptionStatusOptions.splice(RedemptionStatusOptions.length - 1, 1);

      return RedemptionStatusOptions;
    }
  },
  watch: {
    paginationSize(newValue) {
      if (newValue) {
        this.$store.commit(MutationTypeProduct.SetProductTransactionPagination, {
          size: newValue,
          page: 1
        });
        this.fetchData();
      }

      return;
    },
    'orderForm.value'(newValue: any) {
      this.$store.commit(MutationTypeProduct.SetProductTransactionPagination, {
        page: 1,
        order: newValue,
        descending: this.orderForm.desc
      });
      this.fetchData();
    },
    'orderForm.desc'(newValue: any) {
      this.$store.commit(MutationTypeProduct.SetProductTransactionPagination, {
        page: 1,
        order: this.orderForm.value,
        descending: newValue
      });
      this.fetchData();
    }
  }
})

export class TransactionList extends Vue {
  public RedemptionStatusOptions: any[];
  public pagination: IPagingParams;
  public data: IRedemption[];
  public paginationSize: number = 20;
  public redemptionId: number = null;
  public visibleRedemption: boolean = false;
  public redemptionDetail: IRedemption = null;
  public filterEnable: boolean = false;

  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Redemption',
      path: '/redemption'
    },
    {
      label: 'Transaction List',
      path: '',
      current: true
    }
  ];

  public paramsFilter: IProductParamsFilter = {
    text: '',
    progress: null
  };
  public orderForm = {
    value: null,
    desc: false
  };

  public get filterOptions() {
    return {
      progress: this.RedemptionStatusOptions,
    };
  }
  public get formaterListCounter() {
    return formaterListCounter(this.pagination, this.data.length);
  }

  public get redemptionSort() {
    return [
      {
        label: this.$t('none'),
        value: null
      },
      {
        label: 'Qty',
        value: 'qty'
      }
    ];
  }

  public get columns() {
    const c: any = [
      {
        prop: 'owner',
        label: 'Student',
        avatar: {
          formatter: (model: IRedemption) => {
            return model ? model.owner : null;
          }
        },
        formatter: (model: IRedemption) => {
          return model ? model.owner.fullName : null;
        }
      },
      {
        prop: 'centre',
        label: 'Centre',
        formatter: (model: IRedemption) => {
          return model ? model.owner.centre : null;
        },
        width: 150
      },
      {
        prop: 'product',
        label: 'Product',
        avatar: {
          formatter: (model: IRedemption) => {
            return model ? {
              avatar: model.product.images.find((e) => e.isPrimary).src
            } : null;
          }
        },
        formatter: (model: IRedemption) => model.product.title
      },
      {
        prop: 'qty',
        label: 'QTY',
        width: 80
      },
      {
        prop: 'createdAt',
        label: 'Redeem At',
        formatter: (model: IRedemption) => cmaFormatDateFullTime(model.createdAt),
        width: 200
      }, {
        prop: 'progress',
        label: 'status',
        labelStyle: (model: any) => {
          return `redemption__status outline-none ${model.progress}`;
        },
        width: 130
      }];

    if (this.$store.getters[GetterTypeUser.GetAuthuserRole] !== UserRole.Teacher) {
      c.push({prop: 'action'});
    }

    return c;
  }

  public formatterStatus(model: any, value: RedemptionStatus) {
    const redemption: IRedemption = model.scope.row,
      progress = redemption.progress;

    if (progress === RedemptionStatus.Ready) {
      return value !== RedemptionStatus.Completed;
    }

    if (progress === RedemptionStatus.Processing) {
      return value === RedemptionStatus.Completed;
    }

    return true;
  }
  public changeStatus(model: any, value: RedemptionStatus) {
    const h = this.$createElement;
    const redemption: IRedemption = model,
      id = redemption.id;

    if (redemption.progress !== value) {
      this.$msgbox({
        title: 'Change Status?',
        message: h('p', null, [
          h('span', null, 'Confirm change redemption status to '),
          h('span', { style: 'font-family: Nunito-Bold; text-transform: capitalize' }, value)
        ]),
        // message: `Confirm change redemption status to ${value}`,
        showCancelButton: true,
        confirmButtonText: this.$t('confirm').toString(),
        cancelButtonText: this.$t('cancel').toString(),
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = 'Loading...';
            this.$store.dispatch(ActionTypeProduct.TransactionListUpdate, {
              id,
              form: {
                progress: value
              },
              onSuccess: () => {
                instance.confirmButtonLoading = false;
                this.fetchData();
                done();
              },
              onFailure: () => {
                instance.confirmButtonText = this.$t('confirm').toString();
                instance.confirmButtonLoading = false;
              }
            });
          } else {
            done();
          }

          return;
        }
      }).then(() => {
        //
      }).catch(() => {
        // no handle
      });

    }

    return;
  }
  public applyFilter() {
    this.$store.commit(MutationTypeProduct.SetProductTransactionPagination, {
      order: this.orderForm.value,
      descending: this.orderForm.desc,
      page: 1
    });
    this.filterEnable = false;
    this.fetchData();
  }

  public changePagination() {
    this.fetchData();
  }

  public rowClick(model: IRedemption) {
    this.redemptionId = model.id;
    this.visibleRedemption = true;
  }
  public beforeOpen() {
    this.redemptionDetail = this.$store.getters[GetterTypeProduct.GetRedemption](this.redemptionId);
  }

  public clickAction(e: Event) {
    e.stopPropagation();
    e.preventDefault();
  }
  public clickDropdown(newValue: string) {
    const value = newValue.split('|'),
      type: any = value[0],
      id = value[1];

    const model = find(cloneDeep(this.data), (e) => e.id.toString() === id.toString());
    this.changeStatus(model, type);
  }

  public formatterActionLabel(a: any) {
    return a.label;
  }
  public changeFilter(v, name) {
    this.filterEnable = true;
    this.paramsFilter[name] = v;
  }
  protected mounted() {
    this.$nextTick(() => {
      this.fetchData();
    });
  }

  private fetchData(pagination?: IPaginationResult) {
    const filter = cloneDeep(this.paramsFilter);
    const page = pagination ? pagination : this.pagination;
    const formConverted: any = {
      params: {
        ...stripObject(filter),
        ...page
      }
    };
    this.$store.dispatch(ActionTypeProduct.TransactionListFilter, formConverted);
  }
}
