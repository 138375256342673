import { ISubscription, ITransactionPlan } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeSubscription } from './types';

export interface ISubscriptionState extends ICRUDState<ISubscription> {
  loading: boolean;
  transaction: ICRUDState<ITransactionPlan>;
}

export const defaultState: ISubscriptionState = {
  ...CRUDState(),
  loading: false,
  transaction: {
    ...CRUDState()
  }
};

export const mutations = {
  ...crudMutations('subscription'),
  [MutationTypeSubscription.SubscriptionLoading](state: ISubscriptionState) {
    state.loading = true;
  },
  [MutationTypeSubscription.SubscriptionLoaded](state: ISubscriptionState) {
    state.loading = false;
  },
  [MutationTypeSubscription.SetSubscription](state: ISubscriptionState, payload) {
    state.data = payload.data;
    state.pagination = payload.pagination;
  },
  [MutationTypeSubscription.ClearSubScription](state: ISubscriptionState) {
    state.data = [];
    state.pagination = {
      page: 1,
      size: 20
    };
  },
  /**
   * transaction by plan
   */
  [MutationTypeSubscription.SetTransactionByPlan](state: ISubscriptionState, payload) {
    state.transaction.data = payload.data;
    state.transaction.pagination = payload.pagination;
  },
  [MutationTypeSubscription.ClearTransactionByPlan](state: ISubscriptionState) {
    state.transaction.data = [];
    // state.transaction.pagination = PaginationState();
  },
  [MutationTypeSubscription.TransactionByPlanLoading](state: ISubscriptionState) {
    state.transaction.loading = true;
  },
  [MutationTypeSubscription.TransactionByPlanLoaded](state: ISubscriptionState) {
    state.transaction.loading = false;
  },
  [MutationTypeSubscription.TransactionByPlanPaginationChange](state: ISubscriptionState, payload) {
    state.transaction.pagination = {
      ...state.transaction.pagination,
      ...payload
    };
  }
};
