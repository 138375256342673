import { cloneDeep } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import { stripObject } from '@hgiasac/helper';
import { CMAAvatar, CMAButtonFilter, CMAHeader, CMAListData, CMASelect } from 'root/components';
import { cmaFormatDateTime, confirmAction, formaterListCounter, fullNameUser } from 'root/helpers';
import { AssignmentPushStatus, AssignmentTarget, IAssignment,
  IAssignmentParamsFilter, IBreadcrumb, IPagingParams } from 'root/models';
import { IState } from 'root/store';
import { AssignmentTemplateCreateDialog } from '../Components/AssignmentTemplateCreateDialog';
import { ActionTypeAssignment, MutationTypeAssignment } from '../Store/types';
import './style.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      pagination: (state: IState) => state.assignment.pagination,
      data: (state: IState) => state.assignment.data,
      // authUser: (state: IState) => state.global.authUser,
      loading: (state: IState) => state.assignment.loading
    }),
    ...mapGetters(['actionOption', 'targetOptions', 'gradeOptions', 'sortType',
      'statusOptions', 'methodOptions', 'assignmentPushStatus', 'paginationOption']),
    cloneStatusOptions() {
      const statusOptions = cloneDeep(this.statusOptions);
      statusOptions.splice(2, 1);

      return statusOptions;
    },
    cloneActionOption() {
      const actionOption = cloneDeep(this.actionOption);
      actionOption.splice(2, 1, 'delete');
      actionOption.push('templating');

      return actionOption;
    },
    cloneGradeOptions() {
      const gradeOptions = cloneDeep(this.gradeOptions);

      return [
        {
          label: this.$t('all.grade'),
          value: null
        },
        ...gradeOptions
      ];
    },
    cloneMethodOptions() {
      const methodOptions = cloneDeep(this.methodOptions);

      return [
        {
          label: this.$t('all.method'),
          value: null
        },
        ...methodOptions
      ];
    },
    cloneTargetOptions() {
      const targetOptions = cloneDeep(this.targetOptions);

      targetOptions.push({
        value: AssignmentTarget.Centre,
        label: this.$t(AssignmentTarget.Centre),
      });

      return targetOptions;
    }
  },
  components: {
    'cma-header': CMAHeader,
    'cma-select': CMASelect,
    'cma-list-data': CMAListData,
    'assignment-template-create': AssignmentTemplateCreateDialog,
    'cma-btn-filter': CMAButtonFilter,
    'cma-avatar': CMAAvatar
  },
  watch: {
    paginationSize(newValue) {
      if (newValue) {
        this.$store.commit(MutationTypeAssignment.SetAssignmentPagination, {
          size: newValue,
          page: 1
        });
        this.fetchData();
      }

      return;
    },
    'orderForm.value'(newValue: any) {
      this.$store.commit(MutationTypeAssignment.SetAssignmentPagination, {
        page: 1,
        order: newValue,
        descending: this.orderForm.desc
      });
      this.fetchData();
    },
    'orderForm.desc'(newValue: any) {
      this.$store.commit(MutationTypeAssignment.SetAssignmentPagination, {
        page: 1,
        order: this.orderForm.value,
        descending: newValue
      });
      this.fetchData();
    }
  }
})

export class AssignmentList extends Vue {
  public cloneGradeOptions: any[];
  public cloneTargetOptions: any[];
  public cloneRoleOptions: any[];
  public cloneStatusOptions: any[];
  public assignmentPushStatus: any[];
  public cloneMethodOptions: any[];
  public pagination: IPagingParams;
  public data: any[];
  public paginationSize: number = 20;
  public orderForm = {
    value: null,
    desc: false
  };
  public visibleTemplate: boolean = false;
  public filterEnable: boolean = false;

  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Assignment',
      path: ''
    },
    {
      label: 'Assignment List',
      path: '',
      current: true
    }
  ];
  public paramsFilter: IAssignmentParamsFilter = {
    grade: null,
    method: null,
    target: null,
    isStarted: null,
    status: null,
    text: null
  };
  public get filterOptions() {
    return {
      grade: this.cloneGradeOptions,
      method: this.cloneMethodOptions,
      target: this.cloneTargetOptions,
      status: this.cloneStatusOptions,
      isStarted: this.assignmentPushStatus
    };
  }

  public get gradeSort() {
    return [
      {
        label: this.$t('none'),
        value: null
      },
      {
        label: this.$t('grade'),
        value: 'grade'
      },
      {
        label: this.$t('method'),
        value: 'method'
      }
    ];
  }

  public get columns() {
    return [
      {
        prop: 'title',
        label: 'Title',
        width: 200
      },
      {
        prop: 'desc',
        label: 'description',
        width: 210
      },
      {
        prop: 'grade',
        label: 'Grade',
        formatter: (model: IAssignment) => {
          return model.library ? model.library.grade === 0 ? 'DAN' : model.library.grade : null;
        },
        width: 100
      },
      {
        prop: 'method',
        label: 'Method',
        formatter: (model: IAssignment) => {
          return model.library ? this.$t(model.library.method) : null;
        },
        width: 150
      },
      {
        prop: 'target',
        label: 'target',
        width: 150
      },
      // {
      //   prop: 'totalQuestion',
      //   label: 'Total Questions',
      //   width: 92
      // },
      {
        prop: 'status',
        label: 'status',
        width: 76
      },
      {
        prop: 'isStarted',
        label: 'Push status',
        formatter: (model: IAssignment) => {
          return model.isStarted ? AssignmentPushStatus.Sent : AssignmentPushStatus.New;
        },
        width: 95,
        labelStyle: (model: any) => {
          return model.isStarted ? AssignmentPushStatus.Sent : AssignmentPushStatus.New;
        }
      },
      {
        prop: 'createdBy',
        label: 'Created By',
        formatter: (model: any) => {
          return fullNameUser(model.owner);
        },
        width: 104
      },
      {
        prop: 'createdAt',
        label: 'Created At',
        formatter: (model: any) => {
          return cmaFormatDateTime(model.createdAt);
        },
        width: 120
      },
      // {
      //   prop: 'startTime',
      //   label: 'Start Time',
      //   formatter: (model: any) => {
      //     return cmaFormatDateTime(model.startTime);
      //   },
      //   width: 120
      // },
      // {
      //   prop: 'endTime',
      //   label: 'End Time',
      //   formatter: (model: any) => {
      //     return cmaFormatDateTime(model.endTime);
      //   },
      //   width: 120
      // }
    ];
  }

  public get formaterListCounter() {
    return formaterListCounter(this.pagination, this.data.length);
  }

  public goAddNewRouter() {
    this.$router.push('/assignments/new');
  }
  public clickDetail(id: number) {
    this.$router.push(`/assignments/${id}`);
  }
  public handleUpdate(id: number) {
    this.$router.push(`/assignments/${id}/edit`);
  }
  public handleDelete(data: any) {
    this.$store.dispatch(ActionTypeAssignment.AssignmentDelete, {
      id: data.id,
      onSuccess: () => {
        data.instance.confirmButtonLoading = false;
        data.done();
        this.$message({
          type: 'info',
          message: this.$t('delete_successfully').toString()
        });
        this.fetchData();
      },
      onFailure: () => {
        data.instance.confirmButtonLoading = false;
        data.instance.confirmButtonText = 'Confirm';
      }
    });
  }

  public changePagination(pagination) {
    this.$store.dispatch(ActionTypeAssignment.AssignmentFilterNoCache, pagination);
  }

  public applyFilter() {
    this.$store.commit(MutationTypeAssignment.AssignmentPaginationReset);
    this.fetchData();
    this.filterEnable = false;
  }

  public handleCommand(command: string) {
    switch (command) {
    case 'new':
      this.$router.push('/assignments/new');
      break;
    case 'template':
      this.visibleTemplate = true;
      break;
    default:
      break;
    }
  }

  public templating(model: IAssignment) {
    confirmAction(this, {
      title: this.$t('templating.assignment'),
      message: 'Web site will direct to new page!',
      handleFunction: async () => {
        await this.$store.commit(MutationTypeAssignment.AssignmentTemplateSelect, model);
        this.$router.push('/assignments/template/create');
      }
    });
  }

  public changeFilter(v, name) {
    this.filterEnable = true;
    this.paramsFilter[name] = v;
  }

  protected mounted() {
    this.$nextTick(() => {
      const data = cloneDeep(this.$data);

      if (data && data.length > 0) {
        this.$store.dispatch(ActionTypeAssignment.AssignmentFilterNoCacheNoLoading);
      } else {
        this.$store.dispatch(ActionTypeAssignment.AssignmentFilterNoCache);
      }
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeAssignment.AssignmentFilterChange, {});
  }

  private fetchData() {
    const filter = cloneDeep(this.paramsFilter);
    const formConverted: any = {
      params: {
        filterParams: stripObject(filter)
      }
    };

    this.$store.dispatch(ActionTypeAssignment.AssignmentFilterNoCache, formConverted);
  }
}
