export interface INotificationData {
  id: number;
  title: string;
  type: NotificationType;
  referenceId: number;
}
export interface INotification {
  id: string;
  heading: string;
  content: string;
  icon?: string;
  data?: INotificationData;
  isStarted: boolean;
  status?: string;
  target: string[];
}
export enum NotificationType {
  Game = 'game',
  Announcement = 'announcement',
  NewAssignment = 'new_assignment',
  CompletedAssignment = 'completed_assignment',
  Redeem = 'redeem'
}

export const notificationDefault = (): INotification => {
  return {
    id: null,
    content: '',
    data: {
      id: null,
      title: '',
      type: null,
      referenceId: null
    },
    heading: '',
    icon: '',
    isStarted: null,
    target: []
  };
};

export interface INotificationFilter {
  page?: number;
  nextPageToken?: string;
  target?: string;
  title?: string;
  isStarted?: boolean;
}

export interface INotificationForm {
  title: string;
  content: string;
  target: string[];
  sendTime: Date;
}

export const notificationFormDefault = (): INotificationForm => {
  return {
    content: '',
    sendTime: null,
    target: [],
    title: ''
  };
};
