import { stripObject } from '@hgiasac/helper';
import { cloneDeep, omit } from 'lodash';
import { CMAButtonFilter, CMAHeader, CMAListData, CMASelect } from 'root/components';
import { cmaFormatDateFullTime, formaterListCounter, fullNameUser } from 'root/helpers';
import { AssignmentPushStatus, IBreadcrumb, INotification, INotificationFilter, IPagingParams } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { ActionTypeNotification, MutationTypeNotification } from '../Store/types';
import { NotificationRouterName, NotificationRouterPath } from '../index';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'cma-select': CMASelect,
    'cma-list-data': CMAListData,
    'cma-btn-filter': CMAButtonFilter
  },
  computed: {
    ...mapGetters(['assignmentPushStatus', 'paginationOption', 'sortType']),
    ...mapState({
      listCentre: (state: IState) => state.global.globalConfig.listCentre,
      pagination: (state: IState) => state.notification.pagination,
      data: (state: IState) => state.notification.data,
      loading: (state: IState) => state.notification.loading,
    }),
    cloneCentreOptions() {
      const listCentre = cloneDeep(this.listCentre);

      return [
        {
          value: null,
          name: this.$t('all.centre')
        },
        ...listCentre
      ];
    },
  },
  watch: {
    paginationSize(newValue) {
      this.$store.commit(MutationTypeNotification.SetNotificationPagination, {
        size: newValue,
        page: 1
      });
      this.fetchData();

    },
    'orderForm.value'(newValue: any) {
      this.$store.commit(MutationTypeNotification.SetNotificationPagination, {
        page: 1,
        order: newValue,
        descending: this.orderForm.desc
      });
      this.fetchData();
    },
    'orderForm.desc'(newValue: any) {
      this.$store.commit(MutationTypeNotification.SetNotificationPagination, {
        page: 1,
        order: this.orderForm.value,
        descending: newValue
      });
      this.fetchData();
    }
  }
})

export class NotificationList extends Vue {
  public assignmentPushStatus: any[];
  public cloneCentreOptions: any[];
  public pagination: IPagingParams;
  public paginationSize: number = 20;
  public data: any[];
  public orderForm = {
    value: null,
    desc: false
  };
  public filterEnable: boolean = false;
  public listCentre: any[];

  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Notification',
      path: '',
      current: true
    }
  ];
  public paramsFilter: INotificationFilter = {
    title: '',
    target: null,
    isStarted: null
  };

  public get filterOptions() {
    return {
      target: this.cloneCentreOptions,
      isStarted: this.assignmentPushStatus
    };
  }
  public get notificationSort() {
    return [
      {
        label: this.$t('none'),
        value: null
      },
      {
        label: 'Created At',
        value: 'createdAt'
      }
    ];
  }

  public get formaterListCounter() {
    return formaterListCounter(this.pagination, this.data.length);
  }

  public get columns() {
    return [
      {
        prop: 'title',
        label: 'title',
        // width: 120
      },
      {
        label: 'Created by',
        formatter: (model: any) => {
          return fullNameUser(model.owner);
        },
        avatar: {
          formatter: (model: any) => {
            return model ? model.owner : null;
          }
        },
        width: 160
      },
      {
        prop: 'createdAt',
        label: 'Created At',
        formatter: (model: any) => {
          return cmaFormatDateFullTime(model.createdAt);
        },
        width: 170
      },
      {
        prop: 'target',
        label: 'Target',
        width: 170,
        formatter: (model: INotification) => {
          const target = model.target;

          return target.map((e) => this.listCentre.find((l) => l.value === e).name).join(', ');
        }
      },
      {
        prop: 'isStarted',
        label: 'Push status',
        width: 100,
        formatter: (model: INotification) => {
          return model.isStarted ? AssignmentPushStatus.Sent : AssignmentPushStatus.New;
        },
        labelStyle: (model: INotification) => {
          return model.isStarted ? 'sent' : AssignmentPushStatus.New;
        }
      },
      {
        prop: 'status',
        label: 'status',
        width: 120,
        formatter: (model: INotification) => model.status ? this.$t(model.status) : '',
        labelStyle: (model: INotification) => {
          return model.status;
        }
      }];
  }

  public addNew() {
    this.$router.push(NotificationRouterPath.Create);
  }

  public applyFilter() {
    this.fetchData();
    this.filterEnable = false;
  }

  public changePagination() {
    this.fetchData();
  }

  public changeFilter(v, name) {
    this.filterEnable = true;
    this.paramsFilter[name] = v;
  }

  public clickDetail(id: string) {
    this.$router.push({
      name: NotificationRouterName.View,
      params: {
        notifyId: id
      }
    });
  }
  public handleUpdate(id: string) {
    this.$router.push({
      name: NotificationRouterName.Edit,
      params: {
        notifyId: id
      }
    });
  }

  protected mounted() {
    this.$nextTick(() => {
      this.fetchData();
    });
  }

  private fetchData() {
    const _filter = cloneDeep(this.paramsFilter);
    const page = this.pagination;

    const formConverted: any = {
      params: {
        filterParams: {
          ...stripObject(_filter),
          ...omit(page, ['totalPerPage', 'total', 'totalPages'])
        }
      }
    };

    this.$store.dispatch(ActionTypeNotification.NotificationFilterNoCache, formConverted);
  }
}
