import axios, { AxiosRequestConfig } from 'axios';
import { CMAAuth, Logger as log } from 'root/services';

// auth service
import { IAuthService } from 'root/services';

export interface IHttpService {
  getUrl(url: string, mock?: boolean): string;
  get<T = any>(url: string, options?: AxiosRequestConfig): Promise<T>;
  post<T = any>(url: string, data: any, options?: AxiosRequestConfig): Promise<T>;
  put<T = any>(url: string, data: any, options?: AxiosRequestConfig): Promise<T>;
  patch<T = any>(url: string, data: any, options?: AxiosRequestConfig): Promise<T>;
  del<T = any>(url: string, options?: AxiosRequestConfig): Promise<T>;
}

export interface IHttpServiceClientOptions {
  httpService: IHttpService;
  mock?: boolean;
}

export interface IHttpServiceOptions {
  baseURL: string;
  mockBaseURL: string;
  authService?: IAuthService;
}

export function HttpService(options: IHttpServiceOptions): IHttpService {

  const HTTP = axios.create({
    headers: {
      'Content-Type': 'application/json'
    },
  });

  function getUrl(url: string, mock?: boolean): string {
    const baseURL = mock ? options.mockBaseURL : options.baseURL;

    return baseURL + url;
  }

  async function request<T = any>(config: AxiosRequestConfig): Promise<T> {

    const authorization = await CMAAuth.getAccessToken();
    const configRequest: AxiosRequestConfig = {
      ...config,
      headers: {
        ...config.headers,
        authorization,
      },
    };

    let response;
    try {
      response = await HTTP.request<T>(configRequest);
    } catch (error) {
      response = error.response;
    }

    switch (response.status) {
    case 200:
    case 201:
    case 204:
      return response.data;
    case 400:
    case 401:
    case 403:
    case 404:
      log.warn(`[${response.status}]`, response);
    case 500:
    case 550:
      log.error(`[${response.status}]`, response);

      return Promise.reject(response.data);
    }

  }

  async function get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return request({
      ...config,
      url,
      method: 'get',
    });
  }

  async function post<T = any>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
    return request({
      ...config,
      url,
      data,
      method: 'post',
    });
  }

  async function put<T = any>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
    return request({
      ...config,
      url,
      data,
      method: 'put',
    });
  }

  async function patch<T = any>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
    return request({
      ...config,
      url,
      data,
      method: 'put',
    });
  }

  async function del<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return request({
      ...config,
      url,
      method: 'delete',
    });
  }

  return {
    getUrl,
    get,
    put,
    post,
    del,
    patch
  };
}
