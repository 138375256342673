import { RouteConfig } from 'vue-router';
import { DashboardAssignment } from './DashboardAssignment';
import { DashboardContainer } from './DashboardContainer';
import { DashboardDownload } from './DashboardDownload';
import { DashboardRedemption } from './DashboardRedemption';
import { DashboardSubscription } from './DashboardSubscription';
import {DashboardUser} from './DashboardUser';

export enum DashboardRouterPath {
  Default = '/dashboard',
  User = '/dashboard/user',
  Subscription = '/dashboard/subscription',
  Assignment = '/dashboard/assignment',
  Redemption = '/dashboardredemption',
  Download = '/dashboard/download'
}

export const DashboardRouter: RouteConfig = {
  path: DashboardRouterPath.Default,
  component: DashboardContainer,
  children: [
    {
      path: '',
      redirect: DashboardRouterPath.User
    },
    {
      path: DashboardRouterPath.User,
      component: DashboardUser
    },
    {
      path: DashboardRouterPath.Subscription,
      component: DashboardSubscription
    },
    {
      path: DashboardRouterPath.Assignment,
      component: DashboardAssignment
    },
    {
      path: DashboardRouterPath.Redemption,
      component: DashboardRedemption
    },
    {
      path: DashboardRouterPath.Download,
      component: DashboardDownload
    }
  ]
};
