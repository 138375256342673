import { stripObject } from '@hgiasac/helper';
import { cloneDeep } from 'lodash';
import { CMAButtonFilter, CMAListData, CMASelect } from 'root/components';
import { cmaFormatDateFullTime } from 'root/helpers';
import { IAssignment } from 'root/models';
import { ActionTypeAssignment, MutationTypeAssignment } from 'root/pages/Assignment/Store/types';
import { ProductDetailView } from 'root/pages/Product/Components/ProductDetailView';
import { IState } from 'root/store';
import { IPaginationResult } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { UserAssignmentDialog } from '../UserAssignmentDialog';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-select': CMASelect,
    'cma-list-data': CMAListData,
    'product-detail-view': ProductDetailView,
    'user-assignment-dialog': UserAssignmentDialog,
    'cma-btn-filter': CMAButtonFilter
  },
  props: {
    userId: [Number, String]
  },
  computed: {
    ...mapGetters(['sortType', 'gradeOptions', 'methodOptions', 'paginationOption']),
    ...mapState({
      pagination: (state: IState) => state.assignment.pagination,
      assignments: (state: IState) => state.assignment.data,
      loading: (state: IState) => state.user.loading
    }),
    cloneMethodOptions() {
      const methodOptions = cloneDeep(this.methodOptions);

      return [
        {
          label: this.$t('all.method'),
          value: null
        },
        ...methodOptions
      ];
    },
    cloneGradeOptions() {
      const gradeOptions = cloneDeep(this.gradeOptions);

      return [
        {
          label: this.$t('all.grade'),
          value: null
        },
        ...gradeOptions
      ];
    },
  },
  watch: {
    paginationSize(newValue) {
      if (newValue) {
        this.$store.commit(MutationTypeAssignment.SetAssignmentPagination, {
          size: newValue,
          page: 1
        });
        this.fetchData();
      }

      return;
    }
  }
})

export class UserAssignmentList extends Vue {
  public assignmentDetail: IAssignment = null;
  public visibleDetail: boolean = false;
  public pagination: IPaginationResult;
  public cloneGradeOptions: any[];
  public cloneMethodOptions: any[];
  public filterEnable: boolean = false;
  public paginationSize: number = 20;

  public paramsFilter: any = {
    text: null,
    method: null,
    grade: null,
    statusAssignment: null
  };

  public get filterOptions() {
    return {
      grade: this.cloneGradeOptions,
      method: this.cloneMethodOptions,
      statusAssignment: [
        {
          value: null,
          label: 'All Progress'
        },
        {
          value: 'new',
          label: 'New'
        },
        {
          value: 'completed',
          label: 'Completed'
        },
        {
          value: 'overdue',
          label: 'Overdue'
        }
      ]
      // target: this.cloneTargetOptions,
      // status: this.cloneStatusOptions,
      // isStarted: this.assignmentPushStatus
    };
  }

  public get columns() {
    return [
      {
        prop: 'title',
        label: 'title',
      },
      {
        prop: 'grade',
        label: 'grade',
        formatter: (model: any) => {
          if (!model.library) {
            return '';
          }

          return model.library.grade === 0 ? 'DAN' : model.library.grade;
        },
        width: 50
      },
      {
        prop: 'method',
        label: 'method',
        formatter: (model: any) => {
          return model.library ? this.$t(model.library.method).toString() : '';
        },
        width: 120
      },
      {
        prop: 'totalQuestion',
        label: 'total questions',
        align: 'center',
        width: 80
      },
      {
        prop: 'endTime',
        label: 'end time',
        formatter: (model: any) => {
          return cmaFormatDateFullTime(model.endTime);
        },
        labelStyle: () => 'break-word',
        width: 100,
        align: 'center'
      },
      {
        prop: 'totalPoint',
        label: 'Total points',
        width: 80,
        align: 'center'
      },
      {
        prop: 'earnedPoint',
        label: 'earned points',
        formatter: (model: any) => {
          return model.historyGame ? `+${model.historyGame.earnedPoint}` : 0;
        },
        labelStyle: (model: any) => `earned--${model.statusAssignment}`,
        width: 70,
        align: 'center'
      },
      {
        prop: 'statusAssignment',
        label: 'progress',
        width: 90,
        formatter: (model: any) => this.$t(model.statusAssignment),
        labelStyle: (model: any) => model.statusAssignment,
        align: 'center'
      }];
  }

  public get assignmentSort() {
    return [
      {
        label: this.$t('none'),
        value: null
      },
      // {
      //   label: this.$t('created.at'),
      //   value: 'createdAt'
      // },
      {
        label: this.$t('end.time'),
        value: 'endTime'
      },
    ];
  }

  public handleDetail(model: IAssignment) {
    this.assignmentDetail = model;
    this.visibleDetail = true;
  }

  public changePagination() {
    this.fetchData();
  }

  public changeSort() {
    this.fetchData();
  }

  public applyFilter() {
    this.fetchData();
    this.filterEnable = false;
  }

  public changeFilter(v, name) {
    this.filterEnable = true;
    this.paramsFilter[name] = v;
  }

  protected mounted() {
    this.$nextTick(() => {
      this.fetchData();
    });
  }

  private fetchData(pagination?: IPaginationResult) {
    const filter = cloneDeep(this.paramsFilter);
    const page = pagination ? pagination : this.pagination;
    const formConverted: any = {
      params: {
        ...stripObject(filter),
        ...page
      }
    };

    this.$store.dispatch(ActionTypeAssignment.GetAssignmentByUser, {
      id: this.$props.userId,
      _filter: formConverted
    });
  }
}
