import { cmaHttpClient } from 'root/api/http';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IUserState } from './mutations';
import { ActionTypeUser, MutationTypeUser } from './types';
const { filter, create, getById, update, deleteById,
  changePassword, softDelete } = cmaHttpClient.user;

export const actions = {
  ...crudActions('user', {
    filter, create, update, deleteById
  }),
  async [ActionTypeUser.GetUserById](
    { commit, dispatch }: ActionContext<IUserState, IState>, id: number
  ) {
    commit(MutationTypeUser.UserLoading);
    try {
      const data = await getById(id);
      commit(MutationTypeUser.SetUserDetail, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }

    commit(MutationTypeUser.UserLoaded);
  },
  [ActionTypeUser.ChangePassword](
    {}: ActionContext<IUserState, IState>, {id, password}
  ) {
    return changePassword(id, password);
  },
  async [ActionTypeUser.SoftDelete](
    {dispatch}: ActionContext<IUserState, IState>, {
      id,
      onSuccess,
      onFailure
    }
  ) {
    try {
      await softDelete(id);
      onSuccess();
    } catch (error) {
      onFailure();
      dispatch(ActionType.CatchException, error);
    }
  }
};
