import { ILibrary, ILibraryCase } from 'root/models/Library';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface ILibraryHttpService
  extends ICRUDHttpService<ILibrary> {
  getLibraryQuestionById(id: number): Promise<any>;
  getAllCases(method: string, grade: number): Promise<ILibraryCase[]>;
}

export function LibraryHttpService(options: IHttpServiceClientOptions): ILibraryHttpService {

  const basePath = '/admin/libraries';
  // const baseURL = options.httpService.getUrl(basePath, options.mock);

  function getLibraryQuestionById(id: number): Promise<ILibrary> {
    const baseURL = options.httpService.getUrl(`${basePath}/${id}/questions`, options.mock);

    return options.httpService.get(baseURL);
  }

  function getAllCases(method: string, grade: number): Promise<ILibraryCase[]> {
    const allCasePath = options.httpService.getUrl(`${basePath}/cases`, options.mock);

    return options.httpService.post(allCasePath, {
      method,
      grade
    });
  }

  return {
    ...CRUDHttpService<ILibrary>({
      ...options,
      basePath
    }),
    getLibraryQuestionById,
    getAllCases
  };
}
