import { ISimpleUser } from './User';

export interface IPlan {
  id?: number;
  name: string;
  price: number;
  accessibility?: AccessibilityType;
  desc: string;
  duration: number;
  type?: PlanType;
  status?: PlanStatus;
  from?: Date;
  to?: Date;
}

export enum AccessibilityType {
  Permanent = 'permanent',
  Limited = 'limited'
}

export enum PlanType {
  Free = 'free',
  Paid = 'paid'
}

export enum PlanStatus {
  Active = 'active',
  Disabled = 'suspended'
}

export const planForm = (): IPlan => {
  return {
    name: '',
    accessibility: null,
    desc: '',
    duration: null,
    price: null,
    from: null,
    to: null
  };
};

export interface ITransactionPlan {
  id?: number;
  centre: string;
  createdAt: Date;
  status: string;
  owner: ISimpleUser;
}
export const transactionPlanDefault = (): ITransactionPlan => {
  return {
    centre: '',
    createdAt: null,
    owner: {
      avatar: '',
      fullName: '',
      id: null
    },
    status: null
  };
};
