import { cmaHttpClient } from 'root/api/http';
import { ActionType, IState } from 'root/store';
import { crudActions } from 'root/store/helpers';
import { ActionContext } from 'vuex';
import { IProductState } from './mutations';
import { ActionTypeProduct, MutationTypeProduct } from './types';

const { create, filter, deleteById, list,
  update, getById, transactionFilter,
  transactionUpdate, transactionUserFilter,
  transactionListOfDetail } = cmaHttpClient.product;
export const actions = {
  ...crudActions('product', {
    create, filter, deleteById, update, getById, list
  }),

  async [ActionTypeProduct.TransactionListFilter](
    { commit, dispatch}: ActionContext<IProductState, IState>, paramsFilter
  ) {
    commit(MutationTypeProduct.TransactionLoading);
    const order = !paramsFilter.params || !paramsFilter.params.order ? undefined
        : (paramsFilter.params.descending ? '-' + paramsFilter.params.order : paramsFilter.params.order);
    paramsFilter.params.order = order;
    try {
      const data = await transactionFilter(paramsFilter);
      commit(MutationTypeProduct.TransactionSetData, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeProduct.TransactionLoaded);
  },
  async [ActionTypeProduct.TransactionListUpdate](
    { commit, dispatch}: ActionContext<IProductState, IState>, {id, form, onSuccess, onFailure}
  ) {
    commit(MutationTypeProduct.TransactionLoading);

    try {
      const data = await transactionUpdate(id, form);
      commit(MutationTypeProduct.TransactionUpdateModel, data);
      onSuccess();
    } catch (error) {
      dispatch(ActionType.CatchException, error);
      onFailure();
    }
    commit(MutationTypeProduct.TransactionLoaded);
  },
  async [ActionTypeProduct.TransactionUserFilter](
    { commit, dispatch}: ActionContext<IProductState, IState>, {id, form}
  ) {
    commit(MutationTypeProduct.TransactionLoading);
    try {
      const order = !form || !form.params || !form.params.order ? undefined
          : (form.params.descending ? '-' + form.params.order : form.params.order);
      form.params.order = order;
      const data = await transactionUserFilter(id, form);
      commit(MutationTypeProduct.TransactionSetData, data);
    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }

    commit(MutationTypeProduct.TransactionLoaded);
  },
  async [ActionTypeProduct.TransactionListOfProductFilter](
    { commit, dispatch}: ActionContext<IProductState, IState>, {id, params}
  ) {
    commit(MutationTypeProduct.SetTransactionListOfProductLoading);
    try {
      const data = await transactionListOfDetail(id, params);
      commit(MutationTypeProduct.SetTransactionListOfProduct, data);

    } catch (error) {
      dispatch(ActionType.CatchException, error);
    }
    commit(MutationTypeProduct.SetTransactionListOfProductLoaded);
  }
};
