export enum ActionTypeDashboard {
  DashboardFilterNewUsers = 'DashboardFilterNewUsers',
  DashboardFilterAssignment = 'DashboardFilterAssignment',
  DashboardFilterCoolest = 'DashboardFilterCoolest',
  DashboardFilterSubscription = 'DashboardFilterSubscription',
  DashboardFilterRedemption = 'DashboardFilterRedemption',
  DashboardFilterMostActiveUsers = 'DashboardFilterMostActiveUsers'
}

export enum MutationTypeDashboard {
  DashboardSetMostActiveUsers = 'DashboardSetMostActiveUsers',
  DashboardSetMostActiveUsersFilter = 'DashboardSetMostActiveUsersFilter',
  DashboardClearMostActiveUsersFilter = 'DashboardClearMostActiveUsersFilter',

  DashboardSetNewUsers = 'DashboardSetNewUsers',
  DashboardSetUserFilter = 'DashboardSetUserFilter',
  DashboardClearUserFilter = 'DashboardClearUserFilter',

  DashboardSetCoolest = 'DashboardSetCoolest',
  DashboardSetCoolestFilter = 'DashboardSetCoolestFilter',
  DashboardClearCoolest = 'DashboardClearCoolest',

  DashboardCoolestLoading = 'DashboardCoolestLoading',
  DashboardCoolestLoaded = 'DashboardCoolestLoaded',

  DashboardSetSubscription = 'DashboardSetSubscription',
  DashboardSetSubscriptionFilter = 'DashboardSetSubscriptionFilter',
  DashboardClearSubscription = 'DashboardClearSubscription',
  DashboardClearSubscriptionFilter = 'DashboardClearSubscriptionFilter',

  DashboardSetAssignment = 'DashboardSetAssignment',
  DashboardSetAssignmentFilter = 'DashboardSetAssignmentFilter',
  DashboardClearAssignment = 'DashboardClearAssignment',

  DashboardSetRedemption = 'DashboardSetRedemption',
  DashboardSetRedemptionFilter = 'DashboardSetRedemptionFilter',
  DashboardClearRedemption = 'DashboardClearRedemption'
}
