import { INotification } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface INotificationHttpService
  extends ICRUDHttpService<INotification> {
}

export function NotificationHttpService(options: IHttpServiceClientOptions): INotificationHttpService {

  const basePath = '/admin/notifications';

  return {
    ...CRUDHttpService<INotification>({
      ...options,
      basePath
    })
  };
}
