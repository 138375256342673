export enum ActionTypeSubscription {
  SubscriptionFilterNoCache = 'subscriptionFilterNoCache',

  SubscriptionFilterByUser = 'SubscriptionFilterByUser',

  TransactionFilterByPlan = 'TransactionFilterByPlan'
}

export enum MutationTypeSubscription {
  SubscriptionLoading = 'subscriptionLoading',
  SubscriptionLoaded = 'subscriptionLoaded',
  SubscriptionPaginationChange = 'subscriptionPaginationChange',

  SetSubscription = 'SetSubscription',
  ClearSubScription = 'ClearSubScription',

  SetTransactionByPlan = 'SetTransactionByPlan',
  ClearTransactionByPlan = 'ClearTransactionByPlan',
  TransactionByPlanLoading = 'TransactionByPlanLoading',
  TransactionByPlanLoaded = 'TransactionByPlanLoaded',
  TransactionByPlanPaginationChange = 'TransactionByPlanPaginationChange'
}
