import { defaultStatisticFilter, IRedemptionStatistic,
  IStatisticsUser, IStatisticCoolest, IStatisticFilter, ISubscriptionStatistic } from 'root/models';
import { CRUDState, ICRUDState } from 'root/store/helpers';
import { MutationTypeDashboard } from './types';

export interface IDashboardState extends ICRUDState<any> {
  redemptionFilter: IStatisticFilter;
  redemption: IRedemptionStatistic[];

  activeUsers: IStatisticsUser[];
  activeUsersLoading: boolean;
  activeUsersFilter: IStatisticFilter;

  statisticsUser: IStatisticsUser[];
  newUserLoading: boolean;
  userFilter: IStatisticFilter;

  coolest: IStatisticCoolest[];
  coolestFilter: IStatisticFilter;

  coolestLoading: boolean;

  subscription: ISubscriptionStatistic[];
  subscriptionFilter: IStatisticFilter;

  assignment: IStatisticsUser[];
  assignmentFilter: IStatisticFilter;
}

export const defaultState: IDashboardState = {
  ...CRUDState(),
  activeUsers: [],
  activeUsersLoading: false,
  activeUsersFilter: defaultStatisticFilter(),
  statisticsUser: [],
  userFilter: defaultStatisticFilter(),
  coolest: [],
  coolestFilter: defaultStatisticFilter(),
  newUserLoading: false,
  coolestLoading: false,
  subscription: [],
  subscriptionFilter: defaultStatisticFilter(),

  assignment: [],
  assignmentFilter: defaultStatisticFilter(),

  redemption: [],
  redemptionFilter: defaultStatisticFilter(),
};

export const mutations = {
  [MutationTypeDashboard.DashboardSetMostActiveUsers](state: IDashboardState, payload) {
    state.activeUsers = payload;
  },
  [MutationTypeDashboard.DashboardSetMostActiveUsersFilter](state: IDashboardState, payload) {
    state.activeUsersFilter = payload;
  },
  [MutationTypeDashboard.DashboardClearMostActiveUsersFilter](state: IDashboardState) {
    state.activeUsers = [];
    state.activeUsersFilter = defaultStatisticFilter();
  },
  [MutationTypeDashboard.DashboardSetNewUsers](state: IDashboardState, payload) {
    state.statisticsUser = payload;
  },
  [MutationTypeDashboard.DashboardSetUserFilter](state: IDashboardState, payload) {
    state.userFilter = payload;
  },
  [MutationTypeDashboard.DashboardClearUserFilter](state: IDashboardState) {
    state.statisticsUser = [];
    state.userFilter = defaultStatisticFilter();
  },
  /**
   * coolest statistic
   */
  [MutationTypeDashboard.DashboardSetCoolest](state: IDashboardState, payload) {
    state.coolest = payload;
  },
  [MutationTypeDashboard.DashboardSetCoolestFilter](state: IDashboardState, payload) {
    state.coolestFilter = {
      ...state.coolestFilter,
      ...payload
    };
  },
  [MutationTypeDashboard.DashboardClearCoolest](state: IDashboardState) {
    state.coolest = [];
    state.coolestFilter = defaultStatisticFilter();
  },
  [MutationTypeDashboard.DashboardCoolestLoading](state: IDashboardState) {
    state.coolestLoading = true;
  },
  [MutationTypeDashboard.DashboardCoolestLoaded](state: IDashboardState) {
    state.coolestLoading = false;
  },
  /**
   * subscription
   */
  [MutationTypeDashboard.DashboardSetSubscriptionFilter](state: IDashboardState, payload) {
    state.subscriptionFilter = {
      ...state.subscriptionFilter,
      ...payload
    };
  },
  [MutationTypeDashboard.DashboardClearSubscriptionFilter](state: IDashboardState) {
    state.subscriptionFilter = defaultStatisticFilter();
  },
  [MutationTypeDashboard.DashboardSetSubscription](state: IDashboardState, payload) {
    state.subscription = payload;
  },
  [MutationTypeDashboard.DashboardClearSubscription](state: IDashboardState) {
    state.subscription = [];
  },
  /**
   * assignment
   */
  [MutationTypeDashboard.DashboardSetAssignment](state: IDashboardState, payload) {
    state.assignment = payload;
  },
  [MutationTypeDashboard.DashboardClearAssignment](state: IDashboardState) {
    state.assignment = [];
    state.assignmentFilter = defaultStatisticFilter();
  },
  [MutationTypeDashboard.DashboardSetAssignmentFilter](state: IDashboardState, payload) {
    state.assignmentFilter = {
      ...state.assignmentFilter,
      ...payload
    };
  },
  /**
   * redemption
   */
  [MutationTypeDashboard.DashboardSetRedemption](state: IDashboardState, payload) {
    state.redemption = payload;
  },
  [MutationTypeDashboard.DashboardClearRedemption](state: IDashboardState) {
    state.redemption = [];
    state.redemptionFilter = defaultStatisticFilter();
  },
  [MutationTypeDashboard.DashboardSetRedemptionFilter](state: IDashboardState, payload) {
    state.redemptionFilter = {
      ...state.redemptionFilter,
      ...payload
    };
  },
};
