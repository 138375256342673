import { cloneDeep, find, isNull } from 'lodash';
import { cmaFormatDateFullTime } from 'root/helpers';
import { IProduct } from 'root/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    productId: [Number, String],
    productDetail: Object,
    qty: Number,
    progress: String
  }
})

export class ProductDetailView extends Vue {

  public get primaryImageURL(): string {
    const productDetail = cloneDeep(this._productDetail),
      images = productDetail ? productDetail.images : null;

    if (!images) {
      return '';
    }

    return find(images, (e, index) => {
      if (isNull(this.priviewIndex)) {
        return e.isPrimary;
      }

      return index === this.priviewIndex;
    }).src;
  }

  public get _productDetail(): IProduct {
    return this.$props.productDetail;
  }
  public cmaFormatDateFullTime = cmaFormatDateFullTime;
  private priviewIndex: number = null;

  public isPrimaryImage(index: number) {
    if (isNull(this.priviewIndex)) {
      return this.isHaveImgage(index) && this._productDetail.images[index].isPrimary;
    }

    return this.priviewIndex === index;
  }
  public isHaveImgage(index: number): boolean {
    const productDetail = cloneDeep(this._productDetail),
      images = productDetail ? productDetail.images : null;

    if (!images) {
      return false;
    }

    return !!images[index];
  }

  public changePreview(index: number) {
    this.priviewIndex = index;
  }
}
