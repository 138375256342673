import { find, isNil } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    data: {
      type: Array,
      default: () => []
    },
    value: [String, Number, Boolean],
    disabledValueNone: Boolean,
    placeholder: {
      type: String,
      default: 'Select'
    },
    fullWidth: Boolean,
    prefix: String,
    disabled: Boolean
  }
})

export class CMASelect extends Vue {
  public data: string[];
  public value: any;
  public placeholder: string;

  public get getLabel(): string {
    if (isNil(this.value)) {
      const _default = find(this.data, (e: any) => e.value === null);

      return _default ? _default.label || _default.name : this.placeholder;
    }

    const model = find(this.data, (e: any) => e.value === this.value);

    if (!model) {
      return '';
    }

    return model.label || model.name;
  }

  public click(e: Event) {
    e.stopPropagation();
    e.preventDefault();
  }

  public handleCommand(model: any) {
    this.$emit('update:value', model.value);
    this.$emit('change', model.value);
  }
}
