import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IExaminationHttpService
  extends ICRUDHttpService<any> {
  downloadGameExamination(form: any): Promise<any>;
  getExaminationHistories(): Promise<any>;
}

export function ExaminationHttpService(options: IHttpServiceClientOptions): IExaminationHttpService {

  const basePath = '/admin/games/exams/histories';

  function downloadGameExamination(form: any): Promise<any> {
    const url = options.httpService.getUrl('/admin/games/exams');

    return options.httpService.post(url, form);
  }
  function getExaminationHistories(): Promise<any> {
    const url = options.httpService.getUrl('/admin/games/exams/histories');

    return options.httpService.get(url);
  }

  return {
    ...CRUDHttpService<any>({
      ...options,
      basePath
    }),
    downloadGameExamination,
    getExaminationHistories
  };
}
