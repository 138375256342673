import Vue from 'vue';
import Component from 'vue-class-component';
import { DashboardRedemptionActivities } from '../Components/DashboardRedemptionActivities';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'dashboard-redemption-activities': DashboardRedemptionActivities
  }
})

export class DashboardRedemption extends Vue {
  //
}
