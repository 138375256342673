import { RouteConfig } from 'vue-router';
import { ProductContainer } from './ProductContainer';
import { ProductCreate } from './ProductCreate';
import { ProductDetail } from './ProductDetail';
import { ProductList } from './ProductList';
import { TransactionList } from './TransactionList';

export enum ProductRouterPath {
  Redemption = '/redemption'
}

export const ProductRouter: RouteConfig = {
  path: '/redemption',
  component: ProductContainer,
  children: [
    {
      path: '',
      redirect: 'product-list'
    },
    {
      path: 'product-list',
      component: ProductList
    },
    {
      path: 'transaction',
      component: TransactionList
    },
    {
      path: 'new',
      component: ProductCreate,
    },
    {
      path: ':productId/detail',
      component: ProductDetail
    },
    {
      path: ':productId/edit',
      component: ProductCreate
    }
  ]
};
