import { find } from 'lodash';
import { CMAAvatar, CMAHeader, CMAListData } from 'root/components';
import { cmaFormatDateFullTime, formatBirthday } from 'root/helpers';
import { IBreadcrumb, IPhoneInput, IUser, UserRole } from 'root/models';
import { MutationTypeAssignment } from 'root/pages/Assignment/Store/types';
import { MutationTypeProduct } from 'root/pages/Product/Store/types';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { userRouterName, userRouterPath } from '..';
import { UserAssignmentList } from '../Components/UserAssignmentList';
import { UserSubscriptionList } from '../Components/UserSubscriptionList';
import { ActionTypeUser, MutationTypeUser } from '../Store/types';
import { UserRedemptionList } from '../UserRedemptionList';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'cma-avatar': CMAAvatar,
    'cma-list-data': CMAListData,
    'user-redemption-list': UserRedemptionList,
    'user-assignment-list': UserAssignmentList,
    'user-subscription-list': UserSubscriptionList
  },
  computed: {
    ...mapState({
      user: (state: IState) => state.user.detail,
      assignments: (state: IState) => state.assignment.data,
      pagination: (state: IState) => state.assignment.pagination,
      loading: (state: IState) => state.user.loading,
      listCentre: (state: IState) => state.global.globalConfig.listCentre
    })
  },
  watch: {
    getUserId(newValue) {
      if (newValue) {
        this.$store.dispatch(ActionTypeUser.GetUserById, newValue);
      }
    }
  }
})

export class UserDetail extends Vue {

  public get getUserId(): string {
    return this.$route.params.userId;
  }
  public get columns() {
    return [
      {
        prop: 'title',
        label: 'title',
      },
      {
        prop: 'grade',
        label: 'grade',
        formatter: (model: any) => {
          if (!model.library) {
            return '';
          }

          return model.library.grade === 0 ? 'DAN' : model.library.grade;
        },
        width: 50
      },
      {
        prop: 'method',
        label: 'method',
        formatter: (model: any) => {
          return model.library ? this.$t(model.library.method).toString() : '';
        },
        width: 120
      },
      {
        prop: 'totalQuestion',
        label: 'total questions',
        width: 80
      },
      {
        prop: 'endTime',
        label: 'end time',
        formatter: (model: any) => {
          return cmaFormatDateFullTime(model.endTime);
        },
        labelStyle: () => 'break-word',
        width: 100,
        align: 'center'
      },
      {
        prop: 'totalPoint',
        label: 'Total points',
        width: 80,
        align: 'center'
      },
      {
        prop: 'earnedPoint',
        label: 'earned points',
        formatter: (model: any) => {
          return model.historyGame ? `+${model.historyGame.earnedPoint}` : 0;
        },
        labelStyle: (model: any) => `earned--${model.statusAssignment}`,
        width: 70,
        align: 'center'
      },
      {
        prop: 'statusAssignment',
        label: 'progress',
        width: 90,
        formatter: (model: any) => this.$t(model.statusAssignment),
        labelStyle: (model: any) => model.statusAssignment,
        align: 'center'
      }];
  }
  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'User management',
      path: userRouterPath.user
    },
    {
      label: 'User Details',
      path: '',
      current: true
    }
  ];
  public formatBirthday = formatBirthday;
  public data: any[] = [];
  public user: IUser;
  public activeTab: UserTab = UserTab.Assignment;
  public listCentre: any[];

  public get isStudent() {
    const role = this.user.role;

    return role === UserRole.Student;
  }
  public get isTeacher() {
    const role = this.user.role;

    return role === UserRole.Teacher;
  }
  public get isCentreManager() {
    const role = this.user.role;

    return role === UserRole.CentreManager;
  }

  public changeTab() {
    // this.activeTab = tab;
    // console.log(tab);
  }
  // public handleDetail(model: IAssignment) {
  //   this.assignmentDetail = model;
  //   this.visibleDetail = true;
  // }

  public grade(user: IUser, key: string) {
    const grade = <any> find(user.grade, (e: any) => e && e.type === key);

    if (!grade) {
      return '';
    }
    if (grade.grade === 0) {
      return 'DAN';
    }
    if (grade.grade === 14) {
      return 'Foundation';
    }

    if (key === 'ama') {
      return `${grade.grade.number} ${grade.grade.number > 1 ? 'numbers' : 'number'}
        ${grade.grade.digit} ${grade.grade.digit > 1 ? 'digits' : 'digit'}`;
    }

    return grade.grade;
  }

  public goEdit() {
    this.$router.push({
      name: userRouterName.userEdit,
      params: {
        userId: this.getUserId
      }
    });
  }

  public formaterPhone(model: IPhoneInput) {
    if (!model) {
      return '';
    }

    return `${model.code ? model.code : ''} ${model.number}`;
  }

  public formatCentreList(centreManagers: any[]) {
    if (!centreManagers) {
      return '__';
    }
    const centres = centreManagers.map((e) => {
      const current = find(this.listCentre, (centre) => centre.value === e);

      return current.name;
    });

    return centres.join('\n');
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeUser.GetUserById, this.getUserId);
    });
  }

  protected beforeDestroy() {
    this.$store.commit(MutationTypeAssignment.AssignmentPaginationReset);
    this.$store.commit(MutationTypeAssignment.AssignmentRefreshModels, []);
    this.$store.commit(MutationTypeUser.ClearUserDetail);
    this.$store.commit(MutationTypeProduct.TransactionClearData);
  }

}
const enum UserTab {
  Assignment = 'assignment',
  Redemption = 'redemption',
  Report = 'report',
  Game = 'game'
}
