import { RouteConfig } from 'vue-router';
import { ExaminationPageContainer } from './ExaminationPageContainer';
import { Generate } from './Generate';

export enum ExaminationRouterPath {
  Default = '/examination'
}

export const ExaminationRouter: RouteConfig = {
  path: ExaminationRouterPath.Default,
  component: ExaminationPageContainer,
  children: [
    {
      path: '',
      component: Generate
    }
  ]
};
