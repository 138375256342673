import { RouteConfig } from 'vue-router';
import { ExchangeRateContainer } from './ExchangeRateContainer';
import { ExchangeRateList } from './ExchangeRateList';

export enum ExchangeRateRouterPath {
  Default = '/exchange-rate'
}

export const ExchangeRateRouter: RouteConfig = {
  path: ExchangeRateRouterPath.Default,
  component: ExchangeRateContainer,
  children: [
    {
      path: '',
      component: ExchangeRateList
    }
  ]
};
