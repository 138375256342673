import { Form } from 'element-ui';
import { cloneDeep, pick } from 'lodash';
import { CMAHeader } from 'root/components';
import { compareObject, ruleRequired } from 'root/helpers';
import { exchangeRateFormDefault, ExchangeLayout, IBreadcrumb, IExchangeRate, IExchangeRateForm } from 'root/models';
import { IState } from 'root/store';
import { IPaginationResult } from 'root/store/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ExchangeHistory } from '../Components/ExchangeHistory';
import { ActionTypeExchangeRate, MutationTypeExchangeRate } from '../Store/types';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'cma-header': CMAHeader,
    'exchange-rate-history': ExchangeHistory
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.exchange.data,
      pagination: (state: IState) => state.exchange.pagination,
      loading: (state: IState) => state.exchange.loading,
      current: (state: IState) => state.exchange.current,
      layout: (state: IState) => state.exchange.layout
    }),
    isEditor() {
      return this.layout === ExchangeLayout.Editor;
    }
  }
})

export class ExchangeRateList extends Vue {
  public current: IExchangeRate;
  public pagination: IPaginationResult;
  public $refs: {
    form: Form
  };
  public breadcrumbs: IBreadcrumb[] = [
    {
      label: 'Home',
      path: '/'
    },
    {
      label: 'Exchange rate',
      path: '',
      current: true
    }
  ];
  public form: IExchangeRateForm = exchangeRateFormDefault();
  public rules: any = {
    required: ruleRequired()
  };

  public get disableSave(): boolean {
    const current = cloneDeep(this.current);

    return compareObject(
      {
        currency: this.form.currency.toString(),
        point: this.form.point.toString()
      },
      {
        currency: current.currency.toString(),
        point: current.point.toString()
      }
    );
  }

  public goEditRouter() {
    this.form = pick(cloneDeep(this.current), ['currency', 'point']);
    this.$store.commit(MutationTypeExchangeRate.ExchangeRateSetLayout, ExchangeLayout.Editor);
    window.scrollTo(0, 0);
  }

  public clickCancel() {
    this.$store.commit(MutationTypeExchangeRate.ExchangeRateSetLayout, ExchangeLayout.View);
  }
  public clickSave() {
    this.$refs.form.validate((valid) => {
      if (valid) {
        const form = cloneDeep(this.form);

        this.$store.dispatch(ActionTypeExchangeRate.ExchangeRateUpdateRate, {
          form,
          onSuccess: () => {
            this.$store.commit(MutationTypeExchangeRate.ExchangeRateSetLayout, ExchangeLayout.View);
            this.fetchData();
          },
          onFailure: () => {
            //
          }
        });

      }

      return;
    });
  }

  protected mounted() {
    this.$nextTick(() => {
      this.fetchData();
    });
  }

  protected beforeDestroy() {
    this.form = exchangeRateFormDefault();
    this.$store.commit(MutationTypeExchangeRate.ExchangeRateSetLayout, ExchangeLayout.View);
  }

  private fetchData() {
    this.$store.dispatch(ActionTypeExchangeRate.ExchangeRateGetData, {
      params: {
        page: 1
      }
    });
  }
}
