export enum MutationTypeLibrary {
  LoadingLibrary = 'loadingLibrary',
  SetLibraryDetail = 'SetLibraryDetail',
  SetLibraryQuestions = 'SetLibraryQuestions',
  SetLibraryQuestionsLoading = 'SetLibraryQuestionsLoading',
  ResetLibraryDetail = 'ResetLibraryDetail',
  LibraryPaginationReset = 'libraryPaginationReset',
  SetLibraryPagination = 'setLibraryPagination',
  LibraryFilterChange = 'libraryFilterChange',
  setLibraryAllCases = 'setLibraryAllCases'
}

export enum ActionTypeLibrary {
  LibraryUpdate = 'libraryUpdate',
  LibraryDelete = 'libraryDelete',
  LoadingLibrary = 'loadingLibrary',
  LibraryFilterNoCacheNoLoading = 'libraryFilterNoCacheNoLoading',
  LibraryCreate = 'libraryCreate',
  getLibraryId = 'getLibraryId',
  getLibraryQuestions = 'getLibraryQuestions',
  LibraryFilterNoCache = 'libraryFilterNoCache',
  getLibraryAllCases = 'getLibraryAllCases'
}

export enum GetterTypeLibrary {
  getLibraryById = 'getLibraryById'
}
